/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import {createElement} from 'react';
import LabelStore from '../stores/LabelStore';
import Label from '../components/Label.jsx';
import LabelGroup from '../components/LabelGroup.jsx';
import Icon from '../components/Icon.jsx';
import Button from '../components/Button.jsx';
import Badge from '../components/Badge.jsx';
import Tooltip from '../components/Tooltip.jsx';
import Vulnerability from '../components/Vulnerability.jsx';
import WorkloadUtils from './WorkloadUtils';
import DataFormatUtils from './DataFormatUtils';
import UserUtils from './UserUtils';
import PolicyGeneratorUtils from './PolicyGeneratorUtils';
import PortUtils from './PortUtils';
import ServiceUtils from './ServiceUtils';
import RenderUtils from './RenderUtils';
import actionCreators from '../actions/actionCreators';
import TrafficStore from '../stores/TrafficStore';
import IpListStore from '../stores/IpListStore';
import PolicyGeneratorPortProtocolDialog from '../modals/PolicyGeneratorPortProtocolDialog';
import ServicesDialog from '../modals/ServicesDialog';
import PolicyGeneratorAddressesDialog from '../modals/PolicyGeneratorAddressesDialog';
import PolicyGeneratorVulnerabilityDialog from '../modals/PolicyGeneratorVulnerabilityDialog';

function getServicePortsString(value) {
  if (value && value.length) {
    let services = '';

    if (value.length) {
      services = value.map(port => PortUtils.stringifyPortObjectReadonly(port)).join(', ');
    }

    if (services) {
      return services;
    }
  }

  return '';
}

function formatServicePorts(value) {
  const result = getServicePortsString(value);

  if (result) {
    return <span className="ServiceList-ports">{result}</span>;
  }

  return result;
}

function openPortProtocolDialog(row) {
  const {services} = row;
  // Todo: the sorting shouldn't have to happen again here
  const sortedServices = {};
  const sortServicesArr = Object.values(services);

  sortServicesArr.sort((a, b) => a.port - b.port);

  sortServicesArr.forEach(service => {
    sortedServices[`${service.port},${service.protocol}`] = service;
  });

  actionCreators.openDialog(<PolicyGeneratorPortProtocolDialog services={sortedServices} />);
}

function openAddressesDialog(addresses) {
  actionCreators.openDialog(<PolicyGeneratorAddressesDialog addresses={addresses} />);
}

function openVulnerabilityDialog(vulnerabilities) {
  actionCreators.openDialog(<PolicyGeneratorVulnerabilityDialog vulnerabilities={vulnerabilities} />);
}

function openServicesDialog(services) {
  actionCreators.openDialog(<ServicesDialog services={services} />);
}

function formatPolicyGeneratorGridIncExcRolesTitle(providerRoles, consumerRoles, providerConsumerOrder) {
  if (providerConsumerOrder === 'consumerFirst') {
    return `${consumerRoles} --> ${providerRoles}`;
  }

  return `${providerRoles} <-- ${consumerRoles}`;
}

export default {
  formatServicePorts,
  getServicePortsString,

  selectToggle(currentSelection, toggleSelection) {
    let allChecked = true;
    let newSelection = [];

    toggleSelection = Array.isArray(toggleSelection) ? toggleSelection : [toggleSelection];

    _.forEach(toggleSelection, selection => {
      const checked = currentSelection.includes(selection);

      if (!checked) {
        allChecked = false;

        return false;
      }
    });

    if (allChecked) {
      newSelection = _.filter(currentSelection, selection => !toggleSelection.includes(selection));
    } else {
      newSelection = _.union(currentSelection, toggleSelection);
    }

    return newSelection;
  },

  formatString(value) {
    return value;
  },

  formatNumeric(value) {
    return value;
  },

  formatDate(value) {
    return value ? intl.date(value, 'L_HH_mm_ss') : intl('Common.Never');
  },

  formatBoolean(value) {
    return value ? <Icon name="in-use" styleClass="Online" /> : null;
  },

  formatLabelLabel(column) {
    return column.label; // This helper may not be needed anymore, but keeping this in case we want to make global changes to this later
    // return (<Label icon={column.key} text={column.label} />);
  },

  formatVulnerability(value, row) {
    if (!value || (value.hasOwnProperty('num_vulnerabilities') && !value.num_vulnerabilities)) {
      return;
    }

    let policyState = value.policyState;

    if (row) {
      policyState = WorkloadUtils.policyState(row);
    }

    let vulnerabilityExposureScore = value.vulnerabilityExposureScore;
    let maxSeverity = value.maxSeverity;

    if (value.hasOwnProperty('vulnerability_exposure_score')) {
      vulnerabilityExposureScore =
        value.vulnerability_exposure_score === null ? null : value.vulnerability_exposure_score / 10;
    }

    if (value.hasOwnProperty('max_vulnerability_score')) {
      maxSeverity = value.max_vulnerability_score / 10;
    } else if (value.hasOwnProperty('severity')) {
      maxSeverity = value.severity;
    }

    if (value.maxSeverity === null) {
      return null;
    }

    return (
      <Vulnerability
        vulnerability={{
          wideExposure: value.vulnerable_port_wide_exposure || value.wideExposure,
          maxSeverity,
          ...RenderUtils.getEmptyExposures({...value, vulnerabilityExposureScore}, policyState),
        }}
      />
    );
  },

  sortVulnerability(a, b) {
    // Handle empty vulnerabilities
    if (!a && !b) {
      return 0;
    }

    if (!a) {
      return -1;
    }

    if (!b) {
      return 1;
    }

    // Choose the correct values to test
    const vesA = a.hasOwnProperty('vulnerabilityExposureScore')
      ? a.vulnerabilityExposureScore
      : a.vulnerability_exposure_score;
    const vesB = b.hasOwnProperty('vulnerabilityExposureScore')
      ? b.vulnerabilityExposureScore
      : b.vulnerability_exposure_score;
    const severityA = a.hasOwnProperty('severity') ? a.severity : a.max_vulnerability_score;
    const severityB = b.hasOwnProperty('severity') ? b.severity : b.max_vulnerability_score;
    const wideA = a.wideExposure && (a.wideExposure === true || a.wideExposure.internet || a.wideExposure.ip_list);

    // If the VES scores are the same, use the severity
    if (vesA === vesB) {
      // If the severity is also the same use the wide exposure
      if (severityA === severityB) {
        return wideA ? 1 : -1;
      }

      return severityA - severityB;
    }

    if (vesA === null) {
      return -1;
    }

    if (vesB === null) {
      return 1;
    }

    return vesA - vesB;
  },

  formatVulnerabilityTraffic(value) {
    if (!value) {
      return intl('Common.None');
    }

    if (value === intl('Common.Loading')) {
      return;
    }

    const policyDecision = Object.entries(value).reduce((result, traffic) => {
      // Traffic[0] - Traffic href of the consuming traffic
      // Traffic[1] - Array of Connection keys which will all be the same port so use the first
      const trafficData = TrafficStore.getTraffic(traffic[0]);
      const connection = trafficData && trafficData.connections[traffic[1][0]];

      if (connection.allowed || result === 'allowed') {
        return 'allowed';
      }

      if (connection.potentiallyBlocked) {
        return 'potentiallyBlocked';
      }

      return 'blocked';
    }, 'none');

    if (policyDecision === 'allowed') {
      return <div className="Explorer-allowed">{intl('Common.Allowed')}</div>;
    }

    if (policyDecision === 'potentiallyBlocked') {
      return <div className="Explorer-potentially-blocked">{intl('Common.PotentiallyBlocked')}</div>;
    }

    if (policyDecision === 'blocked') {
      return <div className="Explorer-blocked">{intl('Common.Blocked')}</div>;
    }

    return intl('Common.None');
  },

  formatPortProtocol(row, force) {
    if (!row.protocol) {
      if (force) {
        return row.port;
      }

      return null;
    }

    const protocol = ServiceUtils.lookupProtocol(row.protocol);

    return row.port ? `${row.port} ${protocol}` : row.protocol ? protocol : null;
  },

  formatScopeToText(value) {
    const types = ['app', 'env', 'loc'];

    if (value.length) {
      const labels = value.map(label => LabelStore.getSpecified(label.href));

      return (
        labels[0] &&
        types
          .map(type => {
            const currLabel = labels.find(label => label && label.key === type);
            const text = currLabel
              ? UserUtils.getScopeLabelText(currLabel, type)
              : UserUtils.getScopeLabelText(null, type);

            return text;
          })
          .join(' | ')
      );
    }

    return `${intl('Common.AllApplications')} | ${intl('Common.AllEnvironments')} | ${intl('Common.AllLocations')}`;
  },

  formatUserActivityStatus(value) {
    let statusIcon;

    switch (value) {
      case intl('Common.Online'):
      case intl('Workloads.Status.Offline'):
        statusIcon = <Icon name="user" />;
        break;
      case intl('Common.Invited'):
        statusIcon = <Icon name="email" />;
        break;
      case intl('Common.Locked'):
        statusIcon = <Icon name="lock" />;
        break;
    }

    const classString = `UserActivityStatus UserActivityStatus--${value.toLowerCase()}`;

    return (
      <div>
        <div className={classString}>{statusIcon}</div>
        <span className="UserActivityStatus-text" data-tid="elem-text">
          {value}
        </span>
      </div>
    );
  },

  //Format a scope which is either an empty array for ALL ALL ALL or a list of three label hrefs
  formatScope(value) {
    const types = ['app', 'env', 'loc'];

    if (value.length) {
      const labels = value.map(label => LabelStore.getSpecified(label.href));

      return (
        labels[0] &&
        types.map(type => {
          const currLabel = labels.find(label => label && label.key === type);
          const text = currLabel
            ? UserUtils.getScopeLabelText(currLabel, type)
            : UserUtils.getScopeLabelText(null, type);

          return (
            <span key={type} className="RBACScopeLabel">
              <Label text={text} type={type} />
            </span>
          );
        })
      );
    }

    return types.map(type => {
      const text = UserUtils.getScopeLabelText(null, type);

      return (
        <span key={type} className="RBACScopeLabel">
          <Label text={text} type={type} />
        </span>
      );
    });
  },

  formatRole(value) {
    return (
      <div className="RBACScopesRolesList-roleCell">
        <div className="RBACScopesRolesList-roleName">{UserUtils.getFormattedRoleFromHref(value.href)}</div>
      </div>
    );
  },

  roleSort(a, b) {
    const roleOrder = UserUtils.ROLES;

    if (
      a.defaultReadOnly ||
      roleOrder.indexOf(_.last(a.href.split('/'))) > roleOrder.indexOf(_.last(b.href.split('/')))
    ) {
      return 1;
    }

    if (
      b.defaultReadOnly ||
      roleOrder.indexOf(_.last(a.href.split('/'))) < roleOrder.indexOf(_.last(b.href.split('/')))
    ) {
      return -1;
    }

    if (roleOrder.indexOf(_.last(a.href.split('/'))) === roleOrder.indexOf(_.last(b.href.split('/')))) {
      return 0;
    }
  },

  formatRoles(value) {
    //This is a function that sorts a list of roleHrefs by a certain order from UserUtils.ROLES;
    //The order is: owner, admin, read_only, ruleset_manager, limited_ruleset_manager, ruleset_provisioner, global_object_provisioner

    return value
      .sort(this.roleSort)
      .map(role =>
        role.defaultReadOnly ? intl('RBAC.DefaultReadOnly') : UserUtils.getFormattedRoleFromHref(role.href),
      )
      .join(', ');
  },

  formatWorkloadState(value, row) {
    if (!row.agent || !row.agent.status) {
      return <span data-tid="elem-text">{intl('Common.Unmanaged')}</span>;
    }

    const policyState = WorkloadUtils.policyState(row);
    const policyContent = WorkloadUtils.policyStateContent(policyState, row.agent.config.visibility_level);

    return [
      policyContent.iconName ? (
        <Icon name={policyContent.iconName} styleClass={policyContent.iconStyle} position="before" />
      ) : null,
      <span data-tid="elem-text">
        {policyContent.title + (policyContent.visibilityLevel ? ': ' + policyContent.visibilityLevel : '')}
      </span>,
    ];
  },

  formatWorkloadStateTitle(value, row) {
    if (!row.agent || !row.agent.status) {
      // Don't show anything if the workload is Unmanaged
      return null;
    }

    const policyState = WorkloadUtils.policyState(row);
    const policyContent = WorkloadUtils.policyStateContent(policyState, row.agent.config.visibility_level);

    return <span data-tid="elem-text">{policyContent.title}</span>;
  },

  workloadStateSortValue(value, row) {
    if (!row.agent.status) {
      return '';
    }

    const policyState = WorkloadUtils.policyState(row);
    const policyContent = WorkloadUtils.policyStateContent(policyState, row.agent.config.visibility_level);

    return policyContent.title;
  },

  formatVirtualServerState(value, row) {
    const policyState = DataFormatUtils.virtualServers.getFriendlyMode(row.mode);

    return [
      <Icon
        name={row.mode}
        styleClass={row.mode === 'enforced' ? intl('Common.Enforced') : intl('Common.Unmanaged')}
        position="before"
      />,
      policyState,
    ];
  },

  formatReorderRulesGrabHandle() {
    //Size of grab-handle icon is a little weird so font-size is set using grabHandle styleClass
    return <Icon styleClass="grabHandle" name="grab-handle" />;
  },

  sortStringValue(value) {
    return value && value.toLowerCase ? value.toLowerCase() : '';
  },

  sortRolesValue(value) {
    return value.map(role => role.href && UserUtils.ROLE_SORT_VALUES[UserUtils.getRoleFromHref(role.href)]).join('');
  },

  sortNumberValue(value) {
    const int = _.parseInt(value);

    return _.isNaN(int) ? 0 : int;
  },

  formatLabelValue(value, row, column) {
    if (!row.labels || !row.labels.length) {
      return null;
    }

    const matchingLabel = _.find(row.labels, l => l.key === column.key);

    if (!matchingLabel) {
      return null;
    }

    return <Label type={column.key} text={matchingLabel.value} />;
  },

  formatLabelValueOld(value, row, column) {
    let foundLabelExpanded;

    row.labels.some(label => {
      const expandedLabel = LabelStore.getSpecified(label.href);

      if (expandedLabel && expandedLabel.key === column.key) {
        foundLabelExpanded = expandedLabel;

        return true;
      }

      return false;
    });

    if (foundLabelExpanded) {
      return <Label type={column.key} text={foundLabelExpanded.value} />;
    }
  },

  formatLabelAsComponent(value) {
    if (value) {
      return <Label type={value.key} text={value.value} />;
    }
  },

  formatUpdatedType(updateType, version) {
    let badgeType;
    let badgeLabel;
    const draft = !version || version === 'draft';

    switch (updateType) {
      case 'create':
        badgeType = 'created';
        badgeLabel = draft ? intl('Rulesets.Rules.AdditionPending') : intl('Rulesets.Rules.Added');
        break;
      case 'update':
        badgeType = 'updated';
        badgeLabel = draft ? intl('Rulesets.Rules.ModifiedPending') : intl('Rulesets.Rules.Modified');
        break;
      case 'delete':
        badgeType = 'deleted';
        badgeLabel = draft ? intl('Rulesets.Rules.DeletionPending') : intl('Rulesets.Rules.Deleted');
        break;
    }

    return badgeType ? <Badge type={badgeType}>{badgeLabel}</Badge> : null;
  },

  sortUpdatedType(updateType, version) {
    const draft = !version || version === 'draft';

    switch (updateType) {
      case 'create':
        return draft ? intl('Rulesets.Rules.AdditionPending') : intl('Rulesets.Rules.Added');
      case 'update':
        return draft ? intl('Rulesets.Rules.ModifiedPending') : intl('Rulesets.Rules.Modified');
      case 'delete':
        return draft ? intl('Rulesets.Rules.DeletionPending') : intl('Rulesets.Rules.Deleted');
    }
  },

  formatWorkloadHealth(agent, value) {
    if (!agent) {
      return <span className="elem-value">{value}</span>;
    }

    return [
      WorkloadUtils.workloadHealthTag(agent, {badgeOnlyOnNotOk: true}),
      <span className="elem-value">{value}</span>,
    ];
  },

  labelSortValue(value, row, column) {
    let foundLabelExpanded;

    row.labels.some(label => {
      const expandedLabel = LabelStore.getSpecified(label.href);

      if (expandedLabel && expandedLabel.key === column.key) {
        foundLabelExpanded = expandedLabel;

        return true;
      }

      return false;
    });

    if (foundLabelExpanded) {
      return foundLabelExpanded.value.toLowerCase();
    }
  },

  labelSortExpandedValue(value, row, column) {
    if (!row.labels || !row.labels.length) {
      return '';
    }

    const matchingLabel = _.find(row.labels, l => l.key === column.key);

    return matchingLabel && matchingLabel.value ? matchingLabel.value : '';
  },

  getLabelWithIcon(label) {
    if (!label) {
      return null;
    }

    const labelTypeString = this.getLabelTypeString(label);

    return this.formatLabelLabel({key: label.key, label: labelTypeString});
  },

  getLabelTypeString(label, typesArray) {
    if (!label) {
      return null;
    }

    const types = typesArray || [
      {key: 'role', value: intl('Common.Role')},
      {key: 'app', value: intl('Common.Application')},
      {key: 'env', value: intl('Common.Environment')},
      {key: 'loc', value: intl('Common.Location')},
    ];

    const findLabelType = type => type.key === label.key;
    const name = _.find(types, findLabelType).value;

    return name;
  },

  // Takes id from last segment of href
  // If string is empty or last segment is empty, return undefined so default parameter usage on caller side is possible
  // https://jsperf.com/last-string-segment
  getIdFromHref: href => (href && href.substr(href.lastIndexOf('/') + 1)) || undefined,

  getRowValue(row, key) {
    let value = null;

    if (key && key.split) {
      value = key.split('.').reduce((prev, cur) => prev && prev[cur], row);
    }

    return value;
  },

  // Keep legacy service = {} and add ingress_services = [] for services and port/protocols
  getRuleIngressService(service) {
    let services;

    if (service.service_ports || service.windows_services) {
      // legacy 'service = {}'
      services = this.getServicePortsString(service.service_ports || service.windows_services);
    } else if (Array.isArray(service)) {
      // ingress_services = []
      services = service.reduce((items, curItem) => {
        if (!curItem) {
          return items;
        }

        let text;

        if (curItem.service_ports || curItem.windows_services) {
          text = this.getServicePortsString(curItem.service_ports || curItem.windows_services);
        } else {
          text = PortUtils.stringifyPortObjectReadonly(curItem, true);
        }

        items.push(<Label text={text} />);

        return items;
      }, []);
    }

    return services;
  },

  formatServiceOsPorts(value, row) {
    if (row.service_ports) {
      return formatServicePorts(row.service_ports);
    }

    if (row.windows_services) {
      return formatServicePorts(row.windows_services);
    }
  },

  formatAppGroupTrafficService(value, row, extra, config, arrowDirection) {
    const servicesKeys = Object.keys(row.services);
    //Only display the first four services. After that the list is truncated
    const included =
      row.rulesetInclusion.included &&
      (config === 'ringfencing' ||
        (!extra && row.provider.length && row.consumer.length) ||
        (extra &&
          (config.level === 'appgroup' ||
            (row.provider.find(p => p.label.key === 'role') && row.consumer.find(c => c.label.key === 'role')))));
    const servicesForSort = [];

    servicesKeys.forEach(serviceKey => {
      Object.keys(row.services[serviceKey].class).forEach(connectionClass => {
        servicesForSort.push({
          connectionClass,
          port: row.services[serviceKey].port,
          protocol: ServiceUtils.lookupProtocol(row.services[serviceKey].protocol),
          service: row.services[serviceKey].service,
        });
      });
    });

    servicesForSort.sort((a, b) => a.port - b.port);

    const displayedServices = servicesForSort.slice(0, 4);
    let allServices = null;

    if (
      config === 'ringfencing' ||
      config === 'tiertotier' ||
      (extra && config.type === 'tiertotier') ||
      config === 'psuedoRingfencing'
    ) {
      allServices = <div className="PolicyGeneratorGrid-All">{intl('Common.AllServices')}</div>;
    }

    const services = displayedServices.map(service => (
      <div className="PolicyGeneratorGrid-serviceName">
        {service.connectionClass !== 'U' ? (
          <Icon
            position="before"
            size="large"
            styleClass=""
            name={RenderUtils.getTransmissionModeIcon(service.connectionClass)}
          />
        ) : null}
        {[service.protocol.includes('ICMP') ? '' : service.port, service.protocol, service.service].join(' ')}
      </div>
    ));

    //If there are more than four services, display a link for '+ X More' which leads to a dialog with all the services
    const portProtocolModalLink = row.serviceCount > 4 && (
      <div className="PolicyGeneratorGrid-port-protocol-link">
        <a onClick={_.partial(openPortProtocolDialog, row)} data-tid="elem-link-version">
          {row.missingServices
            ? intl('PolicyGenerator.Grid.More')
            : intl('PolicyGenerator.Grid.MorePortProtocol', {numPortsProtocols: row.serviceCount - 4})}
        </a>
      </div>
    );

    const arrowClass = cx(
      'PolicyGeneratorGrid-arrow-bar',
      included ? 'PolicyGeneratorGrid-arrow--allowed' : 'PolicyGeneratorGrid-arrow--blocked',
    );

    if (arrowDirection) {
      return (
        <span className={arrowClass}>
          <Icon name={`arrow-${arrowDirection}-long`} size="xlarge" />
        </span>
      );
    }

    return (
      <div>
        {allServices}
        {services}
        {portProtocolModalLink}
      </div>
    );
  },

  sortValueAppGroupTrafficService(value, row) {
    const servicesKeys = Object.keys(row.services);
    //Only display the first four services. After that the list is truncated
    const displayedServices = servicesKeys.slice(0, 4);
    const servicesForSort = [];

    _.forEach(displayedServices, serviceKey => {
      servicesForSort.push(row.services[serviceKey].port);
    });

    servicesForSort.sort((a, b) => a - b);

    return servicesForSort[0];
  },

  formatBlockedTrafficService(value, row, showIndicator) {
    const service = (
      <div className="BlockedTrafficList-serviceName">
        {`${value} ${ServiceUtils.getPort(row) || ''} ${ServiceUtils.lookupProtocol(row.protocol)}`}
      </div>
    );

    const blocked = row.flow_status.indexOf('potentially') !== 0;
    const atSource = row.flow_status.indexOf('src') > 0;

    const arrowBarClasses = cx('BlockedTrafficList-arrow-bar', {
      'BlockedTrafficList-arrow-bar-blocked': blocked,
      'BlockedTrafficList-arrow-bar-warning': !blocked,
    });

    const arrowHeadClasses = cx('BlockedTrafficList-arrow-head', {
      'BlockedTrafficList-arrow-head-blocked': blocked,
      'BlockedTrafficList-arrow-head-warning': !blocked,
    });

    const empty = <Icon name="p-blocked" styleClass="BlockedTrafficList-empty" />;
    let indicator = <Icon name="p-blocked" styleClass="BlockedTrafficList-warning" />;

    if (blocked) {
      indicator = <Icon name="blocked" styleClass="BlockedTrafficList-blocked" />;
    }

    return (
      <div className="BlockedTrafficList-service">
        {!atSource && showIndicator ? indicator : empty}
        <div>
          {service}
          <div className="BlockedTrafficList-arrow">
            <span className={arrowHeadClasses} />
            <span className={arrowBarClasses} />
          </div>
        </div>
        {atSource && showIndicator ? indicator : empty}
      </div>
    );
  },

  formatBlockedTrafficEndpoint(value, row, handleIpAddress) {
    let name = intl('Common.Internet');

    if (value.workloads) {
      name = value.workloads[0].name;
    } else if (value.virtual_servers) {
      name = value.virtual_servers[0].name;
    } else if (value.ip_lists) {
      name = value.ip_lists[0].name;
    }

    const reporter =
      (row.flow_status.indexOf('src') > 0 && _.isEqual(value, row.source)) ||
      (row.flow_status.indexOf('dst') > 0 && _.isEqual(value, row.destination));

    const classes = cx({
      'BlockedTrafficList-endpoint': true,
      'BlockedTrafficList-endpoint-non-reporter': !reporter,
    });

    const linkClasses = cx({
      'BlockedTraffic-ipaddress': true,
      'BlockedTraffic-link': !value.workloads && !value.virtual_servers,
    });

    return (
      <span>
        <div className={classes}>{name}</div>
        <div className={classes}>
          <div className={linkClasses} onClick={evt => handleIpAddress(value, evt)}>
            {value.ip_address}
          </div>
        </div>
        <div className={classes} />
      </span>
    );
  },

  formatBlockedTrafficDetail(value) {
    let description = null;
    const type =
      value.indexOf('potentially') === 0 ? (
        <div className="BlockedTrafficList-detail BlockedTrafficList-allowed">{intl('Common.PotentiallyBlocked')}</div>
      ) : (
        <div className="BlockedTrafficList-detail BlockedTrafficList-blocked">{intl('Common.Blocked')}</div>
      );

    switch (value) {
      case 'blocked_on_src':
      case 'potentially_blocked_on_src':
        description = intl('BlockedTraffic.List.ByTheConsumer');
        break;
      case 'blocked_on_dst':
      case 'potentially_blocked_on_dst':
        description = intl('BlockedTraffic.List.ByTheProvider');
        break;
      default:
        description = '';
    }

    return (
      <div>
        {type}
        <div className="BlockedTrafficList-by">{description}</div>
      </div>
    );
  },

  /**
   * Function to get the current notifications for a particular list page. Used with the NotificationGroup component
   *
   * @param options   Object of various provided parameter for generating the notifications
   * @returns object  Notification objects for NotificationGroup component
   */
  getNotifications(options) {
    // Currently this function only generates End of Data Set notification

    // These are the required variables to check whether to show the End of Data Set
    const {
      totalRows,
      isFiltered,
      page,
      count: {matched, total},
    } = options;

    // If the user has applied filters from the picker, the actual total is the matched count,
    // otherwise; the complete total
    const actualTotal = isFiltered ? matched : total;

    // Add all the notifications to this array
    const notifications = [];

    // Check if the total available rows are 500 (API maximum) but the actual total is more than 500
    // and the user is on the last page
    if (parseInt(totalRows, 10) === 500 && parseInt(actualTotal, 10) > 500 && parseInt(page, 10) === 10) {
      notifications.push({
        type: 'instruction',
        title: intl('Common.EndOfData'),
        message: intl('Common.PCEMaxDisplay', {count: 500}),
      });
    }

    return notifications;
  },

  formatPolicyGeneratorChoose(row) {
    if (
      row.name === intl('PolicyGenerator.MissingLabels') || row.type === 'append'
        ? row.connectionsWithRules === Number(row.connections.split(' ')[0])
        : row.type === 'replace' && row.connectionsWithRulesReplace === Number(row.connections.split(' ')[0])
    ) {
      return 'Grid-row--disabled';
    }
  },

  formatStatusRowClass(row) {
    if (row.update_type) {
      switch (row.update_type) {
        case 'delete':
          return 'Grid-row--deleted';
        case 'create':
          return 'Grid-row--created';
        default:
          return 'Grid-row--' + row.update_type;
      }
    } else if (row.deleted) {
      return 'Grid-row--deleted';
    } else if (row.created) {
      return 'Grid-row--created';
    }
  },

  areRolesMissing(type, config, roles) {
    return (
      (type === 'extra' &&
        config.level === 'role' &&
        (!roles.provider.some(p => p.label.key === 'role') || !roles.consumer.some(c => c.label.key === 'role'))) ||
      (type !== 'extra' && config !== 'ringfencing' && (!roles.provider.length || !roles.consumer.length))
    );
  },

  formatRulesetInclusion(data, appGroup, onRowToggle, config, type, singleRow, providerConsumerOrder) {
    const {roles, connections, sessions, key, portProtocol, vulnerabilities} = data;
    let {included} = data;
    let missingEndpoint = false;

    if (this.areRolesMissing(type, config, roles)) {
      missingEndpoint = true;
      included = false;
    }

    const disabled = (singleRow && included) || missingEndpoint;
    const switchClass = cx('PolicyGeneratorGrid-inc-exc-switch');
    const switchClick = !disabled && _.partial(onRowToggle, key);
    const disabledClass = cx({
      'PolicyGeneratorGrid-inc-exc-switch--single': true,
      'PolicyGeneratorGrid-inc-active': !missingEndpoint,
      'PolicyGeneratorGrid-exc-active': missingEndpoint,
    });

    let truncAppGroupName = '';
    let truncRoles = '';
    let providerRoles = '';
    let consumerRoles = '';

    if (appGroup && connections) {
      //Depending on whether appGroup has 2 labels or 3 labels, have two different truncation limits.
      truncAppGroupName =
        appGroup.split('|').length > 2
          ? RenderUtils.truncateAppGroupName(appGroup, 28, [16, 6, 6])
          : RenderUtils.truncateAppGroupName(appGroup, 20, [10, 10, 10]);
      providerRoles =
        roles.provider[0] &&
        (roles.provider[0].name ||
          (roles.provider[0].ip_list && roles.provider[0].ip_list.name) ||
          roles.provider[0].label.value);
      consumerRoles =
        roles.consumer[0] &&
        (roles.consumer[0].name ||
          (roles.consumer[0].ip_list && roles.consumer[0].ip_list.name) ||
          roles.consumer[0].label.value);
      truncRoles = PolicyGeneratorUtils.truncateProviderConsumerLink(
        providerRoles || '',
        consumerRoles || '',
        12,
        12,
        providerConsumerOrder,
        included,
      );
    }

    let showRoles = config === 'tiertotier';
    let showAppGroup;
    let showPortProtocol;

    if (type === 'extra') {
      showRoles = config.level && config.type && config.level === 'role' && config.type === 'tiertotier';
      showAppGroup = config.type && config.type === 'tiertotier';
      showPortProtocol =
        config.level && config.type && config.level === 'appgroup' && config.type === 'microsegmentation';
    }

    let maxSeverity = 0;

    const openVulnerabilities = Object.values(vulnerabilities)
      .flat()
      .sort((a, b) => b.severity - a.severity)
      .reduce((result, vulnerability) => {
        const {port, protocol, details} = vulnerability;

        result.push(
          <div className="PolicyGeneratorGrid-inc-exc-vulnerability">
            <Vulnerability vulnerability={vulnerability} list />
            <span className="PolicyGeneratorGrid-inc-exc-vulnerability-port">
              {`${port} ${ServiceUtils.lookupProtocol(protocol)} `}
            </span>
            <span className="PolicyGeneratorGrid-inc-exc-vulnerability-title">{String(details.name)}</span>
          </div>,
        );

        maxSeverity = Math.max(maxSeverity, RenderUtils.getVulnerabilityForRender(vulnerability.severity, 'key'));

        return result;
      }, []);

    let switchComponent = (
      <div className={switchClass}>
        <div
          className={included ? 'PolicyGeneratorGrid-inc-active' : 'PolicyGeneratorGrid-inc'}
          onClick={included ? null : switchClick}
          data-tid="rule-include"
        >
          {intl('Common.Include')}
        </div>
        <div
          className={!included ? 'PolicyGeneratorGrid-exc-active' : 'PolicyGeneratorGrid-exc'}
          onClick={included ? switchClick : null}
          data-tid="rule-exclude"
        >
          {intl('Common.Exclude')}
        </div>
      </div>
    );

    if (disabled) {
      switchComponent = (
        <div className={switchClass}>
          <div className={disabledClass} data-tid={included ? 'rule-include' : 'rule-exclude'}>
            {included ? intl('PolicyGenerator.Included') : intl('PolicyGenerator.Excluded')}
          </div>
        </div>
      );
    }

    if (missingEndpoint) {
      switchComponent = (
        <div className="PolicyGeneratorGrid-missingRole">{intl('PolicyGenerator.MustHaveRoleLabel')}</div>
      );
    }

    return (
      <div className="PolicyGeneratorGrid-inc-exc">
        <div>
          {intl('PolicyGenerator.Grid.Connections', {count: connections}) +
            ' - ' +
            intl('PolicyGenerator.Grid.Sessions', {count: sessions})}
        </div>
        <div
          title={
            showRoles && formatPolicyGeneratorGridIncExcRolesTitle(providerRoles, consumerRoles, providerConsumerOrder)
          }
          className="PolicyGeneratorGrid-inc-exc-roles"
        >
          {showRoles && truncRoles}
        </div>
        <div title={showAppGroup && appGroup} className="PolicyGeneratorGrid-inc-exc-app-group">
          {showAppGroup && truncAppGroupName}
        </div>
        <div className="PolicyGeneratorGrid-inc-exc-port-protocol">
          {showPortProtocol && `${portProtocol.port} ${ServiceUtils.lookupProtocol(portProtocol.protocol)}`}
        </div>
        {openVulnerabilities && Boolean(openVulnerabilities.length) && (
          <div className="PolicyGeneratorGrid-inc-exc-vulnerabilities">
            {intl('PolicyGenerator.Grid.ExposedVulnerabilityCount', {count: openVulnerabilities.length})}
            {openVulnerabilities.length > 15 ? (
              <span
                className="PolicyGeneratorGrid-addresses"
                onClick={_.partial(openVulnerabilityDialog, openVulnerabilities)}
              >
                <Tooltip instant content={intl('PolicyGenerator.ClickToViewVulnerabilites')} width={200}>
                  <span
                    className={`PolicyGeneratorGrid-inc-exc-severity-icon PolicyGeneratorGrid-inc-exc-severity-icon-${maxSeverity}`}
                  >
                    <Icon size="medium" name="info" />
                  </span>
                </Tooltip>
              </span>
            ) : (
              <Tooltip instant content={openVulnerabilities} width={450}>
                <span
                  className={`PolicyGeneratorGrid-inc-exc-severity-icon PolicyGeneratorGrid-inc-exc-severity-icon-${maxSeverity}`}
                >
                  <Icon size="medium" name="info" />
                </span>
              </Tooltip>
            )}
          </div>
        )}
        {switchComponent}
      </div>
    );
  },

  formatAppGroupEntities(tableEntities, extra, level, addresses, type, fqdnType) {
    const entities = _.cloneDeep(tableEntities);
    const other = [];
    const role = [];
    const group = [];

    if (
      (!extra && !entities.length) ||
      (extra && level === 'role' && !entities.some(entity => entity.label.key === 'role')) ||
      (extra && level === 'appGroup' && _.isEmpty(entities))
    ) {
      entities.push({
        label: {
          key: 'role',
          value: intl('Common.NoRole'),
        },
      });
    }

    entities.forEach(entity => {
      if (entity.label) {
        switch (entity.label.key) {
          case 'role':
            role.push(entity);
            break;
          case 'app':
            group.push(entity);
            break;
          case 'env':
            group.push(entity);
            break;
          case 'loc':
            group.push(entity);
            break;
          default:
            break;
        }
      } else {
        other.push(entity);
      }
    });

    const anyIpList = IpListStore.getAnyIpList() && IpListStore.getAnyIpList().href;

    return (
      <div className="PolicyGeneratorGrid-Entity">
        {level === 'ringfencing' || level === 'appgroup' || (level === 'psuedoRingfencing' && type === 'consumer') ? (
          <div className="PolicyGeneratorGrid-All">{intl('Workloads.All')}</div>
        ) : null}
        {[other, role, group].map(type => (
          <div className="PolicyGeneratorGrid-labelRow">
            {type.map(entity => (
              <div className="PolicyGeneratorGrid-label">
                {fqdnType === 'fqdn' && entity.ip_list && RenderUtils.isHrefFqdn(entity.ip_list.href) ? (
                  entity.ip_list && entity.ip_list.name
                ) : (
                  <Label
                    icon={
                      entity.ip_list
                        ? fqdnType === 'fqdnIpList' && anyIpList !== entity.ip_list.href
                          ? 'domain'
                          : 'iplist'
                        : null
                    }
                    key={entity.href || (entity.ip_list && entity.ip_list.href) || entity.label.href}
                    type={entity.label && entity.label.key}
                    text={entity.name || (entity.ip_list && entity.ip_list.name) || entity.label.value}
                  />
                )}
                {entity.ip_list && addresses && addresses[entity.ip_list.href] ? (
                  addresses[entity.ip_list.href].length > 10 ? (
                    <span
                      className="PolicyGeneratorGrid-addresses"
                      onClick={_.partial(openAddressesDialog, addresses[entity.ip_list.href])}
                    >
                      <Tooltip content={intl('PolicyGenerator.ClickToViewAddresses')} width={200}>
                        <Icon styleClass="IpAddresses" size="medium" name="ip-address" />
                      </Tooltip>
                    </span>
                  ) : (
                    <span className="PolicyGeneratorGrid-addresses">
                      <Tooltip
                        content={addresses[entity.ip_list.href].map(address => (
                          <div className="PolicyGeneratorGrid-tooltip-address">{address}</div>
                        ))}
                        width={200}
                      >
                        <Icon styleClass="IpAddresses" size="medium" name="ip-address" />
                      </Tooltip>
                    </span>
                  )
                ) : null}
              </div>
            ))}
          </div>
        ))}
        <div className="PolicyGeneratorGrid-workload-button">
          <Button size="small" text="View Workloads" />
        </div>
      </div>
    );
  },

  sortRuleEntitiesValue(entities) {
    if (!entities) {
      return '';
    }

    const result = [];

    entities.forEach(entity => {
      let text = '';

      if (entity.actors) {
        switch (entity.actors) {
          case 'all':
            text = intl('IPLists.Any');
            break;
          case 'ams':
            text = intl('Workloads.All');
            break;
          case 'container_host':
            text = intl('Common.ContainerHost');
            break;
        }
      } else if (entity.label) {
        text = entity.label.value;
      } else if (entity.label_group) {
        text = entity.label_group.name;
      } else if (entity.ip_list) {
        text = entity.ip_list.name;
      } else if (entity.workload) {
        text = WorkloadUtils.friendlyName(entity.workload);
      } else if (entity.virtual_service) {
        text = entity.virtual_service.name;
      } else if (entity.virtual_server) {
        text = entity.virtual_server.name;
      }

      result.push(text);
    });

    return result.join(',').toLocaleLowerCase();
  },

  formatRuleEntities(entities, row = {}, showUserGroups) {
    const result = [];
    const usageEntities = [];

    entities.forEach(entity => {
      const props = {};

      if (entity.actors) {
        if (entity.actors?.allWorkloads === true) {
          props.key = 'ams';
          props.text = intl('Workloads.All');
          props.icon = 'all-workloads';
        }

        switch (entity.actors) {
          case 'all':
            props.key = 'all';
            props.text = intl('IPLists.Any');
            props.icon = 'ip-list';
            break;
          case 'ams':
            props.key = 'ams';
            props.text = intl('Workloads.All');
            props.icon = 'all-workloads';
            break;
          case 'container_host':
            props.key = 'container_host';
            props.text = intl('Common.ContainerHost');
            props.icon = 'all-workloads';
            break;
        }
      } else if (entity.label) {
        props.key = entity.label.href;
        props.text = entity.label.value;
        props.type = entity.label.key;
        props.exclusion = entity.exclusion;
      } else if (entity.label_group) {
        props.key = entity.label_group.href;
        props.text = entity.label_group.name;
        props.type = entity.label_group.key;
        props.exclusion = entity.exclusion;
      } else if (entity.ip_list) {
        props.key = entity.ip_list.href;
        props.text = entity.ip_list.name;
        props.icon = entity.ip_list.fqdn ? 'domain' : 'ip-list';

        if (entity.ip_list.new) {
          result.push(
            <div className="PolicyGeneratorGrid-label">
              <Badge type="new" />
              <span key={props.key}>{createElement(entity.label_group ? LabelGroup : Label, props)}</span>
            </div>,
          );

          return;
        }
      } else if (entity.workload) {
        props.text = WorkloadUtils.friendlyName(entity.workload);
        props.key = entity.workload.href;
        props.icon = 'workload';

        if (entity.workload.deleted) {
          props.type = 'disabled';
        }
      } else if (entity.virtual_service) {
        props.key = entity.virtual_service.href;
        props.text = entity.virtual_service.name;
        props.icon = 'virtual-service';
      } else if (entity.virtual_server) {
        props.key = entity.virtual_server.href;
        props.text = entity.virtual_server.name;
        props.icon = 'virtual-server';
      } else if (
        entity.usesVirtualServicesWorkloads ||
        entity.usesVirtualServices ||
        entity.useWorkloadSubnets ||
        entity.extraScope
      ) {
        usageEntities.push(entity);

        return;
      }

      if (entity.created) {
        props.type = 'created';
      }

      if (entity.deleted) {
        props.type = 'deleted';
      }

      if (entity.workload && entity.workload.deleted) {
        props.type = 'disabled';
      }

      result.push(
        <span className="PolicyGeneratorGrid-label" key={props.key}>
          {createElement(entity.label_group ? LabelGroup : Label, props)}
        </span>,
      );
    });

    if (showUserGroups) {
      const userGroups = row.consuming_security_principals;

      userGroups.forEach(userGroup => {
        let type;

        if (userGroup.created) {
          type = 'created';
        } else if (userGroup.deleted) {
          type = 'deleted';
        } else {
          type = 'usergroup';
        }

        result.push(<Label type={type} icon="user-group" text={userGroup.name} />);
      });
    }

    if (usageEntities.length) {
      usageEntities.forEach(entity => {
        let className = 'EntityVirtualServices';
        let string;

        if (entity.usesVirtualServices) {
          string = intl('Common.UsesVirtualServices');
        } else {
          string = intl('Common.UsesVirtualServicesWorkloads');
        }

        if (entity.useWorkloadSubnets) {
          string = intl('Rulesets.Rules.UseWorkloadSubnets');
        }

        if (entity.created) {
          className += ' EntityVirtualServices--created';
        }

        if (entity.deleted) {
          className += ' EntityVirtualServices--deleted';
        }

        if (entity.extraScope) {
          string = intl('Map.ExtraScope');
        }

        result.push(
          <div className={className}>
            <strong>{string}</strong>
          </div>,
        );
      });
    }

    return result;
  },

  sortRuleServiceValue(ingressServices) {
    if (!ingressServices) {
      return '';
    }

    const services = [];

    ingressServices.forEach(ingressService => {
      if (ingressService.href) {
        services.push(ingressService.name);

        return;
      }

      const servicePorts = ingressService.service_ports || ingressService.windows_services;
      let serviceString = '';

      if (servicePorts) {
        serviceString = servicePorts
          .map(connection => PortUtils.stringifyPortObjectReadonly(connection, true))
          .join(', ');
      } else {
        serviceString = PortUtils.stringifyPortObjectReadonly(ingressService, true);
      }

      services.push(serviceString);
    });

    return services.join(',').toLocaleLowerCase();
  },

  formatRuleService(ingressServices, rule = {}) {
    const services = [];

    // Display by created then deleted and finally unmodified.
    const displayIngressServices = ingressServices.sort(
      (a, b) => Boolean(b.created) - Boolean(a.created) || Boolean(b.deleted) - Boolean(a.deleted),
    );

    displayIngressServices.forEach((ingressService, index) => {
      const servicePorts = ingressService.service_ports || ingressService.windows_services;
      let className = 'IngressServiceItem';
      let serviceString = '';

      if (servicePorts) {
        serviceString = servicePorts
          .map(connection => PortUtils.stringifyPortObjectReadonly(connection, true))
          .join(', ');
      } else {
        serviceString = PortUtils.stringifyPortObjectReadonly(ingressService, true);
      }

      if (ingressService.created || ingressService.href?.includes('proposed')) {
        className += ' IngressServiceItem--created';
      }

      if (ingressService.deleted) {
        className += ' IngressServiceItem--deleted';
      }

      // Hide (display: none) any service pills after the 10th one.
      // Properly, the array should have been sliced before forEach, but because of the download, we need to render all.
      // That is a hack, normally the download function should have a separate format function on every cell!
      if (index >= 10) {
        className += ' IngressServiceItem--hidden';
      }

      services.push(
        <div className={className}>
          {ingressService.href ? (
            <div className="RulesetRule-Service-name" data-tid="elem-service-name">
              <Icon customClass="RulesetRule-Service-icon" name="service" />
              <span>
                {ingressService.name}
                {serviceString ? '\uFEFF ' : ''}
              </span>
            </div>
          ) : null}
          <div className="RulesetRule-Service-connections" data-tid="elem-service-connections">
            {serviceString}
          </div>
        </div>,
      );

      if (index < displayIngressServices.length - 1) {
        services.push(
          // Insert Hair Space, as invisible delimiter between service pills,
          // which will be replaced with ', ' in the downloaded file, in RuleSearch.jsx.
          // That is a hack, normally the download function should have a separate format function on every cell!
          '\u200A',
        );
      }
    });

    //If there are more than ten services, display a link for '+ X More' which leads to a dialog with all the services
    // Wrap it in a thin space to remove it from the download report in handleDownload in RuleSearch.jsx
    const servicesModalLink = ingressServices.length > 10 && (
      <div className="MoreService">
        {'\u2009'}
        <a onClick={_.partial(openServicesDialog, ingressServices)} data-tid="elem-link-version">
          {intl('PolicyGenerator.Grid.MorePortProtocol', {numPortsProtocols: ingressServices.length - 10})}
        </a>
        {'\u2009'}
      </div>
    );

    if (
      rule.resolve_labels_as?.providers.length === 1 &&
      rule.resolve_labels_as?.providers.includes('virtual_services')
    ) {
      services.push(
        <div className="IngressServiceItem">
          <div className="RulesetRule-Service-name" data-tid="elem-service-name">
            <span>{intl('Rulesets.Rules.DerivedFromProviderVirtualServices')}</span>
          </div>
        </div>,
      );
    }

    // TODO fix this to not have so many flags on the Rule
    const {
      secConnectChanged,
      machineAuthChanged,
      statelessChanged,
      statelessAdded,
      statelessRemoved,
      secConnectAdded,
      secConnectRemoved,
      machineAuthAdded,
      machineAuthRemoved,
    } = rule;
    const machineAuth = rule.machine_auth;
    const secConnect = rule.sec_connect;
    const stateless = rule.stateless;
    const hasEncryption = machineAuth || secConnect;
    const classNames = cx('RulesetRuleServices', {
      'RulesetRule-Service-HasEncryption': hasEncryption || stateless,
      'text-updated': ingressServices.updated,
    });
    const serviceAttributesChanged =
      hasEncryption || stateless || secConnectChanged || machineAuthChanged || statelessChanged;
    let statelessClassname = '';
    let secConnectClassname = '';
    let machineAuthClassname = '';

    if (statelessAdded) {
      statelessClassname = 'text-updated';
    } else if (statelessRemoved) {
      statelessClassname = 'text-deleted';
    }

    if (secConnectAdded) {
      secConnectClassname = 'text-updated';
    } else if (secConnectRemoved) {
      secConnectClassname = 'text-deleted';
    }

    if (machineAuthAdded) {
      machineAuthClassname = 'text-updated';
    } else if (machineAuthRemoved) {
      machineAuthClassname = 'text-deleted';
    }

    return (
      <div className={classNames}>
        {serviceAttributesChanged ? (
          <div className="RulesetRule-Service-RuleEncryption">
            {machineAuth || machineAuthChanged ? (
              <p className={machineAuthClassname} data-tid="elem-machineauth">
                <span className="RulesetRule-Service-RuleEncryption-On">{intl('Common.On').toLocaleUpperCase()}</span>
                <span data-tid="elem-text">
                  <strong>{intl('Common.MachineAuthentication')}</strong>
                </span>
              </p>
            ) : null}
            {secConnect || secConnectChanged ? (
              <p className={secConnectClassname} data-tid="elem-secureconnect">
                <span className="RulesetRule-Service-RuleEncryption-On">{intl('Common.On').toLocaleUpperCase()}</span>
                <span data-tid="elem-text">
                  <strong>{intl('Common.SecureConnect')}</strong>
                </span>
              </p>
            ) : null}
            {stateless || statelessChanged ? (
              <p className={statelessClassname} data-tid="elem-stateless">
                <span className="RulesetRule-Service-RuleEncryption-On">{intl('Common.On').toLocaleUpperCase()}</span>
                <span data-tid="elem-text">
                  <strong>{intl('Common.Stateless')}</strong>
                </span>
              </p>
            ) : null}
          </div>
        ) : null}
        {rule.network_type === 'non_brn' ? (
          <p className="text">
            <strong>{intl('Rulesets.Rules.NonCorporateNetworks')}</strong>
          </p>
        ) : null}
        {rule.network_type === 'all' ? (
          <p className="text">
            <strong>{intl('Rulesets.Rules.AllNetworks')}</strong>
          </p>
        ) : null}
        {services}
        {servicesModalLink}
      </div>
    );
  },
};
