/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import cx from 'classnames';
import _ from 'lodash';
import intl from 'intl';
import {ComponentUtils} from '../../utils';
import {Button, NotificationGroup} from '..';
import {Toolbar, Fieldset, Field, Input, Textarea, Checkbox, Radio, CheckboxGroup, RadioGroup, Select} from '.';

const defaultTid = 'comp-form';
const controlRefPrefix = 'control-ref--';
const fieldKey = 'name';
const controlKey = 'name';

const controlErrorPrefix = 'control-error--';
const controlErrorStringPrefix = 'control-error-string--';
const controlSecondPassPrefix = 'control-second-pass--';

export default React.createClass({
  propTypes: {
    tid: PropTypes.string,
    type: PropTypes.oneOf(['default', 'dialog']),
    model: PropTypes.object,
    errorModel: PropTypes.object,
    fields: PropTypes.array,
    submitText: PropTypes.string,
    cancelText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onErrorChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onHelp: PropTypes.func,
    showHelpModel: PropTypes.object,
  },

  getDefaultProps() {
    return {
      submitText: intl('Common.Save'),
      cancelText: intl('Common.Cancel'),
      onChange: _.noop,
      onErrorChange: _.noop,
      onSubmit: _.noop,
      onCancel: _.noop,
      onHelp: _.noop,
    };
  },

  getDisabled(fieldOrControl) {
    return _.isFunction(fieldOrControl.disabled)
      ? fieldOrControl.disabled(this.props.model)
      : Boolean(fieldOrControl.disabled);
  },

  getHidden(field) {
    return _.isFunction(field.hidden) ? field.hidden(this.props.model) : field.hidden === true;
  },

  setErrorState(control, message) {
    const errorModelUpdates = {
      [controlErrorPrefix + control[controlKey]]: true,
      [controlSecondPassPrefix + control[controlKey]]: true,
    };

    if (message !== undefined) {
      errorModelUpdates[controlErrorStringPrefix + control[controlKey]] = message;
    }

    this.props.onErrorChange(errorModelUpdates);
  },

  handleSubmit(evt) {
    evt.preventDefault();

    if (!this.validateFields(this.props.fields)) {
      this.props.onErrorChange({
        formError: {
          type: 'error',
          title: intl('Common.FormError'),
        },
      });
      this.refs.notifications.scrollIntoView();

      return;
    }

    this.props.onErrorChange({formError: null});
    this.props.onSubmit();
  },

  handleFieldLabelClick(field) {
    if (field.controls && field.controls.length) {
      const control = field.controls[0];
      const controlRef = control[controlKey];

      switch (control.type) {
        case 'text':
        case 'textarea':
        case 'select':
          this.refs[`${controlRefPrefix}${field[fieldKey]}-${controlRef}`].refs.input.focus();
          break;
        case 'radiogroup':
        case 'checkboxgroup':
          this.refs[`${controlRefPrefix}${field[fieldKey]}-${controlRef}`].refs.option0.refs.input.focus();
          break;
        case 'radio':
        case 'checkbox':
          this.refs[`${controlRefPrefix}${field[fieldKey]}-${controlRef}`].refs.input.focus();
          break;
        case 'custom':
          if (control.getRef) {
            control.getRef(this.refs[`${controlRefPrefix}${field[fieldKey]}-${controlRef}`].refs).focus();
          }

          break;
        //no default
      }
    }
  },

  handleControlChange(value, control, field) {
    const modelChanges = {
      [control[controlKey]]: value,
    };

    this.props.onChange(modelChanges);

    if (this.props.errorModel[controlSecondPassPrefix + control[controlKey]]) {
      const newModel = {...this.props.model, [control[controlKey]]: value};

      this.forceUpdate(() => {
        this.validateControl(newModel, control, field);

        if (this.validateFields(this.props.fields)) {
          this.props.onErrorChange({formError: null});
        }
      });
    }
  },

  blurTimeout: null,
  blurTimeoutProperty: null,

  validateFields(fields) {
    let result = true;

    _.forEach(fields, field => {
      if (!this.getDisabled(field) && !this.getHidden(field)) {
        switch (field.type) {
          case 'fieldset':
            if (!this.validateFields(field.fields)) {
              result = false;
            }

            break;
          case 'field':
            if (!this.validateControls(field, field.controls)) {
              result = false;
            }

            break;
          default:
            result = this.validateControl(field);
        }
      }
    });

    return result;
  },

  validateControls(field, controls) {
    let result = true;

    controls.forEach(control => {
      if (
        !this.getDisabled(control) &&
        !this.getHidden(control) &&
        !this.validateControl(this.props.model, control, field)
      ) {
        result = false;
      }
    });

    return result;
  },

  validateControl(model, control, field) {
    if (
      !this.validateRequiredControl(model, control, field) ||
      !this.validateMaxLengthControl(model, control, field) ||
      !this.validateCustomControl(model, control, field)
    ) {
      return false;
    }

    this.resetErrorState(control);

    return true;
  },

  validateRequiredControl(model, control, field) {
    const value = model[control[controlKey]];

    if ((field && field.required && control.required !== false) || control.required) {
      if (value === undefined || value === null) {
        this.setErrorState(control);

        return false;
      }

      if (typeof value === 'string' && value.trim() === '') {
        this.setErrorState(control);

        return false;
      }

      if (Array.isArray(value) && value.length === 0) {
        this.setErrorState(control);

        return false;
      }
    }

    return true;
  },

  validateMaxLengthControl(model, control, field) {
    const value = model[control[controlKey]];

    if (control.maxlength && typeof value === 'string' && value.length > control.maxlength) {
      this.setErrorState(
        control,
        intl('Common.MaxlengthError', {label: field.label || false, maxlength: control.maxlength}),
      );

      return false;
    }

    return true;
  },

  validateCustomControl(model, control, field) {
    if (control.validation) {
      const response = control.validation(model, control, field);

      if (response !== true) {
        this.setErrorState(control, response.errorString);

        return false;
      }
    }

    return true;
  },

  resetErrorState(control) {
    this.props.onErrorChange({
      [controlErrorPrefix + control[controlKey]]: null,
      [controlErrorStringPrefix + control[controlKey]]: null,
    });
  },

  resetControlState(control, resetValue) {
    const newModel = {};

    if (this.props.model[control[controlKey]] !== false && this.props.model[control[controlKey]] !== undefined) {
      newModel[control[controlKey]] = undefined;
    }

    if (!_.isEmpty(newModel) && resetValue) {
      _.defer(() => {
        this.props.onChange(newModel);
      });
    }

    const newErrorModel = {};

    if (this.props.errorModel[controlErrorPrefix + control[controlKey]] !== undefined) {
      newErrorModel[controlErrorPrefix + control[controlKey]] = undefined;
    }

    if (this.props.errorModel[controlErrorStringPrefix + control[controlKey]] !== undefined) {
      newErrorModel[controlErrorStringPrefix + control[controlKey]] = undefined;
    }

    if (this.props.errorModel[controlSecondPassPrefix + control[controlKey]] !== undefined) {
      newErrorModel[controlSecondPassPrefix + control[controlKey]] = undefined;
    }

    if (!_.isEmpty(newErrorModel)) {
      _.defer(() => {
        this.props.onErrorChange(newErrorModel);
      });
    }
  },

  renderControl(control, field, disabled, hidden, index) {
    disabled ||= this.getDisabled(control);

    if (disabled) {
      this.resetControlState(control, hidden);
    }

    const sharedProps = {
      value: this.props.model[control[controlKey]],
      key: 'control' + control[controlKey],
      name: control[controlKey],
      tid: control.tid || control[controlKey],
      disabled,
      placeholder: control.placeholder,
      type: control.type,
      error: this.props.errorModel[controlErrorPrefix + control[controlKey]],
      onChange: value => {
        this.handleControlChange(value, control, field);
      },
      onBlur: () => {
        this.blurTimeoutProperty = control[controlKey];
        //set a timeout to let other focus events fire first in case you are focusing another element apart of this control
        this.blurTimeout = setTimeout(() => {
          this.blurTimeoutProperty = null;
          this.validateControl(this.props.model, control, field);
        }, 1);
      },
      onFocus: () => {
        if (this.blurTimeout) {
          if (this.blurTimeoutProperty === control[controlKey]) {
            //clear the timeout above if its apart of the same control
            clearTimeout(this.blurTimeout);
            this.blurTimeoutProperty = null;
          }
        }
      },
    };

    if (!index) {
      if (field) {
        sharedProps.ref = controlRefPrefix + field[fieldKey] + '-' + control[controlKey];
      } else {
        sharedProps.ref = controlRefPrefix + '-' + control[controlKey];
      }
    }

    if (disabled) {
      sharedProps.tabIndex = '-1';
    }

    switch (control.type) {
      case 'text':
      case 'password':
        return (
          <Input
            onKeyUp={evt => {
              this.handleControlChange(evt.target.value, control, field);
            }}
            {...sharedProps}
          />
        );
      case 'textarea':
        return (
          <Textarea
            onKeyUp={evt => {
              this.handleControlChange(evt.target.value, control, field);
            }}
            {...sharedProps}
          />
        );
      case 'checkboxgroup':
        return <CheckboxGroup options={control.options} display={control.display} {...sharedProps} />;
      case 'radiogroup':
        return <RadioGroup options={control.options} display={control.display} {...sharedProps} />;
      case 'checkbox':
        return (
          <Checkbox
            {...sharedProps}
            checked={sharedProps.value && sharedProps.value.includes(control.value)}
            onChange={value => {
              const newValue = _.clone(sharedProps.value || []);
              const oldValuePosition = newValue.indexOf(value);

              if (oldValuePosition !== -1) {
                newValue.splice(oldValuePosition, 1);
              } else {
                newValue.push(value);
              }

              this.handleControlChange(newValue, control, field);
            }}
            label={control.label}
            sublabel={control.sublabel}
            value={control.value}
          />
        );
      case 'radio':
        return (
          <Radio
            {...sharedProps}
            checked={sharedProps.value === control.value}
            onChange={value => {
              this.handleControlChange(value, control, field);
            }}
            label={control.label}
            sublabel={control.sublabel}
            value={control.value}
          />
        );
      case 'select':
        return <Select options={control.options} {...sharedProps} />;
      case 'custom':
        return control.getControl(sharedProps);
      //no default
    }
  },

  renderAnimateHidden(component, renderAnimate, visible, key) {
    if (renderAnimate) {
      const animationClasses = cx({
        'ReForm-Animate': true,
        'ReForm-Animate--open': visible,
      });

      return (
        <div className={animationClasses} key={`animate-${key}`} data-tid={visible ? 'comp-field-open' : ''}>
          {component}
        </div>
      );
    }

    return component;
  },

  renderControls(field, parentDisabled, parentHidden) {
    let result = field.controls.map((control, index) => {
      const hidden = parentHidden || this.getHidden(control);
      const disabled = hidden || parentDisabled;
      let innerResult = this.renderControl(control, field, disabled, hidden, index);

      if (field.controls.length > 1) {
        const classes = cx({
          'ReForm-Field-Controls-Split-Inner': true,
          'ReForm-Field-Controls-Split-Inner--first': !index,
          'ReForm-Field-Controls-Split-Inner--last': index === field.controls.length - 1,
        });

        innerResult = (
          <div className={classes} key={`controlsplit-${index}`}>
            {innerResult}
          </div>
        );
      }

      return this.renderAnimateHidden(innerResult, control.hidden !== undefined, !hidden, control[controlKey]);
    });

    if (field.controls.length > 1) {
      const classes = cx({
        'ReForm-Field-Controls-Split--horizontal': field.splitStyle !== 'vertical',
        'ReForm-Field-Controls-Split--vertical': field.splitStyle === 'vertical',
      });

      result = <div className={classes}>{result}</div>;
    }

    return result;
  },

  renderField(field, disabled, hidden) {
    let error = false;
    let errorString = '';

    field.controls.forEach(control => {
      if (this.props.errorModel[controlErrorPrefix + control[controlKey]]) {
        error = true;
      }

      if (this.props.errorModel[controlErrorStringPrefix + control[controlKey]] && !errorString) {
        errorString = this.props.errorModel[controlErrorStringPrefix + control[controlKey]];
      }
    });

    return (
      <Field
        label={field.label}
        required={field.required}
        hiddenText={field.hiddenText}
        hint={field.hint}
        hintBelow={field.hintBelow}
        help={field.help}
        showHelpModel={this.props.showHelpModel}
        disabled={disabled}
        error={error}
        errorString={errorString}
        customStyle={field.customStyle}
        key={'field-' + field[fieldKey]}
        tid={field[fieldKey]}
        onLabelClick={_.partial(this.handleFieldLabelClick, field)}
        hideLabel={field.hideLabel}
        onHelpClick={_.partial(this.props.onHelp, field)}
      >
        {this.renderControls(field, disabled, hidden)}
      </Field>
    );
  },

  renderFieldset(fieldset, disabled, parentHidden) {
    return (
      <Fieldset title={fieldset.title} subtitle={fieldset.subtitle} key={'fieldset-' + fieldset[fieldKey]}>
        {this.renderFields(fieldset.fields, disabled, parentHidden)}
      </Fieldset>
    );
  },

  renderFields(fields, parentDisabled, parentHidden) {
    return fields.map(field => {
      const hidden = parentHidden || this.getHidden(field);
      const disabled = parentDisabled || hidden || this.getDisabled(field);
      let result;

      switch (field.type) {
        case 'fieldset':
          result = this.renderFieldset(field, disabled, hidden);
          break;
        case 'field':
          result = this.renderField(field, disabled, hidden);
          break;
        //no default
      }

      return this.renderAnimateHidden(result, field.hidden !== undefined, !hidden, field[fieldKey]);
    });
  },

  renderToolbar(placement) {
    if (placement === 'top' && this.props.type !== 'dialog') {
      return (
        <Toolbar>
          <Button text={this.props.submitText} onClick={this.handleSubmit} tid="save" />
          <Button text={this.props.cancelText} type="secondary" onClick={this.props.onCancel} tid="cancel" />
        </Toolbar>
      );
    }

    if (placement === 'bottom' && this.props.type === 'dialog') {
      return (
        <div className={this.props.type === 'dialog' ? 'ReForm-dialog-actions' : ''} data-tid="comp-dialog-actions">
          <Toolbar align="right">
            <Button text={this.props.cancelText} type="secondary" onClick={this.props.onCancel} tid="cancel" />
            <Button text={this.props.submitText} onClick={this.handleSubmit} tid="save" />
          </Toolbar>
        </div>
      );
    }
  },

  render() {
    const classes = cx({
      'ReForm': true,
      'ReForm--dialog': this.props.type === 'dialog',
    });

    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    return (
      <form className={classes} data-tid={ComponentUtils.tidString(tids)} onSubmit={this.handleSubmit}>
        <div ref="notifications">
          <NotificationGroup notifications={this.props.errorModel.formError ? [this.props.errorModel.formError] : []} />
        </div>
        {this.renderToolbar('top')}
        {this.renderFields(this.props.fields)}
        {this.renderToolbar('bottom')}
      </form>
    );
  },
});
