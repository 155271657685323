/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import Slider from 'react-rangeslider';
import {TrafficStore, SessionStore, OrgStore} from '../../stores';
import {GridDataUtils, GroupDataUtils, PolicyGeneratorUtils, RenderUtils} from '../../utils';
import actionCreators from '../../actions/actionCreators';
import {RouterMixin, PolicyGeneratorMixin, StoreMixin} from '../../mixins';
import {AppGroupTabs} from '../Groups';
import PolicyGeneratorLargeServices from '../../modals/PolicyGeneratorLargeServices.jsx';
import {Navbar, ProgressBar, Button, Radio, RadioGroup, AlertDialog, Banner} from '../../components';
import {ConfigureIntraConnectionsGrid, CoverageProgressBar} from '../../components/PolicyGenerator';

function getStateFromStores() {
  const traffic = TrafficStore.getAllRoleTraffics();
  const state = this.getPolicyGeneratorValues() || {};
  const rulesetId = state.type === 'replace' && state.ruleset && state.ruleset.href.split('/').pop();
  let newState = {...state};

  if (state.appGroup) {
    const roles = this.getAppGroupRoles(TrafficStore.getAllRoleNodes(), state.appGroup.href).map(role => role.href);

    newState = PolicyGeneratorUtils.parseIntraAppGroupTraffic(
      traffic,
      state.appGroup,
      state.intraConfig || 'ringfencing',
      rulesetId,
      roles,
      state.optimizeLevel,
    );
    newState.ruleCoverage = PolicyGeneratorUtils.calculateRuleCoverage(
      newState.intraTableTraffic,
      newState.counts.intraConnectionsWorkingTotal,
      state.intraExclusions,
    );
    newState.closedVulnerabilities = PolicyGeneratorUtils.calculateClosedVulnerabilities(
      newState.vulnerabilities,
      state.intraExclusions,
    );
    newState.intraConfig = state.intraConfig || 'ringfencing';
    newState.rulesetId = rulesetId;
  } else {
    newState.ruleCoverage = 0;
    newState.closedVulnerabilities = 0;
  }

  const id = this.getParams() && this.getParams().id;

  if (id && (!state.appGroup || id !== state.appGroup.href) && TrafficStore.getNodeForPolicyGenerator(id)) {
    this.setAppGroup(TrafficStore.getNodeForPolicyGenerator(id));
  }

  return newState;
}

export default React.createClass({
  mixins: [RouterMixin, PolicyGeneratorMixin, StoreMixin(TrafficStore, getStateFromStores)],

  getInitialState() {
    return {
      vulnerabilitiesEnabled: SessionStore.areVulnerabilitiesEnabled(),
      showProgressBar: 'none',
      sorting: [{key: 'type', direction: false}],
      providerConsumerOrder: OrgStore.providerConsumerOrder(),
    };
  },

  componentDidMount() {
    if (!this.state.appGroup) {
      this.transitionTo('policygenerator');

      return;
    }

    this.getData(this.state, 'intra');
    this.getPolicyGeneratorRuleset(this.state.appGroup);
    this.setState(getStateFromStores.call(this));
    window.addEventListener('scroll', this.handleScroll);
  },

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  async componentWillReceiveProps() {
    const id = this.getParams() && this.getParams().id;
    const appGroup = TrafficStore.getNodeForPolicyGenerator(id);

    if (id && (!this.state.appGroup || id !== this.state.appGroup.href) && appGroup) {
      this.setAppGroup(appGroup);

      const ruleset = await this.getPolicyGeneratorRuleset(appGroup);

      this.setState({ruleset, showProgress: true, appGroup}, () => {
        //reload the data for the new app group
        this.getData(this.state, 'intra');
      });
    }
  },

  getCompleteServices() {
    this.setConfig('microsegmentation');
    actionCreators.updateForAll();
    _.defer(() => this.getData(this.state, 'intra'));
  },

  getTableData(traffic, intraConfig, rulesetId, optimizeLevel) {
    if (intraConfig === 'auto') {
      return PolicyGeneratorUtils.getOptimizedRoleLevelTableData(traffic, rulesetId, 'intra', optimizeLevel);
    }

    return PolicyGeneratorUtils.getRoleLevelTableData(traffic, intraConfig, rulesetId, false, optimizeLevel);
  },

  setExclusions(exclusions) {
    const ruleCoverage = PolicyGeneratorUtils.calculateRuleCoverage(
      this.state.intraTableTraffic,
      this.state.counts.intraConnectionsWorkingTotal,
      exclusions,
    );
    const closedVulnerabilities = PolicyGeneratorUtils.calculateClosedVulnerabilities(
      this.state.vulnerabilities,
      exclusions,
    );

    this.setIntraExclusions(exclusions);
    this.setState({intraExclusions: exclusions, ruleCoverage, closedVulnerabilities});
  },

  setConfig(intraConfig) {
    const {intraTraffic, type, rulesetId, intraExclusions, optimizeLevel, targetNodes, counts} = this.state;
    const intraTableTraffic = this.getTableData(
      intraTraffic,
      intraConfig,
      type === 'replace' && rulesetId,
      optimizeLevel,
    );
    const vulnerabilities = PolicyGeneratorUtils.getVulnerabilities(intraTableTraffic, targetNodes);
    const ruleCoverage = PolicyGeneratorUtils.calculateRuleCoverage(
      intraTableTraffic,
      counts.intraConnectionsWorkingTotal,
      intraExclusions,
    );
    const closedVulnerabilities = PolicyGeneratorUtils.calculateClosedVulnerabilities(vulnerabilities, intraExclusions);

    this.setIntraConfig(intraConfig);
    this.setState({intraConfig, intraTableTraffic, ruleCoverage, vulnerabilities, closedVulnerabilities});
  },

  handleOptimizeLevelChange(optimizeLevel) {
    const {intraTraffic, intraConfig, type, rulesetId, intraExclusions, targetNodes, counts} = this.state;
    const intraTableTraffic = this.getTableData(
      intraTraffic,
      intraConfig,
      type === 'replace' && rulesetId,
      optimizeLevel,
    );
    const vulnerabilities = PolicyGeneratorUtils.getVulnerabilities(intraTableTraffic, targetNodes);
    const ruleCoverage = PolicyGeneratorUtils.calculateRuleCoverage(
      intraTableTraffic,
      counts.intraConnectionsWorkingTotal,
      intraExclusions,
    );
    const closedVulnerabilities = PolicyGeneratorUtils.calculateClosedVulnerabilities(vulnerabilities, intraExclusions);

    this.setOptimizeLevel(optimizeLevel);
    this.setState({optimizeLevel, intraTableTraffic, ruleCoverage, vulnerabilities, closedVulnerabilities});
  },

  handleConfigSelect(evt) {
    const intraConfig = evt.target.value;

    // Warn the user if there are high numbers of connections before selecting Microsegmentation
    if ((intraConfig === 'microsegmentation' || intraConfig === 'auto') && this.state.counts.missingConnections) {
      actionCreators.openDialog(
        <PolicyGeneratorLargeServices
          selectAllServices={_.partial(this.setConfig, 'tiertotier')}
          selectSpecifiedServices={this.getCompleteServices}
        />,
      );

      return;
    }

    this.setConfig(intraConfig);
  },

  handleBack() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupPolicyGenerator', {id});
    } else {
      this.transitionTo('policygenerator');
    }
  },

  handleForceNext() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupIntraScopePreview', {id});
    } else {
      this.transitionTo('intraScopePreview');
    }
  },

  handleNext() {
    if (this.state.ruleCoverage) {
      this.handleForceNext();

      return;
    }

    actionCreators.openDialog(
      <AlertDialog
        title={intl('PolicyGenerator.NoConnections')}
        message={intl('PolicyGenerator.AllExcluded')}
        onConfirm={this.handleForceNext}
      />,
    );
  },

  handleAddFilter(intraConnectionFilters) {
    this.setIntraConnectionFilters(intraConnectionFilters);
    this.setState({intraConnectionFilters});
  },

  handleRemoveFilter() {
    this.setIntraConnectionFilters(null);
    this.setState({intraConnectionFilters: null});
  },

  handleTransmissionFilterChange(filters) {
    this.setIntraTransmissionFilters(filters);
    this.setState({intraTransmissionFilters: filters}, () => this.getAppGroupTraffic(this.state, 'intra'));
  },

  handleRowToggle(row) {
    const exclusions = [...this.state.intraExclusions];

    if (exclusions.includes(row)) {
      const rowIndex = exclusions.indexOf(row);

      exclusions.splice(rowIndex, 1);
    } else {
      exclusions.push(row);
    }

    this.setExclusions(exclusions);
  },

  handleSort(key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({key, direction});
    }

    this.setState({sorting});
  },

  handleIncludeAll() {
    const {intraTableTraffic, intraExclusions, intraConnectionFilters} = this.state;
    const rows = intraTableTraffic.filter(row => row.filterKey.includes(intraConnectionFilters || ''));
    const exclusions = [...intraExclusions];

    rows.forEach(row => {
      if (intraExclusions.includes(row.key)) {
        const rowIndex = exclusions.indexOf(row.key);

        exclusions.splice(rowIndex, 1);
      }
    });
    this.setExclusions(exclusions);
  },

  handleExcludeAll() {
    const {intraTableTraffic, intraExclusions, intraConnectionFilters} = this.state;

    if (intraTableTraffic.length === 1) {
      return;
    }

    const rows = intraTableTraffic.filter(row => row.filterKey.includes(intraConnectionFilters || ''));
    const exclusions = [...intraExclusions];

    rows.forEach(row => {
      if (!intraExclusions.includes(row.key)) {
        exclusions.push(row.key);
      }
    });
    this.setExclusions(exclusions);
  },

  handleScroll() {
    let top = 0;

    if (this.table) {
      // Find the top of the table, because the top div can vary in size
      top = this.table.getBoundingClientRect().top;
    }

    const bar = this.state.showProgressBar;
    const buttons = this.state.bottomButtons;
    const pinAt = -80;

    if (bar !== 'top' && top < pinAt) {
      this.setState({showProgressBar: 'top'});
    } else if (bar !== 'none' && top > pinAt) {
      this.setState({showProgressBar: 'none'});
    }

    if (buttons && top > pinAt) {
      this.setState({bottomButtons: false});
    } else if (!buttons && top < pinAt) {
      this.setState({bottomButtons: true});
    }
  },

  render() {
    const {
      appGroup,
      intraTableTraffic,
      ruleCoverage,
      intraExclusions,
      counts,
      loadingSpinner,
      modal,
      showProgressBar,
      bottomButtons,
      intraConnectionFilters,
      vulnerabilitiesEnabled,
      closedVulnerabilities,
      vulnerabilities,
      optimizeLevel,
      rulesetId,
      intraTransmissionFilters,
      providerConsumerOrder,
    } = this.state;

    if (!appGroup) {
      return null;
    }

    const intraConfig = this.state.intraConfig || 'ringfencing';
    const workingTotal = counts.intraConnectionsWorkingTotal;
    const percent = workingTotal ? ruleCoverage / workingTotal : 0;
    const totalPercent = counts.intraConnections
      ? (ruleCoverage + counts.intraConnectionRules) / counts.intraConnections
      : 0;
    const excluded = workingTotal ? workingTotal - ruleCoverage : 0;
    let title = intl('PolicyGenerator.IntraScope.IntraScopeRuleConfiguration');
    let trafficTable = null;
    let appGroupTabs = null;

    if (this.getParams() && this.getParams().id) {
      const mapRoute = GroupDataUtils.getMapRoute(appGroup, this.getParams().id, this.mapLevel, 'appgroups');

      title = RenderUtils.truncateAppGroupName(appGroup.name, 45, [30, 15, 10]);
      appGroupTabs = (
        <div className="GroupBar">
          <AppGroupTabs active="policyGenerator" mapRoute={mapRoute} />
        </div>
      );
    }

    const progressBar = (
      <CoverageProgressBar
        connections={counts.intraConnections}
        connectionRules={counts.intraConnectionRules}
        ruleCoverage={ruleCoverage}
        excluded={excluded}
        direction="horizontal"
        percent={percent}
      />
    );

    let trafficData = intraTableTraffic;
    let allMissingRoles = true;

    if (!_.isEmpty(this.state.intraConnectionFilters)) {
      trafficData = intraTableTraffic.filter(row => row.filterKey.includes(intraConnectionFilters || ''));
    }

    trafficData.forEach(traffic => {
      allMissingRoles &&= GridDataUtils.areRolesMissing('intra', intraConfig, {
        provider: traffic.target,
        consumer: traffic.source,
      });
    });

    if (intraTableTraffic) {
      trafficTable = (
        <ConfigureIntraConnectionsGrid
          trafficData={trafficData}
          type="intra"
          title={appGroup.name}
          config={intraConfig}
          showToolbar={true}
          subtitle={`${intraTableTraffic.length} Intra-Scope Connections`}
          exclusions={intraExclusions}
          selectable={true}
          onRowToggle={this.handleRowToggle}
          sorting={this.state.sorting}
          onSort={this.handleSort}
          filters={this.state.intraConnectionFilters}
          onAddFilter={this.handleAddFilter}
          onRemoveFilter={this.handleRemoveFilter}
          transmissionFilters={intraTransmissionFilters}
          onTransmissionFilterChange={this.handleTransmissionFilterChange}
          onIncludeAll={this.handleIncludeAll}
          onExcludeAll={this.handleExcludeAll}
          progressBar={progressBar}
          showProgressBar={showProgressBar}
          completeData={intraTableTraffic.length === trafficData.length}
          allMissingRoles={allMissingRoles}
          maxRows={this.getMaxTableRules()}
          rulesetId={rulesetId}
          providerConsumerOrder={providerConsumerOrder}
        />
      );
    }

    if (loadingSpinner && !modal) {
      let message;

      switch (loadingSpinner) {
        case 'rules':
          message = intl('PolicyGenerator.Spinner.CalculatingFlowData');
          break;
        case 'vulnerabilities':
          message = intl('PolicyGenerator.Spinner.CalculatingVulnerabilityData');
          break;
        default:
          message = intl('PolicyGenerator.Spinner.FindingConnections');
      }

      return (
        <div className="RBConfigure RBConfigure--Intra">
          <Banner type="progresscentered" header={intl('PolicyGenerator.CalculationInProgress')} message={message} />
          <Navbar title={title} />
        </div>
      );
    }

    const className = bottomButtons ? 'RBConfigure-Content-Nav--show' : 'RBConfigure-Content-Nav--hidden';

    return (
      <div className="RBConfigure RBConfigure--Intra">
        <Navbar title={title} />
        {appGroupTabs}
        <div className="RBConfigure-Content">
          <ProgressBar
            steps={[
              intl('PolicyGenerator.SelectAppGroup'),
              intl('PolicyGenerator.IntraScope.ConfigureIntraScope'),
              intl('PolicyGenerator.PreviewRules'),
            ]}
            active={1}
          />
          <div className="RBConfigure-Content-Nav">
            <Button text={intl('Common.Back')} type="secondary" onClick={this.handleBack} tid="back" />
            <Button text={intl('Common.Next')} onClick={this.handleNext} tid="next" />
          </div>
          <div className="RBConfigure-Content-Nav-Wrapper">
            <div className={className}>
              <div className="RBConfigure-Content-Nav">
                <Button text={intl('Common.Back')} type="secondary" onClick={this.handleBack} tid="back" />
                <Button text={intl('Common.Next')} onClick={this.handleNext} tid="next" />
              </div>
            </div>
          </div>
          <div className="RBConfigure-Content-AppGroup">
            <div className="RBConfigure-Content-AppGroup-Choose">
              <h2 className="RBConfigure-Content-AppGroup-Choose-Title">
                {intl('PolicyGenerator.IntraScope.Options.Title')}
              </h2>
              <div className="RBConfigure-Content-AppGroup-Choose-Values">
                <RadioGroup value={intraConfig}>
                  <Radio
                    value="ringfencing"
                    onChange={this.handleConfigSelect}
                    checked={intraConfig === 'ringfencing'}
                    label={intl('PolicyGenerator.AppGroupLevel')}
                    tid="ringfencing"
                  >
                    {intl('PolicyGenerator.IntraScope.Options.Ringfencing.Body')}
                  </Radio>
                  <Radio
                    value="tiertotier"
                    onChange={this.handleConfigSelect}
                    checked={intraConfig === 'tiertotier'}
                    label={intl('PolicyGenerator.IntraScope.Options.TierToTier.Title')}
                    tid="tiertotier"
                  >
                    {intl('PolicyGenerator.IntraScope.Options.TierToTier.Body')}
                  </Radio>
                  <Radio
                    value="microsegmentation"
                    onChange={this.handleConfigSelect}
                    checked={intraConfig === 'microsegmentation'}
                    label={intl('PolicyGenerator.IntraScope.Options.Microsegmentation.Title')}
                    tid="microsegmentation"
                  >
                    {intl('PolicyGenerator.IntraScope.Options.Microsegmentation.Body')}
                  </Radio>
                  {vulnerabilitiesEnabled ? (
                    <Radio
                      value="auto"
                      onChange={this.handleConfigSelect}
                      checked={intraConfig === 'auto'}
                      label={intl('PolicyGenerator.IntraScope.Options.AutoLevel.Title')}
                      tid="auto"
                    >
                      {intl('PolicyGenerator.IntraScope.Options.AutoLevel.Body')}
                    </Radio>
                  ) : null}
                  {intraConfig === 'auto' ? (
                    <div className="RBConfigure-Content-AppGroup-Choose-Level">
                      {intl('Common.Severity')}
                      <div className="RBConfigure-Content-AppGroup-Choose-Level-Slider">
                        <div className="VulnerabilitySeverity">
                          <Slider
                            min={1}
                            max={5}
                            step={1}
                            labels={RenderUtils.getVulnerabilityOptions()}
                            value={optimizeLevel}
                            onChange={this.handleOptimizeLevelChange}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </RadioGroup>
              </div>
              <div className="RBConfigure-Content-AppGroup-Choose-Image">
                <img
                  className="RBConfigure-Content-AppGroup-Choose-Image-Img"
                  alt={intraConfig}
                  src={`/images/policygenerator/${intraConfig}.png`}
                />
              </div>
            </div>
            <div className="RBConfigure-Content-AppGroup-Info">
              <p>
                <strong>{intl('Common.AppGroup')}:</strong>{' '}
                {`${appGroup.name} - ${intl('Workloads.WorkloadsNumber', {count: appGroup.workloads})}`}
              </p>
              <p>
                <strong>{intl('PolicyGenerator.IntraScope.IntraScopeConnections')}</strong>
                <br />
                {intl('AppGroupCoverage.RuleCoverageStrong', {val: totalPercent}, {html: true})}
              </p>
              <CoverageProgressBar
                connections={counts.intraConnections}
                connectionRules={counts.intraConnectionRules}
                ruleCoverage={ruleCoverage}
                excluded={excluded}
                direction="vertical"
                percent={percent}
                type="intra"
                totals={vulnerabilitiesEnabled && vulnerabilities.counts}
                closed={vulnerabilitiesEnabled && closedVulnerabilities}
              />
            </div>
          </div>
          <div ref={node => (this.table = node)} data-tid="policy-generator-grid">
            {trafficTable}
          </div>
        </div>
      </div>
    );
  },
});
