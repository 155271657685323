/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import {Link, State} from 'react-router';
import actionCreators from '../../actions/actionCreators';
import Constants from '../../constants';
import {Grid, Navbar, Select, SpinnerOverlay, Pagination} from '../../components';
import {ToolBar, ToolGroup} from '../../components/ToolBar';
import {RouterMixin, StoreMixin, UserMixin} from '../../mixins';
import {
  SessionStore,
  GeneralStore,
  LabelStore,
  ContainerWorkloadStore,
  VirtualServerStore,
  PairingProfileStore,
  VirtualServiceStore,
} from '../../stores';
import {GraphDataUtils, GridDataUtils, GroupDataUtils, RenderUtils, RestApiUtils, WorkloadUtils} from '../../utils';
import {GroupTabs, AppGroupTabs} from '.';
import GroupMemberPanel from './GroupMemberSelect';
import {enforcementStateMap} from '../../utils/RenderUtils';
import SearchBar from '../Map/SearchBar';

const MAX_RESULTS_PER_PAGE = 50;

function getStateFromStores() {
  const {groupLabelHrefs, group} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);

  const virtualServers = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, VirtualServerStore.getAll());
  const pairingProfiles = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, PairingProfileStore.getAll());
  let containerWorkloads = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, ContainerWorkloadStore.getAll());
  let virtualServices = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, VirtualServiceStore.getAll().draft);

  // Find the workloads in a discovery group defined by the traffic
  if (group && group.discovered) {
    containerWorkloads = _.transform(
      group.nodesHrefs,
      (result, href) => {
        const containerWorkload = ContainerWorkloadStore.getSpecified(href);

        if (containerWorkload) {
          result.push(containerWorkload);
        }
      },
      [],
    );

    virtualServices = _.transform(
      group.nodesHrefs,
      (result, href) => {
        const virtualService = VirtualServiceStore.getSpecified(href);

        if (virtualService) {
          result.push(virtualService);
        }
      },
      [],
    );
  }

  return {
    group,
    virtualServices,
    virtualServers,
    containerWorkloads,
    pairingProfiles,
    count: ContainerWorkloadStore.getCount(),
    status: [LabelStore.getStatus(), ContainerWorkloadStore.getStatus()],
  };
}

export default React.createClass({
  mixins: [
    State,
    RouterMixin,
    UserMixin,
    StoreMixin([LabelStore, ContainerWorkloadStore, VirtualServiceStore, PairingProfileStore], getStateFromStores),
  ],

  getInitialState() {
    const {groupHref, groupLabelHrefs} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);
    const selectionObject = GeneralStore.getSelection('groupContainerWorkloads');
    const sorting = GeneralStore.getSorting('groupContainerWorkloads');
    const filter = this.getParams().filter || GeneralStore.getFilter('groupContainerWorkloads');
    const type = GroupDataUtils.getType(this.getPathname());
    const vulnerabilitiesEnabled = SessionStore.areVulnerabilitiesEnabled() && type === 'appgroups';
    let selection = [];

    if (selectionObject && selectionObject.selection && selectionObject.id === this.getParams().id) {
      selection = selectionObject.selection;
    }

    return {
      type,
      groupHref,
      groupLabelHrefs,
      selection,
      sorting:
        sorting || vulnerabilitiesEnabled
          ? [{key: 'vulnerability_summary', direction: true}]
          : [{key: 'name', direction: false}],
      filter: filter || 'all',
      expandedRow: null,
      currentPage: 1,
      groupExists: true,
      vulnerabilitiesEnabled,
    };
  },

  async componentDidMount() {
    RestApiUtils.user.orgs({representation: 'org_permissions'}, SessionStore.getUserId(), true);

    this.mapLevel = await GraphDataUtils.getMapLevelByTotalWorkloads();

    const {groupHref, type, vulnerabilitiesEnabled} = this.state;
    const {group, caps} = await GroupDataUtils.getGroup(
      this.state.group,
      groupHref,
      type,
      vulnerabilitiesEnabled,
      false,
    );

    this.setState({groupExists: !_.isEmpty(group), caps});

    if (group && group.discovered) {
      this.setState(() => {
        /* Set the proper count for "Discovered" group since the API doesn't return a count header. */
        const count = {...this.state.count, matched: group.nodesHrefs.length};

        return {count};
      });
    }
  },

  componentWillReceiveProps() {
    const type = GroupDataUtils.getType(this.getPathname());

    if (this.getParams().id !== this.state.groupHref || type !== this.state.type) {
      const {groupHref, groupLabelHrefs, group} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);

      GroupDataUtils.getGroup(group, groupHref, type, this.state.vulnerabilitiesEnabled);

      this.setState({
        group,
        groupHref,
        groupLabelHrefs,
        selection: [],
      });
    }
  },

  getSelectedWorkloads() {
    return _.compact(_.map(this.state.selection, href => _.find(this.state.containerWorkloads, w => w.href === href)));
  },

  handleChangeComplete() {
    const {group} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);

    this.getWorkloads(group);
    actionCreators.updateGeneralSelection('groupContainerWorkloads', []);
    this.setState({selection: []});
  },

  handleFilterChange(evt) {
    actionCreators.updateGeneralFilter('groupContainerWorkloads', evt.value);
    actionCreators.updateGeneralSelection('groupContainerWorkloads', []);
    this.setState({filter: evt.value, selection: [], currentPage: 1});
  },

  handlePageChange(page) {
    this.setState({
      currentPage: page,
    });
  },

  handleRowClick(row) {
    const url = row.href.split('/');
    const id = url[url.length - 1];

    this.transitionTo('containerWorkload', {id});
  },

  handleSelectToggle(selection) {
    const newSelection = GridDataUtils.selectToggle(this.state.selection, selection);

    actionCreators.updateGeneralSelection('groupContainerWorkloads', {
      id: this.getParams().id,
      selection: newSelection,
    });
    this.setState({selection: newSelection});
  },

  handleSort(key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({key, direction});
    }

    actionCreators.updateGeneralSorting('groupContainerWorkloads', sorting);
    this.setState({sorting});
  },

  render() {
    const {type} = this.state;
    const listPage = sessionStorage.getItem('app_group_list') === 'recents' ? {route: 'appMap'} : {route: 'appGroups'};

    if (!this.state.groupExists) {
      // if we can't find the group, it must be because it's a discovered group
      this.replaceWith(type === 'appgroups' ? listPage.route : 'map');
    } else if (!this.state.group) {
      return <SpinnerOverlay />;
    }

    const columns = [
      {
        key: 'enforcement_mode',
        label: intl('Common.Enforcement'),
        style: 'user',
        type: 'string',
        sortable: true,
        format: value => enforcementStateMap[value] || '',
        sortValue: value => value || '',
      },
      {
        key: 'security_policy_sync_state',
        label: intl('Workloads.PolicySync'),
        style: 'tag',
        sortable: true,
        format(value) {
          return WorkloadUtils.getContainerWorkloadStatusIntl(value);
        },
        sortValue(value, row) {
          return WorkloadUtils.friendlyName(row).toLowerCase();
        },
      },
      {
        key: 'name',
        label: intl('Common.Name'),
        sortable: true,
        format(value, row) {
          return (
            <Link to="containerWorkload" className="Grid-link" params={{id: GridDataUtils.getIdFromHref(row.href)}}>
              {WorkloadUtils.friendlyName(row)}
            </Link>
          );
        },
        sortValue(value, row) {
          return WorkloadUtils.friendlyName(row).toLowerCase();
        },
      },
      {
        key: 'role',
        label: intl('Common.Role'),
        formatHeader: GridDataUtils.formatLabelLabel,
        format: GridDataUtils.formatLabelValue,
        sortValue: (value, row) => {
          const role = row.labels.find(label => label.key === 'role');

          return role ? role.value : null;
        },
        sortable: true,
      },
    ];

    const selectOptions = [
      {value: 'all', label: intl('Common.All')},
      {value: 'idle', label: intl('Common.Idle')},
      {value: 'visibility', label: intl('Common.VisibilityOnly')},
      {value: 'selective', label: intl('Workloads.Selective')},
      {value: 'enforced', label: intl('Workloads.Full')},
    ];

    let title;

    if (this.state.group) {
      title = RenderUtils.truncateAppGroupName(
        _.sortBy(this.state.group.labels, 'key')
          .map(label => label.value)
          .join(' | '),
        45,
        [30, 15, 10],
      );
    }

    const gridData = WorkloadUtils.filterContainerWorkloadsByPolicyState(
      this.state.containerWorkloads,
      this.state.filter,
    );

    const {group, groupHref} = this.state;
    const mapRoute = GroupDataUtils.getMapRoute(group, groupHref, this.mapLevel, type);
    const tabs = GroupDataUtils.getTabs(this.state);
    const appGroups = this.state.type === 'appgroups';
    const searchBar = appGroups ? <SearchBar type="app" navbar title={title} route="appGroupWorkloads" /> : null;

    return (
      <div className="GroupWorkloads ListPage" data-tid="page-appcontainer-containerworkloads">
        {this.state.status.includes(Constants.STATUS_BUSY) ? <SpinnerOverlay /> : null}
        <Navbar
          title={searchBar ? '' : title}
          customSearch={searchBar}
          type="detail"
          up={appGroups || !mapRoute ? listPage : mapRoute}
        />
        <div className="GroupBar">
          {appGroups ? (
            <AppGroupTabs active="members" mapRoute={mapRoute} />
          ) : (
            <GroupTabs active="containerWorkloads" tabs={tabs} group={group} />
          )}
        </div>
        {appGroups ? <GroupMemberPanel active="containerWorkloads" items={tabs} /> : null}

        <ToolBar>
          <ToolGroup>
            <div className="ListPage-Filter">
              <Select options={selectOptions} selected={this.state.filter} onSelect={this.handleFilterChange} />
            </div>
            {this.state.selection.length > 0 &&
              intl(
                'Common.SelectedCount',
                {className: 'Count', count: this.state.selection.length},
                {
                  html: true,
                  htmlProps: {
                    'className': 'ListPage-Count-Selection',
                    'data-tid': 'elem-count-selection',
                  },
                },
              )}
          </ToolGroup>
          {gridData.length ? (
            <ToolGroup tid="pagination">
              <Pagination
                page={this.state.currentPage}
                totalRows={gridData.length}
                pageLength={MAX_RESULTS_PER_PAGE}
                onPageChange={this.handlePageChange}
                isFiltered={true}
                count={this.state.count}
              />
            </ToolGroup>
          ) : null}
        </ToolBar>
        <Grid
          columns={columns}
          data={gridData}
          sorting={this.state.sorting}
          selection={this.state.selection}
          sortable={true}
          selectable={!this.isUserReadOnly()}
          idField="href"
          onSort={this.handleSort}
          onRowSelectToggle={this.handleSelectToggle}
          onRowClick={this.handleRowClick}
          resultsPerPage={MAX_RESULTS_PER_PAGE}
          currentPage={this.state.currentPage}
        />
      </div>
    );
  },
});
