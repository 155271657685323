/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import he from 'he';
import {createElement, Fragment, isValidElement, type ReactElement} from 'react';
import {selectUnit} from '@formatjs/intl-utils';
import intlMessageFormat, {type PrimitiveType} from 'intl-messageformat';
import memoizeFormatConstructor from 'intl-format-cache';
import {locale, lang} from './locale';
import config from 'config';
import formats from './formats';
import langs, {type LangKeys} from './langs';
import * as utils from './utils';
import type {ReactStrictNode} from 'utils/types';

let messages = langs(config)[lang];

// TODO: intl-format-cache is deprecated and should be removed once formatjs is upgraded
declare module 'intl-format-cache' {
  // patching intl-format-cache to get better type inference
  interface MemoizeFormatConstructorFn {
    <
      T extends {
        new (...args: any[]): unknown;
      },
    >(
      constructor: T,
      cache?: Record<string, CacheValue>,
    ): (...args: ConstructorParameters<T>) => InstanceType<T>;
  }
}

const getListFormat = memoizeFormatConstructor(Intl.ListFormat);
const getNumberFormat = memoizeFormatConstructor(Intl.NumberFormat);
const getDateTimeFormat = memoizeFormatConstructor(Intl.DateTimeFormat);
const getMessageFormat = memoizeFormatConstructor(intlMessageFormat);
const getRelativeFormat = memoizeFormatConstructor(Intl.RelativeTimeFormat);

type FormatType = 'list' | 'date' | 'number';

const LIST = 'list';
const DATE = 'date';
const NUMBER = 'number';

function getOptionsFromFormats(type: string, value: string | number, format?: string | unknown) {
  let options = format;

  if (typeof format === 'string') {
    try {
      options = (formats[type] as Record<string, unknown>)[format];
    } catch {
      options = undefined;
    }

    if (options === undefined) {
      throw new ReferenceError(`No '${format}' found in intl/formats.js for the '${type}' type (value ${value})`);
    }
  }

  return options;
}

// Main format function
function format(
  type: 'list',
  value: Parameters<Intl.ListFormat['format']>[0],
  optionsOrFormat?: string | Intl.ListFormatOptions,
): string;
function format(
  type: 'date',
  value: Parameters<Intl.DateTimeFormat['format']>[0],
  optionsOrFormat?: string | Intl.DateTimeFormatOptions,
): string;
function format(
  type: 'number',
  value: Parameters<Intl.NumberFormat['format']>[0],
  optionsOrFormat?: string | Intl.NumberFormatOptions,
): string;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function format(type: FormatType, value: any, optionsOrFormat: any): string {
  const options = getOptionsFromFormats(type, value, optionsOrFormat);

  switch (type) {
    case DATE:
      return getDateTimeFormat(locale, options as Intl.DateTimeFormatOptions).format(value);
    case NUMBER:
      return getNumberFormat(locale, options as Intl.NumberFormatOptions).format(value);
    case LIST:
      return getListFormat(locale, options as Intl.ListFormatOptions).format(value);
    default:
      throw new Error(`Unrecognized format type: ${type}. Value: ${value}`);
  }
}

function formatDate(date: utils.LooserDate, options?: string | Intl.DateTimeFormatOptions) {
  const dateInstance = typeof date === 'object' ? date : new Date(date);

  try {
    return format(DATE, dateInstance, options);
  } catch (error) {
    throw new Error(`A valid date/string/timestamp must be provided to formatDate(). Now it's: ${date}`, {
      cause: error,
    });
  }
}

// Returns relative string with specified unit using Intl.RelativeTimeFormat
function formatRelative(
  num: number,
  unit: Intl.RelativeTimeFormatUnit,
  optionsOrFormat?: string | Intl.RelativeTimeFormatOptions,
) {
  if (__DEV__) {
    if (typeof num !== NUMBER) {
      throw new TypeError(`A number must be provided to formatRelative(). Now it is: ${num}`);
    }

    if (typeof unit !== 'string' || !utils.TIME_UNITS.includes(unit)) {
      throw new TypeError(`A valid string must be provided as unit to formatNumber(). Now it is: ${unit}`);
    }
  }

  const options = getOptionsFromFormats('relative', num, optionsOrFormat) as Intl.RelativeTimeFormatOptions;

  return getRelativeFormat(locale, options).format(num, unit);
}

// Returns relative string with automatically found unit
// For example, '4 days ago', 'tomorrow', 'in one minute'
function formatRelativeBestFit(date: utils.LooserDate, options?: Intl.RelativeTimeFormatOptions) {
  const timestamp = typeof date === 'number' ? date : (date instanceof Date ? date : new Date(date)).getTime();

  if (__DEV__ && isNaN(timestamp)) {
    throw new TypeError(`A date, string or timestamp must be provided to formatRelativeAutoUnit(). Now it is: ${date}`);
  }

  const diff = selectUnit(timestamp);

  return formatRelative(diff.value, diff.unit, options ? {numeric: 'auto', ...options} : {numeric: 'auto'});
}

function formatNumber(num: number, options?: string | Intl.NumberFormatOptions) {
  if (typeof num !== NUMBER) {
    throw new TypeError(`A number must be provided to formatNumber(). Now it: ${num}`);
  }

  return format(NUMBER, num, options);
}

function formatList(array: Parameters<Intl.ListFormat['format']>[0], options?: string | Intl.ListFormatOptions) {
  if (!Array.isArray(array)) {
    throw new TypeError(`An array must be provided to formatList(). Now it is: ${array}`);
  }

  return format(LIST, array, options);
}

// If message contains react elements, replace them with this elements
const reactifyParams = (function () {
  const matchCache = new Map();
  const injectReactElement = (
    messagePart: string,
    params: Record<string, PrimitiveType | ReactElement>,
    keys: string[],
    index: number,
  ): ReactStrictNode[] => {
    const param = keys[index];

    if (!param) {
      return [messagePart];
    }

    let match = matchCache.get(messagePart + param);

    if (!match) {
      // Render of the same string can be invoked several times, so it's better to
      // cache match result for each message part, because creating regexp and matching are not for free
      match = messagePart.match(new RegExp(`^(.*)({${param}})(.*)$`));
      matchCache.set(messagePart + param, match);
    }

    if (!match || !match.length) {
      return [messagePart];
    }

    const result: ReactStrictNode[] = [];
    const left = match[1];
    const right = match[3];

    if (left) {
      result.push(...injectReactElement(left, params, keys, index + 1));
    }

    // This is a safe type assertion because only valid react elements are access here
    // so we don't need to worry about the value being Date or undefined
    result.push(params[param] as ReactStrictNode);

    if (right) {
      result.push(...injectReactElement(right, params, keys, index + 1));
    }

    return result;
  };

  return (message: string, params: Record<string, PrimitiveType | ReactElement>) => {
    const normalParams: Record<string, PrimitiveType> = {};
    const normalParamsKeys: string[] = [];
    const reactParamsKeys: string[] = [];

    for (const [param, value] of Object.entries(params)) {
      if (isValidElement(value)) {
        normalParams[param] = `{${param}}`;
        reactParamsKeys.push(param);
      } else {
        normalParams[param] = value;
        normalParamsKeys.push(param);
      }
    }

    const formatted = normalParamsKeys.length
      ? getMessageFormat(message, locale, formats).format(normalParams)
      : message;

    // Create Fragment and spread result array over it to avoid the need to specify react key for each item
    // Fallback to span while we support react 14
    return createElement(
      Fragment || 'span',
      {},
      ...(reactParamsKeys.length ? injectReactElement(formatted, params, reactParamsKeys, 0) : [formatted]),
    );
  };
})();

type KeyMapper = (key: string) => string;
type ValueMapper = (value: string) => string;

// Optional mapper function that takes key as an argument and can return another key. By default returns the same key
let intlKeyMapper: KeyMapper = key => key;
// Setter to change default intlKeyMapper, for example, can be called in case of Edge in its index.js
export const setIntlKeyMapper = (mapper: KeyMapper): KeyMapper => (intlKeyMapper = mapper);

let intlValueMapper: ValueMapper = value => value;
// Setter to change default intlValueMapper, for example, can be called in case of Edge in its index.js
export const setIntlValueMapper = (mapper: ValueMapper): ValueMapper => (intlValueMapper = mapper);

// Wrap a key into intlKey call to count it by plugin-transform-intl-inline. Key gets inlined (unwrapped from intlKey call).
// Useful in conjunction with intlKeyMapper, to map original key with with the desired one without calling real intl().
// For example, {'Common.Workload': intlKey('Edge.Endpoint')} transformed to {'Common.Workload': 'Edge.Endpoint'}
export const intlKey: KeyMapper = key => key;

interface FormatMessageOptions {
  /**
   * Params contain jsx (react elements)
   * we have html markup in message, but some of tags are react elements and we can't avoid it,
   * example we must wrap some param of message in Link, like 'You have <Link>{count}</Link> rules`,
   * can make like this:
   * You have {count} {countNumber, plural, =1 {rule} other {rules}}'
   * ('somekey', {count: <Link>{count}</Link>, countNumber: count})
   */
  jsx?: boolean;

  /**
   * Message contains html tags, so must not be escaped.
   * It useful when you have markup in your string (yeah, in some cases you can't avoid it because of ux-design).
   * For example, if string contains '<br>' and you put such string into your component
   * will escape it, and <br> will be as text on your page.
   * this flag method returns span with message as unescaped content, and '<br>' will remain line-break
   */
  html?: boolean;

  /**
   * Props that will be passed to html wrapper
   */
  htmlProps?: Record<string, unknown>;
}

// FIXME: instead of inline export default, this is a workaround.
// see https://github.com/import-js/eslint-plugin-import/issues/1590
export default formatMessage;

/**
 * Returns string, format it by ICU with intl-messageformat if params passed
 * @param key - Language key to find message in language bundle
 * @param params - ICU params
 * @param options - Options:
 * @param options.html - Message contains html tags, so must not be escaped
 *                                   It useful when you have markup in your string (yeah, in some cases you can't avoid it because of ux-design).
 *                                   For example, if string contains '<br>' and you put such string into your component
 *                                   react will escape it, and <br> will be as text on your page.
 *                                   With this flag method returns span with message as unescaped content, and '<br>' will remain line-break
 * @param options.htmlProps - Props that will be passed to html wrapper
 * @param options.jsx - Params contain jsx (react elements)
 *                                  If we have html markup in message, but some of tags are react elements and we can't avoid it,
 *                                  for example we must wrap some param of message in Link, like 'You have <Link>{count}</Link> rules`,
 *                                  we can make like this:
 *                                  'You have {count} {countNumber, plural, =1 {rule} other {rules}}'
 *                                  intl('somekey', {count: <Link>{count}</Link>, countNumber: count})
 */
function formatMessage(
  key: LangKeys,
  params: Record<string, PrimitiveType | ReactElement> | undefined,
  options: FormatMessageOptions & ({jsx: true} | {html: true}),
): ReactElement;
function formatMessage(
  key: LangKeys,
  params?: Record<string, PrimitiveType>,
  options?: FormatMessageOptions & {jsx?: false; html?: false},
): string;
function formatMessage(
  key: LangKeys,
  params?: Record<string, PrimitiveType | ReactElement>,
  {jsx = false, html = false, htmlProps}: FormatMessageOptions = {},
): string | ReactElement {
  const mappedKey = intlKeyMapper(key) as LangKeys;
  let message = messages[mappedKey];

  if (!message) {
    // Warn about keys that are not found in language bundle
    // Release task fails if it doesn't find some keys in bundle (plugin-transform-intl-inline)
    // This line exists in dev only, and is eliminated in release task
    if (__DEV__) {
      console.error('Intl not found key:', key);
    }

    return key;
  }

  if (params) {
    if (jsx) {
      return reactifyParams(message, params);
    }

    if (html) {
      params = Object.entries(params).reduce((result: Exclude<typeof params, undefined>, [name, value]) => {
        // need to encode(value) for cross-site scripting vulnerability
        // e.g. value = '<img src onerror=alert(1)'> convert to '&#x3C;img src=z onerror=alert(1)&#x3E';
        result[name] = typeof value === 'string' ? he.encode(value) : value;

        return result;
      }, {});
    }

    // we know params do not have ReactElements at this point
    message = getMessageFormat(message, locale, formats).format(params as Record<string, PrimitiveType>);
  }

  // Map message substrings _after_ it was formatted, to make sure we don't replace parameter names and other meta symbols
  message = intlValueMapper(message);

  return html ? createElement('span', {dangerouslySetInnerHTML: {__html: message}, ...htmlProps}) : message;
}

formatMessage.lang = lang;
formatMessage.locale = locale;

formatMessage.utils = utils;
formatMessage.format = format;
formatMessage.formats = formats;
formatMessage.list = formatList;
formatMessage.date = formatDate;
formatMessage.num = formatNumber;
formatMessage.rel = formatRelative;
formatMessage.relBestFit = formatRelativeBestFit;

// TODO: antman-only. Move this to antman/antman.js once we refactor jest into using the init() function in app.js
/* eslint-disable rulesdir/intl-call-verify */
if (__ANTMAN__) {
  const intl = formatMessage;
  const intlValuesMappingForAntman = {
    [intl('ErrorsAPI.err:rbac_cannot_operate_on_rule_with_broader_consumer_scope')]: intl(
      'Antman.ErrorsAPI.err:rbac_cannot_operate_on_rule_with_broader_consumer_scope',
    ),
    [intl('ErrorsAPI.err:rbac_rule_ub_scope_should_be_same_as_scope')]: intl(
      'Antman.ErrorsAPI.err:rbac_rule_ub_scope_should_be_same_as_scope',
    ),
    [intl('ErrorsAPI.err:service_provider_too_long')]: intl('Antman.ErrorsAPI.err:service_provider_too_long'),
    [intl('ErrorsAPI.err:rs_provider_required')]: intl('Antman.ErrorsAPI.err:rs_provider_required'),
    [intl('ErrorsAPI.err:rs_duplicate_provider')]: intl('Antman.ErrorsAPI.err:rs_duplicate_provider'),
    [intl('ErrorsAPI.err:rs_duplicate_consumer')]: intl('Antman.ErrorsAPI.err:rs_duplicate_consumer'),
    [intl('ErrorsAPI.err:cant_specify_ip_value_actor')]: intl('Antman.ErrorsAPI.err:cant_specify_ip_value_actor'),
    [intl('ErrorsAPI.err:ip_list_referenced_by_enforcement_boundary')]: intl(
      'Antman.ErrorsAPI.err:ip_list_referenced_by_enforcement_boundary',
    ),
    [intl('ErrorsAPI.err:resolve_actors_requires_rule_actors')]: intl(
      'Antman.ErrorsAPI.err:resolve_actors_requires_rule_actors',
    ),
    [intl('ErrorsAPI.err:rule_ub_must_be_managed')]: intl('Antman.ErrorsAPI.err:rule_ub_must_be_managed'),
    [intl('ErrorsAPI.err:rule_pb_only_ip_list_or_all')]: intl('Antman.ErrorsAPI.err:rule_pb_only_ip_list_or_all'),
    [intl('ErrorsAPI.err:provider_not_found')]: intl('Antman.ErrorsAPI.err:provider_not_found'),
    [intl('ErrorsAPI.err:consumer_not_found')]: intl('Antman.ErrorsAPI.err:consumer_not_found'),
    [intl('ErrorsAPI.err:label_still_has_associated_cwp')]: intl('Antman.ErrorsAPI.err:label_still_has_associated_cwp'),
    [intl('ErrorsAPI.err:label_still_has_associated_enforcement_boundary')]: intl(
      'Antman.ErrorsAPI.err:label_still_has_associated_enforcement_boundary',
    ),
    [intl('ErrorsAPI.err:ams_with_label_not_allowed')]: intl('Antman.ErrorsAPI.err:ams_with_label_not_allowed'),
    [intl('ErrorsAPI.err:label_group_referenced_by_enforcement_boundaries')]: intl(
      'Antman.ErrorsAPI.err:label_group_referenced_by_enforcement_boundaries',
    ),
    [intl('ErrorsAPI.err:service_referenced_by_enforcement_boundary')]: intl(
      'Antman.ErrorsAPI.err:service_referenced_by_enforcement_boundary',
    ),
    [intl('ErrorsAPI.err:stateless_rule_consumer_count_exceeded')]: intl(
      'Antman.ErrorsAPI.err:stateless_rule_consumer_count_exceeded',
    ),
    [intl('ErrorsAPI.err:invalid_windows_service_provider')]: intl(
      'Antman.ErrorsAPI.err:invalid_windows_service_provider',
    ),
    [intl('ErrorsAPI.err:non_brn_must_use_ip_list')]: intl('Antman.ErrorsAPI.err:non_brn_must_use_ip_list'),
    [intl('ErrorsAPI.err:virtual_server_no_service_with_workload')]: intl(
      'Antman.ErrorsAPI.err:virtual_server_no_service_with_workload',
    ),
    [intl('ErrorsAPI.err:virtual_server_non_workload_label_provider')]: intl(
      'Antman.ErrorsAPI.err:virtual_server_non_workload_label_provider',
    ),
    [intl('ErrorsAPI.err:service_binding_invalid_operation')]: intl(
      'Antman.ErrorsAPI.err:service_binding_invalid_operation',
    ),
    [intl('ErrorsAPI.err:container_cluster_virtual_service_edit')]: intl(
      'Antman.ErrorsAPI.err:container_cluster_virtual_service_edit',
    ),
    [intl('ErrorsAPI.err:container_cluster_virtual_service_delete')]: intl(
      'Antman.ErrorsAPI.err:container_cluster_virtual_service_delete',
    ),
    [intl('ErrorsAPI.err:ingress_service_must_be_empty')]: intl('Antman.ErrorsAPI.err:ingress_service_must_be_empty'),
    [intl('ErrorsAPI.err:ingress_service_required')]: intl('Antman.ErrorsAPI.err:ingress_service_required'),
    [intl('ErrorsAPI.err:resolve_labels_required')]: intl('Antman.ErrorsAPI.err:resolve_labels_required'),
    [intl('ErrorsAPI.err:stateless_rule_cannot_have_ip_list_provider')]: intl(
      'Antman.ErrorsAPI.err:stateless_rule_cannot_have_ip_list_provider',
    ),
    [intl('ErrorsAPI.err:invalid_egress_windows_service_consumer')]: intl(
      'Antman.ErrorsAPI.err:invalid_egress_windows_service_consumer',
    ),
    [intl('ErrorsAPI.err:container_host_rule_invalid_provider')]: intl(
      'Antman.ErrorsAPI.err:container_host_rule_invalid_provider',
    ),
    [intl('ErrorsAPI.err:container_host_rule_single_consumer')]: intl(
      'Antman.ErrorsAPI.err:container_host_rule_single_consumer',
    ),
    [intl('ErrorsAPI.err:container_host_rule_invalid_resolve_labels')]: intl(
      'Antman.ErrorsAPI.err:container_host_rule_invalid_resolve_labels',
    ),
    [intl('ErrorsAPI.err:container_host_rule_sec_connect_machine_auth')]: intl(
      'Antman.ErrorsAPI.err:container_host_rule_sec_connect_machine_auth',
    ),
    [intl('ErrorsAPI.err:container_host_rule_stateless')]: intl('Antman.ErrorsAPI.err:container_host_rule_stateless'),
    [intl('ErrorsAPI.err:container_host_rule_no_windows_service')]: intl(
      'Antman.ErrorsAPI.err:container_host_rule_no_windows_service',
    ),
    [intl('ErrorsAPI.err:container_host_rule_tcp_only')]: intl('Antman.ErrorsAPI.err:container_host_rule_tcp_only'),
    [intl('ErrorsAPI.err:namespace_key_mismatch')]: intl('Antman.ErrorsAPI.err:namespace_key_mismatch'),
    [intl('ErrorsAPI.err:container_workload_profile_invalid_pairing_key')]: intl(
      'Antman.ErrorsAPI.err:container_workload_profile_invalid_pairing_key',
    ),
    [intl('ErrorsAPI.err:unmanaged_container_workload_profile_labels')]: intl(
      'Antman.ErrorsAPI.err:unmanaged_container_workload_profile_labels',
    ),
    [intl('ErrorsAPI.err:ves_invalid_projected_actor')]: intl('Antman.ErrorsAPI.err:ves_invalid_projected_actor'),
    // ========== ErrorsAPI remapping ends ===========

    [intl('Common.Consumer')]: {
      value: intl('Common.Source'),
      ignoreList: [intl('Users.SSOConfig.AssertionConsumerUrl')],
    },
    [intl('Common.Consuming')]: intl('Common.Source'),
    [intl('Common.Consumers')]: intl('Common.Sources'),
    [intl('Common.ConsumerFqdn')]: intl('Common.SourceFqdn'),
    [intl('Common.Provider')]: {
      value: intl('Common.Destination'),
      ignoreList: [
        intl('Users.SSOConfig.InfoFromIdentityProvider'),
        intl('Users.SSOConfig.SAMLIdentityProviderCertificate'),
        intl('Users.SSOConfig.InfoForIdentityProvider'),
      ],
    },
    [intl('Common.Providing')]: intl('Common.Destination'),
    [intl('Common.Providers')]: intl('Common.Destinations'),
    [intl('Common.ProviderFqdn')]: intl('Common.DestinationFqdn'),
    [intl('Common.ProviderPort')]: intl('Common.DestinationPort'),
    [intl('Common.ProviderOrConsumer')]: intl('Common.DestinationsOrSources'),
    [intl('Common.ProviderAndConsumer')]: intl('Common.DestinationsAndSources'),
    [intl('Common.ConsumerOrProvider')]: intl('Common.SourcesOrDestinations'),
    [intl('Common.ConsumerAndProvider')]: intl('Common.SourcesAndDestinations'),
    [intl('Common.Consumed')]: intl('Edge.Consumed'),
    [intl('Common.NotConsumed')]: intl('Edge.NotConsumed'),
    [intl('Explorer.ConsumerRoles')]: intl('Explorer.SourceRoles'),
    [intl('Explorer.ConsumerProcessService')]: intl('Explorer.SourceProcessService'),
    [intl('Explorer.ConsumerName')]: intl('Explorer.SourceName'),
    [intl('Explorer.ConsumerNames')]: intl('Explorer.SourceNames'),
    [intl('Explorer.ConsumerIPAddresses')]: intl('Explorer.SourceIPAddresses'),
    [intl('Explorer.ConsumerIP')]: intl('EventUtils.SourceIP'),
    [intl('Explorer.ConsumerIPList')]: intl('Explorer.SourceIPList'),
    [intl('Explorer.ConsumerSelections')]: intl('Explorer.SourceSelections'),
    [intl('Explorer.ProviderIP')]: intl('Common.DestinationIP'),
    [intl('Explorer.ProviderIPAddresses')]: intl('Explorer.DestinationIPAddresses'),
    [intl('Explorer.ProviderNames')]: intl('Explorer.DestinationNames'),
    [intl('Explorer.ProviderName')]: intl('Explorer.DestinationName'),
    [intl('Explorer.ProviderHostname')]: intl('Explorer.DestinationHostname'),
    [intl('Explorer.ProviderRoles')]: intl('Explorer.DestinationRoles'),
    [intl('Explorer.ProviderProcessService')]: intl('Explorer.DestinationProcessService'),
    [intl('Explorer.ProviderSelections')]: intl('Explorer.DestinationSelections'),
    [intl('Explorer.SourceInclude')]: intl('Edge.Explorer.SourceInclude'),
    [intl('Explorer.SourceExclude')]: intl('Edge.Explorer.SourceExclude'),
    [intl('Rulesets.Rules.ProvidingService')]: intl('Rulesets.Rules.DestinationService'),
    [intl('Rulesets.Rules.ProvidingProcess')]: intl('Common.DestinationProcess'),
    [intl('Rulesets.Rules.ProvidingUsername')]: intl('Rulesets.Rules.DestinationUsername'),
    [intl('Rulesets.Rules.ConsumingUsername')]: intl('Rulesets.Rules.SourceUsername'),
    [intl('Rulesets.Rules.ConsumingService')]: intl('Rulesets.Rules.SourceService'),
    [intl('Rulesets.Rules.ExtraScope.Title')]: intl('Edge.DenyRule'),
    [intl('Rulesets.Rules.ExtraScope.NoData')]: intl('EnforcementBoundaries.NoData'),
    [intl('Policy.ConsumerFirst')]: intl('Policy.SourceFirst'),
    [intl('Policy.ProviderConsumerOrder')]: intl('Policy.SourceDestinationOrder'),
    [intl('Policy.ProviderFirst')]: intl('Policy.DestinationFirst'),
    [intl('Port.PortProcess')]: intl('Edge.Port.PortProcess'),
    [intl('EnforcementBoundaries.Rules')]: intl('Rulesets.DenyRules'),
    [intl('EnforcementBoundaries.ProvidingServices')]: intl('Rulesets.Rules.DestinationServices'),
    [intl('Workloads.EnforcementBoundary')]: intl('Edge.DenyRule'),
    [intl('Workloads.EnforcementBoundaries')]: intl('Rulesets.DenyRules'),
    [intl('RBAC.ServiceAccounts.RolesAndScopes')]: intl('Common.Roles'),
    [intl('RBAC.GlobalRoles')]: intl('Common.Roles'),
    [intl('EnforcementBoundaries.ProvidingServices')]: intl('Common.Services'),
    [intl('Common.ViewScope')]: intl('Common.View'),
    [intl('Users.AccessScopesRoles')]: intl('Users.AccessRoles'),
    [intl('Users.AddGlobalRole')]: intl('Users.AddRole'),
    [intl('Users.ConvertLocalUserWarning')]: intl('Users.ConvertLocalUserWarningWithoutScope'),
    // map the core name to antman name
    [intl('Common.ConsumersGlobal')]: intl('Antman.Common.ConsumersGlobal'),
    [intl('Common.ContainerWorkloads')]: intl('Antman.Common.ContainerWorkloads'),
    [intl('Common.ContainerWorkload')]: intl('Antman.Common.ContainerWorkload'),
    [intl('Common.DraftEnforcementBoundary')]: intl('Antman.Common.DraftEnforcementBoundary'),
    [intl('Common.InUseByContainerWorkloads')]: intl('Antman.Common.InUseByContainerWorkloads'),
    [intl('Common.InUseByContainerWorkloadsProfiles')]: intl('Antman.Common.InUseByContainerWorkloadsProfiles'),
    [intl('Common.NoDataContainerWorkloads')]: intl('Antman.Common.NoDataContainerWorkloads'),
    [intl('Common.NoDataContainerCluster')]: intl('Antman.Common.NoDataContainerCluster'),
    [intl('Common.NoDataContainerWorkloadProfiles')]: intl('Antman.Common.NoDataContainerWorkloadProfiles'),
    [intl('Common.NoMatchDataContainerClusters')]: intl('Antman.Common.NoMatchDataContainerClusters'),
    [intl('Common.NoMatchDataContainerWorkloads')]: intl('Antman.Common.NoMatchDataContainerWorkloads'),
    [intl('Common.PotentiallyBlocked')]: intl('Antman.Common.PotentiallyBlocked'),
    [intl('Common.PotentiallyBlockedByBoundary')]: intl('Antman.Common.PotentiallyBlockedByBoundary'),
    [intl('Common.ReportedEnforcementBoundary')]: intl('Antman.Common.ReportedEnforcementBoundary'),
    [intl('Common.SelectConsumers')]: intl('Antman.Common.SelectConsumers'),
    [intl('Common.SelectProviders')]: intl('Antman.Common.SelectProviders'),
    [intl('AppGroups.ContainerWorkloads')]: intl('Antman.AppGroups.ContainerWorkloads'),
    [intl('BlockedTraffic.BlockedOnConsumer')]: intl('Antman.BlockedTraffic.BlockedOnConsumer'),
    [intl('BlockedTraffic.BlockedOnProvider')]: intl('Antman.BlockedTraffic.BlockedOnProvider'),
    [intl('BlockedTraffic.PotentiallyBlockedOnConsumer')]: intl('Antman.BlockedTraffic.PotentiallyBlockedOnConsumer'),
    [intl('BlockedTraffic.PotentiallyBlockedOnProvider')]: intl('Antman.BlockedTraffic.PotentiallyBlockedOnProvider'),
    [intl('BlockedTraffic.List.ByTheConsumer')]: intl('Antman.BlockedTraffic.List.ByTheConsumer'),
    [intl('BlockedTraffic.List.ByTheProvider')]: intl('Antman.BlockedTraffic.List.ByTheProvider'),
    [intl('BlockedTraffic.List.DescriptionSelective')]: intl('Antman.BlockedTraffic.List.DescriptionSelective'),
    [intl('Dashboard.BlockedOrPotentiallyBlockedFlow')]: intl('Antman.Dashboard.BlockedOrPotentiallyBlockedFlow'),
    [intl('ContainerClusters.AddNew')]: intl('Antman.ContainerClusters.AddNew'),
    [intl('ContainerClusters.UseContainerAnnotations')]: intl('Antman.ContainerClusters.UseContainerAnnotations'),
    [intl('ContainerClusters.Delete')]: intl('Antman.ContainerClusters.Delete'),
    [intl('ContainerClusters.DeleteConfirm')]: intl('Antman.ContainerClusters.DeleteConfirm'),
    [intl('ContainerClusters.DeletedSuccessfully')]: intl('Antman.ContainerClusters.DeletedSuccessfully'),
    [intl('ContainerClusters.CannotDelete')]: intl('Antman.ContainerClusters.CannotDelete'),
    [intl('ContainerClusters.DuplicateMachineIDsError')]: intl('Antman.ContainerClusters.DuplicateMachineIDsError'),
    [intl('ContainerClusters.ContainerWorkloadProfiles')]: intl('Antman.ContainerClusters.ContainerWorkloadProfiles'),
    [intl('ContainerClusters.ContainerWorkloadProfilesMessage')]: intl(
      'Antman.ContainerClusters.ContainerWorkloadProfilesMessage',
    ),
    [intl('ContainerClusters.ContainerWorkloadProfilesAdd')]: intl(
      'Antman.ContainerClusters.ContainerWorkloadProfilesAdd',
    ),
    [intl('ContainerClusters.ContainerWorkloadProfilesUnmanaged')]: intl(
      'Antman.ContainerClusters.ContainerWorkloadProfilesUnmanaged',
    ),
    [intl('ContainerClusters.ContainerWorkloadProfileTemplate')]: intl(
      'Antman.ContainerClusters.ContainerWorkloadProfileTemplate',
    ),
    [intl('ContainerClusters.DeleteContainerWorkloadProfile')]: intl(
      'Antman.ContainerClusters.DeleteContainerWorkloadProfile',
    ),
    [intl('ContainerClusters.DeleteContainerWorkloadProfileConfirm')]: intl(
      'Antman.ContainerClusters.DeleteContainerWorkloadProfileConfirm',
    ),
    [intl('ContainerClusters.DeletedContainerWorkloadProfileSuccessfully')]: intl(
      'Antman.ContainerClusters.DeletedContainerWorkloadProfileSuccessfully',
    ),
    [intl('ContainerClusters.CannotDeleteContainerWorkloadProfile')]: intl(
      'Antman.ContainerClusters.CannotDeleteContainerWorkloadProfile',
    ),
    [intl('ContainerClusters.AddNewContainerWorkloadProfile')]: intl(
      'Antman.ContainerClusters.AddNewContainerWorkloadProfile',
    ),
    [intl('ContainerClusters.CreatedByContainerCluster')]: intl('Antman.ContainerClusters.CreatedByContainerCluster'),
    [intl('ContainerClusters.UndeletedVirtualServices')]: intl('Antman.ContainerClusters.UndeletedVirtualServices'),
    [intl('VirtualServices.BoundContainerWorkloads')]: intl('Antman.VirtualServices.BoundContainerWorkloads'),
    [intl('VirtualServices.ManagedBy.ContainerCluster')]: intl('Antman.VirtualServices.ManagedBy.ContainerCluster'),
    [intl('VirtualServices.ManagedBy.ContainerClusterDeletedAndOnLeader')]: intl(
      'Antman.VirtualServices.ManagedBy.ContainerClusterDeletedAndOnLeader',
    ),
    [intl('VirtualServices.ManagedBy.ContainerClusterDeletedAndOnMember')]: intl(
      'Antman.VirtualServices.ManagedBy.ContainerClusterDeletedAndOnMember',
    ),
    [intl('VirtualServices.AddWorkloads.Description')]: intl('Antman.VirtualServices.AddWorkloads.Description'),
    [intl('Connectivity.ConsumerError')]: intl('Antman.Connectivity.ConsumerError'),
    [intl('Connectivity.ConsumerMissing')]: intl('Antman.Connectivity.ConsumerMissing'),
    [intl('Connectivity.PortAndProtocol')]: intl('Antman.Connectivity.PortAndProtocol'),
    [intl('Connectivity.ProviderError')]: intl('Antman.Connectivity.ProviderError'),
    [intl('Connectivity.ProviderProvider')]: intl('Antman.Connectivity.ProviderProvider'),
    [intl('Connectivity.SelectWorkloadContainerWorkloadOrIP')]: intl(
      'Antman.Connectivity.SelectWorkloadContainerWorkloadOrIP',
    ),
    [intl('Connectivity.VerifyRulesExist')]: intl('Antman.Connectivity.VerifyRulesExist'),
    [intl('ContainerWorkloads.All')]: intl('Antman.ContainerWorkloads.All'),
    [intl('ContainerWorkloads.View')]: intl('Antman.ContainerWorkloads.View'),
    [intl('ContainerWorkloads.ByCount')]: intl('Antman.ContainerWorkloads.ByCount'),
    [intl('ContainerWorkloads.WithCount')]: intl('Antman.ContainerWorkloads.WithCount'),
    [intl('ContainerWorkloads.Total')]: intl('Antman.ContainerWorkloads.Total'),
    [intl('CoreServices.AcceptedCoreService')]: intl('Antman.CoreServices.AcceptedCoreService'),
    [intl('CoreServices.RejectedCoreService')]: intl('Antman.CoreServices.RejectedCoreService'),
    [intl('CoreServices.AcceptedNotification')]: intl('Antman.CoreServices.AcceptedNotification'),
    [intl('CoreServices.AcceptedError')]: intl('Antman.CoreServices.AcceptedError'),
    [intl('CoreServices.RejectAcceptedError')]: intl('Antman.CoreServices.RejectAcceptedError'),
    [intl('Events.PotentiallyBlockedTraffic')]: intl('Antman.Events.PotentiallyBlockedTraffic'),
    [intl('EventUtils.ContainerWorkloadInvalidPairingConfig')]: intl(
      'Antman.EventUtils.ContainerWorkloadInvalidPairingConfig',
    ),
    [intl('EventUtils.ContainerWorkloadUpdateServices')]: intl('Antman.EventUtils.ContainerWorkloadUpdateServices'),
    [intl('EventUtils.ContainerWorkloadNotCreated')]: intl('Antman.EventUtils.ContainerWorkloadNotCreated'),
    [intl('EventUtils.ContainerWorkloadProfileCreated')]: intl('Antman.EventUtils.ContainerWorkloadProfileCreated'),
    [intl('EventUtils.ContainerWorkloadProfileDeleted')]: intl('Antman.EventUtils.ContainerWorkloadProfileDeleted'),
    [intl('EventUtils.ContainerWorkloadProfileUpdated')]: intl('Antman.EventUtils.ContainerWorkloadProfileUpdated'),
    [intl('EventUtils.ContainerClusterCreated')]: intl('Antman.EventUtils.ContainerClusterCreated'),
    [intl('EventUtils.ContainerClusterDeleted')]: intl('Antman.EventUtils.ContainerClusterDeleted'),
    [intl('EventUtils.ContainerClusterUpdated')]: intl('Antman.EventUtils.ContainerClusterUpdated'),
    [intl('EventUtils.ContainerClusterDuplicateMachineID')]: intl(
      'Antman.EventUtils.ContainerClusterDuplicateMachineID',
    ),
    [intl('EventUtils.ContainerClusterRegionMismatch')]: intl('Antman.EventUtils.ContainerClusterRegionMismatch'),
    [intl('EventUtils.EnforcementBoundariesRuleCreate')]: intl('Antman.EventUtils.EnforcementBoundariesRuleCreate'),
    [intl('EventUtils.EnforcementBoundariesRuleUpdate')]: intl('Antman.EventUtils.EnforcementBoundariesRuleUpdate'),
    [intl('EventUtils.EnforcementBoundariesRuleDelete')]: intl('Antman.EventUtils.EnforcementBoundariesRuleDelete'),
    [intl('EventUtils.ContainerClusterChangeRate')]: intl('Antman.EventUtils.ContainerClusterChangeRate'),
    [intl('Help.Desc.ProvidingService')]: intl('Antman.Help.Desc.ProvidingService'),
    [intl('Help.Desc.ProvidedTraffic')]: intl('Antman.Help.Desc.ProvidedTraffic'),
    [intl('Help.Desc.Explorer')]: intl('Antman.Help.Desc.Explorer'),
    [intl('Help.Desc.PolicySettings')]: intl('Antman.Help.Desc.PolicySettings'),
    [intl('Help.Desc.CCServices')]: intl('Antman.Help.Desc.CCServices'),
    [intl('Help.Desc.ContainerClusters')]: intl('Antman.Help.Desc.ContainerClusters'),
    [intl('Help.Desc.ContainerWorkloadInIllumination')]: intl('Antman.Help.Desc.ContainerWorkloadInIllumination'),
    [intl('Help.Desc.ContainerInheritHostPolicy')]: intl('Antman.Help.Desc.ContainerInheritHostPolicy'),
    [intl('Help.Desc.RejectedCoreService')]: intl('Antman.Help.Desc.RejectedCoreService'),
    [intl('Help.Desc.EnforcementBoundaries')]: intl('Antman.Help.Desc.EnforcementBoundaries'),
    [intl('Help.Desc.SelectiveEnforcementState')]: intl('Antman.Help.Desc.SelectiveEnforcementState'),
    [intl('Help.Desc.EnforcementBoundaryWorkflow')]: intl('Antman.Help.Desc.EnforcementBoundaryWorkflow'),
    [intl('Help.Desc.EnforcementBoundaryBlockedConnections')]: intl(
      'Antman.Help.Desc.EnforcementBoundaryBlockedConnections',
    ),
    [intl('Help.Desc.AllowSelectedConnections')]: intl('Antman.Help.Desc.AllowSelectedConnections'),
    [intl('Help.Title.EnforcementBoundaryWorkflow')]: intl('Antman.Help.Title.EnforcementBoundaryWorkflow'),
    [intl('IPLists.List.Message.CannotDeleteBoundToEnforcementBoundary')]: intl(
      'Antman.IPLists.List.Message.CannotDeleteBoundToEnforcementBoundary',
    ),
    [intl('Labels.ProviderEdit')]: intl('Antman.Labels.ProviderEdit'),
    [intl('Labels.ConsumerEdit')]: intl('Antman.Labels.ConsumerEdit'),
    [intl('Map.CantChangeLabelsOnContainers')]: intl('Antman.Map.CantChangeLabelsOnContainers'),
    [intl('Map.ConsumingAppGroups')]: intl('Antman.Map.ConsumingAppGroups'),
    [intl('Map.ProvidingAppGroups')]: intl('Antman.Map.ProvidingAppGroups'),
    [intl('Map.SearchConsumingAppGroups')]: intl('Antman.Map.SearchConsumingAppGroups'),
    [intl('Map.SearchProvidingAppGroups')]: intl('Antman.Map.SearchProvidingAppGroups'),
    [intl('Map.ExtraScopeConsumers')]: intl('Antman.Map.ExtraScopeConsumers'),
    [intl('Map.ViewBoundContainerWorkloads')]: intl('Antman.Map.ViewBoundContainerWorkloads'),
    [intl('Map.Search.ContainerWorkload')]: intl('Antman.Map.Search.ContainerWorkload'),
    [intl('Map.FlowVisibility.BlockAllowed')]: intl('Antman.Map.FlowVisibility.BlockAllowed'),
    [intl('Map.FlowVisibility.BlockAllowedEdge')]: intl('Antman.Map.FlowVisibility.BlockAllowedEdge'),
    [intl('Map.FlowVisibility.BlockTraffic')]: intl('Antman.Map.FlowVisibility.BlockTraffic'),
    [intl('Map.FlowVisibility.EnhancedDataCollectionDesc')]: intl(
      'Antman.Map.FlowVisibility.EnhancedDataCollectionDesc',
    ),
    [intl('Map.LegendPanel.PotentiallyBlockedVulnerableTraffic')]: intl(
      'Antman.Map.LegendPanel.PotentiallyBlockedVulnerableTraffic',
    ),
    [intl('Map.Workloads.RoleContainerWorkloads')]: intl('Antman.Map.Workloads.RoleContainerWorkloads'),
    [intl('Menu.ContainerClusters')]: intl('Antman.Menu.ContainerClusters'),
    [intl('EnforcementBoundary.AddNew')]: intl('Antman.EnforcementBoundary.AddNew'),
    [intl('Port.ProtocolProcess')]: intl('Antman.Port.ProtocolProcess'),
    [intl('Port.ConsumerProcess')]: intl('Antman.Port.ConsumerProcess'),
    [intl('Provision.TallyLabel.EnforcementBoundaries')]: intl('Antman.Tally.Deny'),
    [intl('Role.GlobalOrgOwner')]: intl('Antman.Role.GlobalOrgOwner'),
    [intl('Role.GlobalAdmin')]: intl('Antman.Role.GlobalAdmin'),
    [intl('Role.GlobalPolicyObjectProvisioner')]: intl('Antman.Role.GlobalPolicyObjectProvisioner'),
    [intl('Role.GlobalReadOnly')]: intl('Antman.Role.GlobalReadOnly'),
    [intl('Rule.Validation.IPListsCantBeProviderAndConsumer')]: intl(
      'Antman.Rule.Validation.IPListsCantBeProviderAndConsumer',
    ),
    [intl('Rule.Validation.ProcessServicesCantWithIPListProvider')]: intl(
      'Antman.Rule.Validation.ProcessServicesCantWithIPListProvider',
    ),
    [intl('Rule.Validation.RequiresProviderServiceConsumer')]: intl(
      'Antman.Rule.Validation.RequiresProviderServiceConsumer',
    ),
    [intl('Rule.Validation.SecureConnectCantWithIPListBoundVirtual')]: intl(
      'Antman.Rule.Validation.SecureConnectCantWithIPListBoundVirtual',
    ),
    [intl('Rule.Validation.UserGroupsCantWithIPListConsumer')]: intl(
      'Antman.Rule.Validation.UserGroupsCantWithIPListConsumer',
    ),
    [intl('Rule.Validation.StatelessWithLabelGroup')]: intl('Antman.Rule.Validation.StatelessWithLabelGroup'),
    [intl('Rule.Validation.StatelessWithIPList')]: intl('Antman.Rule.Validation.StatelessWithIPList'),
    [intl('Rule.Validation.StatelessInvalidConsumers')]: intl('Antman.Rule.Validation.StatelessInvalidConsumers'),
    [intl('Rule.Validation.MachineAuthWithNullService')]: intl('Antman.Rule.Validation.MachineAuthWithNullService'),
    [intl('Rule.Validation.MachineAuthWithNullUbService')]: intl('Antman.Rule.Validation.MachineAuthWithNullUbService'),
    [intl('Rule.Validation.SecureConnectWithNullUbService')]: intl(
      'Antman.Rule.Validation.SecureConnectWithNullUbService',
    ),
    [intl('Rule.Validation.ProviderIpListWithMachineAuth')]: intl(
      'Antman.Rule.Validation.ProviderIpListWithMachineAuth',
    ),
    [intl('Rule.Validation.ConsumerIpListWithMachineAuth')]: intl(
      'Antman.Rule.Validation.ConsumerIpListWithMachineAuth',
    ),
    [intl('Rule.Validation.ProviderWithIpListScopedUser')]: intl('Antman.Rule.Validation.ProviderWithIpListScopedUser'),
    [intl('Rule.Validation.ProviderWithIpListWorkload')]: intl('Antman.Rule.Validation.ProviderWithIpListWorkload'),
    [intl('Rule.Validation.ProviderIpListWindowsService')]: intl('Antman.Rule.Validation.ProviderIpListWindowsService'),
    [intl('Rule.Validation.ProviderIpListVirtualServiceVirtualServerWorkload')]: intl(
      'Antman.Rule.Validation.ProviderIpListVirtualServiceVirtualServerWorkload',
    ),
    [intl('Rule.Validation.ConsumerWithVirtualService')]: intl('Antman.Rule.Validation.ConsumerWithVirtualService'),
    [intl('Rule.Validation.BothVirtualServicesOnlyVirtualServicesWorkloads')]: intl(
      'Antman.Rule.Validation.BothVirtualServicesOnlyVirtualServicesWorkloads',
    ),
    [intl('Rule.Validation.ConsumerIpListWorkload')]: intl('Antman.Rule.Validation.ConsumerIpListWorkload'),
    [intl('Rule.Validation.ConsumerIpListVirtualServiceVirtualServerWorkload')]: intl(
      'Antman.Rule.Validation.ConsumerIpListVirtualServiceVirtualServerWorkload',
    ),
    [intl('Rule.Validation.ProvidingServicesVirtualServices')]: intl(
      'Antman.Rule.Validation.ProvidingServicesVirtualServices',
    ),
    [intl('Rule.Validation.ProviderWithVirtualService')]: intl('Antman.Rule.Validation.ProviderWithVirtualService'),
    [intl('Rule.Validation.ContainerHostProviders')]: intl('Antman.Rule.Validation.ContainerHostProviders'),
    [intl('Rule.Validation.ContainerHostItself')]: intl('Antman.Rule.Validation.ContainerHostItself'),
    [intl('Rule.Validation.ContainerHostSecureConnectMachineAuthStateless')]: intl(
      'Antman.Rule.Validation.ContainerHostSecureConnectMachineAuthStateless',
    ),
    [intl('Rule.Validation.ContainerHostNonTcpService')]: intl('Antman.Rule.Validation.ContainerHostNonTcpService'),
    [intl('Rule.Validation.NonCorporateMustUseIpList')]: intl('Antman.Rule.Validation.NonCorporateMustUseIpList'),
    [intl('Rule.Validation.NetworkTypeMustBeDefaultCorporate')]: intl(
      'Antman.Rule.Validation.NetworkTypeMustBeDefaultCorporate',
    ),
    [intl('Rule.Validation.ConsumerMustHaveOnlyIPLists')]: intl('Antman.Rule.Validation.ConsumerMustHaveOnlyIPLists'),
    [intl('Rule.Validation.ProviderMustHaveOnlyIPLists')]: intl('Antman.Rule.Validation.ProviderMustHaveOnlyIPLists'),
    [intl('PolicyGenerator.MergeConsumers')]: intl('Antman.PolicyGenerator.MergeConsumers'),
    [intl('PolicyGenerator.MergeServices')]: intl('Antman.PolicyGenerator.MergeServices'),
    [intl('PolicyGenerator.ExtraScope.NumConsumingAppGroup')]: intl(
      'Antman.PolicyGenerator.ExtraScope.NumConsumingAppGroup',
    ),
    [intl('RuleSearch.AdvancedDescription')]: intl('Antman.RuleSearch.AdvancedDescription'),
    [intl('RuleSearch.Placeholders.Providers')]: intl('Antman.RuleSearch.Placeholders.Providers'),
    [intl('RuleSearch.Placeholders.Consumers')]: intl('Antman.RuleSearch.Placeholders.Consumers'),
    [intl('Rulesets.NewProposedRuleset')]: intl('Antman.Rulesets.NewProposedRuleset'),
    [intl('Rulesets.EnforcementBoundaryRuleset')]: intl('Antman.Rulesets.EnforcementBoundaryRuleset'),
    [intl('Rulesets.Rules.DerivedFromProviderVirtualServices')]: intl(
      'Antman.Rulesets.Rules.DerivedFromProviderVirtualServices',
    ),
    [intl('Rulesets.Rules.IntraToExtra')]: intl('Antman.Rulesets.Rules.IntraToExtra'),
    [intl('Rulesets.Rules.ExtraToIntra')]: intl('Antman.Rulesets.Rules.ExtraToIntra'),
    [intl('Rulesets.Rules.ExtraScope.Add')]: intl('Antman.Rulesets.Rules.ExtraScope.Add'),
    [intl('Rulesets.Rules.ExtraScope.NoMatch')]: intl('Antman.Rulesets.Rules.ExtraScope.NoMatch'),
    [intl('Rulesets.Rules.ExtraScope.Rules')]: intl('Antman.Rulesets.Rules.ExtraScope.Rules'),
    [intl('Rulesets.Rules.ExtraScope.IPListWarning')]: intl('Antman.Rulesets.Rules.ExtraScope.IPListWarning'),
    [intl('Rulesets.Rules.IntraScope.Title')]: intl('Antman.Rulesets.Rules.IntraScope.Title'),
    [intl('Rulesets.Rules.IntraScope.Add')]: intl('Antman.Rulesets.Rules.IntraScope.Add'),
    [intl('Rulesets.Rules.IntraScope.NoData')]: intl('Antman.Rulesets.Rules.IntraScope.NoData'),
    [intl('Rulesets.Rules.IntraScope.NoMatch')]: intl('Antman.Rulesets.Rules.IntraScope.NoMatch'),
    [intl('Rulesets.Rules.IntraScope.Rules')]: intl('Antman.Rulesets.Rules.IntraScope.Rules'),
    [intl('Services.List.CannotDeleteBoundToEnforcementBoundary')]: intl(
      'Antman.Services.List.CannotDeleteBoundToEnforcementBoundary',
    ),
    [intl('Settings.Containers.ContainerInheritHostPolicyDesc')]: intl(
      'Antman.Settings.Containers.ContainerInheritHostPolicyDesc',
    ),
    [intl('Settings.Containers.AddScopeForContainerInheritHostPolicy')]: intl(
      'Antman.Settings.Containers.AddScopeForContainerInheritHostPolicy',
    ),
    [intl('Settings.Containers.EditScopeForContainerInheritHostPolicy')]: intl(
      'Antman.Settings.Containers.EditScopeForContainerInheritHostPolicy',
    ),
    [intl('Settings.Containers.ConfigInstruction')]: intl('Antman.Settings.Containers.ConfigInstruction'),
    [intl('Settings.Containers.DuplicatedScopesAndLabels')]: intl(
      'Antman.Settings.Containers.DuplicatedScopesAndLabels',
    ),
    [intl('Settings.Containers.UnmanagedDesc')]: intl('Antman.Settings.Containers.UnmanagedDesc'),
    [intl('Settings.Containers.ManagedDesc')]: intl('Antman.Settings.Containers.ManagedDesc'),
    [intl('Settings.Containers.AnnotationLabelHint')]: intl('Antman.Settings.Containers.AnnotationLabelHint'),
    [intl('Settings.Containers.AnnotationLabelTooltip')]: intl('Antman.Settings.Containers.AnnotationLabelTooltip'),
    [intl('Settings.IPForwarding.Description')]: intl('Antman.Settings.IPForwarding.Description'),
    [intl('TrafficEvents.PotentiallyBlockedEvents')]: intl('Antman.TrafficEvents.PotentiallyBlockedEvents'),
    [intl('Explorer.FQDNNotSupported')]: intl('Antman.Explorer.FQDNNotSupported'),
    [intl('Explorer.ProviderIPList')]: intl('Antman.Explorer.ProviderIPList'),
    [intl('Explorer.ConsumerLabels')]: intl('Antman.Explorer.ConsumerLabels'),
    [intl('Explorer.ConsumerHostname')]: intl('Antman.Explorer.ConsumerHostname'),
    [intl('Explorer.ConsumerRole')]: intl('Antman.Explorer.ConsumerRole'),
    [intl('Explorer.ConsumerEnv')]: intl('Antman.Explorer.ConsumerEnv'),
    [intl('Explorer.ConsumerApp')]: intl('Antman.Explorer.ConsumerApp'),
    [intl('Explorer.ConsumerLoc')]: intl('Antman.Explorer.ConsumerLoc'),
    [intl('Explorer.ConsumerEnvironments')]: intl('Antman.Explorer.ConsumerEnvironments'),
    [intl('Explorer.ConsumerApplications')]: intl('Antman.Explorer.ConsumerApplications'),
    [intl('Explorer.ConsumerLocations')]: intl('Antman.Explorer.ConsumerLocations'),
    [intl('Explorer.ConsumerCount')]: intl('Antman.Explorer.ConsumerCount'),
    [intl('Explorer.ProviderCount')]: intl('Antman.Explorer.ProviderCount'),
    [intl('Explorer.ProviderLabels')]: intl('Antman.Explorer.ProviderLabels'),
    [intl('Explorer.ProviderEnvironments')]: intl('Antman.Explorer.ProviderEnvironments'),
    [intl('Explorer.ProviderApplications')]: intl('Antman.Explorer.ProviderApplications'),
    [intl('Explorer.ProviderLocations')]: intl('Antman.Explorer.ProviderLocations'),
    [intl('Explorer.ProviderRole')]: intl('Antman.Explorer.ProviderRole'),
    [intl('Explorer.ProviderEnv')]: intl('Antman.Explorer.ProviderEnv'),
    [intl('Explorer.ProviderApp')]: intl('Antman.Explorer.ProviderApp'),
    [intl('Explorer.ProviderLoc')]: intl('Antman.Explorer.ProviderLoc'),
    [intl('Explorer.ProviderEnforcement')]: intl('Antman.Explorer.ProviderEnforcement'),
    [intl('Explorer.ConsumerIsNot')]: intl('Antman.Explorer.ConsumerIsNot'),
    [intl('Explorer.ProviderIsNot')]: intl('Antman.Explorer.ProviderIsNot'),
    [intl('Explorer.IncludeConsumers')]: intl('Antman.Explorer.IncludeConsumers'),
    [intl('Explorer.ExcludeConsumers')]: intl('Antman.Explorer.ExcludeConsumers'),
    [intl('Explorer.IncludeProviders')]: intl('Antman.Explorer.IncludeProviders'),
    [intl('Explorer.ExcludeProviders')]: intl('Antman.Explorer.ExcludeProviders'),
    [intl('Explorer.IncludeConsumersOrProviders')]: intl('Antman.Explorer.IncludeConsumersOrProviders'),
    [intl('Explorer.ExcludeConsumersOrProviders')]: intl('Antman.Explorer.ExcludeConsumersOrProviders'),
    [intl('Explorer.SwapConsumersProviders')]: intl('Antman.Explorer.SwapConsumersProviders'),
    [intl('Explorer.AllowAllConnectionsMessage')]: intl('Antman.Explorer.AllowAllConnectionsMessage'),
    [intl('Explorer.AllowFilteredConnectionsMessage')]: intl('Antman.Explorer.AllowFilteredConnectionsMessage'),
    [intl('Users.RulesetManagerSublabel')]: intl('Antman.Users.RulesetManagerSublabel'),
    [intl('Users.LimitedRuleWritingSublabel')]: intl('Antman.Users.LimitedRuleWritingSublabel'),
    [intl('VEN.ContainerCluster')]: intl('Antman.VEN.ContainerCluster'),
    [intl('Workloads.EnforcementBoundariesIdle')]: intl('Antman.Workloads.EnforcementBoundariesIdle'),
    [intl('Workloads.EnforcementBoundariesVersionLow')]: intl('Antman.Workloads.EnforcementBoundariesVersionLow'),
    [intl('Workloads.EnforcementBoundariesActiveVersion')]: intl('Antman.Workloads.EnforcementBoundariesActiveVersion'),
    [intl('Workloads.EnforcementBoundariesInboundNoFound')]: intl(
      'Antman.Workloads.EnforcementBoundariesInboundNoFound',
    ),
    [intl('Workloads.EnforcementBoundariesOutboundNoFound')]: intl(
      'Antman.Workloads.EnforcementBoundariesOutboundNoFound',
    ),
    [intl('Workloads.EnforcementBoundariesSelectedInbound')]: intl(
      'Antman.Workloads.EnforcementBoundariesSelectedInbound',
    ),
    [intl('Workloads.IncompatibilityTypes.UnwantedWFProviders')]: intl(
      'Antman.Workloads.IncompatibilityTypes.UnwantedWFProviders',
    ),
    [intl('EnforcementBoundaries.BlockedConnections')]: intl('Antman.EnforcementBoundaries.BlockedConnections'),
    [intl('EnforcementBoundaries.BlockedConnectionDesc')]: intl('Antman.EnforcementBoundaries.BlockedConnectionDesc'),
    [intl('EnforcementBoundaries.Errors.Edit')]: intl('Antman.EnforcementBoundaries.Errors.Edit'),
    [intl('EnforcementBoundaries.Errors.Create')]: intl('Antman.EnforcementBoundaries.Errors.Create'),
    [intl('EnforcementBoundaries.Errors.ConsumerProviders')]: intl(
      'Antman.EnforcementBoundaries.Errors.ConsumerProviders',
    ),
    [intl('EnforcementBoundaries.Warning.FQDN')]: intl('Antman.EnforcementBoundaries.Warning.FQDN'),
    [intl('EnforcementBoundaries.RulesCount')]: intl('Antman.EnforcementBoundaries.RulesCount'),
    [intl('EnforcementBoundaries.RulesProvision')]: intl('Antman.EnforcementBoundaries.RulesProvision'),
    [intl('EnforcementBoundaries.Remove')]: intl('Antman.EnforcementBoundaries.Remove'),
    [intl('EnforcementBoundaries.DeleteConfirm')]: intl('Antman.EnforcementBoundaries.DeleteConfirm'),
    [intl('EnforcementBoundaries.WorkloadsInScopeDesc.Selective')]: intl(
      'Antman.EnforcementBoundaries.WorkloadsInScopeDesc.Selective',
    ),
    [intl('EnforcementBoundaries.WorkloadsInScopeDesc.SupportEnforcement')]: intl(
      'Antman.EnforcementBoundaries.WorkloadsInScopeDesc.SupportEnforcement',
    ),
    [intl('EnforcementBoundaries.WorkloadsInScopeDesc.Total')]: intl(
      'Antman.EnforcementBoundaries.WorkloadsInScopeDesc.Total',
    ),
    [intl('EnforcementBoundaries.Description')]: intl('Antman.EnforcementBoundaries.Description'),
    [intl('Tooltip.ContainerWorkload')]: intl('Antman.Tooltip.ContainerWorkload'),
    [intl('Tooltip.ContainerWorkloadCount')]: intl('Antman.Tooltip.ContainerWorkloadCount'),
  };

  setIntlValueMapper(utils.getIntlValueMapper(intlValuesMappingForAntman));
}
/* eslint-enable rulesdir/intl-call-verify */

// Dev only. Accept HMR updates of language bundle, rerequire it and invoke force update of current react tree
if (module.hot) {
  module.hot.accept('./langs', () => /*updatedDependencies*/ {
    // TODO: use dynamic import();
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    const langs = require('./langs').default;

    messages = langs(config)[lang];
  });
}
