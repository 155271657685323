/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {getId} from '../GeneralUtils';
import RestApiUtils from '../RestApiUtils';
import RenderUtils from '../RenderUtils';
import actionCreators from '../../actions/actionCreators';
import {UserStore} from '../../stores';

const POLLING_INTERVAL = 10_000;
const STARTING_POLLING_INTERVAL = 500;
const UI_EXPLORER_QUERY_PREFIX = 'UI_EXPLORER_QUERY_';

function calculateTime(key) {
  const now = new Date();

  switch (key) {
    case intl('DateTimeInput.Now'):
      return now;
    case intl('Explorer.LastHours', {count: 1}):
    case intl('Explorer.HoursAgo', {count: 1}):
      return intl.utils.subtractTime(now, 'h', 1);
    case intl('Explorer.LastHours', {count: 24}):
    case intl('Explorer.HoursAgo', {count: 24}):
    case intl('Explorer.LastDays', {count: 1}):
    case intl('Explorer.DaysAgo', {count: 1}):
    case intl('Explorer.LastDay'):
      return intl.utils.subtractTime(now, 'd', 1);
    case intl('Explorer.LastWeeks', {count: 1}):
    case intl('Explorer.WeeksAgo', {count: 1}):
      return intl.utils.subtractTime(now, 'd', 7);
    case intl('Explorer.LastMonths', {count: 1}):
    case intl('Explorer.MonthsAgo', {count: 1}):
      return intl.utils.subtractTime(now, 'M', 1);
    case intl('DateTimeInput.Anytime'):
      return intl.utils.subtractTime(now, 'y', 5);
  }
}

export function getStartDate(time) {
  let range = time.split(`${intl('DateTimeInput.From')}: `);

  if (range.length === 2) {
    range = range[1].split(` ${intl('DateTimeInput.To')}: `);

    return calculateTime(range[0]) || new Date(range[0]);
  }

  return calculateTime(time);
}

export function getEndDate(time) {
  let range = time.split(`${intl('DateTimeInput.From')}: `);

  if (range.length === 2) {
    range = range[1].split(` ${intl('DateTimeInput.To')}: `);

    return calculateTime(range[1]) || new Date(range[1]);
  }

  return new Date();
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const QUERY_STATUS = {
  WORKING: 'working',
  QUEUED: 'queued',
  KILLED: 'killed',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCEL_REQUESTED: 'cancel_requested',
};

export function isQueryPending(status) {
  return status === QUERY_STATUS.QUEUED || status === QUERY_STATUS.WORKING;
}

export function isQueryComplete(status) {
  return status === QUERY_STATUS.COMPLETED;
}

export function isQueryKilled(status) {
  return status === QUERY_STATUS.KILLED;
}

export function getSortedRecents(recents) {
  return Object.values(recents || {}).sort((a, b) =>
    !a?.date && !b?.date ? 0 : !a?.date ? 1 : !b?.date ? -1 : new Date(b.date).getTime() - new Date(a.date).getTime(),
  );
}

export function getUniqueRecents(recents, max) {
  return Object.values(
    (recents || []).reduce((result, recent) => {
      // Keep the first
      if (!result[recent.label] && Object.keys(result).length < max) {
        result[recent.label] = recent;
      }

      return result;
    }, {}),
  );
}

function getQueryData(
  consumers = [[]],
  providers = [[]],
  services = [],
  time,
  policyDecisions = ['potentially_blocked', 'allowed', 'blocked', 'unknown'],
  boundaryDecisions = [],
  queryOp = 'or',
) {
  return {
    sources: {
      include: consumers,
      exclude: [],
    },
    destinations: {
      include: providers,
      exclude: [],
    },
    services: {
      include: services,
      exclude: [],
    },
    exclude_workloads_from_ip_list_query: true,
    sources_destinations_query_op: queryOp,
    start_date: getStartDate(time).toISOString(),
    end_date: getEndDate(time).toISOString(),
    policy_decisions: policyDecisions,
    boundary_decisions: boundaryDecisions,
    max_results: UserStore.getExplorerMaxResults(),
  };
}

/**
 * Start polling for the newly requested query to complete
 */
async function pollQuery(queryHrefs, setPollingId = () => {}, options = [], count = 1) {
  const ids = ((Array.isArray(queryHrefs) && queryHrefs) || [queryHrefs]).map(queryHref => getId(queryHref));

  const responses = await Promise.all(ids.map(id => RestApiUtils.trafficFlows.async.getInstance(id)));
  const statuses = responses.map(response => response?.body?.status);

  if (statuses.some(status => isQueryPending(status))) {
    // Start fast, then max out at 10sec intervals
    const pollingId = setTimeout(
      () => this.pollQuery(queryHrefs, setPollingId, options, count + 1),
      count < POLLING_INTERVAL / STARTING_POLLING_INTERVAL ? count * STARTING_POLLING_INTERVAL : POLLING_INTERVAL,
    );

    setPollingId(pollingId);

    return;
  }

  if (statuses.some(status => isQueryComplete(status))) {
    for (const index in ids) {
      if (isQueryComplete(statuses[index])) {
        // Concatenate all but the first
        await RestApiUtils.trafficFlows.async.getResults(ids[index], {
          ...options[index],
          concatenate: parseInt(index, 10) !== 0,
        });
      }
    }
  } else {
    // This is the case where a query failed or was cancelled
    actionCreators.clearNewExplorerQuery();
  }

  setPollingId();
}

export function getPolicyDecisionName(policyDecision) {
  switch (policyDecision) {
    case 'allowed':
      return intl('Common.Allowed');
    case 'blocked':
      return intl('Common.Blocked');
    case 'potentially_blocked':
    case 'potentiallyBlocked':
      return intl('Common.PotentiallyBlocked');
    default:
      return intl('Common.Unknown');
  }
}

function getItems(types) {
  return types ? Object.values(types || {}).filter(type => type.length) : [];
}

function getUnNestedEndpointQuery(types) {
  return Object.keys(types || {}).reduce((result, type) => {
    if (type === 'labels') {
      Object.values(types[type]).forEach(labelType => {
        if (labelType.length) {
          result = [...result, ...labelType];
        }
      });
    } else {
      result = [...result, ...types[type]];
    }

    return result;
  }, []);
}

function getNestedEndpointQuery(types) {
  const finalProduct = Object.keys(types).reduce((result, type) => {
    const items = types[type];

    if (type === 'labels') {
      // For the Label items send the cartesian product of each type of label
      // Example: role: [r1, r2, r3] and env: [e1, e2]
      // Is sent as: [[r1, e1], [r1, e2], [r2, e1], [r2, e2], [r3, e1], [r3, e2]]
      // Algorithm taken from http://stackoverflow.com/questions/12303989
      const product = Object.values(getItems(items)).reduce(
        (result, labelType) => result.flatMap(inner => labelType.map(label => inner.concat([label]))),
        [[]],
      );

      return product[0].length ? [...result, ...product] : result;
    }

    if (items.length && type !== 'tranmission') {
      items.forEach(item => result.push(type === 'appgroups' ? item : [item]));
    }

    return result;
  }, []);

  return finalProduct.length ? finalProduct : [[]];
}

function cartesianProductForServices(types) {
  const productTypes = {...types};

  delete productTypes.policyServices;

  // For the Label items send the cartesian product of each type of label
  // Example: role: [r1, r2, r3] and env: [e1, e2]
  // Is sent as: [[r1, e1], [r1, e2], [r2, e1], [r2, e2], [r3, e1], [r3, e2]]
  // Algorithm taken from http://stackoverflow.com/questions/12303989
  const product = getItems(productTypes).reduce(
    (result, serviceType) => result.flatMap(inner => serviceType.map(service => ({...inner, ...service}))),
    [[]],
  );

  return [...product.flat(), ...types.policyServices];
}

// returns true if list only contains allowed types
function containsAllowedTypes(list, disallowedTypes) {
  if (Array.isArray(list) && list.length === 0) {
    return true;
  }

  return !(list || []).some(entry => {
    const {key: currentKey} = entry;
    const restKeys = list.filter(entry => entry.key !== currentKey).map(entry => entry.key);
    const disallowedEntries = Object.keys(disallowedTypes[currentKey] || {});

    return disallowedEntries.some(item => restKeys.includes(item));
  });
}

function getPolicyClass(policyDecision) {
  switch (policyDecision) {
    case intl('Common.Unknown'):
      return 'Explorer-unknown';
    case intl('Common.PotentiallyBlocked'):
      return 'Explorer-potentially-blocked';
    case intl('Common.Blocked'):
      return 'Explorer-blocked';
    case intl('Common.Allowed'):
      return 'Explorer-allowed';
  }
}

function getReportedBoundaryInfo(link) {
  if (link.boundaryDecision) {
    return 'boundary';
  }
}

function getDraftBoundaryInfo(link) {
  if (
    link.denyRules &&
    link.denyRules.length &&
    link.src_mode !== 'full' &&
    link.dst_mode !== 'full' &&
    (!link.src_modes || !link.src_modes.has('full')) &&
    (!link.dst_modes || !link.dst_modes.has('full'))
  ) {
    return link.rules?.length ? 'across-boundary' : 'boundary';
  }
}

function getReportedPolicyDecision(link) {
  return link.policies ? getPolicy(Object.values(link.policies)) : link;
}

function getDraftPolicyDecision(link, edge) {
  let policy = link.rules?.length
    ? intl('Common.Allowed')
    : link.rules
    ? intl('Common.Blocked')
    : intl('Common.Unknown');

  if (policy === intl('Common.Blocked') && link.src_mode !== 'full' && link.dst_mode !== 'full') {
    policy = edge ? intl('Common.Blocked') : intl('Common.PotentiallyBlocked');
  }

  if (edge && link.flow_direction === 'outbound') {
    if (!link.rules?.length && link.denyRules && link.denyRules.length) {
      policy = intl('Common.Blocked');
    }

    if (link.rules && !link.rules.length && link.denyRules && !link.denyRules.length) {
      policy = intl('Common.Allowed');
    }
  }

  // For Boundary flows in core
  if (!edge && !link.rules?.length && link.denyRules && link.denyRules.length) {
    policy =
      link.src_mode === 'full' ||
      link.src_mode === 'selective' ||
      link.dst_mode === 'full' ||
      link.dst_mode === 'selective'
        ? intl('Common.Blocked')
        : intl('Common.PotentiallyBlocked');
  }

  return policy;
}

function getEndpointKey(link, endpoint) {
  const labelsKey = `${endpoint}_labels`;
  const ipListKey = `${endpoint}_ip_lists`;
  const typeKey = `${endpoint}_type`;

  if (link[labelsKey]) {
    const keys = link[labelsKey].map(label => getId(label.href));

    keys.push(
      link[typeKey] === intl('Common.VirtualServices') || link[typeKey] === intl('Common.VirtualServers') ? 'vs' : 'wl',
    );

    return keys.join(',');
  }

  if (link[ipListKey]) {
    return link[ipListKey].map(list => list.href).join(',');
  }

  return 'any';
}

function getLatestPolicy(currentPolicy, link) {
  if (
    !currentPolicy ||
    currentPolicy.policy === intl('Common.Unknown') ||
    (currentPolicy.lastDetected < link.lastDetected && link.policy !== intl('Common.Unknown'))
  ) {
    return {policy: link.policy, boundaryDecision: link.boundaryDecision, lastDetected: link.lastDetected};
  }

  return currentPolicy;
}

function getPolicy(policies) {
  const pds = {
    blocked: {policy: intl('Common.Blocked'), boundaryDecision: false},
    blockedByBoundary: {policy: intl('Common.Blocked'), boundaryDecision: true},
    potentiallyBlocked: {policy: intl('Common.PotentiallyBlocked'), boundaryDecision: false},
    potentiallyBlockedByBoundary: {policy: intl('Common.PotentiallyBlocked'), boundaryDecision: true},
    allowedAcrossBoundary: {policy: intl('Common.Allowed'), boundaryDecision: true},
    allowed: {policy: intl('Common.Allowed'), boundaryDecision: false},
    unknown: {policy: intl('Common.Unknown'), boundaryDecision: false},
    unknownWithBoundary: {policy: intl('Common.Unknown'), boundaryDecision: true},
  };

  // This is the reverse order of precedence.
  const pd = ['unknownWithBoundary', ...RenderUtils.policyDecisions()].reverse().find(pd => {
    return policies.some(
      policy =>
        policy && policy.policy === pds[pd].policy && Boolean(policy.boundaryDecision) === pds[pd].boundaryDecision,
    );
  });

  return pds[pd] || {policy: intl('Common.Unknown'), boundaryDecision: false};
}

export function aggregateLinks(links) {
  return Object.values(
    links.reduce((result, link) => {
      const serviceKey = [link.port, link.protocol].join(',');
      const connectionKey = [serviceKey, link.src_ip, link.dst_ip].join(',');
      const srcEndpointKey = getEndpointKey(link, 'src');
      const dstEndpointKey = getEndpointKey(link, 'dst');
      const linkKey = [serviceKey, srcEndpointKey, dstEndpointKey, link.profile].join(',');
      const endpointKey = [srcEndpointKey, dstEndpointKey].join(',');

      if (!srcEndpointKey || !dstEndpointKey) {
        return result;
      }

      result[linkKey] ||= {
        ...link,
        profile: link.profile,
        src_type: 'aggregated',
        dst_type: 'aggregated',
        links: new Set(),
        src_ips: new Set(),
        dst_ips: new Set(),
        src_modes: new Set(),
        dst_modes: new Set(),
        src_deletedWorkloadIps: new Set(),
        dst_deletedWorkloadIps: new Set(),
        inboundPolicies: {},
        outboundPolicies: {},
        policies: {},
        connectionKeys: new Set(),
        rules: link.rules && [...link.rules],
        denyRules: link.denyRules && [...link.denyRules],
        connectionCount: 0,
        numFlows: 0,
        byteIn: 0,
        byteOut: 0,
        linkKey,
        endpointKey,
        osType: link.dst_os,
        processName: '',
        serviceName: '',
        windowsService: '',
        username: '',
      };

      const newLink = result[linkKey];

      if (!newLink.connectionKeys.has(connectionKey)) {
        newLink.numFlows += link.numFlows;
        newLink.byteIn += link.byteIn;
        newLink.byteOut += link.byteOut;
        newLink.connectionCount += 1;
      }

      newLink.links.add(link);
      newLink.src_ips.add(link.src_ip);
      newLink.dst_ips.add(link.dst_ip);
      newLink.src_modes.add(link.src_mode);
      newLink.dst_modes.add(link.dst_mode);
      newLink.connectionKeys.add(connectionKey);

      if (link.flow_direction === 'inbound') {
        newLink.inboundPolicies[connectionKey] = getLatestPolicy(newLink.inboundPolicies[connectionKey], link);
      } else {
        newLink.outboundPolicies[connectionKey] = getLatestPolicy(newLink.outboundPolicies[connectionKey], link);
      }

      newLink.policies[connectionKey] = getPolicy([
        newLink.inboundPolicies[connectionKey],
        newLink.outboundPolicies[connectionKey],
      ]);

      newLink.firstDetected = newLink.firstDetected < link.firstDetected ? newLink.firstDetected : link.firstDetected;
      newLink.lastDetected = newLink.lastDetected > link.lastDetected ? newLink.lastDetected : link.lastDetected;

      if (link.flow_direction === 'inbound') {
        newLink.processName ||= link.processName;
        newLink.serviceName ||= link.serviceName;
        newLink.windowsService ||= link.windowsService;
        newLink.username ||= link.username;
      }

      if (link.flow_direction === 'outbound') {
        newLink.outboundProcessName ||= link.processName;
        newLink.outboundServiceName ||= link.serviceName;
        newLink.outboundWindowsService ||= link.serviceName;
        newLink.outboundUsername ||= link.username;
      }

      if (link.src_workload === intl('Common.DeletedWorkload')) {
        newLink.src_deletedWorkloadIps.add(link.src_ip);
      }

      if (link.dst_workload === intl('Common.DeletedWorkload')) {
        newLink.dst_deletedWorkloadIps.add(link.dst_ip);
      }

      if (
        link.src_workload !== intl('Common.DeletedWorkload') &&
        link.dst_workload !== intl('Common.DeletedWorkload')
      ) {
        if (newLink.onlyDeleted) {
          newLink.rules = link.rules && [...link.rules];
          newLink.onlyDeleted = false;
        }

        // If the rules are loaded for the aggregated and regular link
        // And both have rules aggregate them
        if (link.rules?.length && newLink.rules?.length) {
          newLink.rules = [...new Set([...newLink.rules, ...link.rules])];
        } else if (link.rules && newLink.rules) {
          // If both links are loaded, but either is missing rules, the aggregated link is blocked.
          newLink.rules = [];
        } else {
          // If either is not loaded the aggregated link is not loaded
          newLink.rules = null;
        }

        // For the deny rules, if any link is blocked they are all blocked
        if (link.denyRules || newLink.denyRules) {
          newLink.denyRules = [...new Set([...(newLink.denyRules || []), ...(link.denyRules || [])])];
        }
      } else if (newLink.links.size === 1) {
        newLink.onlyDeleted = true;
      }

      return result;
    }, {}),
  );
}

function getEndpointService(link, endpoint, aggregationLevel) {
  let processName = '';
  let windowsService = '';
  let username = '';

  if (aggregationLevel === 'labels' && endpoint === 'outbound') {
    processName = link.outboundProcessName || '';
    windowsService = link.outboundWindowsService || '';
    username = link.outboundUsername || '';
  } else if (aggregationLevel === 'labels' || link.flow_direction === endpoint) {
    processName = link.processName || '';
    windowsService = link.windowsService || '';
    username = link.username || '';
  }

  return {processName, windowsService, username};
}

function getAggregatedItems(row, endpoint) {
  const deletedWorkloadIps = row[[endpoint, 'deletedWorkloadIps'].join('_')];

  return [...row.links].reduce((result, item) => {
    const itemIp = item[[endpoint, 'ip'].join('_')];
    const workload = item[[endpoint, 'name'].join('_')];
    const hostname = item[[endpoint, 'hostname'].join('_')];
    const key = workload || hostname || itemIp;

    result[key] = {...item, deletedWorkloadIps};

    return result;
  }, {});
}

function transformPolicyFilters(filterValues) {
  // Transforms the "Policy Decision" filters into the "policyDecisions" and "boundaryDecisions" fields
  // expected by the API.

  return filterValues.reduce(
    (result, value) =>
      value.endsWith('by_boundary')
        ? {
            boundaryDecisions: ['blocked'],
            policyDecisions: [...result.policyDecisions, value.replace(/_by_boundary$/, '')],
          }
        : {...result, policyDecisions: [...result.policyDecisions, value]},
    {policyDecisions: [], boundaryDecisions: []},
  );
}

function filterPolicyFilterOptions(policyOptions, selectedFilters, showBoundaries) {
  // Removes incompatible "Policy Decision" filter options from the list of policyOptions based on
  // the current selectedFilters.

  const selectedPolicyOptions = Object.keys(selectedFilters).filter(option =>
    [
      intl('Common.Allowed'),
      intl('Common.PotentiallyBlocked'),
      intl('Common.Blocked'),
      intl('Common.Unknown'),
    ].includes(option),
  );
  const selectedBoundaryPolicyOptions = Object.keys(selectedFilters).filter(option =>
    [intl('Common.PotentiallyBlockedByBoundary'), intl('Common.BlockedByBoundary')].includes(option),
  );

  return Object.keys(policyOptions).reduce((result, option) => {
    const isBoundaryOption = [intl('Common.PotentiallyBlockedByBoundary'), intl('Common.BlockedByBoundary')].includes(
      option,
    );

    if (
      (showBoundaries || !isBoundaryOption) &&
      (option === intl('Common.ReportedPolicy') ||
        (selectedPolicyOptions.length === 0 && selectedBoundaryPolicyOptions.length === 0) ||
        (selectedBoundaryPolicyOptions.length > 0 && isBoundaryOption) ||
        (selectedPolicyOptions.length > 0 && !isBoundaryOption))
    ) {
      result[option] = policyOptions[option];
    }

    return result;
  }, {});
}

export default {
  aggregateLinks,
  getEndpointService,
  getAggregatedItems,
  getPolicyDecisionName,
  pollQuery,
  getQueryData,
  getStartDate,
  getEndDate,
  formatBytes,
  isQueryPending,
  isQueryComplete,
  isQueryKilled,
  getSortedRecents,
  getUniqueRecents,
  getNestedEndpointQuery,
  getUnNestedEndpointQuery,
  getItems,
  cartesianProductForServices,
  getPolicyClass,
  getReportedPolicyDecision,
  getDraftPolicyDecision,
  getDraftBoundaryInfo,
  getReportedBoundaryInfo,
  containsAllowedTypes,
  transformPolicyFilters,
  filterPolicyFilterOptions,
  UI_EXPLORER_QUERY_PREFIX,
};
