/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import React, {PropTypes} from 'react';
import cx from 'classnames';
import {State, Navigation} from 'react-router';
import {isMac} from '../utils/GeneralUtils';
import actionCreators from '../actions/actionCreators';
import UserMixin from '../mixins/UserMixin';
import MainMenu from './MainMenu.jsx';
import HelpPopupLauncher from './HelpPopup/HelpPopupLauncher.jsx';
import UserMenu from './UserMenu.jsx';
import HelpMenu from './HelpMenu.jsx';
import InstantSearch from './InstantSearch/InstantSearch';
import Button from './Button.jsx';
import Icon from './Icon.jsx';
import ScopeSelector from './ScopeSelector.jsx';
import HealthState from './HealthState.jsx';
import ProvisionCount from './ProvisionCount.jsx';
import Tooltip from './Tooltip.jsx';
import RouteLink from './RouteLink';
import DataFormatUtils from '../utils/DataFormatUtils';
import {InstantSearchStore, SessionStore} from '../stores';
import AnalyticsUtils from '../utils/AnalyticsUtils';

export default React.createClass({
  propTypes: {
    allowUnlabeled: PropTypes.bool,
    appEnvOnly: PropTypes.bool,
    up: PropTypes.object,
    onUp: PropTypes.func,
    noRoleSearch: PropTypes.bool,
    search: PropTypes.bool,
    subTitle: PropTypes.any,
    customSearch: PropTypes.any,
    title: PropTypes.string,
    type: PropTypes.oneOf(['detail', 'edit', 'main']).isRequired,
  },

  mixins: [UserMixin, State, Navigation],

  getDefaultProps() {
    return {
      type: 'main',
      search: false,
      title: '',
    };
  },

  componentDidMount() {
    this.updateDocumentTitle();
  },

  componentDidUpdate() {
    this.updateDocumentTitle();
  },

  updateDocumentTitle() {
    const label = DataFormatUtils.pages.getPageTypeFromPath(this.getPathname());
    const {subTitle, title} = this.props;

    document.title = _.compact([subTitle, title, label, intl('Illumio.Name')]).join(' - ');
  },

  handleToggleInstantSearch() {
    AnalyticsUtils.sendAnalyticsEvent('instantSearch.show', {
      routeName: DataFormatUtils.pages.getRouteWithoutAppPrefix(
        this.getRoutes()
          .map(route => route.name)
          .join('.'),
      ),
      method: 'click',
      length: InstantSearchStore.getHistory()?.length,
    });

    actionCreators.openInstantSearch(<InstantSearch />);
  },

  render() {
    const type = this.props.type === 'main' ? 'primary' : 'secondary';
    const label = DataFormatUtils.pages.getPageTypeFromPath(this.getPathname());

    let navigation = (
      <div className="Navbar-main-nav" data-tid="comp-main-menu">
        <MainMenu type={type} />
      </div>
    );
    let header = (
      <div className="Navbar-label" data-tid="comp-navbar-label">
        {this.props.label || label}
      </div>
    );

    let provision = null;
    let health = null;
    let dashboard = null;
    let uiToggle = null;

    if (SessionStore.isHealthEnabled()) {
      health = (
        <div className="Navbar-health" data-tid="comp-navbar-health">
          <HealthState />
        </div>
      );
    }

    if (!SessionStore.isUserWithReducedScope()) {
      provision = (
        <div className="Navbar-provision" data-tid="comp-navbar-provision">
          <ProvisionCount />
        </div>
      );
    }

    if ((__ANTMAN__ || __TARGET__ === 'core') && !SessionStore.isUserWithReducedScope()) {
      dashboard = (
        <Tooltip location="bottom" content={intl('Common.Dashboard')} width="auto" delayed>
          <div data-tid="comp-navbar-dashboard" className="HealthState">
            <RouteLink to="dashboard">
              <Icon name="dashboard" size="xxlarge" />
            </RouteLink>
          </div>
        </Tooltip>
      );
    }

    let edit = null;

    const user = (
      <div className="Navbar-user" data-tid="comp-nav-user">
        <UserMenu />
      </div>
    );
    const help = (
      <div className="Navbar-help" data-tid="comp-nav-help">
        <HelpMenu />
      </div>
    );

    // Edit and Create Pages
    if (this.props.type === 'edit') {
      let subTitle = DataFormatUtils.pages.isCreate(this.getPathname())
        ? `${intl('Common.Add')} ${label}`
        : `(${intl('Common.Edit')})`;

      if (this.props.subTitle) {
        subTitle = this.props.subTitle;
      }

      edit = (
        <span className="Navbar-edit" data-tid="comp-navbar-edit">
          {subTitle}
        </span>
      );
    }

    // Detail, Edit, and Create Pages
    if (this.props.type !== 'main') {
      let handleUp;

      if (this.props.onUp) {
        handleUp = this.props.onUp;
      } else {
        const route = {};

        if (this.props.up) {
          // If the page sends a back option, use that instead
          route.route = this.props.up.route;
          route.params = this.props.up.params;
          route.query = this.props.up.query;
        }

        handleUp ||= () => {
          this.transitionTo(route.route, route.params, route.query);
        };
      }

      navigation = (
        <span className="Navbar-detail-nav">
          <MainMenu type="primary" />
          <div className="Navbar-detail-up">
            <Tooltip location="bottom" content={intl('Common.NavigateUp')} width={100} delayed>
              <Button onClick={handleUp} tid="detail-nav">
                <Icon name="navigateup" size="large" noTitle />
              </Button>
            </Tooltip>
          </div>
        </span>
      );

      header = (
        <div className="Navbar-label" data-tid="comp-navbar-label">
          {this.props.label || label}
        </div>
      );
    }

    const classes = cx({
      'Navbar-title': true,
      'Navbar-title--search': this.props.search && this.props.title,
    });

    const main = (
      <div className={classes}>
        {this.props.title || edit ? <span className="Navbar-title-dash">&ndash;</span> : null}
        {this.props.title ? (
          <span className="Navbar-title-text" data-tid="comp-navbar-title">
            {this.props.title}
          </span>
        ) : null}
        {edit}
      </div>
    );

    // Search Bar
    const search = (
      <div className="Navbar-search" data-tid="comp-navbar-search">
        {this.props.search ? (
          <ScopeSelector
            autoFocus={true}
            noRole={this.props.noRoleSearch}
            appEnvOnly={this.props.appEnvOnly}
            allowAll={this.props.allowAll}
            allowUnlabeled={this.props.allowUnlabeled}
          />
        ) : null}
      </div>
    );

    const instantSearch = (
      <div className="Navbar-instantSearch">
        <Button
          tid="is-header-search"
          content="icon-only"
          icon="search"
          iconTitle={intl('Common.OpenInstantSearch', {command: isMac() ? 'Cmd + K' : 'Ctrl + K'})}
          title={intl('Common.OpenInstantSearch', {command: isMac() ? 'Cmd + K' : 'Ctrl + K'})}
          onClick={this.handleToggleInstantSearch}
        />
      </div>
    );

    if (SessionStore.isUIToggleEnabled()) {
      uiToggle = (
        <div className="Navbar-uiToggle">
          <Tooltip location="bottomleft" content={intl('Antman.Toggle.TryPreview')} width={240}>
            <RouteLink to="xpressdashboard">
              <Icon name="disabled" size="large" />
            </RouteLink>
          </Tooltip>
        </div>
      );
    }

    return (
      <nav className="Navbar" data-tid="comp-navbar">
        {navigation}
        {header}
        {main}
        {this.props.customSearch}
        {search}
        {dashboard}
        {health}
        {provision}
        {user}
        {instantSearch}
        <HelpPopupLauncher title={this.props.title} />
        {help}
        {uiToggle}
      </nav>
    );
  },
});
