/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {State} from 'react-router';
import {RestApiUtils, RuleGraphUtils, GridDataUtils, GraphDataUtils, PolicyGeneratorUtils, RenderUtils} from '../utils';
import actionCreators from '../actions/actionCreators';
import {PolicyGeneratorDisabledRulesetsDialog} from '../modals';
import {TrafficStore, ServiceStore, IpListStore, SessionStore} from '../stores';

const maxTableRules = 500;
const maxRulesetRules = 40_000;
const objectsPerMin = 400;
const rulesPerCall = 250;

let values = {
  appGroup: null,
  ruleset: null,
  intraConfig: null,
  extraConfig: null,
  ipListConfig: null,
  type: 'append',
  optimizeLevel: '2',
  selectedConnectedAppGroups: [],
  selectedIpLists: [],
  selectedRingFenceAppGroups: [],
  intraExclusions: [],
  extraExclusions: [],
  intraSuccessInfo: {},
  extraSuccessInfo: {},
  ringFenceSuccessInfo: {},
  intraConnectionFilters: null,
  extraConnectionFilters: null,
  ipListConnectionFilters: null,
  intraTransmissionFilters: {unicast: true, broadcast: false, multicast: false, core_service: false},
  extraTransmissionFilters: {unicast: true, broadcast: false, multicast: false, core_service: false},
  ipListTransmissionFilters: {unicast: true, broadcast: false, multicast: false, core_service: false},
};

const storeInSession = () => {
  if (values.appGroup) {
    sessionStorage.setItem('ruleBuilderValues', JSON.stringify(values));
  }
};

export default {
  ...State,

  clearValues() {
    values = {
      appGroup: null,
      intraConfig: null,
      ruleset: null,
      type: 'append',
      route: {},
      extraConfig: {},
      ipListConfig: {},
      optimizeLevel: '2',
      selectedConnectedAppGroups: [],
      selectedIpLists: [],
      selectedRingFenceAppGroups: [],
      intraExclusions: [],
      extraExclusions: [],
      ipListExclusions: [],
      intraSuccessInfo: {},
      extraSuccessInfo: {},
      ipListSuccessInfo: {},
      ringFenceSuccessInfo: {},
      intraConnectionFilters: null,
      extraConnectionFilters: null,
      ipListConnectionFilters: null,
      intraTransmissionFilters: {unicast: true, broadcast: false, multicast: false, core_service: false},
      extraTransmissionFilters: {unicast: true, broadcast: false, multicast: false, core_service: false},
      ipListTransmissionFilters: {unicast: true, broadcast: false, multicast: false, core_service: false},
    };
  },

  setAppGroup(appGroup) {
    let newAppGroup = null;

    if (appGroup) {
      newAppGroup = {...appGroup};
      delete newAppGroup.providing;
      delete newAppGroup.consuming;
      delete newAppGroup.roleCounts;
      delete newAppGroup.consumingTraffic;
      delete newAppGroup.providingTraffic;
    }

    if (!_.isEqual(newAppGroup, values.appGroup)) {
      this.clearValues();
      values.appGroup = newAppGroup;
      sessionStorage.setItem('ruleBuilderValues', JSON.stringify(values));
      this.setState({getMicroTraffic: false, potentialRuleset: null});
    }
  },

  setType(type) {
    values.type = type;
  },

  setRoute() {
    const id = this.getParams() && this.getParams().id;
    const policyGenerator = this.getPath().includes('policygenerator');

    if (id && policyGenerator) {
      values.route = {id, path: this.getPath()};
    } else if (policyGenerator) {
      values.route = {};
    }

    storeInSession();
  },

  setRuleset(ruleset) {
    values.ruleset = ruleset;
  },

  setIntraConfig(config) {
    values.intraConfig = config;
  },

  setOptimizeLevel(level) {
    values.optimizeLevel = level;
  },

  setSelectedConnectedAppGroups(selected) {
    values.selectedConnectedAppGroups = selected;
  },

  setSelectedIpLists(selected) {
    values.selectedIpLists = selected;
  },

  setSelectedRingFenceAppGroups(selected) {
    values.selectedRingFenceAppGroups = selected;
  },

  setIntraSuccessInfo(info) {
    values.intraSuccessInfo = info;
  },

  setExtraSuccessInfo(info) {
    values.extraSuccessInfo = info;
  },

  setIpListSuccessInfo(info) {
    values.ipListSuccessInfo = info;
  },

  setRingFenceSuccessInfo(info) {
    values.ringFenceSuccessInfo = info;
  },

  setExtraConfig(config) {
    values.extraConfig = config;
  },

  setIpListConfig(config) {
    values.ipListConfig = config;
  },

  setIntraExclusions(exclusions) {
    values.intraExclusions = exclusions;
  },

  setExtraExclusions(exclusions) {
    values.extraExclusions = exclusions;
  },

  setIpListExclusions(exclusions) {
    values.ipListExclusions = exclusions;
  },

  setIntraTransmissionFilters(filters) {
    values.intraTransmissionFilters = filters;
  },

  setExtraTransmissionFilters(filters) {
    values.extraTransmissionFilters = filters;
  },

  setIpListTransmissionFilters(filters) {
    values.ipListTransmissionFilters = filters;
  },

  setIntraConnectionFilters(filters) {
    values.IntraConnectionFilters = filters;
  },

  setExtraConnectionFilters(filters) {
    values.extraConnectionFilters = filters;
  },

  setIpListConnectionFilters(filters) {
    values.ipListConnectionFilters = filters;
  },

  getAppGroup() {
    if (values.appGroup) {
      return values.appGroup;
    }

    const rbSessionValues = JSON.parse(sessionStorage.getItem('ruleBuilderValues'));

    if (rbSessionValues && rbSessionValues.appGroup) {
      return rbSessionValues.appGroup;
    }
  },

  getPolicyGeneratorValues() {
    if (values.appGroup) {
      return values;
    }

    const rbSessionValues = JSON.parse(sessionStorage.getItem('ruleBuilderValues'));

    if (rbSessionValues) {
      values = rbSessionValues;

      return rbSessionValues;
    }

    return values;
  },

  componentWillMount() {
    if (!SessionStore.isIlluminationApiEnabled() && this.getPath().includes('policygenerator')) {
      this.transitionTo('landing');
    }

    const rbValues = this.getPolicyGeneratorValues();

    if (!this.isBasePolicyGeneratorRoute() && !rbValues) {
      this.transitionTo('policygenerator');
    }

    this.setState(rbValues);
  },

  isBasePolicyGeneratorRoute() {
    return this.context.router.getCurrentPath().replace('/', '') === 'policygenerator';
  },

  async updateAppGroupRules(href) {
    const appGroup = this.state && this.state.appGroup;

    // If it has never been calculated, chances are we need to calculate the traffic first
    if (appGroup && appGroup.intraGroupCoverage === null && !TrafficStore.isTrafficRequested('roles', href)) {
      await this.setStateAsync({loadingSpinner: 'rules'});
      await GraphDataUtils.getTraffic(
        {roles: true, app_group_keys: JSON.stringify([href])},
        {type: 'rebuild', route: 'policyGenerator'},
      );
    }

    await this.setStateAsync({loadingSpinner: 'rules'});
    await RestApiUtils.appGroupRules.update(href).catch(() => {
      RestApiUtils.appGroupRules.startAppGroupRulesForGroupTimer(href);
    });

    await this.setStateAsync({loadingSpinner: false});
  },

  setStateAsync(newState) {
    return new Promise(resolve => {
      this.setState(newState, resolve);
    });
  },

  async getAppGroups() {
    await GraphDataUtils.getMapLevelByTotalWorkloads();

    if (!GraphDataUtils.isSummaryDataLoaded('requested')) {
      Promise.all([
        GraphDataUtils.getTraffic('location', {route: 'policyGenerator'}),
        GraphDataUtils.getTraffic('appGroup', {route: 'policyGenerator'}),
      ]);
    }
  },

  async getData(state, workflow, preview) {
    const selectedAppGroup = (state && state.appGroup) || values.appGroup;

    // Make sure these things are loaded in order
    if (selectedAppGroup && !selectedAppGroup.ringfence) {
      await this.setStateAsync({loadingSpinner: 'data'});
      await this.getPolicyGeneratorRuleset(selectedAppGroup, workflow === 'select');
      actionCreators.updatePolicyGeneratorRuleset();
    }

    await GraphDataUtils.getMapLevelByTotalWorkloads();

    if (!IpListStore.getAnyIpList()) {
      RestApiUtils.ipLists.getInstance('any');
    }

    if (!GraphDataUtils.isSummaryDataLoaded('requested')) {
      await this.setStateAsync({loadingSpinner: 'data'});
      await Promise.all([
        GraphDataUtils.getTraffic('location', {route: 'policyGenerator'}),
        GraphDataUtils.getTraffic('appGroup', {route: 'policyGenerator'}),
      ]);
    }

    const params = this.getParams();

    if (params && params.id && TrafficStore.getNode(params.id)) {
      RestApiUtils.appGroupRules.get(params.id);
    } else if (!TrafficStore.isPolicyGeneratorCoverageLoaded()) {
      if (TrafficStore.getLoadRulesForAppGroups()) {
        RestApiUtils.appGroupRules.startAppGroupRulesTimer();
      }
    }

    if (!ServiceStore.isLoaded()) {
      await this.setStateAsync({loadingSpinner: 'data'});
      await RestApiUtils.services.getCollection({max_results: 100_000});
    }

    if (!IpListStore.isLoaded()) {
      await this.setStateAsync({loadingSpinner: 'data'});
      await RestApiUtils.ipLists.getCollection();
    }

    const appGroup = state && state.appGroup && TrafficStore.getNode(state.appGroup.href);

    // If there is no app group selected quit here
    if (!state || !state.appGroup || (workflow === 'select' && !appGroup)) {
      this.setStateAsync({loadingSpinner: false});

      return;
    }

    // An app group has been selected, but the rule coverage is not loaded for it
    if (workflow === 'select' && appGroup.intraGroupCoverage === undefined) {
      await this.setStateAsync({loadingSpinner: 'rules'});
      await RestApiUtils.appGroupRules.get(appGroup.href);
      await this.setStateAsync({loadingSpinner: false});
    }

    const roles = await this.getAppGroupTraffic(state, workflow);

    if (SessionStore.areVulnerabilitiesEnabled()) {
      const vulnerabilityNodes = TrafficStore.getUnrequestedNodesForVulnerabilities(roles);

      if (vulnerabilityNodes.length) {
        await this.setStateAsync({loadingSpinner: 'vulnerability'});
        await RestApiUtils.vulnerabilityInstances.iterate(
          'roles',
          vulnerabilityNodes.map(role => role.key),
        );
      }
    }

    // Load Vulnerabilities
    if (SessionStore.areVulnerabilitiesEnabled()) {
      const vulnerabilityNodes = TrafficStore.getUnrequestedNodesForVulnerabilities(
        roles.map(role => role.key || role.href),
      );

      if (vulnerabilityNodes.length) {
        await this.setStateAsync({loadingSpinner: 'vulnerabilities'});
        await RestApiUtils.vulnerabilityInstances.iterate('roles', vulnerabilityNodes);
      }

      this.setState({loadingSpinner: false});

      // Load What if analysis
      if (preview && !SessionStore.isSuperclusterLeader() && !SessionStore.isSuperclusterMember()) {
        const {appGroup, ruleset, rules, removeRules, rulesetDisabled} = this.state;

        try {
          if (ruleset && !rulesetDisabled) {
            let currentRules = [...ruleset.rules];

            if (currentRules && removeRules && removeRules.length) {
              currentRules = currentRules.filter(
                rule => !removeRules.some(removeRule => removeRule.href === rule.href),
              );
            }

            await RestApiUtils.ruleSets.projectedVes(ruleset.href.split('/').pop(), {
              rules: PolicyGeneratorUtils.getStrippedVesRules([
                ...currentRules.filter(rule => rule.update_type !== 'delete' && rule.enabled),
                ...rules,
              ]),
            });
          } else {
            await RestApiUtils.ruleSets.projectedVesCreate({
              scopes: [appGroup.labels.map(label => ({label: {href: label.href}}))],
              rules: PolicyGeneratorUtils.getStrippedVesRules(rules),
            });
          }

          this.setState({vesError: false});
        } catch {
          this.setState({vesError: true});
        }
      }
    } else {
      this.setState({loadingSpinner: false});
    }
  },

  async getAppGroupTraffic(state, workflow) {
    const requests = [];
    let clearTraffic = false;
    let filters = ['unicast'];
    let roles;

    if (workflow.toLowerCase().includes('iplist')) {
      filters = PolicyGeneratorUtils.getTransmissionFilters(state.ipListTransmissionFilters);
    } else if (workflow.toLowerCase().includes('extra')) {
      filters = PolicyGeneratorUtils.getTransmissionFilters(state.extraTransmissionFilters);
    } else if (workflow.toLowerCase().includes('intra')) {
      filters = PolicyGeneratorUtils.getTransmissionFilters(state.intraTransmissionFilters);
    }

    ['unicast', 'broadcast', 'multicast', 'core_service'].forEach(transmission => {
      const roleKey = `${state.appGroup.href}x${transmission}`;

      // If we've loaded data not in the filter list, clear the traffic, and load only what is needed
      if (!filters.includes(transmission) && TrafficStore.isTrafficLoaded('roles', roleKey)) {
        // Leave App Groups in place
        actionCreators.updateForAll(true);
        clearTraffic = true;
      }
    });

    // Allow some time for the traffic to clear
    if (clearTraffic) {
      await this.setStateAsync({loadingSpinner: 'traffic'});
      await this.setStateAsync({loadingSpinner: false});
    }

    filters.forEach(transmission => {
      const roleKey = `${state.appGroup.href}x${transmission}`;

      if (!TrafficStore.isTrafficRequested('roles', roleKey)) {
        requests.push(
          GraphDataUtils.getTraffic(
            {
              roles: true,
              app_group_keys: JSON.stringify([state.appGroup.href]),
              traffic_class: transmission,
              max_ports: this.getNumberOfServices(state) || 5000,
            },
            {route: 'policyGenerator'},
          ),
        );
      }
    });

    if (requests.length) {
      await this.setStateAsync({loadingSpinner: 'traffic'});

      const results = await Promise.all(requests);

      await this.setStateAsync({loadingSpinner: false});

      const nodes = results.reduce((result, resultNodes) => {
        if (result.body?.nodes) {
          resultNodes = {...resultNodes, ...result.body.nodes};
        }

        return resultNodes;
      }, {});

      roles = this.getAppGroupRoles(
        {...nodes, ...TrafficStore.getAllRoleNodes()},
        state.appGroup.href,
        results.body?.labels || {},
      );
    } else {
      roles = this.getAppGroupRoles(TrafficStore.getAllRoleNodes(), state.appGroup.href);
    }

    await this.getTrafficRules(workflow, state, roles);

    return roles;
  },

  async getTrafficRules(workflow, state, roles) {
    // Get the Rules
    let traffics;

    if (workflow === 'intra') {
      traffics = PolicyGeneratorUtils.parseIntraAppGroupTraffic(
        TrafficStore.getAllRoleTraffics(),
        state.appGroup,
        'ringfencing',
      ).intraTraffic;
    } else if (workflow === 'extra') {
      traffics = PolicyGeneratorUtils.parseExtraAppGroupTraffic(
        TrafficStore.getAllRoleTraffics(),
        state.appGroup,
      ).extraTraffic;
    } else if (workflow === 'extraselected') {
      traffics = PolicyGeneratorUtils.parseExtraAppGroupConfigTraffic(
        TrafficStore.getAllRoleTraffics(),
        state.appGroup,
        state.selectedConnectedAppGroups,
        state.ruleset && state.ruleset.href.split('/').pop(),
        state.extraConfig,
        roles,
        state.optimizeLevel,
      ).extraTraffic;
    } else if (workflow === 'ipList') {
      traffics = PolicyGeneratorUtils.parseIpListTraffic(
        TrafficStore.getAllRoleTraffics(),
        state.appGroup,
      ).ipListTraffic;
    }

    await this.setStateAsync({noRules: workflow === 'extra'});

    if (traffics && (workflow !== 'extra' || traffics.length < 7500)) {
      // Filter the links which have already been requested from the next set of links
      const requestedHrefsForRuleCoverage = TrafficStore.getRequestedHrefsForRuleCoverage();
      const newLinkHrefs = _.difference(
        traffics.map(traffic => traffic.href),
        requestedHrefsForRuleCoverage,
      );

      // This returns all of these links, which have traffic data ready to send to avenger.
      const readyHrefsForRuleCoverage = TrafficStore.getReadyHrefsForRuleCoverage(newLinkHrefs);

      if (readyHrefsForRuleCoverage.length) {
        await this.setStateAsync({loadingSpinner: 'rules'});
        // This will iterate through the rule coverage links
        await RestApiUtils.ruleCoverage.iterate(readyHrefsForRuleCoverage);
        await this.setStateAsync({loadingSpinner: false});
      }

      await this.setStateAsync({noRules: false});
    }
  },

  getNodeLabels(node, labels) {
    return _.map(node.label_ids, labelId => labels[labelId]?.label); // returns {href: href}
  },

  getAppGroupRoles(nodes, appGroup, labels) {
    return _.transform(
      nodes,
      (result, node) => {
        if (
          (node.appGroupParent ||
            RenderUtils.getAppGroupParent(
              {...node, labels: labels ? this.getNodeLabels(node, labels) : appGroup.labels},
              TrafficStore.getAppGroupsType(),
            )) === appGroup
        ) {
          result.push(node);
        }
      },
      [],
    );
  },

  getNumberOfServices(state) {
    const appGroup = state.appGroup;

    if (state.intraTraffic && appGroup && appGroup.intraGroupCoverage) {
      return values.intraConfig === 'microsegmentation' && appGroup.intraGroupCoverage.num_services;
    }

    if (state.extraTraffic && appGroup && appGroup.extraGroupCoverage) {
      return values.extraConfig.type === 'microsegmentation' && appGroup.extraGroupCoverage.num_services;
    }

    if (state.ipListTraffic && appGroup && appGroup.ipListCoverage) {
      return values.ipListConfig.type === 'microsegmentation' && appGroup.ipListCoverage.num_services;
    }
  },

  async getPolicyGeneratorRuleset(appGroup, warnDisabled, askToUseMatching) {
    try {
      // Find any ruleset with the appGroup name
      const result = await RestApiUtils.ruleSets.getCollection(
        {
          representation: 'rule_set_services_labels_and_names',
          external_data_reference: appGroup.name,
        },
        'draft',
        true,
      );

      if (result.body && result.body.length) {
        this.verifyRuleset(result.body[0], warnDisabled);
        this.setRuleset(result.body[0]);

        return result.body[0];
      }

      try {
        // Find any ruleset with the appGroup name
        const result = await RestApiUtils.ruleSets.getCollection(
          {
            representation: 'rule_set_services_labels_and_names',
            external_data_reference: appGroup.href.split('x').join(' | '),
          },
          'draft',
          true,
        );

        if (result.body && result.body.length) {
          this.verifyRuleset(result.body[0], warnDisabled);
          this.setRuleset(result.body[0]);

          return result.body[0];
        }

        try {
          const nameResult = await RestApiUtils.ruleSets.getCollection(
            {
              name: appGroup.name,
            },
            'draft',
            true,
          );

          const potentialRuleset =
            nameResult.body &&
            nameResult.body.length &&
            nameResult.body.find(ruleset => ruleset.name === appGroup.name);

          const matchingScope =
            potentialRuleset &&
            potentialRuleset.enabled &&
            potentialRuleset.update_type !== 'delete' &&
            potentialRuleset.scopes.length === 1 &&
            PolicyGeneratorUtils.getPolicyGeneratorId(potentialRuleset.scopes[0]) === appGroup.href;

          if (askToUseMatching && matchingScope) {
            this.setState({
              ruleset: null,
              rulesetName: RenderUtils.getUniqueRulesetName(appGroup.name, nameResult.body),
              potentialRuleset,
            });
          } else if (potentialRuleset && (!matchingScope || !askToUseMatching)) {
            // If we are creating a new Ruleset, but the standard name is taken use this one
            this.setState({
              ruleset: null,
              rulesetName: RenderUtils.getUniqueRulesetName(appGroup.name, nameResult.body),
            });
          } else if (!potentialRuleset) {
            this.setState({ruleset: null});
          }

          this.setRuleset();
        } catch (error) {
          console.error('Get Ruleset by name', error);
        }
      } catch (error) {
        console.error('Get Ruleset by app group id', error);
      }
    } catch (error) {
      console.error('Get Ruleset failed', error);
    }
  },

  async getAllPolicyGeneratorRulesets() {
    const responses = await Promise.all([
      RestApiUtils.ruleSets.getCollection(
        {
          representation: 'rule_set_scopes',
          external_data_set: 'illumio_rule_builder',
        },
        'draft',
        true,
      ),
      RestApiUtils.ruleSets.getCollection(
        {
          representation: 'rule_set_scopes',
          external_data_set: 'illumio_policy_generator',
        },
        'draft',
        true,
      ),
    ]);

    return responses[0].body.concat(responses[1].body).reduce((result, ruleset) => {
      // Map rulesets by App Group name which is used as the external_data_reference
      result[ruleset.external_data_reference] = {
        updated: ruleset.updated_at,
        deleted: ruleset.update_type === 'delete',
        disabled: !ruleset.enabled,
        rulesetHref: ruleset.href,
        rulesetName: ruleset.name,
      };

      return result;
    }, {});
  },

  handleClose() {
    this.setState({modal: false});
  },

  verifyRuleset(ruleset, warnDisabled) {
    const disabled = !ruleset.enabled;
    const deleted = ruleset.update_type === 'delete';
    const rulesetDisabled = disabled || deleted;
    const type = disabled ? 'disabled' : 'deleted';

    if (warnDisabled && rulesetDisabled && !this.state.modal) {
      actionCreators.openDialog(
        <PolicyGeneratorDisabledRulesetsDialog
          disabled={disabled ? [ruleset] : []}
          deleted={deleted ? [ruleset] : []}
          onClose={this.handleClose}
        />,
      );
      this.setState({modal: true, rulesetDisabled: type});

      return false;
    }

    this.setState({rulesetDisabled: rulesetDisabled && type});

    return true;
  },

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },

  async deleteServices(services) {
    for (const serviceChunk of _.chunk(services, 800)) {
      await RestApiUtils.services.delete(serviceChunk.map(href => ({href})));
    }
  },

  async createServices(services, rules) {
    this.setState({spinner: true});

    let nextBatch = 0;
    const servicesThrottleChunks = _.chunk(services, objectsPerMin);
    let timer;

    if (servicesThrottleChunks.length > 1) {
      const start = Date.now();
      // Length of time it should take chunks*1min - the last one, will only be a partial minute
      const length = (servicesThrottleChunks.length - 1) * 600 + 150;

      this.setState({message: intl('PolicyGenerator.Spinner.CreatingServices'), progress: 1});
      timer = setInterval(
        (start, length) =>
          // Estimate the % completion base on time, but don't go over 100%
          this.setState({progress: Math.min(Math.round((Date.now() - start) / length), 100)}),
        1000,
        start,
        length,
      );
    }

    for (const servicesThrottle of servicesThrottleChunks) {
      if (nextBatch && nextBatch > Date.now()) {
        await this.timeout(nextBatch - Date.now());
      }

      nextBatch = Date.now() + 60_000;

      // Create new services for all the ports without existing services
      for (const serviceChunk of _.chunk(servicesThrottle, 5)) {
        try {
          await Promise.all(serviceChunk.map(service => this.createService(service, rules)));
        } catch (error) {
          console.error(error);

          // Backout all the created services
          if (this.createdServices) {
            this.deleteServices(this.createdServices);
            this.createdServices = false;
            this.servicesFailed = true;
          }

          this.setState({spinner: false});

          return 'error';
        }
      }
    }

    RestApiUtils.services.getCollection({max_results: 100_000}, 'draft', true);

    if (timer) {
      clearInterval(timer);
    }
  },

  async createIpLists(ipLists, rules) {
    this.setState({spinner: true});

    let nextBatch = 0;
    const ipListsThrottleChunks = _.chunk(ipLists, objectsPerMin);
    let timer;

    if (ipListsThrottleChunks.length > 1) {
      const start = Date.now();
      // Length of time it should take chunks*1min - the last one, will only be a partial minute
      const length = (ipListsThrottleChunks.length - 1) * 600 + 150;

      this.setState({message: intl('PolicyGenerator.Spinner.CreatingIpLists'), progress: 1});
      timer = setInterval(
        (start, length) =>
          // Estimate the % completion base on time, but don't go over 100%
          this.setState({progress: Math.min(Math.round((Date.now() - start) / length), 100)}),
        1000,
        start,
        length,
      );
    }

    for (const ipListsThrottle of ipListsThrottleChunks) {
      if (nextBatch && nextBatch > Date.now()) {
        await this.timeout(nextBatch - Date.now());
      }

      nextBatch = Date.now() + 60_000;

      // Create new services for all the ports without existing services
      for (const serviceChunk of _.chunk(ipListsThrottle, 5)) {
        try {
          await Promise.all(serviceChunk.map(ipList => this.createIpList(ipList, rules)));
        } catch (error) {
          console.error(error);

          // Backout all the created Services
          if (this.createdServices) {
            this.deleteServices(this.createdServices);
            this.createdServices = false;
          }

          // Backout all the created IpLists
          if (this.createdIpLists) {
            RestApiUtils.ipLists.delete(this.createdIpLists);
            this.createdIpLists = false;
            this.IpListsFailed = true;
          }

          this.setState({spinner: false});

          return 'error';
        }
      }
    }

    RestApiUtils.services.getCollection({max_results: 100_000}, 'draft', true);

    if (timer) {
      clearInterval(timer);
    }
  },

  async createRules(rules, removeRules) {
    let ruleset = this.state.ruleset && {...this.state.ruleset};

    if (ruleset && removeRules && removeRules.length) {
      ruleset.rules = ruleset.rules.filter(rule => !removeRules.some(removeRule => removeRule.href === rule.href));
    }

    if (removeRules && removeRules.length) {
      this.setState({message: intl('PolicyGenerator.Spinner.CreatingRules'), progress: 0});

      try {
        const rules = removeRules.map(rule => ({href: rule.href}));

        // Update existing ruleset to delete rules
        await RestApiUtils.secRules.deleteClean(GridDataUtils.getIdFromHref(ruleset.href), {rules});
      } catch (error) {
        console.error(error);
        this.handleError();

        return 'error';
      }
    }

    if (rules.length) {
      const ruleChunks = _.chunk(rules, rulesPerCall);
      let percentIncrement = 0;
      let progress = 0;

      if (ruleChunks.length > 3) {
        percentIncrement = Math.floor(100 / ruleChunks.length);
        progress = 1;
      }

      this.setState({message: intl('PolicyGenerator.Spinner.CreatingRules'), progress});

      for (const rulesChunk of ruleChunks) {
        if (!ruleset) {
          try {
            // Create a new ruleset
            const result = await this.createRuleset(this.state.appGroup, rulesChunk);

            ruleset = result.body;
            progress += percentIncrement;
            this.setState({progress: Math.min(progress, 100)});
          } catch (error) {
            console.error(error);
            this.handleError();

            return 'error';
          }
        } else {
          try {
            // Update existing ruleset
            const result = await this.updateRuleset(rulesChunk, ruleset);

            ruleset = result.body;
            progress += percentIncrement;
            this.setState({progress: Math.min(progress, 100)});
          } catch (error) {
            console.error(error);
            this.handleError();

            return 'error';
          }
        }
      }
    }

    // Update the rule coverage when we are done
    const rulesetId = GridDataUtils.getIdFromHref(ruleset.href);
    const scopes = RuleGraphUtils.getAllScopesFromRuleset(ruleset);

    actionCreators.updateRuleCoverageForScope({rulesetId, scopes});

    // Update the provision item count
    RestApiUtils.secPolicies.dependencies();
    this.updateAppGroupRules(this.state.appGroup.href);

    return rulesetId;
  },

  handleError() {
    if (this.createdServices) {
      this.deleteServices(this.createdServices);
    }

    if (this.createdIpLists) {
      RestApiUtils.ipLists.delete(this.createdIpLists);
    }

    this.setState({spinner: false});
    RestApiUtils.secPolicies.dependencies();
    this.updateAppGroupRules(this.state.appGroup.href);
  },

  async updateRuleset(rules, ruleset) {
    ruleset.rules = ruleset.rules.concat(rules);
    ruleset.preserve_existing_rules = true;
    ruleset.external_data_set = 'illumio_policy_generator';
    ruleset.external_data_reference = this.state.appGroup.labels
      .map(label => label.href.split('/').pop())
      .sort((a, b) => a - b)
      .join(' | ');
    this.setState({spinner: true});

    const rulesetId = GridDataUtils.getIdFromHref(ruleset.href);

    await RestApiUtils.ruleSet.updateClean(rulesetId, PolicyGeneratorUtils.getStrippedRuleset(ruleset));

    if (this.createdIpLists) {
      GraphDataUtils.getTraffic(
        {roles: true, app_group_keys: JSON.stringify([this.state.appGroup.href])},
        {
          type: 'rebuild',
          route: {type: 'focused', id: this.state.appGroup.href},
        },
      );
    }

    return RestApiUtils.ruleSets.getInstance(rulesetId, 'draft', true);
  },

  async createRuleset(appGroup, rules) {
    const strippedRules = PolicyGeneratorUtils.getStrippedRules(rules);

    this.setState({spinner: true});

    const result = await RestApiUtils.ruleSets.create({
      enabled: true,
      name: this.state.rulesetName || appGroup.name,
      description: intl('PolicyGenerator.AutoDescription'),
      scopes: [appGroup.labels.map(label => ({label: {href: label.href}}))],
      rules: strippedRules,
      external_data_set: 'illumio_policy_generator',
      external_data_reference: appGroup.href.split('x').join(' | '),
    });

    return RestApiUtils.ruleSets.getInstance(GridDataUtils.getIdFromHref(result.body.href), 'draft', true);
  },

  async createService(service, rules) {
    const response = await RestApiUtils.services.create(service.service, 'draft', false);
    const href = response.body.href;

    if (this.servicesFailed) {
      // Remove any service which was in-flight when another service failure occurred
      RestApiUtils.services.delete([{href}]);

      return;
    }

    this.createdServices ||= [];

    this.createdServices.push(href);
    service.indexes.forEach(index => (rules[index.ruleIndex].services[index.serviceIndex].href = href));
  },

  async createIpList(ipList, rules) {
    const response = await RestApiUtils.ipLists.create(ipList.ipList, 'draft', false);
    const href = response.body.href;

    if (this.IpListsFailed) {
      // Remove any ipList which was in-flight when another service failure occurred
      RestApiUtils.ipLists.delete([{href}]);

      return;
    }

    this.createdIpLists ||= [];

    this.createdIpLists.push({href});
    ipList.indexes.forEach(index => (rules[index.ruleIndex].providers[index.providerIndex].ip_list.href = href));
  },

  getIntraSuccessInfo() {
    return values.intraSuccessInfo;
  },

  getExtraSuccessInfo() {
    return values.extraSuccessInfo;
  },

  getIpListSuccessInfo() {
    return values.ipListSuccessInfo;
  },

  getRingFenceSuccessInfo() {
    return values.ringFenceSuccessInfo;
  },

  componentDidMount() {
    this.setRoute();

    // No remove listener on unmount intentionally
    window.addEventListener('beforeunload', storeInSession);
  },

  getMaxTableRules() {
    return maxTableRules;
  },

  getMaxRulesetRules() {
    return maxRulesetRules;
  },

  getObjectsPerMin() {
    return objectsPerMin;
  },
};
