/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import intl from 'intl';
import {findDOMNode} from 'react-dom';
import update from 'react-addons-update';
import {getViewportWidth, getViewportHeight} from 'utils/dom';
import {UserMixin, RouterMixin} from '../../mixins';
import NodeComponent from './Node.jsx';
import LinkComponent from './Link.jsx';
import ClusterComponent from './Cluster.jsx';
import LocationComponent from './Location.jsx';
import Banner from '../Banner.jsx';
import GraphFilters from '../../utils/Filter';
import GraphHoveringUtils from '../../utils/GraphHoveringUtils';
import GraphPatterns from '../../utils/Pattern';
import ZoomPanel from './ZoomPanel.jsx';
import Timestamp from '../../pages/Map/Timestamp.jsx';
import actionCreators from '../../actions/actionCreators';
import DragWorkloadModal from './DragWorkloadModal.jsx';
import GraphVisualization from '../../utils/Graph';
import RestApiUtils from '../../utils/RestApiUtils';
import GraphDataUtils from '../../utils/GraphDataUtils';
import RenderUtils from '../../utils/RenderUtils';
import Button from '../Button.jsx';
import Vulnerability from '../Vulnerability.jsx';
import {
  SessionStore,
  GraphStore,
  GraphTransformStore,
  MapPageStore,
  MapSpinnerStore,
  TrafficStore,
  FilterStore,
  TrafficFilterStore,
  CommandPanelStore,
  IpListStore,
  UserStore,
} from '../../stores';
import InteractionPanel from './InteractionPanel';

let freezeInteraction = false;
// Mouse start position when mouse down
let resizeTimer;
const MAX_WORKLOAD_THRESHOLD = 100_000;
const MAX_CLUSTERS_PER_LOCATION_FOR_LINKS = 75;
const MAX_CONNECTED_CLUSTERS = 500;

function getStateFromStores(initial) {
  let graphData = GraphStore.getGraphData();
  const selections = GraphStore.getSelections();

  if (selections.length) {
    let nextState = {...graphData};

    if (RenderUtils.isInternetIpList(selections[0])) {
      // iplist, internet
      nextState = GraphHoveringUtils.hoverInternet(selections[0], nextState.links, nextState.nodes, nextState.clusters);
    } else if (
      selections[0].type !== 'appGroup' &&
      selections[0].type !== 'group' &&
      selections[0].type !== 'traffic'
    ) {
      // node other than clusters
      nextState = GraphHoveringUtils.hoverNode(selections[0], nextState.links, nextState.nodes, nextState.clusters);
    } else if (selections[0].type === 'traffic') {
      // traffic
      const links = [...GraphStore.getLinks(), ...GraphStore.getClusters().flatMap(cluster => cluster.links)];
      const isMultiSelection = selections.length > 1;

      selections.forEach(selectedLink => {
        links.forEach(link => {
          if (selectedLink.href === link.href) {
            nextState = GraphHoveringUtils.hoverLink(
              link,
              nextState.links,
              nextState.nodes,
              nextState.clusters,
              isMultiSelection,
            );
          }
        });
      });
    }

    graphData = {...graphData, ...nextState};
  }

  return {
    ...graphData,
    transform: GraphTransformStore.getTransform(),
    mapType: MapPageStore.getMapType(),
    mapLevel: MapPageStore.getMapLevel(),
    mapRoute: MapPageStore.getMapRoute(),
    appMapVersion: MapPageStore.getAppMapVersion(),
    defaultAppMap: UserStore.getDefaultAppMap(),
    graphCalculated: !initial && GraphStore.isGraphCalculated(),
    totalWorkloads: MapPageStore.getTotalWorkloads(),
    noLocationWorkloads: MapPageStore.getNoLocationWorkloads(),
    appGroupDisabled: TrafficStore.isAppGroupDisabled(),
    appGroupMatching: TrafficStore.isAppGroupTypeMatching(),
    calculating: MapSpinnerStore.getTrafficSpinner() && MapPageStore.getMapLevel() !== 'full',
  };
}

/** Put the specified element to the last of elements array
 * @param {Array} elements the elements to rearrange
 * @param {Object} element the element to put last
 * @param {Function} pred the predicate to determine which element in elements array to put last
 */
const putDraggedElementOnTop = (elements, element, pred) => {
  let inElements = false;
  const filteredElements = _.reject(elements, item => {
    if (pred(item, element)) {
      inElements = true;

      return inElements;
    }
  });

  if (inElements) {
    filteredElements.push(element);
  }

  return filteredElements;
};

export default React.createClass({
  mixins: [UserMixin, RouterMixin],

  getInitialState() {
    return getStateFromStores('init');
  },

  async componentWillMount() {
    if (window) {
      const userAgent = window.navigator.userAgent;
      const isUserAgentFirefox = userAgent.includes('Firefox');
      // If in a full map, unselect and close command panel by default to prevent DOM render issue in Firefox
      // todo: file a SVG filter bug on Bugzilla

      if (isUserAgentFirefox) {
        actionCreators.unselectComponent();
      }
    }

    RestApiUtils.orgSettings.get();

    RestApiUtils.user.orgs({representation: 'org_permissions'}, SessionStore.getUserId(), true);
    this.checkAppGroupLevel();

    if (SessionStore.isSuperclusterLeader()) {
      RestApiUtils.health.get();
    }

    GraphDataUtils.getMapLevelByTotalWorkloads();

    if (!IpListStore.getAnyIpList()) {
      RestApiUtils.ipLists.getInstance('any');
    }

    if (SessionStore.canUserViewEnforcementBoundaries()) {
      RestApiUtils.enforcementBoundaries.getCollection('draft', true, {max_results: 1});
    }
  },

  componentDidMount() {
    _.defer(() => {
      this.calculateSVGDimensions();
      // zoom to fit, if the graph is mounted after the traffic is already loaded
      actionCreators.updateZoomToFit();
    }, true);
    this.handleLinkColorChange(); // when we come back to the page, make sure to fetch rule graph for any changes
    TrafficStore.addUpdateListener(this.handleLinkColorChange); // and also to add a listener for any changes
    MapPageStore.addUpdateListener(this.handleLinkColorChange);
    GraphStore.addChangeListener(this.handleWorkloadCountChange);
    GraphStore.addUpdateListener(this.handleSelectionChange);
    CommandPanelStore.addUpdateListener(this.handleHoverCommandPanel);
    FilterStore.addChangeListener(this.handleFilterChange);
    TrafficFilterStore.addChangeListener(this.handleFilterChange);
    GraphTransformStore.addChangeListener(this.transformChange);
    MapSpinnerStore.addUpdateListener(this.updateCalculating);
    window.addEventListener('resize', this.windowResize);

    this.d3Wrapper = d3.select(findDOMNode(this.refs.svg));
    this.d3Wrapper
      .select('defs')
      .call(GraphFilters.hoverFilter)
      .call(GraphFilters.selectFilter)
      .call(GraphFilters.linkGradient1)
      .call(GraphFilters.linkGradient2)
      .call(GraphFilters.linkGradient3)
      .call(GraphFilters.linkGradient4)
      .call(GraphPatterns.unmanagedIcon);
    this.d3Wrapper.call(_.bind(GraphVisualization.update, GraphVisualization, this.state.transform));
    this.d3Wrapper.call(
      _.bind(
        GraphVisualization.initializeZoom,
        GraphVisualization,
        this.wheelZooming,
        this.wheelZoomingEnd,
        this.state.transform,
      ),
    );
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.policyVersion === 'reported' && nextProps.policyVersion !== 'reported') {
      GraphDataUtils.loadRules(this.state);
    }
  },

  shouldComponentUpdate(nextProps, nextState) {
    // Do not update if render moves from true to false
    // Do not update if mapType is changing and graphCalculated is false
    return (
      (!this.props.render || nextProps.render) && this.props.mapType === this.state.mapType && nextState.graphCalculated
    );
  },

  componentDidUpdate() {
    this.checkAppGroupLevel();

    const banner = document.querySelector('.Graph-no-data');
    const bannerHeight = banner ? banner.clientHeight : 0;

    if (bannerHeight !== this.state.bannerHeight) {
      this.calculateSVGDimensions();
    }

    this.d3Wrapper.call(_.bind(GraphVisualization.update, GraphVisualization, this.state.transform));
  },

  componentWillUnmount() {
    TrafficStore.removeUpdateListener(this.handleLinkColorChange);
    MapPageStore.removeUpdateListener(this.handleLinkColorChange);
    GraphStore.removeChangeListener(this.handleWorkloadCountChange);
    GraphStore.removeUpdateListener(this.handleSelectionChange);
    CommandPanelStore.removeUpdateListener(this.handleHoverCommandPanel);
    FilterStore.removeChangeListener(this.handleFilterChange);
    TrafficFilterStore.removeChangeListener(this.handleFilterChange);
    GraphTransformStore.removeChangeListener(this.transformChange);
    MapSpinnerStore.removeUpdateListener(this.updateCalculating);
    window.removeEventListener('resize', this.windowResize);
    actionCreators.unmountGraph();
  },

  updateCalculating() {
    this.setState({calculating: MapSpinnerStore.getTrafficSpinner() && MapPageStore.getMapLevel() !== 'full'});
  },

  checkAppGroupLevel() {
    const group = GraphStore.getCluster(this.state.mapRoute.id);

    // Close connected group if all links between connected and foccused app group are hidden.
    const isconnectedLinksHidden = this.state.links.length && this.state.links.every(link => link.isHidden);

    if (
      this.state.mapType === 'app' &&
      !_.isEmpty(this.state.mapRoute) &&
      !this.state.mapRoute.previd &&
      TrafficStore.isAppGroupsLoaded() &&
      !TrafficStore.getAppGroupNode(this.state.mapRoute.id)
    ) {
      _.defer(() => {
        this.props.updateMapLevel({});
      });
    } else if (
      (this.state.mapType === 'app' &&
        !_.isEmpty(this.state.mapRoute) &&
        this.state.mapRoute.previd &&
        this.state.graphCalculated &&
        (!group || (!group.nodes.length && !TrafficFilterStore.getHiddenPolicyStates().length))) ||
      (this.state.mapType === 'app' && isconnectedLinksHidden)
    ) {
      // Close the connected group if the connected group is empty
      const id = this.state.mapRoute.previd;

      _.defer(() => {
        this.props.updateMapLevel({type: 'focused', id});
      });
    }
  },

  handleFilterChange() {
    //@Joy: Is it fine to remove this ? So that when filter changes we update our graph with new traffic data!
    GraphDataUtils.loadTraffic();
  },

  handleHoverCommandPanel() {
    const hoverComponents = CommandPanelStore.getHoverComponents();

    if (hoverComponents && hoverComponents.length) {
      if (hoverComponents[0].type === 'workload') {
        this.hoverNode(hoverComponents[0]);
      } else {
        this.hoverCluster(hoverComponents[0]);
      }
    } else {
      this.unhoverClusterAndLinks();
      this.unhoverNodeAndLink();
    }
  },

  handleLinkColorChange() {
    GraphDataUtils.loadTraffic();
  },

  handleSelectionChange() {
    this.setState({
      locations: GraphStore.getLocations(),
      clusters: GraphStore.getClusters(),
      nodes: GraphStore.getNodes(),
      links: GraphStore.getLinks(),
      clusterLinks: GraphStore.getClusterLinks(),
      appSupergroupLinks: GraphStore.getAppSupergroupLinks(),
    });
  },

  handleWorkloadCountChange() {
    const nextState = getStateFromStores();

    if (nextState.emptyPositions && nextState.mapLevel !== 'full' && !_.isEmpty(nextState.mapRoute)) {
      // if map is finished loading and everything is still empty, route is back to /map and return
      _.defer(() => {
        this.props.updateMapLevel({});
      });

      return;
    }

    if (sessionStorage.getItem('rebuild_map') === 'true') {
      GraphDataUtils.loadTraffic('rebuild', false);
    } else {
      GraphDataUtils.loadTraffic();
    }

    if (!_.isEqual(GraphStore.getPrevMapLevel(), nextState.mapLevel) && nextState.graphCalculated) {
      // if previous map level is not the same of current mapLevel
      // and the data is ready
      // then zoom to fit
      _.defer(() => {
        actionCreators.updateZoomToFit();
        _.defer(() => {
          actionCreators.stopSpinner();
        });
      });
    } else if (nextState.graphCalculated) {
      _.defer(() => {
        actionCreators.stopSpinner();
      });
    }

    // Note: whatever data we get from parent, set it as state.
    // From here on out in Graph.jsx, we will only use state and not props
    // so as to only keep one source of truth
    this.setState(nextState);
  },

  afterDragCluster(draggedCluster) {
    freezeInteraction = false;

    if (this.state.mapRoute.id) {
      // drag groups in group view
      const focusedLocation = _.find(this.state.locations, loc => loc.href === draggedCluster.locHref);
      let distance =
        Math.pow(draggedCluster.x - focusedLocation.x, 2) + Math.pow(draggedCluster.y - focusedLocation.y, 2);

      distance = Math.sqrt(distance);

      if (distance < focusedLocation.r) {
        actionCreators.updatePosition(draggedCluster);
      }
    } else if (!this.state.mapRoute.id) {
      actionCreators.updatePosition(draggedCluster);
    }
  },

  afterDragLocation(draggedLocation) {
    freezeInteraction = false;
    actionCreators.updatePosition(draggedLocation);
  },

  async afterDragNode(node, oldCluster) {
    freezeInteraction = false;

    if (!node) {
      return;
    }

    const emptyLabels = labels => _.isEmpty(labels) || Object.values(labels).every(label => _.isEmpty(label));

    const newCluster = _.find(this.state.clusters, cluster => {
      const x1 = cluster.x - cluster.width / 2;
      const y1 = cluster.y - cluster.height / 2;
      const x2 = x1 + cluster.width;
      const y2 = y1 + cluster.height;

      return x1 < node.x && node.x < x2 && y1 < node.y && node.y < y2;
    });

    if ((!newCluster && !oldCluster) || node.type === 'role') {
      actionCreators.updatePosition();
    } else {
      let data;

      const newClusterHasPermission =
        newCluster && newCluster.caps
          ? newCluster.caps.workloads.includes('write') || newCluster.caps.workloads.includes('add')
          : false;
      const oldClusterHasPermission =
        oldCluster && oldCluster.caps
          ? oldCluster.caps.workloads.includes('write') || oldCluster.caps.workloads.includes('add')
          : false;

      const newClusterhasNoLabels =
        !newCluster || newCluster.href.includes('discover') || emptyLabels(newCluster.labels);
      const oldClusterhasNoLabels =
        !oldCluster || oldCluster.href.includes('discover') || emptyLabels(oldCluster.labels);

      if (oldClusterHasPermission) {
        if (newClusterHasPermission) {
          //PERM --> PERM
          data = {addWorkload: true, node, cluster: newCluster};
        } else if (newClusterhasNoLabels) {
          //PERM --> DISCOVERY
          if (SessionStore.isUserOwner() || SessionStore.isUserAdmin()) {
            //PERM --> DISCOVERY (OWNER OR ADMIN)
            data = {node};
          } else {
            //PERM --> DISCOVERY (OTHERWISE)
            data = {disableWarning: true, node};
          }
        } else {
          //PERM --> NOPERM
          data = {isReadonly: true};
        }
      } else if (oldClusterhasNoLabels) {
        // Only if the user is owner allow the user to drag workloads from discovery groups.
        if (SessionStore.isUserOwner() || SessionStore.isUserAdmin()) {
          //DISCOVERY --> PERM (OWNER OR ADMIN)
          data = {addWorkload: true, node, cluster: newCluster};
        } else if (newClusterhasNoLabels) {
          //DISCOVERY --> DISCOVERY
          data = {isDiscovery: true, node};
        } else {
          // DISCOVERY --> NOPERM
          data = {isReadonly: true};
        }
      } else {
        // NOPERM --> NOPERM
        data = {isReadonly: true};
      }

      actionCreators.openDialog(<DragWorkloadModal data={data} />);
    }

    return false;
  },

  calculateWindowSize() {
    const navBar = document.querySelector('.Navbar');
    const navBarHeight = navBar ? navBar.clientHeight : 0;
    const navMenu = document.querySelector('.NavMenu');
    const navMenuHeight = navMenu ? navMenu.clientHeight : 0;
    const searchBar = document.querySelector('.SearchBar');
    const searchBarHeight = searchBar ? searchBar.clientHeight : 0;
    const banner = document.querySelector('.Graph-no-data');
    const bannerHeight = banner ? banner.clientHeight : 0;
    const width = getViewportWidth();
    const height = getViewportHeight() - navBarHeight - searchBarHeight - bannerHeight - navMenuHeight;

    return {width, height, bannerHeight};
  },

  calculateSVGDimensions() {
    // Set width/height the browser window or document size.
    const {width, height, bannerHeight} = this.calculateWindowSize();

    this.setState({width, height, bannerHeight});
  },

  // hover groups in group/details/full view
  hoverCluster(cluster) {
    if (freezeInteraction) {
      return;
    }

    const nextState = GraphHoveringUtils.hoverCluster(cluster, this.state.clusters, this.state.clusterLinks);
    const clusters = nextState.clusters;
    const clusterLinks = nextState.clusterLinks;

    if (_.isEmpty(clusterLinks)) {
      this.setState({clusters});
    } else {
      this.setState({clusters, clusterLinks});
    }
  },

  // hover clusterLinks
  hoverClusterLink(clusterLink) {
    if (freezeInteraction) {
      return;
    }

    const nextState = GraphHoveringUtils.hoverClusterLink(clusterLink, this.state.clusterLinks, this.state.clusters);
    const clusterLinks = nextState.clusterLinks;
    const clusters = nextState.clusters;

    this.setState({clusterLinks, clusters});
  },

  // hover internetLinks, inter/intra group links
  hoverLink(link) {
    if (freezeInteraction) {
      return;
    }

    const nextState = GraphHoveringUtils.hoverLink(link, this.state.links, this.state.nodes, this.state.clusters);
    const links = nextState.links;
    const clusters = nextState.clusters;
    const nodes = nextState.nodes;

    this.setState({links, nodes, clusters});
  },

  // hover workloads/roles/internets/ipLists
  hoverNode(node) {
    if (freezeInteraction) {
      return;
    }

    let nextState;

    if (RenderUtils.isInternetIpList(node)) {
      nextState = GraphHoveringUtils.hoverInternet(node, this.state.links, this.state.nodes, this.state.clusters);
    } else {
      nextState = GraphHoveringUtils.hoverNode(node, this.state.links, this.state.nodes, this.state.clusters);
    }

    const clusters = nextState.clusters;
    const links = nextState.links;
    const nodes = nextState.nodes;

    this.setState({clusters, links, nodes});
  },

  // special locations == Discovery locations || location with 0 workloads
  // special locations should be hidden in location and group views
  // special locations should be hidden in workload view if
  // they has no connections with centered location
  shouldHideLocation(data) {
    const connectedHref = this.state.mapRoute.prevtype === 'focused' ? this.state.mapRoute.id : null;

    if (
      data.appGroupsNum === 0 ||
      (this.state.mapRoute.type === data.appGroupType &&
        connectedHref &&
        data.connectedAppGroups.every(group => group.href === connectedHref || group.isHidden))
    ) {
      return true;
    }

    const mapLevel = this.state.mapLevel;

    // discovery/no locations
    return (
      ((data.href === 'discovered' || data.href === 'no_location') &&
        (mapLevel === 'location' || mapLevel === 'group' || (mapLevel === 'workload' && !data.expanded))) ||
      // locations with 0 workloads
      (!data.entityCounts && !data.focused && !data.expanded)
    );
  },

  transformChange() {
    // transform is to update zoom.scale and zoom.translate
    // zoomToFitScale is to update zoom.scaleExtent
    const transform = GraphTransformStore.getTransform();

    GraphVisualization.updateZoom(transform);
    this.setState({transform});
  },

  // unhover cluster and clusterLinks
  unhoverClusterAndLinks() {
    if (freezeInteraction) {
      return;
    }

    let nextState = {
      ...GraphHoveringUtils.unhoverNodeAndLink(this.state.links, this.state.nodes, this.state.clusters),
      ...GraphHoveringUtils.unhoverClusterAndLinks(this.state.clusterLinks, this.state.clusters),
    };
    const selections = GraphStore.getSelections();

    if (selections.length) {
      if (RenderUtils.isInternetIpList(selections[0])) {
        // iplist, internet
        nextState = {
          ...nextState,
          ...GraphHoveringUtils.hoverInternet(selections[0], nextState.links, nextState.nodes, nextState.clusters),
        };
      } else if (
        selections[0].type !== 'appGroup' &&
        selections[0].type !== 'group' &&
        selections[0].type !== 'traffic'
      ) {
        // node other than clusters
        nextState = {
          ...nextState,
          ...GraphHoveringUtils.hoverNode(selections[0], nextState.links, nextState.nodes, nextState.clusters),
        };
      } else if (selections[0].type === 'traffic') {
        // traffic
        const links = [
          ...GraphStore.getLinks(),
          ...GraphStore.getClusters().flatMap(cluster => cluster.links),
          ...GraphStore.getClusterLinks(),
        ];
        const isMultiSelection = selections.length > 1;

        selections.forEach(selectedLink => {
          links.forEach(link => {
            if (selectedLink.href === link.href) {
              if (selectedLink.clusterHref) {
                nextState = {
                  ...nextState,
                  ...GraphHoveringUtils.hoverLink(
                    link,
                    nextState.links,
                    nextState.nodes,
                    nextState.clusters,
                    isMultiSelection,
                  ),
                };
              } else {
                nextState = {
                  ...nextState,
                  ...GraphHoveringUtils.hoverClusterLink(
                    link,
                    nextState.clusterLinks,
                    nextState.clusters,
                    isMultiSelection,
                  ),
                };
              }
            }
          });
        });
      }
    }

    const clusters = nextState.clusters;
    const links = nextState.links;
    const nodes = nextState.nodes;
    const clusterLinks = nextState.clusterLinks;

    this.setState({clusters, links, nodes, clusterLinks});
  },

  // unhover workloads/internet/ipList and links
  unhoverNodeAndLink() {
    if (freezeInteraction) {
      return;
    }

    let nextState = {
      ...GraphHoveringUtils.unhoverNodeAndLink(this.state.links, this.state.nodes, this.state.clusters),
      ...GraphHoveringUtils.unhoverClusterAndLinks(this.state.clusterLinks, this.state.clusters),
    };
    const selections = GraphStore.getSelections();

    if (selections.length) {
      if (RenderUtils.isInternetIpList(selections[0])) {
        // iplist, internet
        nextState = {
          ...nextState,
          ...GraphHoveringUtils.hoverInternet(selections[0], nextState.links, nextState.nodes, nextState.clusters),
        };
      } else if (
        selections[0].type !== 'appGroup' &&
        selections[0].type !== 'group' &&
        selections[0].type !== 'traffic'
      ) {
        // node other than clusters
        nextState = {
          ...nextState,
          ...GraphHoveringUtils.hoverNode(selections[0], nextState.links, nextState.nodes, nextState.clusters),
        };
      } else if (selections[0].type === 'traffic') {
        // traffic
        const links = [
          ...GraphStore.getLinks(),
          ...GraphStore.getClusters().flatMap(cluster => cluster.links),
          ...GraphStore.getClusterLinks(),
        ];
        const isMultiSelection = selections.length > 1;

        selections.forEach(selectedLink => {
          links.forEach(link => {
            if (selectedLink.href === link.href) {
              if (selectedLink.clusterHref) {
                nextState = {
                  ...nextState,
                  ...GraphHoveringUtils.hoverLink(
                    link,
                    nextState.links,
                    nextState.nodes,
                    nextState.clusters,
                    isMultiSelection,
                  ),
                };
              } else {
                nextState = {
                  ...nextState,
                  ...GraphHoveringUtils.hoverClusterLink(
                    link,
                    nextState.clusterLinks,
                    nextState.clusters,
                    isMultiSelection,
                  ),
                };
              }
            }
          });
        });
      }
    }

    const clusters = nextState.clusters;
    const links = nextState.links;
    const nodes = nextState.nodes;
    const clusterLinks = nextState.clusterLinks;

    this.setState({clusters, links, nodes, clusterLinks});
  },

  handleSelectMultiLinks(selectedLinks) {
    if (!selectedLinks.length) {
      return;
    }

    const allLinks = [...this.state.links, ...this.state.clusters.flatMap(cluster => cluster.links)];

    let nextState = {links: this.state.links, nodes: this.state.nodes, clusters: this.state.clusters};

    selectedLinks.forEach(selectedLink => {
      allLinks.forEach(link => {
        if (selectedLink.href === link.href) {
          nextState = GraphHoveringUtils.hoverLink(link, nextState.links, nextState.nodes, nextState.clusters, true);
        }
      });
    });

    const clusters = nextState.clusters;
    const links = nextState.links;
    const nodes = nextState.nodes;

    this.setState({clusters, links, nodes});
  },

  wheelZooming() {
    freezeInteraction = true;
  },

  wheelZoomingEnd(translate, scale) {
    freezeInteraction = false;

    const newTransform = {
      scale,
      translate,
      isInitial: false,
    };

    actionCreators.updateTransform(newTransform);
  },

  whileDragCluster(draggedCluster) {
    freezeInteraction = true;

    let clusters = _.map(this.state.clusters, cluster => {
      if (cluster.href === draggedCluster.href) {
        return draggedCluster;
      }

      return cluster;
    });

    // put cluster being dragged to the front
    clusters = putDraggedElementOnTop(
      clusters,
      draggedCluster,
      (cluster1, cluster2) => cluster1.href === cluster2.href,
    );

    if (draggedCluster.displayType === 'summary') {
      const clusterLinks = _.map(this.state.clusterLinks, link => {
        // Update clusterLinks
        let newLink;

        if (link.source.href === draggedCluster.href) {
          newLink = update(link, {
            $merge: {source: draggedCluster, drag: true},
          });
        }

        if (link.target.href === draggedCluster.href) {
          newLink = update(link, {
            $merge: {target: draggedCluster, drag: true},
          });
        }

        return newLink || link;
      });

      if (this.state.mapRoute.id) {
        // drag groups in group view
        const focusedLocation = _.find(this.state.locations, loc => loc.href === draggedCluster.locHref);
        let distance =
          Math.pow(draggedCluster.x - focusedLocation.x, 2) + Math.pow(draggedCluster.y - focusedLocation.y, 2);

        distance = Math.sqrt(distance) + draggedCluster.width / 2;

        //Move cluster only within the radius of the location bubble
        if (distance < focusedLocation.r) {
          this.setState({clusters, clusterLinks});
        }
      } else if (!this.state.mapRoute.id) {
        this.setState({clusters, clusterLinks});
      }
    } else if (draggedCluster.displayType === 'full') {
      const links = _.map(this.state.links, link => {
        let newLink;

        //if link source or target === draggedCluster, also update links positions
        // if cluster is discovered, also check clusterId to see if clusters are the same cluster
        if (
          link.source.clusterParent === draggedCluster.href ||
          (link.source.clusterParent === 'discovered' &&
            link.source.cluster &&
            link.source.cluster.clusterId === draggedCluster.clusterId)
        ) {
          const newSource = update(link.source, {
            $merge: {
              cluster: draggedCluster,
            },
          });

          newLink = update(link, {
            $merge: {
              source: newSource,
              drag: true,
            },
          });
        } else if (
          link.target.clusterParent === draggedCluster.href ||
          (link.target.clusterParent === 'discovered' &&
            link.target.cluster &&
            link.target.cluster.clusterId === draggedCluster.clusterId)
        ) {
          const newTarget = update(link.target, {
            $merge: {
              cluster: draggedCluster,
            },
          });

          newLink = update(link, {
            $merge: {
              target: newTarget,
              drag: true,
            },
          });
        }

        return newLink || link;
      });

      this.setState({clusters, links});
    }
  },

  whileDragLocation(draggedLocation) {
    freezeInteraction = true;

    let locations = _.map(this.state.locations, location => {
      if (location.href === draggedLocation.href) {
        return draggedLocation;
      }

      return location;
    });

    locations = putDraggedElementOnTop(locations, draggedLocation, (loc1, loc2) => loc1.href === loc2.href);

    this.setState({locations});
  },

  whileDragNode(obj, node) {
    freezeInteraction = true;

    const nodes = _.map(this.state.nodes, node => {
      if (node.href === obj.href) {
        return obj;
      }

      return node;
    });

    let clusters = _.map(this.state.clusters, cluster => {
      if (cluster.href === obj.href) {
        return obj;
      }

      return cluster;
    });

    const links = _.map(this.state.links, link => {
      // Update any of the clusters' links whose
      // source or target may be the updated node
      let newLink;

      if (link.source.href === node.href) {
        newLink = update(link, {
          $merge: {source: node, drag: true},
        });
      }

      if (link.target.href === node.href) {
        newLink = update(link, {
          $merge: {target: node, drag: true},
        });
      }

      return newLink || link;
    });

    // when start dragging, put the dragged element on top of the graph
    if (node) {
      // put cluster being dragged to the front
      clusters = putDraggedElementOnTop(clusters, obj, (cluster1, cluster2) => cluster1.href === cluster2.href);
    }

    this.setState({nodes, links, clusters});
  },

  windowResize() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      // this function is called only on window resize end
      this.calculateSVGDimensions();
      actionCreators.updateZoomToFit();
    }, 500);
  },

  zoomIn() {
    actionCreators.updateZoomIn(this.state.transform);
  },

  zoomOut() {
    actionCreators.updateZoomOut(this.state.transform);
  },

  zoomToFit() {
    if (this.state.expandedClusters && this.state.expandedClusters.length === 2) {
      actionCreators.updateZoomToFit(this.state.expandedClusters);
    } else if (this.state.clusters.length > 20) {
      let focused = this.state.clusters.find(cluster => cluster.displayType === 'full');

      focused = focused ? [focused.href] : null;
      actionCreators.updateZoomToFit(focused);
    } else {
      actionCreators.updateZoomToFit();
    }
  },

  handleGraphLinkClick(params) {
    if (this.state.appMapVersion === 'vulnerability' || this.state.defaultAppMap === 'appMap') {
      return this.props.updateMapLevel(params);
    }

    this.transitionTo('appGroupIllumination', {id: params.id});
  },

  handleBackgroundContext(evt) {
    evt.preventDefault();

    let data = [];

    /* For effective zoomtoFit when 2 clusters on the map are expanded. */
    if (this.state.expandedClusters && this.state.expandedClusters.length === 2) {
      data = this.state.expandedClusters;
    }

    if (this.props.mapType === 'loc') {
      actionCreators.showMapMenu({
        type: 'location-map-bg',
        data,
        location: {x: evt.pageX, y: evt.pageY},
      });
    }

    /* Hide the tooltip on right click */
    this.tooltip = false;
    actionCreators.hideMapTooltip();
  },

  render() {
    if (GraphStore.isGraphCalculated() && !this.state.totalWorkloads) {
      // if there are no workloads paired, send them to the dashboard
      this.transitionTo('landing');
    }

    const svgStyle = {
      width: this.state.width || this.calculateWindowSize().width,
      height: this.state.height || this.calculateWindowSize().height,
    };
    const {
      mapLevel,
      mapType,
      mapRoute,
      appMapVersion,
      totalWorkloads,
      noLocationWorkloads,
      recentAppGroups,
      transform,
      graphCalculated,
      appGroupDisabled,
      appGroupMatching,
      linearAnimation,
      groupTooLarge,
    } = this.state;
    const {policyVersion} = this.props;

    const truncated = GraphStore.getTruncated();
    const areFiltersApplied = GraphStore.areFiltersApplied();
    // groups can only be dragged in group level and full map in location view
    const isGroupDraggable = mapType === 'loc' && mapLevel !== 'workload';
    // the graph should not be updated when mapType is changing
    const preventGraphElementAnimation = this.props.mapType !== mapType;

    // calculate location component
    const locations = _.map(this.state.locations, location => {
      // Discovery locations should be hidden in location and group views
      if (this.shouldHideLocation(location)) {
        return null;
      }

      return (
        <LocationComponent
          key={location.href}
          data={location}
          preventGraphElementAnimation={preventGraphElementAnimation}
          mapType={mapType}
          whileDragLocation={this.whileDragLocation}
          afterDragLocation={this.afterDragLocation}
          policyVersion={this.props.policyVersion}
          updateMapLevel={this.props.updateMapLevel}
        />
      );
    });

    const appSupergroupLinks = _.map(this.state.appSupergroupLinks, link => (
      <LinkComponent key={link.identifier} data={link} preventGraphElementAnimation={preventGraphElementAnimation} />
    ));

    const clusters = _.map(this.state.clusters, cluster => (
      <ClusterComponent
        key={cluster.href}
        data={cluster}
        mapLevel={mapLevel}
        mapType={this.props.mapType}
        preventGraphElementAnimation={preventGraphElementAnimation}
        truncated={truncated}
        linearAnimation={linearAnimation}
        hoverCluster={!truncated && this.state.clusters.length < 200 ? this.hoverCluster : () => {}}
        unhoverCluster={!truncated && this.state.clusters.length < 200 ? this.unhoverClusterAndLinks : () => {}}
        hoverNode={this.hoverNode}
        unhoverNode={this.unhoverNodeAndLink}
        hoverInternet={this.hoverNode}
        unhoverInternet={this.unhoverNodeAndLink}
        hoverLink={this.hoverLink}
        unhoverLink={this.unhoverNodeAndLink}
        whileDragCluster={isGroupDraggable && this.whileDragCluster}
        afterDragCluster={isGroupDraggable && this.afterDragCluster}
        whileDragNode={this.whileDragNode}
        afterDragNode={this.afterDragNode}
        updateMapLevel={this.props.updateMapLevel}
      />
    ));

    const nodes = _.map(this.state.nodes, node => (
      <NodeComponent
        key={node.href}
        data={node}
        textWhite={this.props.policyVersion === 'draft'}
        hoverNode={this.hoverNode}
        unhoverNode={this.unhoverNodeAndLink}
        hoverInternet={this.hoverNode}
        unhoverInternet={this.unhoverNodeAndLink}
        hoverLink={this.hoverLink}
        unhoverLink={this.unhoverNodeAndLink}
        whileDragNode={this.whileDragNode}
        afterDragNode={this.afterDragNode}
      />
    ));

    // Links in GraphComponent is interapp links
    const links = _.map(this.state.links, link => (
      <LinkComponent
        key={link.identifier}
        data={link}
        preventGraphElementAnimation={preventGraphElementAnimation}
        mapLevel={mapLevel}
        hoverLink={this.hoverLink}
        unhoverLink={this.unhoverNodeAndLink}
      />
    ));

    const tooManyClusters =
      mapLevel === 'group' &&
      clusters.length > (localStorage.getItem('max_clusters_for_links') || MAX_CLUSTERS_PER_LOCATION_FOR_LINKS);

    const clusterLinks = _.transform(
      this.state.clusterLinks,
      (result, link) => {
        let isLinkExisted = false;

        if (!tooManyClusters) {
          isLinkExisted = true;
        } else if (link.hovered === 'hovered') {
          link.type = 'gray';
          isLinkExisted = true;
        }

        if (isLinkExisted) {
          result.push(
            <LinkComponent
              key={link.identifier}
              data={link}
              preventGraphElementAnimation={preventGraphElementAnimation}
              truncated={truncated}
              hoverLink={this.hoverClusterLink}
              unhoverLink={this.unhoverClusterAndLinks}
            />,
          );
        }
      },
      [],
    );

    let banner = null;

    // don't draw the graph if only discovered or no_location left
    // instead, showing no data banner
    const onlySpecialLocations =
      this.state.locations.length &&
      _.every(this.state.locations, location => location.href === 'no_location' || location.href === 'discovered');

    const totalWorkloadThreshold =
      parseInt(localStorage.getItem('total_workload_threshold'), 10) || MAX_WORKLOAD_THRESHOLD;

    let appGroupRoute;

    if (!SessionStore.isEdge()) {
      appGroupRoute =
        mapLevel === 'workload' &&
        mapRoute &&
        TrafficStore.getNode(mapRoute.id) &&
        TrafficStore.getNode(mapRoute.id).appGroupParent;
    }

    if (mapType === 'app' && appGroupDisabled && totalWorkloads > 0) {
      const config = (
        <div className="AppGroup-Padding">
          <Button
            text={intl('AppGroups.SetAppGroupType')}
            onClick={() => this.transitionTo('appGroupType')}
            disabled={!SessionStore.isUserOwner() || SessionStore.isSuperclusterMember()}
          />
        </div>
      );

      banner = (
        <div className="Graph-no-data">
          <Banner type="notice" header={intl('Map.AppGroupDisabled')} content={config} />
        </div>
      );
    } else if (appGroupRoute && clusterLinks.length > MAX_CONNECTED_CLUSTERS) {
      let config = (
        <div className="AppGroup-Padding">
          <Button
            text={intl('AppGroups.OpenAppGroupMap')}
            onClick={() => this.transitionTo('appMapLevel', {type: 'focused', id: appGroupRoute})}
          />
        </div>
      );

      if (appGroupDisabled) {
        config = (
          <div className="AppGroup-Padding">
            <Button
              text={intl('AppGroups.SetAppGroupType')}
              onClick={() => this.transitionTo('appGroupType')}
              disabled={SessionStore.isSuperclusterMember() && !SessionStore.isUserOwner()}
            />
          </div>
        );
      }

      banner = (
        <div className="Graph-no-data">
          <Banner type="notice" header={intl('Map.TooManyConnectedGroups')} content={config} />
        </div>
      );
    } else if (mapType === 'app' && !appGroupMatching && totalWorkloads > 0) {
      banner = (
        <div className="Graph-no-data">
          <Banner type="notice" header={intl('Map.AppGroupConfigurationNotComplete')} />
        </div>
      );
    } else if (mapLevel === 'globalAppGroup' && totalWorkloads > 0) {
      const appGroupLinks = _.map(recentAppGroups, appGroup => {
        const appGroupName = RenderUtils.truncateAppGroupName(appGroup.name, 50, [20, 20, 10]);

        return (
          <div
            className="Graph-routelink"
            key={appGroup.name}
            title={appGroup.name}
            onClick={_.partial(this.handleGraphLinkClick, {type: 'focused', id: appGroup.id})}
          >
            {appMapVersion === 'vulnerability' && appGroup.vulnerability && (
              <Vulnerability vulnerability={appGroup.vulnerability} before />
            )}
            <span className={appMapVersion === 'vulnerability' && !appGroup.vulnerability ? 'Graph-vulnerability' : ''}>
              {appGroupName}
            </span>
          </div>
        );
      });

      const content = !_.isEmpty(recentAppGroups) && (
        <div className="RecentAppGroupsList" data-tid="elem-banner-table">
          <div className="RecentAppGroups">{intl('Map.RecentlyViewedAppGroups')}</div>
          {appGroupLinks}
        </div>
      );

      banner = (
        <div className="Graph-no-data">
          <Banner type="info" header={intl('Map.EnterAppGroup')} content={content || null} />
        </div>
      );
    } else if (mapType === 'loc' && totalWorkloads > totalWorkloadThreshold) {
      banner = (
        <div className="Graph-no-data">
          <Banner type="notice" header={intl('Map.Workloads.TooManyToDisplay')} />
        </div>
      );
    } else if (groupTooLarge) {
      banner = (
        <div className="Graph-no-data">
          <Banner
            type="notice"
            header={
              this.state.mapType === 'app'
                ? intl('Map.Workloads.AppGroupTooManyToDisplay')
                : intl('Map.Workloads.GroupTooManyToDisplay')
            }
          />
        </div>
      );
    } else if (
      mapType === 'loc' &&
      mapLevel !== 'full' &&
      totalWorkloads > 0 &&
      noLocationWorkloads === totalWorkloads
    ) {
      const msg = intl('Map.Workloads.MustHaveLocationLabels');

      banner = (
        <div className="Graph-no-data">
          <Banner type="notice" header={msg} />
        </div>
      );
    } else if (mapLevel === 'location' && GraphStore.getLocationsTruncated()) {
      banner = (
        <div className="Graph-no-data">
          <Banner type="notice" header={intl('Map.Locations.LocationsExceedsMaximum')} />
        </div>
      );
    } else if (
      (graphCalculated && !this.state.locations.length && !this.state.clusters.length && !this.state.nodes.length) ||
      onlySpecialLocations
    ) {
      const msg = areFiltersApplied ? intl('Map.Traffic.Filter.NoResult') : intl('Common.NoData');

      banner = (
        <div className="Graph-no-data">
          <Banner type="notice" header={msg} />
        </div>
      );
    }

    let graphContainer = null;
    let graphBackground = null;

    if (!banner) {
      const graphTransform =
        'translate(' + transform.translate[0] + ',' + transform.translate[1] + ') scale(' + transform.scale + ')';

      graphBackground = (
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          className="il-graph-background"
          onContextMenu={this.handleBackgroundContext}
        />
      );
      graphContainer = (
        <g className="il-graph-container" transform={graphTransform}>
          {appSupergroupLinks}
          {locations}
          {clusterLinks}
          {clusters}
          {links}
          {nodes}
        </g>
      );
    }

    const classes = cx({
      'GraphComponent': true,
      'Graph-draft': policyVersion === 'draft',
    });

    return (
      <div className={classes}>
        {banner}
        <svg className="svg-illumination" id="svg-illumination" ref="svg" style={svgStyle}>
          <defs />
          {graphBackground}
          {graphContainer}
        </svg>
        <InteractionPanel data={this.state} onSelectLinks={this.handleSelectMultiLinks} />
        <ZoomPanel
          data={this.state}
          handleZoomIn={this.zoomIn}
          handleZoomOut={this.zoomOut}
          handleZoomToFit={this.zoomToFit}
        />
        <Timestamp />
      </div>
    );
  },
});
