/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import Label from '../components/Label';
import LabelGroup from '../components/LabelGroup';
import {edge} from '../stores/UserStore';

const displayIconLabels = {
  app: 'app',
  env: 'env',
  loc: 'location' /* Don't display role icon in Illumio Edge */,
  ...(!edge && {role: 'role'}),
};

export const iconNames = {
  ...displayIconLabels,

  [intl('Common.IPLists')]: 'ip-list',
  [intl('Common.VirtualServices')]: 'virtual-service',
  [intl('Common.VirtualServers')]: 'virtual-server',
  [intl('Workloads.All')]: 'all-workloads',
  [intl('Common.Workloads')]: 'workload',
};

// maps the policy object's plural form to the object's singular form - EYE-63310
const labelMap = new Map([
  [intl('Common.IPLists'), intl('Common.IPList')],
  [intl('Labels.Groups'), intl('Labels.Group')],
  [intl('Common.VirtualServices'), intl('Common.VirtualService')],
  [intl('Common.Workloads'), intl('Common.Workload')],
  [intl('Common.UserGroups'), intl('Common.UserGroup')],
]);

export default {
  iconNames,

  // Function to convert label to an object that the combobox accepts
  labelMap(label) {
    return {label: label.value, value: label.href};
  },

  // Similar to above function, but this one creates the obj for the new OS comp (notice less API reliance)
  buildLabelTypeObject(labelsArray) {
    if (!labelsArray) {
      return {};
    }

    const labelsObject = {};

    labelsArray.forEach(label => {
      labelsObject[label.key] = label;
    });

    return labelsObject;
  },

  // Used to convert object of type:href mapping into array of labels with only href
  buildLabelHrefArray(labelTypeObject) {
    const labels = [];

    Object.keys(labelTypeObject).forEach(key => {
      const labelType = labelTypeObject[key];

      if (labelType && labelType.type !== 'all') {
        labels.push({href: (labelType && labelType.href) || labelType});
      }
    });

    return labels;
  },

  // Similar to above function, but specific to OS comp [no more just href since we are not expanding labels now]
  buildOSLabelArray(labelTypeObject) {
    if (!labelTypeObject) {
      return;
    }

    return Object.keys(labelTypeObject).map(key => labelTypeObject[key]);
  },

  // Strip 'key' and 'value' from labels array
  stripExtraneous(labelsArray) {
    if (!labelsArray) {
      return;
    }

    return labelsArray.map(label => ({href: label.href}));
  },

  stripExtraneousFromObject(labelsObject) {
    if (!labelsObject) {
      return;
    }

    return Object.keys(labelsObject).map(key => ({href: labelsObject[key].href}));
  },

  generateLabelOrLabelgroupOrPlainElement(props) {
    if (props.type) {
      return [intl('Labels.Group'), intl('Labels.Groups')].includes(props.group) ? (
        <LabelGroup {...props} />
      ) : (
        <Label {...props} />
      );
    }

    const newProps = {...props, className: 'ObjectSelector-item-title'};

    if (props['data-tid'] && !props['data-tid'].endsWith('-title')) {
      newProps['data-tid'] = `${props['data-tid']}-title`;
    }

    return <span {...newProps}>{props.text}</span>;
  },

  singularizeLabel(label) {
    return labelMap.get(label) ?? label;
  },
};
