/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

/**
 * Convert RGB white space syntax to comma syntax
 *
 * @param rgbString
 */
export const convertSpaceRGBToComma = (rgbString: string): string => {
  return rgbString.trim().replaceAll(/\s*[\s/]\s*/g, ', ');
};

/**
 * convert hex to rgb
 *
 * @param hexColor
 */
export const hexToRgb = (hexColor: string): string | undefined => {
  const result = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(hexColor);

  return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : undefined;
};

/**
 * convert rgb to hex
 *
 * @param rgbColor
 */
const rgb2hex = (rgbColor: string): string => {
  return rgbColor
    ? '#' +
        rgbColor
          .match(/\d+/g)!
          .map(x => Number(x).toString(16).padStart(2, '0'))
          .join(``)
    : rgbColor;
};

/**
 * convert different style color to hex
 *
 * @param allCssVariables
 * @param color
 */
export const convertToHex = (
  allCssVariables: Record<string, string>,
  color: string | undefined,
): string | undefined => {
  if (color?.includes('var')) {
    const varRgb = allCssVariables[color.substring(4, color.length - 1)];
    const rgbColor = varRgb ? convertSpaceRGBToComma(varRgb) : '';

    return rgb2hex(rgbColor);
  }

  if (color?.includes('rgb')) {
    return rgb2hex(color);
  }

  return color;
};
