/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import {Dialog, Notification} from '../../components';
import {Form, Fieldset, Field} from '../../components/Form';
import {UserStore, HealthStore} from '../../stores';
import SessionStore from '../../stores/SessionStore';
import intl from 'intl';
import RestApiUtils from '../../utils/RestApiUtils';

// If these values change, remember to change file MyProfile.js as well
const MIN_NUMBER_CONNS = 1;
const MAX_NUMBER_CONNS_DB = 200_000;
const MAX_NUMBER_CONNS_EXPLORER = 100_000;

const INPUT_RETURNED_FROM_DB = 'returnedFromDB';
const INPUT_DISPLAYED_IN_EXPLORER = 'displayedInExpl';

const ResultsSettingsDialog = React.createClass({
  getInitialState() {
    return {
      error: null,
      returnedFromDB: UserStore.getExplorerMaxDownloadResults(),
      displayedInExpl: UserStore.getExplorerMaxResults(),
      returnedFromDBError: null,
      displayedInExplError: null,
    };
  },

  componentDidMount() {
    // Make body unscrolable when component is shown
    document.body.style.overflow = 'hidden';
  },

  componentWillUnmount() {
    // Make body scrollable again on component close
    document.body.style.overflow = 'unset';
  },

  async onSaveDialog() {
    if (this.validateInputs()) {
      this.setState({
        error: null,
      });

      const {showMaxDBResults} = this.props;
      const {displayedInExpl, returnedFromDB} = this.state;

      try {
        // get the old settings
        const response = await RestApiUtils.kvPairs.getInstance(SessionStore.getUserId(), 'settings');
        const settings = response.body;

        // include the old values as well because update will replace the entire object
        const newSettings = {
          ...settings,
          explorerMaxResults: parseInt(displayedInExpl.toString(), 10),
          ...(showMaxDBResults && {explorerMaxDownloadResults: parseInt(returnedFromDB.toString(), 10)}),
        };

        // save the settings
        await RestApiUtils.kvPair.update(SessionStore.getUserId(), 'settings', newSettings);
        // if success
        this.props.onClose();
      } catch (error) {
        this.setState({
          error: error.message,
        });
      }
    }
  },

  handleOnInputChanged(event) {
    const {
      target: {name, value},
    } = event;

    // only update the state if input is number of empty string
    if (/^\d+$/.test(value) || value === '') {
      this.setState({
        [name]: value,
      });
    }
  },

  validateInputs() {
    this.setState({
      returnedFromDBError: null,
      displayedInExplError: null,
    });

    const {returnedFromDB, displayedInExpl} = this.state;

    let validInput = true;

    if (this.props.showMaxDBResults) {
      const parsedReturnedFromDB = parseInt(returnedFromDB.toString(), 10);

      if (
        isNaN(parsedReturnedFromDB) ||
        parsedReturnedFromDB < MIN_NUMBER_CONNS ||
        parsedReturnedFromDB > MAX_NUMBER_CONNS_DB
      ) {
        validInput = false;
        this.setState({
          returnedFromDBError: true,
        });
      }
    }

    const parsedDisplayedInExpl = parseInt(displayedInExpl.toString(), 10);

    if (
      isNaN(parsedDisplayedInExpl) ||
      parsedDisplayedInExpl < MIN_NUMBER_CONNS ||
      parsedDisplayedInExpl > MAX_NUMBER_CONNS_EXPLORER
    ) {
      validInput = false;
      this.setState({
        displayedInExplError: true,
      });
    }

    return validInput;
  },

  handleInputBlur() {
    this.validateInputs();
  },

  render() {
    const {showMaxDBResults, onClose} = this.props;
    const {returnedFromDB, displayedInExpl, error, returnedFromDBError, displayedInExplError} = this.state;
    const numOfClusters = HealthStore.getClusters()?.length ?? 1;

    // Defines the modal actions
    const dialogActions = [
      {
        text: intl('Common.Cancel'),
        type: 'linky',
        tid: 'cancel',
        ref: 'cancelButton',
        onClick: onClose,
      },
      {
        text: intl('Common.OK'),
        tid: 'confirm',
        ref: 'confirmButton',
        onClick: this.onSaveDialog,
      },
    ];

    return (
      <Dialog
        type="detail"
        title={intl('Explorer.ResultsSettings')}
        className="ResultsSettingsDialog"
        actions={dialogActions}
        onClose={onClose}
      >
        <Notification
          className="infoMsg"
          type="instruction"
          message={intl('Explorer.ResultsSettingsInfoMessageExplorer')}
          closeable={false}
        />
        <Form>
          <Fieldset title={intl('Explorer.MaxNumberOfConnections')}>
            <div className="contentForm">
              <Field
                type="text"
                tid="explorer-max-results"
                label={intl('Explorer.DisplayedInExplorer')}
                isRequired
                subText={intl('Explorer.ValidRange', {min: MIN_NUMBER_CONNS, max: MAX_NUMBER_CONNS_EXPLORER})}
                value={displayedInExpl}
                name={INPUT_DISPLAYED_IN_EXPLORER}
                onChange={this.handleOnInputChanged}
                onBlur={this.handleInputBlur}
                state={displayedInExplError ? 'error' : 'old'}
                errorMessage={displayedInExplError ? intl('Explorer.WrongValidRange') : null}
              />
              {showMaxDBResults && (
                <Field
                  type="text"
                  tid="explorer-max-download-results"
                  label={intl('Explorer.ReturnedFromDB', {numOfClusters})}
                  isRequired
                  subText={intl('Explorer.ValidRange', {min: MIN_NUMBER_CONNS, max: MAX_NUMBER_CONNS_DB})}
                  value={returnedFromDB}
                  name={INPUT_RETURNED_FROM_DB}
                  onChange={this.handleOnInputChanged}
                  onBlur={this.handleInputBlur}
                  state={returnedFromDBError ? 'error' : 'old'}
                  errorMessage={returnedFromDBError ? intl('Explorer.WrongValidRange') : null}
                />
              )}
            </div>
          </Fieldset>
        </Form>

        {error && <Notification type="error" title={intl('Common.Error')} message={<span>{error}</span>} />}
      </Dialog>
    );
  },
});

export default ResultsSettingsDialog;

ResultsSettingsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  showMaxDBResults: PropTypes.bool.isRequired,
};

ResultsSettingsDialog.defaultProps = {
  data: [],
};
