/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import {Navigation} from 'react-router';
import intl from 'intl';
import * as clipboard from 'clipboard-polyfill';
import {Menu, MenuItem} from './Menu';
import Label from './Label';
import _ from 'lodash';
import actionCreators from '../actions/actionCreators';
import cx from 'classnames';
import {GridDataUtils} from '../utils';

export default React.createClass({
  mixins: [Navigation],

  getDefaultProps() {
    return {
      labelProps: {
        iconName: '',
        text: '',
        labelActionData: {},
        noLabel: false,
      },
      disabled: false,
      copy: true,
      tid: '',
      menuItems: [],
    };
  },

  handleClickMenuItem(labelActionData, type) {
    if (!labelActionData) {
      return;
    }

    actionCreators.setFilterOnHoverSelection({filterKey: type, filter: labelActionData});
  },

  handleClick() {
    const {type, labelActionData} = this.props;

    if (!labelActionData.href) {
      return;
    }

    if (type === 'role' || type === 'env' || type === 'loc' || type === 'app') {
      this.transitionTo('labels.item', {id: GridDataUtils.getIdFromHref(labelActionData.href)});
    } else if (type === 'workload') {
      if (labelActionData.href.search(/container_workloads/) > 0) {
        this.transitionTo('containerWorkload', {id: GridDataUtils.getIdFromHref(labelActionData.href)});
      } else {
        this.transitionTo('workloads.item', {id: GridDataUtils.getIdFromHref(labelActionData.href)});
      }
    } else if (type === 'iplist') {
      this.transitionTo('iplists.item', {id: GridDataUtils.getIdFromHref(labelActionData.href), pversion: 'draft'});
    } else if (type === 'group') {
      this.transitionTo('groups.view', {group: labelActionData.groupId}, {tab: 'inboundpolicy', pversion: 'draft'});
    }
  },

  handleCopyToClipboard(copyText) {
    clipboard.writeText(copyText);
  },

  render() {
    const {labelProps, labelActionData, noLabel, menuItems, type, labelType, disabled, copy} = this.props;
    const text = labelActionData?.name ?? labelActionData?.hostname ?? labelActionData?.value;
    const className = cx('hover-menu', {
      'hover-menu-nolabel': noLabel,
    });

    const portProtocolClass =
      type === 'portProtocol' || type === 'processName' || type === 'windowsService' || type === 'ipaddress'
        ? 'portProtocolClass'
        : null;

    if (disabled) {
      return labelProps.iconName || labelType ? (
        <Label {...labelProps} icon={labelProps.iconName} labelType={labelType} />
      ) : (
        <div className={portProtocolClass}>{text}</div>
      );
    }

    return (
      <div className={className} data-tid="comp-hover-menu">
        <Menu
          onSelect={_.partial(this.handleClick, type)}
          triggerOnHoverOpenDebounce={400}
          triggerOnHoverCloseTimeout={100}
          triggerOnHover
          disableAnimation
          label={
            labelType || labelProps.iconName ? (
              <Label labelType={labelType} icon={labelProps.iconName} text={labelProps.text} />
            ) : (
              labelProps.text
            )
          }
          tid="hover-menu"
          focusItemOnOpen="first"
        >
          {menuItems.map(item => (
            <MenuItem
              onSelect={_.partial(this.handleClickMenuItem, labelActionData, item.type)}
              text={item.enTranslation}
              data-tid={item.tid}
            />
          ))}
          {copy ? (
            <MenuItem
              onSelect={_.partial(this.handleCopyToClipboard, text)}
              text={intl('Common.CopyToClipboard')}
              data-tid="copy"
            />
          ) : null}
        </Menu>
      </div>
    );
  },
});
