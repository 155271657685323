/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import Slider from 'react-rangeslider';
import {TrafficStore, SessionStore, OrgStore} from '../../stores';
import {GridDataUtils, PolicyGeneratorUtils, RenderUtils, GroupDataUtils} from '../../utils';
import actionCreators from '../../actions/actionCreators';
import {RouterMixin, PolicyGeneratorMixin, StoreMixin} from '../../mixins';
import PolicyGeneratorLargeServices from '../../modals/PolicyGeneratorLargeServices.jsx';
import {Navbar, ProgressBar, Button, Radio, RadioGroup, AlertDialog, Banner} from '../../components';
import {AppGroupTabs} from '../Groups';
import {ConfigureIntraConnectionsGrid, CoverageProgressBar} from '../../components/PolicyGenerator';

function getStateFromStores() {
  const traffic = TrafficStore.getAllRoleTraffics();
  const state = this.getPolicyGeneratorValues() || {};
  const rulesetId = state.type === 'replace' && state.ruleset && state.ruleset.href.split('/').pop();

  if (_.isEmpty(state.ipListConfig)) {
    state.ipListConfig = {level: 'role', type: 'tiertotier', inclusion: 'all'};
  }

  let newState = {...state};

  if (state.appGroup) {
    const roles = this.getAppGroupRoles(TrafficStore.getAllRoleNodes(), state.appGroup.href).map(role => role.href);

    newState = PolicyGeneratorUtils.parseIpListConfigTraffic(
      traffic,
      state.appGroup,
      state.selectedIpLists,
      rulesetId,
      state.ipListConfig,
      roles,
      state.optimizeLevel,
    );
    newState.ruleCoverage = PolicyGeneratorUtils.calculateRuleCoverage(
      newState.ipListTableTraffic,
      newState.counts.ipListConnectionsWorkingTotal,
      state.ipListExclusions,
    );
    newState.closedVulnerabilities = PolicyGeneratorUtils.calculateClosedVulnerabilities(
      newState.vulnerabilities,
      state.ipListExclusions,
    );
    newState.rulesetId = rulesetId;
  } else {
    newState.totalConnections = 0;
    newState.ruleCoverage = 0;
  }

  const id = this.getParams() && this.getParams().id;

  if (id && (!state.appGroup || id !== state.appGroup.href) && TrafficStore.getNodeForPolicyGenerator(id)) {
    this.setAppGroup(TrafficStore.getNodeForPolicyGenerator(id));
  }

  return newState;
}

export default React.createClass({
  mixins: [RouterMixin, PolicyGeneratorMixin, StoreMixin(TrafficStore, getStateFromStores)],

  getInitialState() {
    return {
      vulnerabilitiesEnabled: SessionStore.areVulnerabilitiesEnabled(),
      ipListExclusions: [],
      // TODO: We can store this sorting info in some storage
      sorting: [{key: 'type', direction: false}],
      showProgressBar: 'none',
      spreadButtons: 'narrow',
      providerConsumerOrder: OrgStore.providerConsumerOrder(),
    };
  },

  componentDidMount() {
    if (!this.state.appGroup) {
      this.transitionTo('policygenerator');

      return;
    }

    this.getData(this.state, 'ipList');
    this.getPolicyGeneratorRuleset(this.state.appGroup);
    this.setState(getStateFromStores.call(this));
    window.addEventListener('scroll', this.handleScroll);
  },

  async componentWillReceiveProps() {
    const id = this.getParams() && this.getParams().id;
    const appGroup = TrafficStore.getNodeForPolicyGenerator(id);

    if (id && (!this.state.appGroup || id !== this.state.appGroup.href) && appGroup) {
      this.setAppGroup(appGroup);

      const ruleset = await this.getPolicyGeneratorRuleset(appGroup);

      this.setState({ruleset, showProgress: true, appGroup}, () => {
        //reload the data for the new app group
        this.getData(this.state, 'ipList');
      });
    }
  },

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  getCompleteServices() {
    this.setTypeSelect('microsegmentation');
    actionCreators.updateForAll();
    _.defer(() => this.getData(this.state, 'ipList'));
  },

  setTypeSelect(type) {
    this.handleConfigSelect({
      type,
      level: this.state.ipListConfig.level,
      inclusion: this.state.ipListConfig.inclusion,
    });
  },

  handleTypeSelect(evt) {
    const type = evt.target.value;

    // Warn the user if there are high numbers of connections before selecting Microsegmentation
    if ((type === 'microsegmentation' || type === 'auto') && this.state.counts.missingConnections) {
      actionCreators.openDialog(
        <PolicyGeneratorLargeServices
          selectAllServices={_.partial(this.setTypeSelect, 'tiertotier')}
          selectSpecifiedServices={this.getCompleteServices}
        />,
      );

      return;
    }

    this.setTypeSelect(type);
  },

  handleLevelSelect(evt) {
    this.handleConfigSelect({
      level: evt.target.value,
      type: this.state.ipListConfig.type,
      inclusion: this.state.ipListConfig.inclusion,
    });
  },

  handleSort(key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({key, direction});
    }

    this.setState({sorting});
  },

  handleInclusionSelect(evt) {
    this.handleConfigSelect({
      level: this.state.ipListConfig.level,
      type: this.state.ipListConfig.type,
      inclusion: evt.target.value,
    });
  },

  setExclusions(exclusions) {
    const {ipListTableTraffic, counts, vulnerabilities} = this.state;
    const ruleCoverage = PolicyGeneratorUtils.calculateRuleCoverage(
      ipListTableTraffic,
      counts.ipListConnectionsWorkingTotal,
      exclusions,
    );
    const closedVulnerabilities = PolicyGeneratorUtils.calculateClosedVulnerabilities(vulnerabilities, exclusions);

    this.setIpListExclusions(exclusions);
    this.setState({ipListExclusions: exclusions, ruleCoverage, closedVulnerabilities});
  },

  handleConfigSelect(ipListConfig) {
    const {appGroup, selectedIpLists, targetNodes, optimizeLevel, ipListExclusions, rulesetId, type, counts} =
      this.state;
    const traffic = TrafficStore.getAllRoleTraffics();
    const roles = this.getAppGroupRoles(TrafficStore.getAllRoleNodes(), appGroup.href).map(role => role.href);
    const {ipListTableTraffic} = PolicyGeneratorUtils.parseIpListConfigTraffic(
      traffic,
      appGroup,
      selectedIpLists,
      type === 'replace' && rulesetId,
      ipListConfig,
      roles,
      optimizeLevel,
    );
    const vulnerabilities = PolicyGeneratorUtils.getVulnerabilities(ipListTableTraffic, targetNodes);
    const ruleCoverage = PolicyGeneratorUtils.calculateRuleCoverage(
      ipListTableTraffic,
      counts.ipListConnectionsWorkingTotal,
      ipListExclusions,
    );
    const closedVulnerabilities = PolicyGeneratorUtils.calculateClosedVulnerabilities(
      vulnerabilities,
      ipListExclusions,
    );

    this.setIpListConfig(ipListConfig);
    this.setState({ipListConfig, ipListTableTraffic, ruleCoverage, vulnerabilities, closedVulnerabilities});
  },

  handleOptimizeLevelChange(optimizeLevel) {
    const {appGroup, selectedIpLists, targetNodes, ipListConfig, ipListExclusions, rulesetId, type, counts} =
      this.state;
    const traffic = TrafficStore.getAllRoleTraffics();
    const roles = this.getAppGroupRoles(TrafficStore.getAllRoleNodes(), appGroup.href).map(role => role.href);
    const {ipListTableTraffic} = PolicyGeneratorUtils.parseIpListConfigTraffic(
      traffic,
      appGroup,
      selectedIpLists,
      type === 'replace' && rulesetId,
      ipListConfig,
      roles,
      optimizeLevel,
    );
    const vulnerabilities = PolicyGeneratorUtils.getVulnerabilities(ipListTableTraffic, targetNodes);
    const ruleCoverage = PolicyGeneratorUtils.calculateRuleCoverage(
      ipListTableTraffic,
      counts.ipListConnectionsWorkingTotal,
      ipListExclusions,
    );
    const closedVulnerabilities = PolicyGeneratorUtils.calculateClosedVulnerabilities(
      vulnerabilities,
      ipListExclusions,
    );

    this.setOptimizeLevel(optimizeLevel);
    this.setState({optimizeLevel, ipListTableTraffic, ruleCoverage, vulnerabilities, closedVulnerabilities});
  },

  handleRowToggle(row) {
    const exclusions = [...this.state.ipListExclusions];

    if (exclusions.includes(row)) {
      const rowIndex = exclusions.indexOf(row);

      exclusions.splice(rowIndex, 1);
    } else {
      exclusions.push(row);
    }

    this.setExclusions(exclusions);
  },

  handleIncludeAll() {
    const {ipListTableTraffic, ipListExclusions, ipListConnectionFilters} = this.state;
    const rows = ipListTableTraffic.filter(row => row.filterKey.includes(ipListConnectionFilters || ''));
    const exclusions = [...ipListExclusions];

    rows.forEach(row => {
      if (ipListExclusions.includes(row.key)) {
        const rowIndex = exclusions.indexOf(row.key);

        exclusions.splice(rowIndex, 1);
      }
    });
    this.setExclusions(exclusions);
  },

  handleExcludeAll() {
    const {ipListTableTraffic, ipListExclusions, ipListConnectionFilters} = this.state;
    const rows = ipListTableTraffic.filter(row => row.filterKey.includes(ipListConnectionFilters || ''));
    const exclusions = [...ipListExclusions];

    rows.forEach(row => {
      if (!ipListExclusions.includes(row.key)) {
        exclusions.push(row.key);
      }
    });
    this.setExclusions(exclusions);
  },

  handleScroll() {
    let top = 0;

    if (this.table) {
      // Find the top of the table, because the top div can vary in size
      top = this.table.getBoundingClientRect().top;
    }

    const bar = this.state.showProgressBar;
    const buttons = this.state.bottomButtons;
    const pinAt = -80;

    if (bar !== 'top' && top < pinAt) {
      this.setState({showProgressBar: 'top'});
    } else if (bar !== 'none' && top > pinAt) {
      this.setState({showProgressBar: 'none'});
    }

    if (buttons && top > pinAt) {
      this.setState({bottomButtons: false});
    } else if (!buttons && top < pinAt) {
      this.setState({bottomButtons: true});
    }
  },

  handleBack() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupIpListChoose', {id});
    } else {
      this.transitionTo('ipListChoose');
    }
  },

  handleNext() {
    if (this.state.ruleCoverage) {
      this.handleForceNext();

      return;
    }

    actionCreators.openDialog(
      <AlertDialog
        title={intl('PolicyGenerator.NoConnections')}
        message={intl('PolicyGenerator.AllExcluded')}
        onConfirm={this.handleForceNext}
      />,
    );
  },

  handleForceNext() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupIpListPreview', {id});
    } else {
      this.transitionTo('ipListPreview');
    }
  },

  handleAddFilter(ipListConnectionFilters) {
    this.setIpListConnectionFilters(ipListConnectionFilters);
    this.setState({ipListConnectionFilters});
  },

  handleRemoveFilter() {
    this.setIpListConnectionFilters(null);
    this.setState({ipListConnectionFilters: null});
  },

  handleTransmissionFilterChange(filters) {
    this.setIpListTransmissionFilters(filters);
    this.setState({ipListTransmissionFilters: filters}, () => this.getAppGroupTraffic(this.state, 'ipList'));
  },

  render() {
    const {
      appGroup,
      ipListTableTraffic,
      ruleCoverage,
      ipListExclusions,
      counts,
      loadingSpinner,
      modal,
      showProgressBar,
      bottomButtons,
      ipListConnectionFilters,
      vulnerabilitiesEnabled,
      closedVulnerabilities,
      vulnerabilities,
      optimizeLevel,
      rulesetId,
      ipListTransmissionFilters,
      providerConsumerOrder,
    } = this.state;

    if (!appGroup) {
      return null;
    }

    const workingTotal = counts.ipListConnectionsWorkingTotal;
    const percent = workingTotal ? ruleCoverage / workingTotal : 0;
    const totalPercent = counts.ipListConnections
      ? (ruleCoverage + counts.ipListConnectionRules) / counts.ipListConnections
      : 0;
    const excluded = workingTotal ? workingTotal - ruleCoverage : 0;
    let title = intl('PolicyGenerator.IpList.ConfigureIpList');
    let appGroupTabs = null;

    if (this.getParams() && this.getParams().id) {
      const mapRoute = GroupDataUtils.getMapRoute(appGroup, this.getParams().id, this.mapLevel, 'appgroups');

      title = RenderUtils.truncateAppGroupName(appGroup.name, 45, [30, 15, 10]);
      appGroupTabs = (
        <div className="GroupBar">
          <AppGroupTabs active="policyGenerator" mapRoute={mapRoute} />
        </div>
      );
    }

    let {ipListConfig} = this.state;

    if (_.isEmpty(ipListConfig)) {
      ipListConfig = {level: 'appgroup', type: 'tiertotier'};
    }

    let trafficTable = null;

    const progressBar = (
      <CoverageProgressBar
        connections={counts.ipListConnections}
        connectionRules={counts.ipListConnectionRules}
        ruleCoverage={ruleCoverage}
        excluded={excluded}
        direction="horizontal"
        percent={percent}
      />
    );

    let trafficData = ipListTableTraffic;
    let allMissingRoles = true;

    if (!_.isEmpty(ipListConnectionFilters)) {
      trafficData = ipListTableTraffic.filter(row => row.filterKey.includes(ipListConnectionFilters || ''));
    }

    trafficData.forEach(traffic => {
      allMissingRoles &&= GridDataUtils.areRolesMissing('ipList', ipListConfig, {
        provider: traffic.target,
        consumer: traffic.source,
      });
    });

    if (ipListTableTraffic) {
      trafficTable = (
        <ConfigureIntraConnectionsGrid
          trafficData={trafficData}
          title={appGroup.name}
          type="ipList"
          config={ipListConfig}
          showToolbar={true}
          subtitle={`${ipListTableTraffic.length} IP List Connections`}
          exclusions={ipListExclusions}
          selectable={true}
          onRowToggle={this.handleRowToggle}
          onInclusionToggle={this.handleInclusionToggle}
          sorting={this.state.sorting}
          onSort={this.handleSort}
          filters={ipListConnectionFilters}
          onAddFilter={this.handleAddFilter}
          onRemoveFilter={this.handleRemoveFilter}
          transmissionFilters={ipListTransmissionFilters}
          onTransmissionFilterChange={this.handleTransmissionFilterChange}
          onIncludeAll={this.handleIncludeAll}
          onExcludeAll={this.handleExcludeAll}
          progressBar={progressBar}
          showProgressBar={showProgressBar}
          completeData={ipListTableTraffic.length === trafficData.length}
          allMissingRoles={allMissingRoles}
          maxRows={this.getMaxTableRules()}
          rulesetId={rulesetId}
          providerConsumerOrder={providerConsumerOrder}
        />
      );
    }

    if (loadingSpinner && !modal) {
      let message;

      switch (loadingSpinner) {
        case 'rules':
          message = intl('PolicyGenerator.Spinner.CalculatingRuleCoverage');
          break;
        case 'vulnerabilities':
          message = intl('PolicyGenerator.Spinner.CalculatingVulnerabilityData');
          break;
        default:
          message = intl('PolicyGenerator.Spinner.RecalculatingFlowData');
      }

      return (
        <div className="RBConfigure RBConfigure--Extra">
          <Banner type="progresscentered" header={intl('PolicyGenerator.CalculationInProgress')} message={message} />
          <Navbar title={title} />
        </div>
      );
    }

    const className = bottomButtons ? 'RBConfigure-Content-Nav--show' : 'RBConfigure-Content-Nav--hidden';

    return (
      <div className="RBConfigure RBConfigure--Extra">
        <Navbar title={title} />
        {appGroupTabs}
        <div className="RBConfigure-Content">
          <ProgressBar
            steps={[
              intl('PolicyGenerator.SelectAppGroup'),
              intl('PolicyGenerator.IpList.ChooseIpList'),
              intl('PolicyGenerator.IpList.ConfigureIpList'),
              intl('PolicyGenerator.PreviewRules'),
            ]}
            active={2}
          />
          <div className="RBConfigure-Content-Nav">
            <Button text={intl('Common.Back')} type="secondary" onClick={this.handleBack} tid="back" />
            <Button text={intl('Common.Next')} onClick={this.handleNext} tid="next" />
          </div>
          <div className="RBConfigure-Content-Nav-Wrapper">
            <div className={className}>
              <div className="RBConfigure-Content-Nav">
                <Button text={intl('Common.Back')} type="secondary" onClick={this.handleBack} tid="back" />
                <Button text={intl('Common.Next')} onClick={this.handleNext} tid="next" />
              </div>
            </div>
          </div>
          <div className="RBConfigure-Content-AppGroup">
            <div className="RBConfigure-Content-AppGroup-Choose">
              <h2 className="RBConfigure-Content-AppGroup-Choose-Title">
                {intl('PolicyGenerator.IpList.Options.Title')}
              </h2>
              <div className="RBConfigure-Content-AppGroup-Choose-Values">
                <h3 className="RBConfigure-Content-AppGroup-Choose-Values-RadioGroupTitle">
                  {intl('PolicyGenerator.ServiceRuleConfig')}
                </h3>
                <RadioGroup value={ipListConfig.type}>
                  <Radio
                    value="tiertotier"
                    onChange={this.handleTypeSelect}
                    checked={ipListConfig.type === 'tiertotier'}
                    label={intl('Common.AllServices')}
                    tid="allservices"
                  >
                    {intl('PolicyGenerator.Options.AllServices.Body', {level: ipListConfig.level})}
                  </Radio>
                  <Radio
                    value="microsegmentation"
                    onChange={this.handleTypeSelect}
                    checked={ipListConfig.type === 'microsegmentation'}
                    label={intl('PolicyGenerator.Options.SpecifiedServices.Title')}
                    tid="specifiedservices"
                  >
                    {intl('PolicyGenerator.Options.SpecifiedServices.Body', {level: ipListConfig.level})}
                  </Radio>
                  {vulnerabilitiesEnabled ? (
                    <Radio
                      value="auto"
                      onChange={this.handleTypeSelect}
                      checked={ipListConfig.type === 'auto'}
                      label={intl('PolicyGenerator.IntraScope.Options.AutoLevel.Title')}
                      tid="auto"
                    >
                      {intl('PolicyGenerator.IntraScope.Options.AutoLevel.Body')}
                    </Radio>
                  ) : null}
                  {ipListConfig.type === 'auto' ? (
                    <div className="RBConfigure-Content-AppGroup-Choose-Level">
                      {intl('Common.Severity')}
                      <div className="RBConfigure-Content-AppGroup-Choose-Level-Slider">
                        <div className="VulnerabilitySeverity">
                          <Slider
                            min={1}
                            max={5}
                            step={1}
                            labels={RenderUtils.getVulnerabilityOptions()}
                            value={optimizeLevel}
                            onChange={this.handleOptimizeLevelChange}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </RadioGroup>
              </div>
              <div className="RBConfigure-Content-AppGroup-Choose-Image">
                <img
                  className="RBConfigure-Content-AppGroup-Choose-Image-Img"
                  alt={ipListConfig.level + ' ' + ipListConfig.type}
                  src={`/images/policygenerator/iplists${ipListConfig.level}-${ipListConfig.type}.png`}
                />
              </div>
            </div>
            <div className="RBConfigure-Content-AppGroup-Info">
              <p>
                <strong>{intl('Common.AppGroup')}:</strong>{' '}
                {`${appGroup.name} - ${intl('Workloads.WorkloadsNumber', {count: appGroup.workloads})}`}
              </p>
              <p>
                <strong>{intl('PolicyGenerator.IpListConnections')}</strong>
                <br />
                {intl('AppGroupCoverage.RuleCoverageStrong', {val: totalPercent}, {html: true})}
              </p>
              <CoverageProgressBar
                connections={counts.ipListConnections}
                connectionRules={counts.ipListConnectionRules}
                ruleCoverage={ruleCoverage}
                excluded={excluded}
                direction="vertical"
                type="ipList"
                percent={percent}
                iplist
                totals={vulnerabilitiesEnabled && vulnerabilities.counts}
                closed={vulnerabilitiesEnabled && closedVulnerabilities}
              />
            </div>
          </div>
          <div ref={node => (this.table = node)} data-tid="policy-generator-grid">
            {trafficTable}
          </div>
        </div>
      </div>
    );
  },
});
