/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import StoreUtils from '../utils/StoreUtils';
import _ from 'lodash';
import SessionStore from './SessionStore';
import dispatcher from '../actions/dispatcher';
import {hexToRgb, convertToHex} from 'utils/color';
import {getCSSVariables} from 'utils/dom';

const REALMap = {
  role: {
    initial: 'R',
    icon: 'role',
    background_color: 'var(--purple-200)',
    foreground_color: '#ffffff',
    background_color_rgb: 'rgb(206, 147, 216)',
    foreground_color_rgb: 'rgb(255, 255, 255)',
    display_name_plural: intl('Common.Roles'),
  },
  env: {
    initial: 'E',
    icon: 'env',
    background_color: 'var(--teal-400)',
    foreground_color: '#ffffff',
    background_color_rgb: 'rgb(38, 166, 154)',
    foreground_color_rgb: 'rgb(255, 255, 255)',
    display_name_plural: intl('Common.Environments'),
  },
  app: {
    initial: 'A',
    icon: 'app',
    background_color: 'var(--blue-400)',
    foreground_color: '#ffffff',
    background_color_rgb: 'rgb(66, 165, 245)',
    foreground_color_rgb: 'rgb(255, 255, 255)',
    display_name_plural: intl('Common.Applications'),
  },
  loc: {
    initial: 'L',
    icon: 'loc',
    background_color: 'rgb(133, 122, 214)',
    foreground_color: '#ffffff',
    background_color_rgb: 'rgb(133, 122, 214)',
    foreground_color_rgb: 'rgb(255, 255, 255)',
    display_name_plural: intl('Common.Locations'),
  },
};

const defaultCustomStyle = {icon: '', background_color: 'var(--gray-500)', foreground_color: '#ffffff'};
const allCssVariables = getCSSVariables();

let labels = [];
let count = {};
let labelDimensions = [
  {
    key: 'role',
    display_name: intl('Common.Role'),
    display_info: REALMap.role,
  },
  {
    key: 'env',
    display_name: intl('Common.Environment'),
    display_info: REALMap.env,
  },
  {
    key: 'app',
    display_name: intl('Common.Application'),
    display_info: REALMap.app,
  },
  {
    key: 'loc',
    display_name: intl('Common.Location'),
    display_info: REALMap.loc,
  },
];
const labelMap = {};

let isLoaded = false;

function setLabels(data) {
  labels = data;
  data.forEach(i => (labelMap[i.href] = i));
}

function setLabelDimensions(data) {
  labelDimensions = data;
}

function setCount(data) {
  count = data;
}

export default createApiStore(['LABEL_', 'LABELS_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.LABELS_GET_COLLECTION_SUCCESS:
        setLabels(action.data);
        setCount(action.count);
        isLoaded = true;
        break;

      case Constants.LABELS_GET_INSTANCE_SUCCESS:
        labelMap[action.data.href] = action.data;
        StoreUtils.setObject(action.data, labels);
        break;

      case Constants.LABEL_DIMENSIONS_GET_COLLECTION_SUCCESS:
        dispatcher.waitFor([SessionStore.dispatchToken]);

        const parseList = action.data.map(item => {
          const style = item.display_info;
          const defaultStyle = REALMap[item.key] ?? defaultCustomStyle;

          return {
            ...item,
            ...(SessionStore.isEdge() && item.key === 'role' && {name: intl('Common.Group')}),
            display_info: {
              initial: style?.initial ?? defaultStyle.initial ?? Array.from(item.display_name)[0],
              icon: style?.icon ?? defaultStyle.icon,
              background_color: convertToHex(allCssVariables, style?.background_color ?? defaultStyle.background_color),
              foreground_color: style?.foreground_color ?? defaultStyle.foreground_color,
              background_color_rgb: hexToRgb(convertToHex(style?.background_color ?? defaultStyle.background_color)),
              foreground_color_rgb: hexToRgb(style?.foreground_color ?? defaultStyle.foreground_color),
              display_name_plural: style?.display_info?.display_name_plural ?? defaultStyle?.display_name_plural,
            },
          };
        });

        setLabelDimensions(parseList);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getCount() {
    return count;
  },

  getSpecified(href) {
    return labelMap[href];
  },

  getAll() {
    return labels;
  },

  getByType(type) {
    return _.filter(labels, label => label.key === type);
  },

  getLabelSettingsList() {
    return labelDimensions;
  },

  getLabelTypes() {
    return labelDimensions.map(label => label.key);
  },

  getLabelDisplayName() {
    return labelDimensions.reduce((result, label) => ({...result, [label.key]: label.display_name}), {});
  },

  getLabelKeys() {
    return labelDimensions.map(label => label.key);
  },

  isLoaded() {
    return isLoaded;
  },
});
