/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import ErrorsAPI from './errorsAPI';

export default {
  ErrorsAPI,

  Common: {
    Action: 'Action',
    Actions: 'Actions',
    Active: 'Active',
    ActiveDirectory: 'Active Directory',
    ActiveDirectoryCertificate: 'Active Directory Certificate Services',
    ActiveDirectoryLightweightDirectory: 'Active Directory Lightweight Directory Services',
    ActiveDirectoryRightsManagement: 'Active Directory Rights Management Services',
    AccessRestrictions: 'Access Restrictions',
    Accept: 'Accept',
    Allow: 'Allow',
    DontAllow: "Don't Allow",
    Add: 'Add',
    AddIpRange: 'Add IP Range',
    AdditionalTools: 'Additional Tools',
    AddNewGroup: 'Add new Group',
    AddressLine1: 'Address Line 1',
    AddressLine2: 'Address Line 2',
    AddValidName: 'Please enter a valid Name.',
    AddValidAddress: 'Please enter a valid Address.',
    AddValidIPAddressOrFQDN: 'Please enter a valid IP Address or FQDN.',
    AddValidDescription: 'Please enter a valid Description',
    AdminGroup: 'Admin Group',
    AuthenticationSettings: 'Authentication Settings',
    Alert: 'Alert',
    All: 'All',
    ALL: 'ALL',
    AllAllowed: 'All Allowed',
    AllApplications: 'All Applications',
    AllBlocked: 'All Blocked',
    AllEnvironments: 'All Environments',
    AllLocations: 'All Locations',
    AllRoles: 'All Roles',
    AllGroups: 'All Groups',
    AllPorts: 'All Ports',
    AllPortsProtocols: 'All Ports/Protocols',
    Allowed: 'Allowed',
    AllowedAcrossBoundary: 'Allowed across Boundary',
    AllowItemsRemove: 'These items are available to delete',
    And: 'and',
    CABundle: 'Trusted CA Bundle',
    Canceled: 'Canceled',
    CancelRequested: 'Cancel Requested',
    Certificate: 'Certificate',
    City: 'City',
    CloudSecure: 'CloudSecure',
    Configure: 'Configure',
    ConsumerOrProvider: 'Consumers Or Providers',
    ConsumerAndProvider: 'Consumers And Providers',
    ConsumerFqdn: 'Consumer FQDN',
    Corporate: 'Corporate',
    SourceFqdn: 'Source FQDN',
    Containers: 'Containers',
    Completed: 'Completed',
    Chain: 'Chain',
    Anything: 'Anything',
    Apply: 'Apply',
    API: 'API',
    AppGroup: 'App Group',
    AppGroups: 'App Groups',
    AppGroupsList: 'App Groups List',
    AppGroupsMore: 'App Groups...',
    Contract: 'Contract',
    Core: 'Core',
    Corex: 'CoreX',
    Edge: 'Edge',
    GroupsMore: 'Groups...',
    AppGroupsSettings: 'App Group Configuration',
    Application: 'Application',
    Applications: 'Applications',
    AppGroupMap: 'App Group Map',
    AppGroupMapDetails: 'Use the Classic App Group Map by default',
    ApplicationDescription: 'The name of the application that the Workload supports. E.g., eCommerce or ERP.',
    PoolTarget: 'Pool Target',
    AreYouSure: 'Are you sure?',
    Arrow: 'Arrow',
    ArrowLeft: 'Arrow left',
    ArrowRight: 'Arrow right',
    ArrowUp: 'Arrow up',
    ArrowDown: 'Arrow down',
    ArrowLeftLong: 'Arrow left long',
    ArrowRightLong: 'Arrow right long',
    AssignRole: 'Assign Role',
    AssignRoles: 'Assign Roles',
    Attributes: 'Attributes',
    AUSUsers: 'Adaptive User Segmentation (AUS) users',
    Authentication: 'Authentication',
    AutoRefresh: 'Auto Refresh',
    AzureNSGFlow: 'Azure NSG Flow',
    Back: 'Back',
    BackToList: 'Back to list',
    BlockAction: 'Reject Connections',
    Blocked: 'Blocked',
    BlockedByBoundary: 'Blocked by Boundary',
    BlockedByDenyRules: 'Blocked by Deny Rules',
    Boundary: 'Boundary',
    BrowserGrayWarning: 'Your browser is not formally supported',
    Build: 'Build',
    Busy: 'Busy',
    By: 'by',
    ByEndpoint: 'by {endpoint}',
    Bytes: 'Bytes',
    BytesIn: 'Bytes In',
    BytesOut: 'Bytes Out',
    Cancel: 'Cancel',
    CannotRemoveItemInUse: 'This item cannot be removed because it is in use.',
    CannotRemoveItemsInUse: 'The selected items cannot be removed because they are in use.',
    MatchingServices: '{count, plural, =1 {# Matching Service} other {# Matching Services}}',
    MatchingServicesOnly: 'Matching Services',
    Capslock: 'Capslock',
    Change: 'Change',
    ChangesHighlighted: 'Changes are highlighted in Green (Addition) and Red (Deletion).',
    ChooseApiExplorer: 'Choose an item from the menu on the left side',
    ClickHere: 'Click here for more information',
    Close: 'Close',
    Coexistence: 'Coexistence',
    Collapse: 'Collapse',
    Comment: 'Comment',
    CompanyURL: 'Company URL',
    CompatibilityReport: 'Compatibility Report',
    ConfigurationWizard: 'Configuration Wizard',
    Confirm: 'Confirm',
    ConfirmRemoval: 'Confirm Removal',
    Connectivity: 'Connectivity',
    Connections: 'Connections',
    ConnectionFailed: 'Failed to establish connection to the PCE Server',
    ConnectionState: 'Connection State',
    Console: 'Console',
    Consumer: 'Consumer',
    Consumers: 'Consumers',
    ConsumersGlobal: 'Global Consumers',
    Copy: 'Copy',
    CopyToClipboard: 'Copy to clipboard',
    CountMore: '+{count} more',
    CountMoreLabels: '+{count, plural, =1 {# more label} other {# more labels}}',
    ColumnsHidden: '({count, plural, =1 {# column is} other {# columns are}} hidden)',
    Country: 'Country',
    ExportToCsv: 'Export to CSV',
    AsJSON: 'as JSON',
    AsCSV: 'as CSV',
    Calculate: 'Calculate',
    Consuming: 'Consuming',
    Consumed: 'Consumed',
    Corrupt: 'Corrupt',
    NotConsumed: 'Not Consumed',
    ConsumerWorkload: 'Source is a Workload',
    Container: 'Container',
    ContainerHost: 'Container Host',
    ContainerWorkloads: 'Container Workloads',
    ContainerWorkload: 'Container Workload',
    Continue: 'Continue',
    ContinueWaiting: 'Continue waiting',
    ConfirmChange: 'Confirm Change',
    Create: 'Create',
    Created: 'Created',
    CreatedOn: 'Created On',
    Critical: 'Critical',
    CSV: 'CSV',
    Custom: 'Custom',
    CustomColumns: 'Customize columns',
    DateAtTime: '{when, date, L} at {when, date, HH_mm_ss}',
    DateAtTimeBy: '{when, date, L} at {when, date, HH_mm_ss} by {name}',
    Days: 'Days',
    DaysNumber: '{count, plural, =1 {# Day} other {# Days}}',
    MonthsNumber: '{count, plural, =1 {# Month} other {# Months}}',
    Dashboard: 'Dashboard',
    Default: 'Default',
    Delete: 'Delete',
    Deleted: 'Deleted',
    Description: 'Description',
    DescriptionIsTooLong: 'Description must not be more than {number} characters',
    Deny: 'Deny',
    Dismiss: 'Dismiss',
    OptionalDescription: 'Optional Description',
    Destination: 'Destination',
    Destinations: 'Destinations',
    Details: 'Details',
    DeviceType: 'Device Type',
    Disable: 'Disable',
    Disabled: 'Disabled',
    DisabledLow: 'disabled',
    DistinguishedName: 'Distinguished Name',
    Discard: 'Discard',
    DiscardUnsavedChanges: 'Discard Unsaved Changes',
    DiscardUnsavedChangesMessage: 'Are you sure you want to discard unsaved changes?',
    DiscardUnsavedChangesToObjectMessage:
      'Are you sure you want to discard unsaved changes to the {object, select, service {Service} iprange {IP Range} label {Label} label_group {Label Group} rule {Rule}}?',
    DomainTable: 'Unmanaged FQDNs',
    Domain: 'Domain',
    Domains: 'Domains',
    Done: 'Done',
    DoNotAllow: 'Do Not Allow',
    DoNotShowMessage: "Don't show this message again.",
    Down: 'Down',
    Download: 'Download',
    Draft: 'Draft',
    DraftAll: 'All Draft',
    DraftEnforcementBoundary: 'Draft Enforcement Boundary',
    DraftPolicy: 'Draft Policy Decision',
    Duplicate: 'Duplicate',
    DestinationIP: 'Destination IP',
    DestinationIPRanges: 'Destination IP Ranges',
    DestinationPort: 'Destination Port',
    DestinationProcess: 'Destination Process',
    Edit: 'Edit',
    Email: 'Email',
    EmailUsernameGroup: 'Email/Username/Group Name',
    EmailAddress: 'Email Address',
    EmailProvideValid: 'Please provide a valid email',
    Edited: 'Edited',
    Enable: 'Enable',
    Enabled: 'Enabled',
    EnabledLow: 'enabled',
    EndOfData: 'End of Data Set',
    Enforce: 'Enforce',
    Enforced: 'Enforced',
    Enforcement: 'Enforcement',
    EnforcedHighDetail: 'Enforced: High Detail',
    EnforcedLowDetail: 'Enforced: Low Detail',
    EnforcedNoDetail: 'Enforced: No Detail',
    EnforcementStop: 'Stop Enforcement',
    EntityState: 'Entity State',
    EntitiesMore: 'Workload...',
    EntitiesIsNotMore: 'Workload is not...',
    ExcludeWorkload: 'Exclude Workload...',
    EntitiesIsNot: 'Workload is not',
    EntriesCount: '{current, number} of {all, number} Entries',
    Environment: 'Environment',
    Environments: 'Environments',
    EnvironmentDescription: 'Stage of application development. E.g., QA, Staging, Production.',
    Error: 'Error',
    Errors: 'Errors',
    ErrorCount: '{count, plural, =1 {# Error} other {# Errors}}',
    Event: 'Event',
    Events: 'Events',
    EventSettings: 'Event Settings',
    Examples: 'Examples',
    Exclude: 'Exclude',
    Exclusive: 'Exclusive',
    Expand: 'Expand',
    Expired: 'Expired',
    ExpiresAt: 'Expires At',
    ExpiresIn: 'Expires In',
    Explorer: 'Explorer',
    Export: 'Export',
    ExportAll: 'Export All',
    ExportFiltered: 'Export Filtered',
    ExportJSON: 'Export to JSON',
    ExposureScore: 'Exposure Score',
    ExternalReference: 'External Data Reference',
    External: 'External',
    ExternalConnections: 'External Connections',
    ExternalSet: 'External Data Set',
    Failed: 'Failed',
    Failure: 'Failure',
    False: 'False',
    Filter: 'Filter',
    FilterByTime: 'Filter by Time',
    FilterView: 'Select properties to filter view',
    Finish: 'Finish',
    FlipHorizontal: 'Flip Horizontal',
    Flows: 'Flows',
    Formats: 'Formats',
    FormError: 'Correct the errors in red before saving',
    FlowCollectionFilters: 'Flow Collection',
    Fqdns: 'FQDNs',
    InSync: 'In Sync',
    OutOfSync: 'Out of Sync (Last Connection Time: {when, date, L_HH_mm_ss})',
    UsesVirtualServicesWorkloads: 'Uses Virtual Services and Workloads',
    UsesVirtualServices: 'Uses Virtual Services only',
    General: 'General',
    Generate: 'Generate',
    GenerateSupportReport: 'Generate Support Bundle',
    Generating: 'Generating...',
    GenerateKey: 'Generate Key',
    GeneratedBy: 'Generated By',
    GeneratedByUser: 'Generated By User',
    GeneratedByAgent: 'Generated By Agent',
    GeneratedAt: 'Generated At',
    GeneratedAtTime: 'Generated at {time}',
    GetStarted: 'Get Started',
    Global: 'Global',
    Go: 'Go',
    Group: 'Group',
    SourceToTarget: '{source}-To-{target}',
    Groups: 'Groups',
    GroupsCount: '{count, plural, =1 {One Group} other {# Groups}}',
    GroupsCountNumber: '{count, plural, =1 {# Group} other {# Groups}}',
    GroupsByCount: '{count, plural, =1 {Group} other {Groups}}',
    GroupDashboard: 'Group Dashboard',
    GroupWizard: 'Group Wizard',
    Help: 'Help',
    Hide: 'Hide',
    Hidden: 'Hidden',
    High: 'High',
    Healthy: 'Healthy',
    Version: 'Version',
    Home: 'Home',
    Hours: 'Hours',
    HoursNumber: '{count, plural, =1 {# Hour} other {# Hours}}',
    HostAttributes: 'Host Attributes',
    Hostname: 'Hostname',
    Href: 'Href',
    ID: 'ID',
    IdleWorkload: 'Idle Workload',
    Inbound: 'Inbound',
    IncreaseTrafficUpdateRate: 'Increase Traffic Update Rate',
    IncreaseTrafficUpdateRateMessage:
      'Increase traffic flow frequency to {minutes, plural, =1 {one minute} other {# minutes}}. After {minutes, plural, =1 {one minute} other {# minutes}}, traffic flow information is sent to the PCE at regular intervals.',
    IncreaseTrafficUpdateRateTooltip:
      'Increase the rate of traffic flow updates from Workloads for the next {minutes, plural, =1 {one minute} other {# minutes}}',
    Include: 'Include',
    InProgress: 'In Progress',
    Invited: 'Invited',
    Import: 'Import',
    IPRange: 'IP Range',
    IPAddress: 'IP Address',
    IPAddressHostname: 'IP Address or Hostname',
    IPOverrides: 'IP Overrides',
    IPAddressInvalid: 'Invalid IP Address',
    IPAddressPublic: 'Public IP Address',
    IPAddressesMore: 'IP Addresses...',
    IPAddressOptions: 'IPs, IP Ranges, FQDNs, CIDR Blocks, Any IP (0.0.0.0/0 and ::/0)',
    IPList: 'IP List',
    IPLists: 'IP Lists',
    IPListsCount: '{count, plural, =1 {# IP List} other {# IP Lists}}',
    IPRangesCount: '{count, plural, =1 {# IP Range} other {# IP Ranges}}',
    IpTables: 'iptables',
    NetworkNameInvalid: 'Invalid Network Name',
    Idle: 'Idle',
    IdleControl: 'VEN does not take control of the host firewall',
    IdleControlEdge:
      'Illumio Edge does not take control of the workload’s native OS firewall and no traffic is blocked in this state',
    Illuminate: 'Illuminate',
    Illuminated: 'Illuminated',
    Illumination: 'Illumination',
    IlluminationMap: 'Illumination Map',
    IlluminationPlus: 'Illumination Plus',
    IlluminationPlusDetails: 'Use the Illumination Plus App Group Map by default',
    Info: 'Info',
    Information: 'Information',
    Installed: 'Installed',
    Internet: 'Internet',
    Invite: 'Invite',
    InUse: 'In use',
    InUseBy: 'In use by',
    InUseByBlockAction: 'In Use By Reject Connections',
    InUseByLabelGroups: 'In Use By Label Groups',
    InUseByRulesets: 'In Use By Rulesets',
    InUseByVirtualServices: 'In Use By Virtual Services',
    InUseByVirtualServer: 'In Use By Virtual Servers',
    InUseByContainerInheritHostPolicy: 'In Use By Container Inherit Host Policy',
    InUseByLoopbackInterfaces: 'In Use By Loopback Interfaces',
    InUseByContent: 'In Use by {content}',
    InUseByPairingProfiles: 'In Use By Pairing Profiles',
    InUseByRBAC: 'In Use By RBAC',
    InUseByWorkloads: 'In Use By Workloads',
    InUseByContainerWorkloads: 'In Use By Container Workloads',
    InUseByContainerWorkloadsProfiles: 'In Use By Container Workloads Profiles',
    InUseByStaticPolicy: 'In Use By Static Policy',
    InUseByFirewallCoexistence: 'In Use By Firewall Coexistence',
    InUseByIPForwarding: 'In Use By IP Forwarding',
    InvalidDay: 'Invalid day',
    IPAddressOrCIDRBlock: 'IP Address/CIDR Block',
    Items: 'Items',
    ItemsCannotRemove: 'These items cannot be removed:',
    IsRequired: '{label} is required',
    JSON: 'JSON',
    JSONResponse: 'JSON Response for: {selected}',
    Kubelink: 'Kubelink',
    Label: 'Label',
    Labels: 'Labels',
    LastModified: 'Last Modified',
    LastModifiedAt: 'Last Modified At',
    LearnMore: 'Learn More',
    LastPairingKeyGenerated: 'Last Pairing Key Generated',
    LastVENPaired: 'Last VEN Paired',
    Leave: 'Leave',
    UpdatedBy: 'Updated By',
    CreatedBy: 'Created By',
    LabelSettings: 'Label Settings',
    LastModifiedBy: 'Last Modified By',
    LastModifiedOn: 'Last Modified On',
    LastReportUpdate: 'Last Report Update',
    LastUsed: 'Last Used',
    LDAP: 'LDAP',
    LDAPS: 'LDAPS',
    Limited: 'Limited',
    Link: 'Link',
    Linked: 'Linked',
    Loading: 'Loading',
    Local: 'Local',
    Location: 'Location',
    Locations: 'Locations',
    LocationDescription: 'Physical or geographic location of the Workload',
    Lock: 'Lock',
    Locked: 'Locked',
    LogIn: 'Log In',
    Low: 'Low',
    MachineId: 'Machine ID',
    MainDropdownMenu: 'Main Dropdown Menu',
    Map: 'Map',
    Managed: 'Managed',
    Management: 'Management',
    MaxlengthError:
      '{label, select, false {Value} other {{label}}} must be less than or equal to {maxlength, plural, =1 {# character} other {# characters}}',
    Members: 'Members',
    Medium: 'Medium',
    Minutes: 'Minutes',
    MinutesNumber: '{count, plural, =1 {# Minute} other {# Minutes}}',
    Missing: 'Missing',
    Mode: 'Mode',
    Modify: 'Modify',
    ModificationNotAllowed: 'Modification Not Allowed',
    More: 'more',
    Move: 'Move',
    MoveTool: 'Hand Tool',
    MoveToolDescription: 'Drag to pan the map view. Shortcut: Press space bar to enable.',
    MoveModeAlert:
      'The Hand Tool is used only to pan the map. To select or reposition an object, use the Selection Tool.',
    MustBeAnInteger: '{name} must be an integer',
    NotApplicable: 'Not Applicable',
    NA: 'N/A',
    Name: 'Name',
    NameIsNotAllowed: 'That name is not allowed, please use another name',
    Namespace: 'Namespace',
    NavigateUp: 'Navigate Up',
    NamespaceOrProject: 'Namespace/Project',
    NetworkInterfaces: 'Network Interfaces',
    Never: 'Never',
    Next: 'Next',
    New: 'New',
    No: 'No',
    NotAvailable: 'Not available',
    NoInternetConnectionAvailable: 'No internet connection available',
    NoLabel: 'No Label',
    NotRecommended: 'Not Recommended',
    NoPeers: 'No Peers',
    NoSavedFilters: '<span class="{className}"> None Saved<br/>To save a filter set, click the Save icon.</span>',
    EmptySavedFilters: 'No Saved Filter. To save a filter set, click the Save icon',
    EmptyRecentFilters: 'No Recent Filter.',
    Note: 'Note',
    NoRoleLabel: 'No Role Label',
    NoApplicationLabel: 'No Application Label',
    NoEnvironmentLabel: 'No Environment Label',
    NoLocationLabel: 'No Location Label',
    NoObjectSelected: 'No objects were selected',
    NoLabels: 'No {name} Labels',
    AllLabels: 'All {name} Labels',
    AllRoleLabels: 'All Role Labels',
    AllApplicationLabels: 'All Application Labels',
    AllEnvironmentLabels: 'All Environment Labels',
    AllLocationLabels: 'All Location Labels',
    NameIsTooShort: 'Name must be atleast 2 characters',
    NameIsTooLong: 'Name must not be more than 255 characters',
    NamespaceIsTooLong: '{namespace} must not be more than 255 characters',
    NeverExpires: 'Never expires',
    NoAppGroup: 'No App Group',
    NoRole: 'No Role',
    NoApplication: 'No Application',
    NoEnvironment: 'No Environment',
    NoGroup: 'No Group',
    NoLocation: 'No Location',
    DeletedWorkload: 'Deleted Workload',
    DeletedWorkloads: 'Deleted {count, plural, =1 {Workload} other {Workloads}}',
    DeletedWorkloadIps: '{count} Deleted Workload IPs',
    DeletedWorkloadCount: '{count, plural, =1 {# Deleted Workload IP} other {# Deleted Workload IPs}}',
    Notification: 'Notification',
    NoData: 'No data to display',
    NoRules: 'No rules to display',
    NoMembersToDisplay: 'No Members to display',
    Node: 'Node',
    NoDataLoadBalancer: 'No Load Balancers to display',
    NoDataContainerWorkloads: 'No Container Workloads to display',
    NoDataContainerCluster: 'No Container Clusters to display',
    NoDataServiceBackends: 'No Service Backends to display',
    NoDataContainerWorkloadProfiles: 'No Container Workload Profiles to display',
    NoDataWorkload: 'No Workloads to display',
    NoMatchRules: 'No matching Rules',
    NoMatchServices: 'No matching Services to display',
    NoMatchDataContainerClusters: 'No matching Container Workload Profiles to display',
    NoMatchDataContainerWorkloads: 'No matching Container Workloads to display',
    NoResultsFound: 'No results found',
    NoDataNetwork: 'No Networks to display',
    NoServicesData: 'No Services to display',
    None: 'None',
    NoneSelected: 'None selected',
    NotEnforced: 'Not Enforced',
    NotYetConnected: 'Not yet connected',
    Notifications: 'Notifications',
    NotSecure: 'Not Secure',
    NumberSign: '#',
    NumOfNum: '{low, number} of {high, number}',
    Object: 'Object',
    Off: 'Off',
    OK: 'OK',
    OS: 'OS',
    OSFamily: 'OS Family',
    OkToRemoveRemovable: 'If you click OK, only the removable items will be removed.',
    On: 'On',
    Online: 'Online',
    OpenLDAP: 'OpenLDAP',
    OpenShift: 'OpenShift',
    Optional: 'Optional',
    Options: 'Options',
    Or: 'or',
    Organization: 'Organization',
    OrganizationEvent: 'Organization Event',
    OrganizationEvents: 'Organization Events',
    Other: 'Other',
    Override: 'Override',
    Outbound: 'Outbound',
    Packages: 'Packages',
    PageFirst: 'First page',
    PageLast: 'Last page',
    Pagination:
      '<strong>{notZero, select, true {{min, number} – } false {}}{max, number}</strong> of <strong>{total, number}{plus, select, true {+} false {}}</strong> {type, select, label {Label Set Connections} connections {Connections} matched {Matched} total {Total}}',
    PaginationNumberString:
      '<strong>{notZero, select, true {{min, number} – } false {}}{max, number}</strong> of <strong>{total, number}{plus, select, true {+} false {}}</strong>',
    PaginationTypeString:
      '{type, select, label {Label Set Connections} connections {Connections} matched {Matched} total {Total}}',
    Paired: 'Paired',
    PairedOn: 'Paired On',
    DisplayingPartialResults: 'Displaying Partial Results',
    DisplayingPartialResultsMessage:
      'The number of matching connections found in the database exceeds the configured maximum. The displayed connection count is partial and could be misleading.',
    PairingKey: 'Pairing Key',
    Password: 'Password',
    ConfirmPassword: 'Confirm Password',
    PasswordChange: 'Change Password',
    Patents: 'Patents',
    Pause: 'Pause',
    PCE: 'PCE',
    PCEMaxDisplay:
      'The PCE web console only displays a maximum of {count, number} entries in a list. To see more results, apply a filter to the list.',
    PCEUnavailable: 'PCE is currently unavailable. Wait or try again after some time.',
    PCEUpgradeRulesetName: 'Illumio PCE Upgrade - Non-Corporate Endpoint Policies',
    Pending: 'Pending',
    Play: 'Play',
    PotentiallyBlocked: 'Potentially Blocked',
    PotentiallyBlockedByBoundary: 'Potentially Blocked by Boundary',
    PotentiallyBlockedByDenyRules: 'Simulated Block by Deny Rules',
    Policies: 'Policies',
    PolicyData: 'Policy Data',
    PolicyDecision: 'Policy Decision',
    Prev: 'Prev',
    Preview: 'Preview',
    Previous: 'Previous',
    PrivateAddress: 'Private Address',
    PrivateAddresses: 'Private Addresses',
    Protect: 'Protect',
    PublicAddress: 'Public Address',
    PublicAddresses: 'Public Addresses',
    PrivacyPolicy: 'Privacy Policy',
    Process: 'Process',
    Processes: 'Processes',
    Product: 'Product',
    ProductDemo: 'Watch Product Demo',
    ProductVersion: 'Product Version',
    Project: 'Project',
    Protocol: 'Protocol',
    InvalidProcessNameMessage: 'Invalid Process Name. E.g. c:\\windows\\myprocess.exe',
    WinService: 'Windows Services',
    WinServiceMessage: 'Eg. myprocess',
    InvalidWinServiceMessage: 'Invalid Windows Service Name',
    PerPageCount: '{count, number} per page',
    ProtocolMissing: 'Missing protocol',
    ProtocolInvalid: 'Invalid protocol',
    InvalidProtocolMessage: 'Invalid Port and/or Protocol \n E.g. 22, 514 UDP, ICMP',
    InvalidPortRangeMessage: 'Invalid Port Range \n E.g. 71-74, 80-90 TCP',
    PortAndOrProtocol: 'Port and/or Protocol',
    ProvideName: 'Please provide your name',
    Provider: 'Provider',
    ProviderFqdn: 'Provider FQDN',
    DestinationFqdn: 'Destination FQDN',
    Providers: 'Providers',
    ProvideAName: 'Provide a name',
    ProviderPort: 'Provider Port',
    ProviderOrConsumer: 'Providers Or Consumers',
    ProviderAndConsumer: 'Providers And Consumers',
    Providing: 'Providing',
    Provided: 'Provided',
    NotProvided: 'Destination not in Group',
    ProviderWorkload: 'Destination is a Workload',
    Provision: 'Provision',
    True: 'True',
    Radios: 'Radios',
    RBAC: 'RBAC',
    ReadOnly: 'Read Only',
    Readonly: 'Read only',
    ReadOnlyDraft: 'Read only draft',
    Recalculate: 'Recalculate',
    RecentlyUsed: 'Recently Used {name}',
    Recommended: 'Recommended',
    Recommendation: 'Recommendation',
    Refresh: 'Refresh',
    Reload: 'Reload',
    Remove: 'Remove',
    Removed: 'Removed',
    RemoveGroup: 'Remove Group',
    Regenerate: 'Regenerate',
    Report: 'Report',
    Reports: 'Reports',
    Reported: 'Reported',
    ReportedEnforcementBoundary: 'Reported Enforcement Boundary',
    ReportedPolicy: 'Reported Policy Decision',
    Reset: 'Reset',
    ResetLayout: 'Reset Layout',
    ResetToDefault: 'Reset to Default',
    ResetSortingBy: 'Reset to default sorting (by {name})',
    ResetColumnSelection: 'Reset to default columns',
    ResetSelsectionAndSortingBy: 'Reset to default columns and default sorting (by {name})',
    Required: 'Required',
    Resource: 'Resource',
    ResourceType: 'Resource Type',
    Restore: 'Restore',
    ResolveDomainsAlert:
      'FQDNs are resolved using reverse DNS from the PCE. Accuracy of results may vary depending on the relative locations of the PCE and the target device.',
    Reverse: 'Reverse',
    Revert: 'Revert',
    DoubleClickToExpand: 'Double-click to expand',
    RightClickForOptions: 'Right-click for Options',
    RFC1918: 'RFC 1918',
    RFC1918Private: 'Private (RFC 1918)',
    Role: 'Role',
    Roles: 'Roles',
    RoleDescription: 'The function the Workload plays in the application. E.g., Database.',
    RoleUnassigned: 'Role not assigned',
    Rules: 'Rules',
    RulesCount: '{count, plural, =1 {# Rule} other {# Rules}}',
    Ruleset: 'Ruleset',
    Rulesets: 'Rulesets',
    RulesetsCount: '{count, plural, =1 {# Ruleset} other {# Rulesets}}',
    Running: 'Running',
    Run: 'Run',
    Retry: 'Retry',
    ReportPolicy: 'Report Policy',
    Save: 'Save',
    SaveAs: 'Save As',
    Saving: 'Saving',
    SaveAndProvision: 'Save and Provision',
    SaveAndApply: 'Save and Apply',
    SaveChangesConfirmation: 'Are you sure you want to save the changes?',
    SaveChangesTitle: 'Save Changes',
    SAML: 'SAML',
    Scope: 'Scope',
    Scoped: 'Scoped',
    Scopes: 'Scopes',
    Search: 'Search',
    Secret: 'Secret',
    SelectScope: 'Select Scope',
    SecondsNumber: '{count, plural, =1 {# Second} other {# Seconds}}',
    SecureConnect: 'SecureConnect',
    SecureConnectGateways: 'Secure Connect Gateways',
    SecureConnectGatewaysCount: '{count, plural, =1 {# Secure Connect Gateway} other {# Secure Connect Gateways}}',
    RuleSearch: 'Rule Search',
    MachineAuthentication: 'Machine Authentication',
    Seconds: 'Seconds',
    Security: 'Security',
    Secure: 'Secure',
    SecuritySettings: 'Security Settings',
    Select: 'Select',
    Selected: 'Selected',
    SelectProtocol: 'Select a Protocol',
    SelectConsumers: 'Select Consumers',
    SelectLabels: 'Select Labels',
    SelectLabelsLabelGroups: 'Select Labels And Label Groups',
    ShowLess: 'Show less',
    FullmapSelectModeAlert:
      'Dragging the mouse is used only to select traffic links in full map. To reposition a group, use the Hand Tool',
    SelectModeAlert: 'Dragging the mouse is used only to select traffic links. To pan the map, use the Hand Tool.',
    SelectModeAlertShortcut:
      '<span class="{className}">Shortcut: </span>Press and hold the space bar to enable the Hand Tool.',
    SelectionTool: 'Selection Tool',
    SelectionToolDescription: 'Click to select objects. Drag to move objects.',
    SelectProviders: 'Select Providers',
    SelectedCount: '{count, plural, other {<span class="{className}">#</span>&nbsp;Selected}}',
    SelectedNumber: '<span class="{className}">{count, number}</span>',
    Server: 'Server',
    Servers: 'Servers',
    Service: 'Service',
    ServicePort: 'Service/Port',
    ServiceAccounts: 'Service Accounts',
    ServiceBackends: 'Service Backends',
    ServiceBindings: 'Service Bindings',
    ServiceNotRunning: 'Service not running',
    ServiceRunning: 'Service running',
    Services: 'Services',
    ServiceMore: 'Service...',
    ServiceIs: 'Service is',
    ServiceIsNot: 'Service is not',
    ServiceIsNotMore: 'Service is not...',
    ServiceTag: '{multiple, select, false {Service Tag} true {Service Tags}}',
    ExcludeService: 'Exclude Service',
    ExcludeServiceMore: 'Exclude Service...',
    ServicesCount: '{count, plural, =1 {# Service} other {# Services}}',
    AllServices: 'All Services',
    BroadServices: 'Broad Services',
    StandardServices: 'Standard Services',
    CustomServices: 'Custom Services',
    Setting: 'Setting',
    Settings: 'Settings',
    SettingsCount: '{count, plural, =1 {# Setting} other {# Settings}}',
    Severity: 'Severity',
    SideBySideDiff: 'Side-by-side diff',
    Show: 'Show',
    Sort: 'Sort',
    Source: 'Source',
    Sources: 'Sources',
    SourcesOrDestinations: 'Sources Or Destinations',
    SourcesAndDestinations: 'Sources And Destinations',
    DestinationsOrSources: 'Destinations Or Sources',
    DestinationsAndSources: 'Destinations And Sources',
    SortedBy: 'Sorted by {column}',
    Staged: 'Staged',
    STARTTLS: 'STARTTLS',
    State: 'State',
    Stateless: 'Stateless',
    StateProvinceTerritory: 'State/Province/Territory',
    Static: 'Static',
    StaticPolicy: 'Static Policy',
    Status: 'Status',
    Step: 'Step {step}',
    Stop: 'Stop',
    Stopped: 'Stopped',
    Style: 'Style',
    Success: 'Success',
    SuperClusterPartialDataWarning: 'Partial Map based on data for this PCE only',
    SuperClusterBlockedTrafficDataWarning:
      'This page only shows blocked traffic events from Workloads that have been paired with this PCE',
    SystemInitializing: 'System initializing',
    Summary: 'Summary',
    Support: 'Support',
    SubDropdownMenu: 'Sub Dropdown Menu',
    System: 'System',
    Terms: 'Terms',
    Test: 'Test',
    Today: 'Today',
    ToClose: 'to close',
    ToNavigate: 'to navigate',
    ToSelect: 'to select',
    Total: 'Total',
    TotalNumber: '<span class="{className}">{count, number}</span> Total',
    OpenInstantSearch: 'Open Instant Search ({command})',
    ThirdParty: 'Third Party',
    Timestamp: 'Timestamp',
    Timezone: 'Time Zone',
    TotalFlows: 'Total Flows',
    TotalPCEs: 'Total PCEs',
    TotalVENs: 'Total VENs',
    Traffic: 'Traffic',
    TrafficType: 'Traffic Type',
    TransmissionMode: 'Transmission Mode',
    Troubleshooting: 'Troubleshooting',
    TurnOff: 'Turn Off',
    TurnOn: 'Turn On',
    Type: 'Type',
    Types: 'Types',
    UUID: 'UUID',
    Undo: 'Undo',
    Unidentified: 'Unidentified',
    Uninstalled: 'Uninstalled',
    Unlimited: 'Unlimited',
    Unlink: 'Unlink',
    Unlock: 'Unlock',
    Unlocked: 'Unlocked',
    Unmanage: 'Unmanage',
    Unmanaged: 'Unmanaged',
    CloudWorkload: 'Cloud Workload',
    CloudPaaS: 'Cloud PaaS',
    UnmanagedIPAddresses: 'Unmanaged IP Addresses',
    UnmanagedWorkload: 'Unmanaged Workload',
    Unavailable: 'Unavailable',
    Unchanged: 'Unchanged',
    Unknown: 'Unknown',
    Unpair: 'Unpair',
    UnifiedDiff: 'Unified diff',
    UnsavedChanges: 'You have unsaved changes.',
    UnsavedPendingWarning: 'Leaving this page will discard pending changes.',
    UseVirtualService: 'Use port/protocol in the Virtual Service',
    Up: 'Up',
    Updated: 'Updated',
    UpdatedAt: 'Updated at',
    Upgrade: 'Upgrade',
    Upload: 'Upload',
    UserEmail: 'Email Address/Username',
    UserGroup: 'User Group',
    UserGroups: 'User Groups',
    Username: 'Username',
    Value: 'Value',
    VEN: 'VEN',
    VENS: 'VENs',
    Verify: 'Verify',
    VerifyTLS: 'Verify TLS',
    VIPPort: 'VIP & Port',
    Ports: 'Port(s)',
    ValidationError: 'Validation Error',
    Video: 'Video',
    View: 'View',
    ViewDraft: 'View Draft',
    ViewScope: 'View Scope',
    VirtualServer: 'Virtual Server',
    VirtualServers: 'Virtual Servers',
    VirtualServersCount: '{count, plural, =1 {# Virtual Server} other {# Virtual Servers}}',
    VirtualServices: 'Virtual Services',
    VirtualService: 'Virtual Service',
    VirtualServicesCount: '{count, plural, =1 {# Virtual Service} other {# Virtual Services}}',
    Visible: 'Visible',
    Visibility: 'Visibility',
    VisibilityOnly: 'Visibility Only',
    Vulnerabilities: 'Vulnerabilities',
    Vulnerability: 'Vulnerability',
    VulnerabilityData: 'Vulnerability Data',
    Warning: 'Warning',
    WarningCount: '{count, plural, =1 {# Warning} other {# Warnings}}',
    WelcomeToIllumio: 'Welcome to Illumio.',
    WelcomeUser: 'Welcome, {userName}!',
    Workload: 'Workload',
    WorkloadManagement: 'Workloads and VENs',
    Workloads: 'Workloads',
    Yes: 'Yes',
    ZIPCode: 'ZIP Code',
    PostalCode: 'Postal Code',
    ZoomIn: 'Zoom in',
    ZoomOut: 'Zoom out',
    ZoomToFit: 'Zoom to fit',
    NameExist: 'Name {value} already exists',
    XpressDashboard: 'Xpress Dashboard',
  },
  Antman: {
    Dashboard: {
      BlockedOrPotentiallyBlockedFlow: 'Blocked / Simulated Block Flow',
      GoToIllumination: 'Go to Illumination',
    },
    /* eslint-disable rulesdir/intl-key-verify */
    ErrorsAPI: {
      'err:rbac_cannot_operate_on_rule_with_broader_consumer_scope':
        'You cannot modify Rules with broader Source Scope(s) than your permitted Scope(s)',
      'err:rbac_rule_ub_scope_should_be_same_as_scope': "User's scope and Source-scope labels cannot be different: %s",
      'err:service_provider_too_long': 'Service Destination is too long.',
      'err:rs_provider_required': 'Rule must specify at least one Destination.',
      'err:rs_duplicate_provider': 'Duplicate Destination %s',
      'err:rs_duplicate_consumer': 'Duplicate Source %s',
      'err:cant_specify_ip_value_actor': 'Invalid Destination or user',
      'err:ip_list_referenced_by_enforcement_boundary':
        'Can not delete Ip List that is referenced by an existing Deny Rule',
      'err:resolve_actors_requires_rule_actors':
        'Resolve Actors must be used with one of Destinations, Sources, or providers_or_consumers',
      'err:rule_ub_must_be_managed': 'Only Managed Workloads are allowed in the Sources field of Unmanaged Services.',
      'err:rule_pb_only_ip_list_or_all':
        'Only IP Lists or All are allowed in the Destinations field of Unmanaged Services.',
      'err:provider_not_found': 'Destinations for Rule not found',
      'err:consumer_not_found': 'Sources for Rule not found',
      'err:label_still_has_associated_cwp': 'Label is referenced by Namespaces',
      'err:label_still_has_associated_enforcement_boundary': 'Label is referenced by Deny Rule',
      'err:ams_with_label_not_allowed': 'All Workloads cannot be used with labels or label groups in a Deny Rule',
      'err:label_group_referenced_by_enforcement_boundaries': 'Label Group is still referenced by a Deny Rule',
      'err:service_referenced_by_enforcement_boundary':
        'Cannot delete service that is referenced by an existing Deny Rule',
      'err:stateless_rule_consumer_count_exceeded':
        'Multiple Sources are allowed only if they are Any (0.0.0.0/0 and ::/0) and ams or labels of different types',
      'err:invalid_windows_service_provider': 'Windows based services cannot have IP Lists as a Destination',
      'err:non_brn_must_use_ip_list':
        'A rule with Network Type "All" or "Non-Corporate (Endpoints Only)" must have only IP lists on Sources or Destinations',
      'err:virtual_server_no_service_with_workload': 'Virtual Server Workload Destination requires a Service.',
      'err:virtual_server_non_workload_label_provider': 'Virtual server Destination must be workload or label',
      'err:service_binding_invalid_operation': 'Pod service bindings cannot be deleted',
      'err:container_cluster_virtual_service_edit':
        'Cannot edit a virtual service created by a Kubernetes Cluster that is now deleted',
      'err:container_cluster_virtual_service_delete':
        'Virtual service created by a Kubernetes Cluster that is now deleted may only be deleted from the leader region',
      'err:ingress_service_must_be_empty':
        'If the only value for the Destinations resolve_labels field is "virtual_services", then ingress_services must be empty',
      'err:ingress_service_required':
        'At least one ingress service is required if Destination resolve_labels_as includes workloads',
      'err:resolve_labels_required':
        'Both the Destinations and Sources fields must have at least one value for resolve_labels_as',
      'err:stateless_rule_cannot_have_ip_list_provider': 'Stateless rules cannot have IP Lists as their Destination',
      'err:invalid_egress_windows_service_consumer':
        'Windows based service on Source side cannot have IP Lists as a Source',
      'err:container_host_rule_invalid_provider':
        'Only Workloads, Labels, or All Workloads can be used as Destinations if container_host is the Source',
      'err:container_host_rule_single_consumer': 'container_host cannot be used with any other Source',
      'err:container_host_rule_invalid_resolve_labels':
        'resolve_labels_as for Destinations and Sources must be "workloads" if container_host is the Source',
      'err:container_host_rule_sec_connect_machine_auth':
        'SecureConnect and Machine Authentication cannot be used with container_host as the Source',
      'err:container_host_rule_stateless': 'The stateless option cannot be used with container_host as the Source',
      'err:container_host_rule_no_windows_service':
        'Windows services are not supported with container_host as the Source',
      'err:container_host_rule_tcp_only':
        'Rule ingress_service_ports only supports tcp (6) protocol with container_host as the Source',
      'err:namespace_key_mismatch': 'Paring key is invalid for Namespace',
      'err:container_workload_profile_invalid_pairing_key': 'Namespace pairing key does not match',
      'err:unmanaged_container_workload_profile_labels': 'An unmanaged Namespace cannot have assigned labels',
      'err:ves_invalid_projected_actor': 'Projected VES calculation only supports a subset of Destinations and Sources',
    },
    /* eslint-enable rulesdir/intl-key-verify */
    Toggle: {
      ExitPreview: 'Exit the preview of Xpress',
      TryPreview: 'Try the preview version of Xpress',
    },
    Enforcement: {
      MoveTo: 'Move to enforcement',
      MoveAll: 'Move all {type} to enforcement',
    },
    AutoRefresh: 'Refresh the {entity} list every 30 seconds',
    ViewGlobalMap: 'View my global map',
    ViewGlobalMapDescription:
      'Examine traffic across all applications and environments. Write rules for simulated block and blocked traffic.',
    ViewApplicationMap: 'View an application map',
    ViewApplicationMapDescription: 'Examine traffic to/from a particular app group.',
    QueryExplorer: 'Query data in Explorer',
    QueryExplorerDescription:
      'Examine traffic by source and destination labels or IP addresses, port, protocol, and time period. Write rules for simulated block and blocked traffic.',
    SelectOrTypeConsumers: 'Select or type for more sources',
    SelectOrTypeProviders: 'Select or type for more destinations',
    ViewVENs: 'View my VENs',
    ViewWorkloads: 'View my Workloads',
    ViewSecurityPolicies: 'View my Security Policies',
    RowsProvided: 'Analysis complete: {count} rows',
    RowsUpdated: '{count} workloads will be updated',
    RowsDuplicated: '{count} rows will be skipped as duplicates',
    AllowRuleCreation: 'This will create {count} new allow {count, plural, =1 {rule} other {rules}}',
    DenyRuleCreation: 'This will create {count} new deny {count, plural, =1 {rule} other {rules}}',
    Common: {
      Analyzing: 'Analyzing ...',
      AgentReport: 'Agent Report',
      ConsumersGlobal: 'Global Sources',
      ContainerWorkloads: 'Pods',
      ContainerWorkload: 'Pod',
      Counter: '{count} out of {total}',
      DraftEnforcementBoundary: 'Draft Deny Rule',
      ErrorMessageToContactSupport:
        '{errorMessage}. If the issue persists, contact Illumio Support for more information.',
      InUseByContainerWorkloads: 'In Use By Pods',
      InUseByContainerWorkloadsProfiles: 'In Use By Namespaces',
      Next: 'Next >',
      NoDataContainerWorkloads: 'No Pods to display',
      NoDataContainerCluster: 'No Kubernetes Clusters to display',
      NoDataContainerWorkloadProfiles: 'No Namespaces to display',
      NoMatchDataContainerClusters: 'No matching Namespaces to display',
      NoMatchDataContainerWorkloads: 'No matching Pods to display',
      PortsAndProtocols: 'Ports and Protocols',
      PotentiallyBlocked: 'Simulated Block',
      PotentiallyBlockedByBoundary: 'Simulated Block By Boundary',
      QuickPair: 'Quick Pair',
      ReportedEnforcementBoundary: 'Reported Deny Rule',
      SelectConsumers: 'Select Sources',
      SelectForPolicy: 'Select for Policy',
      SelectProviders: 'Select Destinations',
      TotalProtected: 'Protected',
      Unlabeled: 'Unlabeled',
      Unprotected: 'Protection Ready',
      WarningDeletion: 'This {item} is used by onboarding wizards and cannot be removed',
      WarningEdit: 'This {item} is used by onboarding wizards and cannot be edited',
    },
    AppGroups: {
      ContainerWorkloads: 'App Group Pods',
    },
    BlockedTraffic: {
      BlockedOnConsumer: 'Traffic is blocked by the Source',
      BlockedOnProvider: 'Traffic is blocked by the Destination',
      PotentiallyBlockedOnConsumer: 'Traffic is simulated block by the Source',
      PotentiallyBlockedOnProvider: 'Traffic is simulated block by the Destination',
      List: {
        ByTheConsumer: 'By the Source',
        ByTheProvider: 'By the Destination',
        DescriptionSelective:
          'If the Workload is in <span class="{className}">{state}</span>, traffic is <span class="{className}">{status1}</span> or <span class="{className}">{status2}</span> depending on the Deny Rule.',
      },
    },
    ContainerClusters: {
      AddNew: 'Add a new Kubernetes Cluster',
      UseContainerAnnotations: 'Use Kubernetes Annotations',
      Delete: 'Remove {count, plural, =1 {Kubernetes Cluster} other {# Kubernetes Clusters}}',
      DeleteConfirm:
        'Are you sure you want to remove the {count, plural, =1 {Kubernetes Cluster} other {# Kubernetes Clusters}}?',
      DeletedSuccessfully:
        'The following {count, plural, =1 {Kubernetes Cluster has} other {# Kubernetes Clusters have}} been successfully deleted',
      CannotDelete:
        'The following {count, plural, =1 {Kubernetes Cluster is} other {# Kubernetes Clusters are}} still online. Please stop Kubelink before deleting the cluster from the PCE.',
      DuplicateMachineIDsError:
        'There are duplicate machine IDs among your cluster nodes. Kubernetes Cluster functionality will be limited until this issue is resolved.',
      ContainerWorkloadProfiles: 'Namespaces',
      ContainerWorkloadProfilesMessage: 'Editing the template does not affect existing Namespaces.',
      ContainerWorkloadProfilesAdd: 'Add Namespace',
      ContainerWorkloadProfilesUnmanaged:
        'Namespaces will be created automatically for discovered {isOpenshift, select, false {Namespaces} true {Projects}}',
      ContainerWorkloadProfileTemplate: 'Namespace - Default Settings',
      DeleteContainerWorkloadProfile: 'Remove {count, plural, =1 {Namespace} other {# Namespaces}}',
      DeleteContainerWorkloadProfileConfirm:
        'Are you sure you want to remove the {count, plural, =1 {Namespace} other {# Namespaces}}?',
      DeletedContainerWorkloadProfileSuccessfully:
        'The following {count, plural, =1 {Namespace has} other {#  Namespaces have}} been successfully deleted',
      CannotDeleteContainerWorkloadProfile:
        'The following {count, plural, =1 {Namespace is} other {# Namespaces are}} cannot be deleted.',
      AddNewContainerWorkloadProfile: 'Add a new Namespace',
      CreatedByContainerCluster: 'Created by Kubernetes Cluster',
      UndeletedVirtualServices: 'Created by Deleted Kubernetes Cluster',
    },
    VirtualServices: {
      BoundContainerWorkloads: 'Bound Pods',
      ManagedBy: {
        ContainerCluster: 'This Virtual Service is managed by a Kubernetes Cluster',
        ContainerClusterDeletedAndOnLeader:
          'This Virtual Service was managed by a Kubernetes Cluster that is now deleted. You may delete the Virtual Service.',
        ContainerClusterDeletedAndOnMember:
          'This Virtual Service was managed by a Kubernetes Cluster that is now deleted. You may delete the Virtual Service from the leader PCE.',
      },
      AddWorkloads: {
        Description:
          'Select a Workload to bind to the Virtual Service. If the Service has a Port/Protocol, you can specify which ports to override for those Workloads when a connection is initiated by a Source.',
      },
    },

    Connectivity: {
      ConsumerError: 'Source error',
      ConsumerMissing: 'Missing Source',
      PortAndProtocol: 'Destination Port and Protocol',
      ProviderError: 'Destination error',
      ProviderProvider: 'Missing Destination',
      SelectWorkloadContainerWorkloadOrIP: 'Select Workload, Pod, or IP Address',
      VerifyRulesExist: 'Verify if Rules exist that allow connections between Workloads, Pods,  or IP addresses',
    },
    ContainerWorkloads: {
      All: 'All Pods',
      View: 'View Pods',
      ByCount: '{count, plural, =1 {Pod} other {Pods}}',
      WithCount: '{count} {count, plural, =1 {Pod} other {Pods}}',
      Total: 'Total Pods',
    },

    CoreServices: {
      AcceptedCoreService: '{name} is hosting the {service} core service',
      RejectedCoreService: '{name} is no longer recognized as hosting the {service} core service',
      AcceptedNotification:
        'Edit Labels to distinguish workloads running core services from other workloads. Click "Reject" if a server no longer provides the core service.',
      AcceptedError: 'An unexpected error occurred. The server could not be accepted as hosting the Core Service',
      RejectAcceptedError: 'An unexpected error occurred. The server could not be rejected as hosting the Core Service',
    },
    Servers: {
      Add: 'Add Servers',
      AgentReportForHostname: 'Agent Report for {hostname}',
      CopyScriptToServers: 'Copy the agent installation script and run it on your servers as a privileged user.',
      Enforcement: {
        ConfirmMessage: 'Are you sure you want to enforce the existing policy?',
        ConfirmTitle: 'Confirm Server Enforcement Change',
        Description: 'Moving to enforcement will enforce all policies applied to this server.',
        EmptyMessage: 'No servers to enforce',
        Mode: 'Enforcement Mode',
        Server: 'Server: {server}',
        Title: 'Server enforcement',
        TrafficSelector: 'Show Traffic over',
        Subtitle: 'Click on a server to review traffic and change its enforcement mode',
      },
      InstallAgents: "Let's start by installing agents on your servers",
      Labeling: {
        ChooseProtectionSchema: 'Choose Protection Schema',
        Description:
          'Illumio automatically identifies some of your servers and suggests appropriate labels and policies.',
        Manual: '(Manual)',
        ProtectionSchema: 'Protection Schema',
        Recommended: '(Recommended)',
        SubDescription: 'You can also change the recommendation.',
        LearnMoreAboutLabels: 'To learn more about labels, visit {link}.',
        LearnMoreAboutPolicies: 'To learn more about policies, visit {link}.',
        HereText: 'here',
        ServerRolesRefreshRate: 'Server roles refresh rate details',
        ServerRolesRefreshRateDetails:
          'Discovered server roles are generated when you pair a server that is already configured with server roles or 24 hours after server roles are configured or when restarting the VEN.',
        Title: 'Choose protection schemas to apply',
        Why: 'Why this recommendation?',
        WhyTooltip: '{protection} was recommended because of the detected server role(s)',
      },
      Protect: 'Protect Servers',
      ProtectDescription:
        'Identify and protect your critical applications using best-practices for ring-fencing, tailored to your network traffic',
      RecommendedRulesForHostname: 'Recommended Rules for {hostname}',
      RulesThatApplyDescription: '{count, plural, =1 {1 Rule (Summary of Rule)} other {# Rules (Summary of Rules)}}',
      ServersWillAppearBelow: 'Servers will appear below as you add them.',
      StatusMessages: {
        CompatibleConfiguration: 'Compatible configuration',
        IncompleteConfiguration: 'Incomplete configuration',
        NoReportAvailable: 'No report available',
      },
      NoneFound: 'No servers found',
      NoPairingScript: 'No pairing script available for {os}, please select another or contact Illumio for support.',
    },
    Endpoints: {
      Add: 'Add Endpoints',
      Admin: 'Admin Endpoints',
      CopyScriptToEndpoints: 'Copy the agent installation script and run it on your endpoints as a privileged user',
      Enforcement: {
        EmptyMessage: 'No endpoints to enforce',
        Title: 'Endpoint enforcement',
        Subtitle:
          'Select an endpoint type to review traffic from the past 24 hours and change the enforcement mode for one or more endpoints',
        ConfirmTitle: 'Confirm Endpoint Enforcement Change',
        Filter: 'Filter by endpoint name',
      },
      EndpointsWillAppearBelow: 'Endpoints will appear below as you add them to the network',
      InstallAgents: "Let's start by installing agents on your endpoints",
      NoneFound: 'No endpoints found',
      Protect: 'Protect Endpoints',
      ProtectDescription: 'Protect your user workstations by making sure only authorized inbound traffic is allowed',
      Pairing: {
        WindowsAdmin: 'Windows Admin',
        WindowsAdminScript: 'Windows Admin Pairing Script',
        WindowsUser: 'Windows User',
        WindowsUserScript: 'Windows User Pairing Script',
      },
      Traffic: {
        ApplyingRules: 'Applying Rules...',
        AllowRecommended: 'Allow (Recommended)',
        DontAllowRecommended: "Don't Allow (Recommended)",
        Error: 'There was an error saving rules based on traffic recommendations to your endpoints. Please try again.',
        InboundToAllow: 'Review and accept inbound traffic recommendations',
        InboundToAllowDescription:
          'By default, Illumio will block any additional inbound traffic not listed, including future traffic as well',
        IncludePreviouslyPairedServers: 'Include previously paired servers',
        Loading: 'Loading Traffic...',
        NoTraffic: 'No traffic detected',
        ObservedTraffic: 'Observed Services & Traffic',
        SaveRules: 'Save Rules',
        Success: 'Rules based on recommendations have been applied to your endpoints',
        Unchanged: 'There were no new rules to be saved',
      },
      User: 'User Endpoints',
    },
    Events: {
      PotentiallyBlockedTraffic: 'Simulated Block Traffic',
    },
    EventUtils: {
      ContainerWorkloadInvalidPairingConfig: 'Pod invalid pairing config',
      ContainerWorkloadUpdateServices: 'Pod Update Services',
      ContainerWorkloadNotCreated: 'Pod not created',
      ContainerWorkloadProfileCreated: 'Namespace created',
      ContainerWorkloadProfileDeleted: 'Namespace deleted',
      ContainerWorkloadProfileUpdated: 'Namespace updated',
      ContainerClusterCreated: 'Kubernetes Cluster created',
      ContainerClusterDeleted: 'Kubernetes Cluster deleted',
      ContainerClusterUpdated: 'Kubernetes Cluster updated',
      ContainerClusterDuplicateMachineID: 'Kubernetes Cluster duplicate machine ID',
      ContainerClusterRegionMismatch: 'Kubernetes Cluster region mismatch',

      EnforcementBoundariesRuleCreate: 'Deny Rule created',
      EnforcementBoundariesRuleUpdate: 'Deny Rule updated',
      EnforcementBoundariesRuleDelete: 'Deny Rule deleted',
      ContainerClusterChangeRate: 'Kubernetes Cluster Change Rate',
    },
    Help: {
      Desc: {
        ProvidingService:
          'Resource defined by a Rule that allows specific types of communication (such MySQL) between Destinations and Sources.',
        ProvidedTraffic:
          'Traffic that has been observed into a vulnerable port, and if it was allowed, blocked or simulated block',
        Explorer:
          'Analyze traffic flows for auditing, reporting, and troubleshooting purposes. You can view traffic by time, port, Sources, Destinations, and services. Explorer is not an interactive tool, so you cannot write rules using it.',
        PolicySettings:
          'Describe context before provisioning rules. By default, it is set to ‘No’. On changing it to ‘Yes’, makes providing a ‘Provision Note’ mandatory before provisioning rules. Also allows the order of of Destination and Source columns to be changed across the UI.',
        CCServices: 'Kubelink creates a virtual service in the PCE for services in the Kubernetes Cluster.',
        ContainerClusters: 'An object used to store all the information about a Kubernetes Cluster in the PCE.',
        ContainerWorkloadInIllumination:
          'After deploying containers, you can view Pods and traffic associated with them in Illumination, App Group Map, Vulnerability Map, and Explorer.',
        ContainerInheritHostPolicy:
          'Handle network traffic to/from containers on this host the same way as traffic to/from the host itself',
        RejectedCoreService:
          'Displays the list of core service recommendations that were rejected. When you reject a core service recommendation, that IP address is no longer recommended as a Destination of the requested core service.',
        EnforcementBoundaries:
          'Sets of rules that determine where rules apply. Deny Rules can block traffic from communicating with workloads you specify, while still allowing you to progress toward a Zero Trust environment. Unlike firewall policies, boundaries provide a simple policy model that does not depend on rule order.',
        SelectiveEnforcementState:
          'Rules are enforced directionally for selected services when a workload is within the scope of Deny Rules. To apply a Deny Rule to workloads, you must move the required workloads into the Selective Enforcement state.',
        EnforcementBoundaryWorkflow:
          'After creating a Deny Rule, the PCE web console guides you through the process to review blocked connections and add any required rules (allowlist policy) to traverse the boundary.',
        EnforcementBoundaryBlockedConnections:
          'Page that displays the traffic flows blocked between workloads due to a Deny Rule. Review the blocked connections and add rules for connections that must traverse the boundary.',
        AllowSelectedConnections:
          'Connections that you designate as being allowed to cross a Deny Rule. After creating a Deny Rule, you can select some or all connections that cross the boundary. The PCE web console then displays proposed rules for these connections that you can accept as is or modify.',
      },

      Title: {
        EnforcementBoundaryWorkflow: 'Deny Rule Workflow',
      },
    },
    IPLists: {
      List: {
        Message: {
          CannotDeleteBoundToEnforcementBoundary:
            'Cannot remove {count, plural, =1 {IP List that is} other {# IP Lists that are}} referenced by an existing Deny Rule',
        },
      },
    },
    Labels: {
      ProviderEdit: 'Edit Destination Labels',
      ConsumerEdit: 'Edit Source Labels',
    },
    Map: {
      CantChangeLabelsOnContainers: 'Cannot change the labels of Pods.',
      ConsumingAppGroups: 'Source App Groups',
      ProvidingAppGroups: 'Destination App Groups',
      SearchConsumingAppGroups: 'Search Source App Groups',
      SearchProvidingAppGroups: 'Search Destination App Groups',
      ExtraScopeConsumers: 'Extra Scope Sources',
      ViewBoundContainerWorkloads: 'View Bound Pods',
      Search: {
        ContainerWorkload: 'Pod: {workload}',
      },
      FlowVisibility: {
        BlockAllowed: 'VEN logs connection information for allowed, blocked and simulated block traffic',
        BlockAllowedEdge: 'Illumio Edge logs and display traffic information for allowed and simulated block traffic',
        BlockTraffic: 'VEN logs connection information for blocked and simulated block traffic only',
        EnhancedDataCollectionDesc:
          'VEN logs byte counts in addition to connection details for allowed, blocked, and simulated block traffic',
      },
      LegendPanel: {
        PotentiallyBlockedVulnerableTraffic: 'Simulated Block Vulnerable Traffic',
      },
      Workloads: {
        RoleContainerWorkloads: '{count, plural, =0 {}  =1 {1 Pod} other {# Pods}}',
      },
    },
    Menu: {
      ContainerClusters: '{multiple, select, false {Kubernetes Cluster} true {Kubernetes Clusters}}',
    },
    EnforcementBoundary: {AddNew: 'Add a new Deny Rule'},
    Onboarding: {
      WelcomePairingSetup: 'Welcome to Pairing a Workload with VEN Setup page',
      WelcomePairingSetupDesc:
        "'Workloads' are the physical or virtual servers that make up your data center, in the cloud or on-premise.",
      WelcomePairingSetupDescVens:
        "'VENs' are agents installed on your workloads, which allow you to see traffic and activity between them, as well as apply segmentation rules.",
      PairWorkloads:
        "Installing a VEN and connecting it to the Illumio console is called 'Pairing'. Let's pair a few workloads now.",
      WorkloadCopyPairingScript:
        "Copy the relevant script for {os} and run it as administrator on the workload you'd like to add.",
      SkipTour: 'Skip Tour',
      LandingPrompt: 'What would you like to do?',
    },
    TemplatesWizard: {
      SelectBestPractice: 'Select Best Practice',
      ShowPreview: 'Show Preview of Rules',
      PreviewImpact: 'Preview Impact of Rules',
      Confirm: 'Confirm and Save',
      ErrorMessage: 'An unexpected error occurred while trying to add a Best Practice ruleset.',
      ErrorDetails:
        'Sorry, it seems something went wrong. Please try again, or reach out to support if the problem persists.',
      SelectFromRecommendations: 'Select from best practices we recommend',
      SelectorPlaceholder: 'Select Labels and/or Workloads',
      AddFromTemplate: 'Add from template',
      AddFromScratch: 'Add from scratch',
      AddBestPracticeRuleset: 'Add Best Practice Ruleset',
      ProvideAdditionalDetails: 'Provide Additional Details',
      DomainControllerSelection: 'Select Labels and/or Workloads for Domain Controller',
    },
    Port: {
      ProtocolProcess: 'Port/Protocol/Process',
      ConsumerProcess: 'Source Port/Process',
    },

    Role: {
      GlobalOrgOwner: 'Organization Owner',
      GlobalAdmin: 'Administrator',
      GlobalPolicyObjectProvisioner: 'Policy Objects (Non-Rulesets) Provisioner',
      GlobalReadOnly: 'Read-Only User',
    },

    Rule: {
      Validation: {
        EgressSpecifiedWithNoIngress: 'An outbound service is specified with no inbound service',
        IPListsCantBeProviderAndConsumer: 'Cannot use IP Lists for both Destinations and Sources.',
        ProcessServicesCantWithIPListProvider: 'Process-based Services cannot be used with IP List Destinations',
        RequiresProviderServiceConsumer: 'Cannot save. A Rule requires a Destination, Service, and Source.',
        SecureConnectCantWithIPListBoundVirtual:
          "SecureConnect is not supported for Rules that use IP Lists, 'Uses Virtual Services and Workloads', or 'Uses Virtual Services only' for Destinations or Sources.",
        UserGroupsCantWithIPListConsumer: 'Cannot use User Groups if IP Lists is a Source.',
        StatelessWithLabelGroup: 'Label Group in Sources with a Stateless Rule is not supported',
        StatelessWithIPList: 'IP Lists in Destinations with a Stateless Rule is not supported',
        StatelessInvalidConsumers:
          'A Stateless Rule can only have the following types of Source: Any IP List plus All Workloads, or a Label (maximum one of a specific type) , or an individual item',
        MachineAuthWithNullService:
          'Machine Authentication is not supported for Rules that use From Destinations for Services',
        MachineAuthWithNullUbService:
          'Machine Authentication is not supported for Rules that use IP Lists, ‘Uses Virtual Services and Workloads’, or ‘Uses Virtual Services only’ for Destinations or Sources.',
        SecureConnectWithNullUbService:
          "SecureConnect is not supported for Rules that use IP Lists, 'Uses Virtual Services and Workloads', or 'Uses Virtual Services only' for Destinations.",
        ProviderIpListWithMachineAuth:
          'Machine Authentication is not supported for Rules that use IP Lists for Destinations.',
        ConsumerIpListWithMachineAuth:
          'Machine Authentication is not supported for Rules that use IP Lists for Sources.',
        ProviderWithIpListScopedUser:
          'Users with Scoped Roles cannot create Extra-Scope Rules which have IP Lists as Destinations.',
        ProviderWithIpListWorkload:
          "Cannot use IP Lists or Workloads if you select 'Uses Virtual Services only' for a Destination.",
        ProviderIpListWindowsService: 'Cannot use Windows Services as a Service when IP Lists is the Destination.',
        ProviderIpListVirtualServiceVirtualServerWorkload:
          "Cannot use IP Lists, Virtual Services, Virtual Servers, or Workloads along with 'Uses Virtual Services and Workloads' for Destinations.",
        ConsumerIpListWindowsService: 'Cannot use Windows Egress Services as a Service when IP Lists is the Source.',
        ConsumerWithVirtualService:
          "To use Virtual Services for Sources, you must first select 'Uses Virtual Services only' or 'Uses Virtual Services and Workloads'.",
        BothVirtualServicesOnlyVirtualServicesWorkloads:
          "Cannot use 'Uses Virtual Services and Workloads' and 'Uses Virtual Services only' together for Sources.",
        ConsumerIpListWorkload:
          "Cannot use IP Lists or Workloads if you select 'Uses Virtual Services only' for a Source.",
        ConsumerIpListVirtualServiceVirtualServerWorkload:
          "Cannot use IP Lists, Virtual Services, or Workloads along with 'Uses Virtual Services and Workloads' for Sources.",
        ProvidingServicesVirtualServices: "Cannot use a Service if 'Uses Virtual Services only' is a Destination.",
        ConsumingServicesVirtualServices:
          "Cannot use a Service if 'Uses Virtual Services only' or 'Uses Virtual Services and Workloads' is a Source.",
        ProviderWithVirtualService:
          "To use Virtual Services or Virtual Servers for Destinations, you must first select 'Uses Virtual Services only' or 'Uses Virtual Services and Workloads'.",
        ContainerHostProviders:
          "Only Labels, Workloads, or All Workloads can be used as Destinations if 'Container Host' is the Source.",
        ContainerHostItself: "'Container Host' must be the only Source specified in the rule.",
        ContainerHostSecureConnectMachineAuthStateless:
          "Cannot use SecureConnect, Stateless Rules, or Machine Authentication in a Rule if 'Container Host' is the Source.",
        ContainerHostNonTcpService: "Must use a TCP Port or Port Range with 'Container Host'.",
        NonCorporateMustUseIpList:
          "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' is only supported for Rules that contain IP List(s) as Sources or Destinations",
        NetworkTypeMustBeDefaultCorporate:
          "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' cannot be used with the 'Use Virtual Services only' option as Sources or Destinations",
        ConsumerMustHaveOnlyIPLists:
          "Sources must have only IP List(s) with 'Non-Corporate Networks (Endpoints Only)' or 'All Networks'",
        ProviderMustHaveOnlyIPLists:
          "Destinations must have only IP List(s) with 'Non-Corporate Networks (Endpoints Only)' or 'All Networks'",
      },
    },
    PairingProfiles: {
      CopyScript: 'Copy Script',
      PreviewScript: 'Preview Script',
    },
    PolicyGenerator: {
      MergeConsumers: 'Merge rules with common Destination and Service',
      MergeServices: 'Merge rules with common Destination and Source',

      ExtraScope: {
        NumConsumingAppGroup: '{count, plural, =1 {1 Source App Group} other {# Source App Groups}}',
      },
    },
    RuleSearch: {
      AdvancedDescription: 'Search by Destination, Source, or both',
      Placeholders: {
        Providers: 'Filter by Destinations and Rule attributes',
        Consumers: 'Filter by Sources',
      },
    },
    Tally: {
      OverrideDeny: '{count, plural, =1 {Override Deny Rule} other {Override Deny Rules}}',
      Deny: '{count, plural, =1 {Deny Rule} other {Deny Rules}}',
      Allow: '{count, plural, =1 {Allow Rule} other {Allow Rules}}',
    },
    Rulesets: {
      NewProposedRuleset:
        'This Proposed Ruleset contains rules to allow blocked connections across the Deny Rule. Rules in the Proposed Ruleset can be edited prior to saving',
      EnforcementBoundaryRuleset: 'Proposed Deny Ruleset',
      OverrideLabels: "Selecting 'All Workloads' replaces Labels and Label Groups selection",
      OverrideAllWorkloads: "Selecting Labels or Label Groups replaces 'All Workloads' selection",
      Rules: {
        DerivedFromProviderVirtualServices: 'Derived from Destination Virtual Services',
        IntraToExtra: 'Rule changed from Allow Rule to Deny Rule.',
        ExtraToIntra: 'Rule changed from Deny Rule to Allow Rule.',
        ExtraScope: {
          Add: 'Add Deny Rule',
          AddDesc: 'Deny traffic from specified Source to Destination not explicitly allowed by an Allow Rule',
          NoMatch: 'No matching Deny Rules',
          Rules: '{count, plural, =0 {No Deny Rules} =1 {1 Deny Rule} other {# Deny Rules}}',
          IPListWarning:
            'To allow outbound access to IP Lists, create an Allow Rule to prevent the Rule from being applied to a larger set of Workloads.',
        },
        IntraScope: {
          Title: 'Allow Rule',
          Add: 'Add Allow Rule',
          AddDesc: 'Allow traffic from specified Source to Destination',
          NoData: 'No Allow Rules to display',
          NoMatch: 'No matching Allow Rules',
          Rules: '{count, plural, =0 {No Allow Rules} =1 {1 Allow Rule} other {# Allow Rules}}',
        },
        OverrideDeny: {
          AddRule: 'Add Override Deny Rule',
          AddDesc: 'Deny traffic from specified Source to Destination regardless of subsequent Allow Rules',
          Rules: 'Override Deny Rules',
        },
      },
      TrafficBasedOn: '<b>Draft View</b> traffic based on data from the <b>{timeframe}</b>',
      ImpactOfRule: 'Impact of Rule',
      InspectTraffic: 'Inspect Traffic',
    },
    Services: {
      SourceProcessServices: 'Source Process Services',
      List: {
        CannotDeleteBoundToEnforcementBoundary:
          'Cannot remove {count, plural, =1 {Service that is} other {# Services that are}} referenced by an existing Deny Rule',
      },
      ServiceName: 'Service Name',
      Mixin: {
        AtLeastOneEgressProcess: 'Atleast one service name is required',
      },
    },
    Settings: {
      Containers: {
        ContainerInheritHostPolicyDesc:
          'Illumio Core handle traffic to/from containers on this host the same way as traffic to/from the host itself if enabled.',
        AddScopeForContainerInheritHostPolicy: 'Add Workload labels for Container Hosts',
        EditScopeForContainerInheritHostPolicy: 'Edit Workload labels for Container Hosts',
        ConfigInstruction: 'You need to select at least one label to enable this functionality',
        DuplicatedScopesAndLabels: 'The same Workload Labels have already been configured',
        UnmanagedDesc: 'Pods in this namespace are ignored by PCE',
        ManagedDesc: 'Pods in their namespace are managed by PCE',
        AnnotationLabelHint:
          'Labels as specified by Kubernetes annotations are accepted by default. You can assign a Label here or restrict the Labels that may be used in Kubernetes annotations. If you assign a label here, it cannot be overridden by Kubernetes annotations',
        AnnotationLabelTooltip:
          '"Use Kubernetes Annotations" gives the ability to container platform administrators to set the Labels on pods and services using annotations in the manifest files, within the allowed list defined by the Illumio admin.\n\n"Assign Label" unidirectionally sets the Label selected on pods and services within this namespace/project. This label cannot be overwritten with container annotations.',
      },

      IPForwarding: {
        Description:
          'IP forwarding is blocked by default unless the host is part of a Kubernetes Cluster. Linux Workloads that match these scopes will allow all forwarded traffic. Illumio Core does not provide visibility of forwarded traffic.',
      },
    },
    TrafficEvents: {
      PotentiallyBlockedEvents: 'Simulated Block Events',
    },
    Explorer: {
      FQDNNotSupported: 'The IP List select contains an FQDN which is not supported as a Source Filter',
      ProviderIPList: 'Destination IPList',
      ConsumerLabels: 'Source Labels',
      ConsumerHostname: 'Source Hostname',
      ConsumerRole: 'Source Role',
      ConsumerEnv: 'Source Env',
      ConsumerApp: 'Source App',
      ConsumerLoc: 'Source Loc',
      ConsumerEnvironments: 'Source Environments',
      ConsumerApplications: 'Source Applications',
      ConsumerLocations: 'Source Locations',
      ConsumerCount: '{count, plural, =0 {no Sources} =1 {# Source IP} other {# Source IPs}}',
      ProviderCount: '{count, plural, =0 {no Destinations} =1 {# Destination IP} other {# Destination IPs}}',
      ProviderLabels: 'Destination Labels',
      ProviderEnvironments: 'Destination Environments',
      ProviderApplications: 'Destination Applications',
      ProviderLocations: 'Destination Locations',
      ProviderRole: 'Destination Role',
      ProviderEnv: 'Destination Env',
      ProviderApp: 'Destination App',
      ProviderLoc: 'Destination Loc',
      ProviderEnforcement: 'Destination Enforcement',
      ConsumerIsNot: 'Source is not',
      ProviderIsNot: 'Destination is not',
      IncludeConsumers: 'Include in Sources',
      ExcludeConsumers: 'Exclude in Sources',
      IncludeProviders: 'Include in Destinations',
      ExcludeProviders: 'Exclude in Destinations',
      IncludeConsumersOrProviders: 'Include Sources Or Destinations',
      ExcludeConsumersOrProviders: 'Exclude Sources Or Destinations',
      SwapConsumersProviders: 'Swap Destinations and Sources',
      AllowAllConnectionsMessage:
        '{count, plural, =0 {No connections} =1 {# connection} other {# connections}} will be blocked by the Deny Rule when it is provisioned. {count, plural, =0 {} other {To allow all of these connections, click <span class="{className}">Allow All Connections</span>.}}',
      AllowFilteredConnectionsMessage:
        '{count, plural, =0 {No filtered connections} =1 {# filtered connection} other {# filtered connections}} will be blocked by the Deny Rule when it is provisioned. {count, plural, =0 {} other {To allow all of these connections, click <span class="{className}">Allow Filtered Connections</span>.}}',
    },

    Users: {
      RulesetManagerSublabel: 'Manage Rulesets that match the scope and Rules where the Destination matches the scope.',
      LimitedRuleWritingSublabel:
        'Manage Rulesets that match the scope and Rules where the Destination and Source match the scope.',
    },
    VEN: {
      ContainerCluster: 'Kubernetes Cluster',
    },
    Workloads: {
      EnforcementBoundariesIdle:
        'Idle Workloads do not receive Deny Rules. Change the Enforcement Mode of the Workload to receive Deny Rules',
      EnforcementBoundariesVersionLow:
        'The workload is not managed by a VEN that supports Deny Rules. Upgrade the VEN to version 21.2.0 or above for Deny Rules',
      EnforcementBoundariesActiveVersion: 'This list shows only active Deny Rules',
      EnforcementBoundariesInboundNoFound: 'No Inbound Deny Rules found',
      EnforcementBoundariesOutboundNoFound: 'No Outbound Deny Rules found',
      EnforcementBoundariesSelectedInbound:
        'Rules are enforced only for selected inbound services when workload is within scope of a Deny Rule',
      IncompatibilityTypes: {
        UnwantedWFProviders: 'Non-Illumio WFP Destinations',
      },
      WorkloadsAppearHere: 'Your Workloads will appear here',
      CSVFile: 'CSV File',
      CSVInvalid: 'The CSV file is invalid',
      CSVMissing: 'A CSV file needs to be uploaded',
      CSVImport: "Import a CSV file with the workloads info and the labels you'd like to assign to them.",
      CSVFormat: 'The format: href, hostname, loc, env, app, role.',
      CSVColumns: 'Columns can be in any order.',
      CSVImportTitle: 'Import a CSV to edit workload labels',
      CSVParseError: 'Fail to parse the CSV file: {reason}',
      CSVTooLarge: 'The CSV file is too large',
      CSVRowsExceed: 'The CSV file exceeds {count} rows',
      CSVEmpty: 'The CSV file is empty',
      CSVMissingHeader: 'The CSV file is missing a valid header row',
      CSVCreateNewLabel: "Create new labels if they don't exist",
    },
    EnforcementBoundaries: {
      BlockedConnections: 'Deny Rule  Blocked Connections',
      BlockedConnectionDesc: 'These connections will be blocked when the Deny Rule is provisioned',
      Errors: {
        Edit: 'Unable to Edit Deny Rule',
        Create: 'Unable to Create Deny Rule',
        ConsumerProviders: 'Cannot use IP Lists for both Sources and Destinations',
      },
      Warning: {
        FQDN: 'FQDN is not supported. The PCE allows adding FQDNs to IP lists; however, it drops the FQDN component when the Deny Rule results in an outbound deny rule to an IP list with FQDNs.',
      },
      RulesCount: '{count, plural, =1 {# Deny Rule} other {# Deny Rules}}',
      RulesProvision:
        '{count, plural, =1 {# Deny Rule} other {# Deny Rules}} added to the Deny Ruleset allow connections to cross the boundary. Be sure to provision these rules before provisioning the boundary.',
      Remove: 'Remove Deny Rule',
      DeleteConfirm: 'Are you sure you want to remove this Deny Rule?',
      WorkloadsInScopeDesc: {
        Selective: 'Traffic that matches the Deny Rule is blocked unless allowed by a Rule. Unmatching is not blocked.',
        SupportEnforcement:
          '{context} <span class="{className}">These workloads must be set to Selective Enforcement to support Deny Rule</span>',
        Total:
          '<span class="{className}">{count} {count, plural, =1 {workload} other {workloads}}</span> are within the scope of the Deny Rule',
      },
      Description:
        'a Deny Rule is defined by a scope consisting of Source, Provider, and Service. When a Deny Rule is provisioned, connections that match the scope are blocked. A blocked connection can be allowed to cross the Deny Rule by writing a Rule.',
    },
    Tooltip: {
      ContainerWorkload: 'Pod:',
      ContainerWorkloadCount: '{count, plural, =1 {Pod} other {Pods}}: <span class="{className}">{count}</span>',
    },
  },
  AccessRestriction: {
    NoData: 'No Data to display',
    AddNew: 'Add a new Access Restriction',
    AddValidRestriction: 'Add a valid Access Restriction',
    MaxIPAddresses: 'Maximum 8 IPv4 Addresses or CIDR Blocks',
    AccessRestrictionTitle: 'Access Restriction',
    AccessRestrictionDelete: 'Remove {count, plural, =1 {Access Restriction} other {Access Restrictions}}',
    AccessRestrictionDeleteConfirm:
      'Are you sure you want to remove {count, plural, =1 {this Access Restriction} other {# selected Access Restrictions}}?',
    RestrictionAppliedTo: 'Restriction Applied To',
    UserSessionAndAPIKey: 'User Session and API Key',
    UserSession: 'User Session',
    APIKey: 'API Key',
    TypeRestrictionName: 'Type Access Restriction Name',
    TypeRestrictionDescription: 'Type Access Restriction Description',
    List: {
      Message: {
        RemoveSuccess:
          '{count, plural, =1 {Access Restriction has} other {# Access Restrictions have}} been successfully removed',
      },
    },
    Errors: {
      Edit: 'Unable to Edit Access Restriction',
      APIKey: 'Unable to Edit API Key',
      Create: 'Unable to Create Access Restriction',
      SuperclusterMember:
        'API keys cannot be edited from a Super Cluster Member. Please log out and log into the Super Cluster Leader',
    },
    Warning: {
      FirstMessage: 'Assigning misconfigured access restrictions can prevent users from accessing the PCE',
      SecondMessage:
        'Assigning misconfigured access restrictions can prevent users from accessing the PCE. Are you sure you want to continue?',
    },
  },
  TrustedProxy: {
    TrustedProxyIp: 'Trusted Proxy IPs',
    TrustedProxy: 'Trusted Proxy',
    ConfigurationInfo: 'Proxy Configuration - cannot add more than {max} Trusted Proxy IPs',
    RegionsAvailable: '{region} ({groupedRegions} of {maxTrustedListRegions}) Available',
    Add: 'Add Trusted Proxy IPs',
    Edit: 'Edit Trusted Proxy IPs',
    List: {
      RemoveConfirm: 'Are you sure you want to remove the selected Trusted Proxy IPs?',
      RemoveResultTitle: 'Result of {count, plural, =1 {Trusted Proxy IP} other {# Trusted Proxy IPs}} removal',
      Delete: 'Remove {count, plural, =1 {Trusted Proxy IP} other {# Trusted Proxy IPs}}',
      Empty: 'No Proxy IPs',
    },
    Errors: {
      Edit: 'Unable to Edit',
    },
  },
  StatusCodes: {
    InternalServerError: '500 Internal Server Error',
    NotImplemented: '501 Not Implemented',
    BadGateway: '502 Bad Gateway',
    ServiceUnavailable: '503 Service Unavailable',
    GatewayTimeout: '504 Gateway Timeout',
    HttpVersionNotSupported: '505 HTTP Version Not Supported',
    VariantAlsoNegotiates: '506 Variant Also Negotiates',
    InsufficientStorage: '507 Insufficient Storage',
    LoopDetected: '508 Loop Detected',
    NotExtended: '510 Not Extended',
    NetworkAuthenticationRequired: '511 Network Authentication Required',
  },
  APIKeySettings: {
    APIKeySettings: 'API Key Settings',
    DefaultAPIKeyExpiresIn: 'By default, API Key for Service Accounts expires in',
    ExpirationSettingText: 'Changing this setting will not change the expiration of existing API Keys',
    ExpirationSettingViewText: 'Time rounded to days. Click Edit to see exact time in hours.',
    KeepExpiredAPIKeysFor: 'Keep Expired API Keys for',
  },
  AppGroups: {
    AppEnv: 'Application | Environment',
    AppEnvDesc: 'Two Workload labels required to match. (Location label is ignored.)',
    AppEnvLoc: 'Application | Environment | Location',
    AppEnvLocDesc: 'Three Workload labels required to match',
    AppGroupType: 'App Group Type',
    AppGroupTypeDescription:
      'App Groups are created automatically based on Workload labels and the App Group Type setting. App Groups can be configured to require two or three matching labels.',
    SetAppGroupType: 'Set App Group Type',
    EditAppGroupType: 'Edit App Group Type',
    OpenAppGroupMap: 'Open App Group Map',
    ChangingAppGroupsType: 'Re-set App Group Type',
    ChangingAppGroupsWarning:
      'The App Group Map must recalculate traffic before re-displaying the map. Recalculating traffic takes longer for large Organizations.',
    Workloads: 'App Group Workloads',
    ContainerWorkloads: 'App Group Container Workloads',
    Rules: 'App Group Rules',
    PairingProfiles: 'App Group Pairing Profiles',
    Vulnerabilities: 'App Group Vulnerabilities',
    VirtualServers: 'App Group Virtual Servers',
    VirtualServices: 'App Group Virtual Services',
    Explorer: 'App Group Explorer',
    PolicyGenerator: 'App Group Policy Generator',
    AppGroupNoMember: 'The current App Group has no members, please select a different App Group.',
    SelectAppGroup: 'Select a different App Group',
  },
  AuthenticationSettings: {
    AuthenticationSettingsIntro:
      'Choose your <span class="{className}">Authentication Method</span> to authenticate users for accessing the <span class="{className}">PCE</span>',
    SignInOptionsTip: 'Sign in to the PCE using either SAML or LDAP along with local credentials.',
    SignInOnlySAMLOptionsTip: 'Sign in to the PCE using SAML along with local credentials.',
    Options: {
      Local:
        "User will sign into the PCE only with a local credential provided by the user's organization password policy.",
      SAML: 'SAML users can also authenticate to the PCE using local credentials.',
      LDAP: 'LDAP users can also authenticate to the PCE using local credentials.',
    },
    AddServer: 'Create Server',
    LDAPServerConfiguration: 'LDAP server configuration',
    LearnMore: 'Learn about supported SSO providers',
    Banner: 'Authentication Settings banner image',
    TestConnection: 'Test Connection',
    CheckYourConfigurationAndRetry: 'Check your configuration and retry.',
    LDAPEnabled: 'LDAP authentication is active.',
    EnableLDAP: 'LDAP authentication is not active. Click Turn On to apply on all the LDAP servers.',
    EnableLDAPSupercluster:
      'LDAP authentication is not active. Click Turn On to apply on all the LDAP servers on all PCEs.',
    SAMLEnabled: 'SAML authentication is active.',
    EnableSAML: 'SAML authentication is not active. Click Turn On to enable SAML.',
    EnableSAMLSupercluster: 'SAML authentication is not active. Click Turn On to enable SAML on all PCEs.',
    MaxServersAllowed: 'Cannot add more than 3 servers.',
    MaxServersAllowedSupercluster: 'Cannot add more than 3 servers per PCE.',
    EmptyLDAPConfigs: 'No LDAP servers configured',
    ConfirmDeleteLDAPConfig: 'Delete this LDAP server configuration and remove it from the list?',
    ConfirmEnableSAML: 'Confirm SAML as the default authentication setting?',
    ConfirmDisableSAML: 'Are you sure you want to disable SAML as the default authentication setting?',
    ConfirmEnableLDAP: 'Confirm LDAP as the default authentication setting?',
    ConfirmEnableLDAPWithoutConfigs:
      'LDAP configuration has been enabled, but no servers have been added. Please add LDAP servers.',
    ConfirmLastLDAPConfigRemoval:
      "Deleting the last LDAP server configuration disables LDAP authentication. Only the 'Local' setting will be available. Click OK to proceed.",
    CountOfMax: '({count} of {max})',

    // LDAP config form keys
    BindDN: 'Bind DN',
    AnonymousBind: 'Anonymous Bind',
    BindPassword: 'Bind Password',
    ConfirmBindPassword: 'Confirm Bind Password',
    PasswordConfirmed: 'Password is confirmed',
    RequestTimeoutPeriod: 'Request Timeout Period (seconds)',
    UserDirectoryConfiguration: 'User Directory Configuration',
    UserBaseDN: 'User Base DN',
    UserDNPattern: 'User DN Pattern',
    UserSearchFilter: 'User Search Filter',
    UserNameAttribute: 'User Name Attribute',
    FullNameAttribute: 'Full Name Attribute',
    GroupMembershipAttribute: 'Group Membership Attribute',

    // LDAP config form placeholders (hints)
    NameHint: 'Enter a unique name for this setting',
    HostnameOrIPAddressHint: 'Enter a IP address or Hostname',
    TypeHint: 'Select a Type',
    PortHint: 'Enter a Port',
    BindDNHint: 'This is the distinguished name used to bind to the LDAP server',
    UserBaseDNHint: 'The location of your LDAP users, specified by the DN of your user subtree',
    UserDNPatternHint:
      'Pattern used to create a DN string for a user during login,\n e.g. uid=*,ou=people, where * will be replaced with the username',
    UserSearchFilterHint: 'The LDAP search filter used to filter users',
    UserNameAttributeHint: 'The user attribute that contains the username',
    FullNameAttributeHint: 'Attribute on a user object which contains the full name',
    GroupMembershipAttributeHint: 'Attribute on a user object containing group membership information',

    // Example secondary text
    PortExample: 'Defaults: 389 for LDAP or LDAP with STARTTLS, 636 for LDAPS. Valid values are 1 to 65,535',
    BindDNExample: 'Example: cn=Manager,dc=domain,dc=com',
    RequestTimeoutPeriodExample: 'Time to wait when opening new server connection.',
    UserBaseDNExample: 'Example: CN=Users,DC=MyDomain,DC=com',
    UserSearchFilterExample: 'Example: department=IT',
    UserNameAttributeExample: 'Default directory Active Directory (AD): sAMAccountName',
    FullNameAttributeExample: 'Example: cn,commonName,displayName',
    GroupMembershipAttributeExample: 'Example: memberOf',

    LDAP: {
      Config: {
        // LDAP server configuration (required fields)
        PCERequired: 'PCE is required',
        NameRequired: 'Configuration Name is required',
        IPAddressOrHostnameRequired: 'IP Address or Hostname is required',
        ProtocolRequired: 'Protocol is required',
        PortRequired: 'Port is required',
        PortRequiredHint: 'Port number must be greater than or equal to 1',
        BindDNRequired: 'Bind DN is required',
        AnonymousBindRequired: 'Anonymous Bind is required',
        BindPasswordRequired: 'Bind Password is required',
        ConfirmBindPasswordRequired: 'Confirm Bind Password is required',
        RequestTimeoutPeriodRequired: 'Request Timeout Period is required',
        RequestTimeoutPeriodRequiredHint: 'Request Timeout Period must be less than or equal to 60 seconds',
        // User Directory Configuration (required fields)
        UserBaseDNRequired: 'User Base DN is required',
        UserNameAttributeRequired: 'User Name Attribute is required',

        //Misc
        PasswordsDoNotMatch: 'Passwords do not match',
        ConfigError: 'LDAP server configuration error',
        UserDNPatternError: 'User DN pattern must have exactly one * character in the pattern',
        AnonymousBindTooltip: "It is not recommended to use 'Allow' Anonymous Bind.",
        ConfirmAnonymousBind:
          "It is not recommended to use 'Allow' Anonymous Bind. Click confirm to 'Allow' or click Cancel and select 'Do Not Allow'.",
      },
    },
    SAML: {
      Config: {
        ConfigError: 'SAML configuration error',
      },
    },
  },
  BlockedTraffic: {
    BlockedOnConsumer: 'Traffic is blocked by the Consumer',
    BlockedOnProvider: 'Traffic is blocked by the Provider',
    Name: 'Blocked Traffic',
    PotentiallyBlockedOnConsumer: 'Traffic is potentially blocked by the Consumer',
    PotentiallyBlockedOnProvider: 'Traffic is potentially blocked by the Provider',
    TrafficStatus: 'Traffic Status',
    BlockedConnections: 'Blocked Connections',
    Entity: {
      EntityName: 'Entity Name',
    },
    List: {
      ByTheConsumer: 'By the Consumer',
      ByTheProvider: 'By the Provider',
      ConfirmDeleteServices: 'Are you sure you want to remove these blocked traffic events?',
      DeleteServices: 'Remove Selected Blocked Traffic',
      Description:
        'Traffic is <span class="{className}">{status}</span> when a Workload is in <span class="{className}">{state}</span> mode.',
      DescriptionSelective:
        'If the Workload is in <span class="{className}">{state}</span>, traffic is <span class="{className}">{status1}</span> or <span class="{className}">{status2}</span> depending on the Enforcement Boundaries Rule.',
      DisabledNotice: 'Blocked Traffic feature is disabled',
      LastDetected: 'Last Detected',
    },
  },
  ContainerClusters: {
    AddNew: 'Add a new Container Cluster',
    Allowed: 'allowed',
    NoLabelAllowed: 'No label allowed',
    LabelsAllowedNumberAndType: '{count} {labelTypeName} Labels allowed',
    UseContainerAnnotations: 'Use Container Annotations',
    AssignLabel: 'Assign Label',
    Delete: 'Remove {count, plural, =1 {Container Cluster} other {# Container Clusters}}',
    DeleteConfirm:
      'Are you sure you want to remove the {count, plural, =1 {Container Cluster} other {# Container Clusters}}?',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Container Cluster has} other {# Container Clusters have}} been successfully deleted',
    CannotDelete:
      'The following {count, plural, =1 {Container Cluster is} other {# Container Clusters are}} still online. Please stop Kubelink before deleting the cluster from the PCE.',
    DuplicateMachineIDsError:
      'There are duplicate machine IDs among your cluster nodes. Container cluster functionality will be limited until this issue is resolved.',
    DuplicatedIdNodeList: 'The nodes with duplicate IDs are: {list}',
    DuplicatedIdList: 'The duplicate IDs are: {list}',
    CopyTokenMessage:
      'Please copy the following token and ID. This information will not be available after you leave the page. A new token will need to be generated.',
    ClusterId: 'Cluster ID',
    ClusterToken: 'Cluster Token',
    ClusterPairingToken: 'Cluster Pairing Token',
    KubelinkVersion: 'Kubelink Version',
    CopyKey: 'Copy Key',
    ContainerWorkloadProfiles: 'Container Workload Profiles',
    ContainerWorkloadProfilesMessage: 'Editing the template does not affect existing Container Workload Profiles.',
    ContainerWorkloadProfilesAdd: 'Add Container Workload Profile',
    ContainerWorkloadProfilesUnmanaged:
      'Container Workload Profiles will be created automatically for discovered {isOpenshift, select, false {Namespaces} true {Projects}}',
    ContainerAnnotationLabels: 'Container Annotation Labels',
    AssignedLabels: 'Assigned Labels',
    EditDefaultSettings: 'Edit default settings',
    ContainerWorkloadProfileTemplate: 'Container Workload Profile - Default Settings',
    DeleteContainerWorkloadProfile:
      'Remove {count, plural, =1 {Container Workload Profile} other {# Container Workload Profiles}}',
    DeleteContainerWorkloadProfileConfirm:
      'Are you sure you want to remove the {count, plural, =1 {Container Workload Profile} other {# Container Workload Profiles}}?',
    DeletedContainerWorkloadProfileSuccessfully:
      'The following {count, plural, =1 {Container Workload Profile has} other {#  Container Workload Profiles have}} been successfully deleted',
    CannotDeleteContainerWorkloadProfile:
      'The following {count, plural, =1 {Container Workload Profile is} other {# Container Workload Profiles are}} cannot be deleted.',
    CopyPairingKeyMessage:
      'Please copy the following pairing key. This information will not be available after you leave the page. A new profile will need to be created.',
    AddNewContainerWorkloadProfile: 'Add a new Container Workload Profile',
    ProfileLink: 'Profile Link',
    CreatedByContainerCluster: 'Created by Container Cluster',
    ContainerRuntime: 'Container Runtime',
    UndeletedVirtualServices: 'Created by Deleted Container Cluster',
    NoLabelAllowedHeader: 'No Label Allowed',
    AddLabels: 'Add Labels',
  },
  VirtualServices: {
    View: 'View Virtual Services',
    Add: 'Add Virtual Service',
    Additional: 'Additional Virtual Services',
    AddNew: 'Add a new Virtual Service',
    BoundWorkload: 'Bound Workload',
    BoundWorkloads: 'Bound Workloads',
    BoundContainerWorkloads: 'Bound Container Workloads',
    Delete: 'Remove Virtual {count, plural, =1 {Service} other {Services}}',
    DeleteConfirm:
      'Are you sure you want to remove the {count, plural, =1 {selected Virtual Service} other {# selected Virtual Services}}?',
    DeleteSingleVirtualService: 'Are you sure you want to remove the Virtual Service?',
    ManagedBy: {
      ContainerCluster: 'This Virtual Service is managed by a Container Cluster',
      ContainerClusterDeletedAndOnLeader:
        'This Virtual Service was managed by a Container Cluster that is now deleted. You may delete the Virtual Service.',
      ContainerClusterDeletedAndOnMember:
        'This Virtual Service was managed by a Container Cluster that is now deleted. You may delete the Virtual Service from the leader PCE.',
    },
    PortOverride: 'Port Override',
    BoundToWorkloads:
      'The following {count, plural, =1 {Virtual Service is} other {# Virtual Services are}} bound to workloads',
    SingleBoundToWorkloads: 'The Virtual Service is bound to workloads',
    BoundToRules:
      'The following {count, plural, =1 {Virtual Service is} other {# Virtual Services are}} bound to rules',
    SingleBoundToRules: 'The Virtual Service is bound to rules',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Virtual Service has} other {# Virtual Services have}} been successfully deleted',
    NoMatchData: 'No matching Virtual Services to display',
    NoData: 'No Virtual Services to display',
    SeeAll: 'View All {count, plural, =1 {Workload} other {# Workloads}}',
    ShowFewer: 'Show Fewer Workloads',
    ServicePorts: 'Service / Ports',
    ServiceOrPorts: 'Service or Ports',
    ServiceOrPortsIsRequired: 'Service or Ports is required',
    SelectAPolicyServiceOrPorts: 'Select a Policy Service or Ports',
    HostOnly: 'Host Only (Default)',
    InternalBridgeNetwork: 'Internal Bridge Network',
    IPAddressesAndFqdns: 'IP Addresses and FQDNs',
    AddressPool: 'Address Pool',
    AddIPAddressesOrFqdn: 'Add IP Addresses or Wildcard FQDNs',
    EditAddIPAddressesOrFqdn: 'Edit/Add IP Addresses or Wildcard FQDNs',
    AddIpv4: 'Add IPv4',
    AddIpv6: 'Add IPv6',
    AddFqdn: 'Add FQDN',
    ConnectionServicesOrPorts: 'Connection Service Or Ports',
    AddIPv4Addresses: 'Add IPv4 Addresses',
    AddIPv6Addresses: 'Add IPv6 Addresses',
    AddFqdns: 'Add FQDNs',
    EditIPv4Addresses: 'Edit IP Address',
    EditIPv6Addresses: 'Edit IPv6 Address',
    EditDomainNames: 'Edit FQDN',
    Total: 'Total Virtual Services',
    TypeIPAddressOrFqdn: 'Type or paste IP Address or FQDN (wildcards allowed)',
    TypeIPAddresses: 'Type or paste one or more IP Addresses',
    TypeFqdnAddresses: 'Type or paste one or more FQDNs (wildcards allowed)',
    IpAddresses4FormattingError: 'IPv4 Addresses formatting error: {list}',
    IpAddresses6FormattingError: 'IPv6 Addresses formatting error: {list}',
    IpAddressesAreRequired: 'IP Addresses are required',
    IpAddressesDuplicateError: 'Duplicated IP Addresses: {list}',
    IpAddressesExistError: 'IP Address with current network has been configured: {list}',
    DomainNameFormattingError: 'Domain name formatting error: {list}',
    DomainNameDuplicateError: 'Duplicated domain names: {list}',
    DomainNameExistError: 'Domain name has been configured: {list}',
    DomainsAreRequired: 'Domains are required',
    OptionalCommentAboutTheEntry: 'Optional Comment about the entry',
    NetworkForIps: 'Network for IPs',
    SelectNetworkHint: 'Select a Network',
    AddWorkloads: {
      SubTitle: 'Bind Workloads',
      Description:
        'Select a Workload to bind to the Virtual Service. If the Service has a Port/Protocol, you can specify which ports to override for those Workloads when a connection is initiated by a Consumer.',
      PortsOverrideDesc: 'Cannot override entries that has all Ports/Protocols or no Port.',
      InvalidPortRangeErrror:
        'Invalid port range. Enter a valid port range override or uncheck ‘Override ports’ checkbox.',
      InvalidPortError: 'Invalid port. Enter a valid port override or uncheck ‘Override ports’ checkbox.',
      UniquenessError: 'Cannot save a Port Override for an existing Virtual Service’s binded Workload.',
    },
    Containers: {
      Delete: 'Remove Virtual Service Container',
      DeleteConfirm: 'Are you sure you want to remove the selected Virtual Service Container?',
    },
    Workloads: {
      Delete: 'Remove Virtual Service Workload',
      DeleteConfirm: 'Are you sure you want to remove the selected Virtual Service Workload?',
      RemoveRecommand: 'Cannot override "Unidentified" port - removing the port override is recommended.',
      CannotBind: 'Unable to Bind Workloads',
      WorkloadDeleted: 'Removing the non-existing Workload is recommended.',
      Label: {
        Bind: 'Bind',
      },
    },
    Errors: {
      Edit: 'Unable to Edit Virtual Service',
      Create: 'Unable to Create Virtual Service',
    },
  },
  Certificate: {
    ValidTrial: 'Your Illumio Core trial is valid until <span class="{className}">{when, date, LL}</span>.',
    ValidCert: 'Your Illumio PCE certificate expires on {when, date, LL}',
    TrialEnded: 'Your Illumio Core trial has ended.',
    TrialEndedMessage:
      'To continue using Illumio Core, update the PCE with a valid certificate issued by a trusted certificate authority.',
    CertExpired: 'Your Illumio PCE certificate has expired.',
    CertExpiredMessage: 'Your VENs can no longer communicate with the PCE.',
    MessageSelfSigned: 'You are seeing this message because the PCE is using a temporary self-signed certificate.',
    MessageRealCert: 'VENs will be unable to communicate with the PCE when the certificate expires.',
    Days: '{count, plural, =1 {DAY} other {DAYS}}',
  },
  Groups: {
    PairingProfiles: {
      TimeAndUseLimited: 'Time and Use Limited',
      TimeLimited: 'Time Limited',
      UseLimited: 'Use Limited',
    },
    GroupHelpTextSubtitle: 'Displays the Groups, their provision status, and the number of VENs.',
    GroupDashboardHelpTextSubtitle: 'Displays the Groups created and the inbound traffic for the selected Group.',
    GroupWizardHelpTextSubtitle: 'Helps create group and define security rules.',
    SelectOneOrMore: 'Select one or more Groups',
    SelectingAllGroupsTooltip: 'Selecting All Groups will override Group selection',
    SelectingGroupTooltip: 'Selecting a Group will override All Groups selection',
  },
  Components: {
    AttributeListTest: 'AttributeList Test',
    ConfirmDelete: 'Do you really want to delete?',
    ConfirmDeleteIPLists: 'Are you sure you want to remove the selected IP list(s)?',
    ConfirmLeavePage: 'Are you sure you want to leave the page?',
    Dialog: 'Dialog',
    FieldsetTitle: 'Fieldset Title',
    InviteAnotherUser: 'Invite Another User',
    Modal: 'Modal',
    PleaseEnterFullName: 'Please enter your full name',
    PleaseEnterTimeZone: 'Please enter your time zone information',
    PleaseLogOut: 'Please Log out',
    PleaseLogOutBeforeCompleteInvite: 'Please log out before attempting to complete an invite for another user.',
    PleaseSelectRuleset: 'Please select a Ruleset',
    ProvideRulesetName: 'Please provide a Ruleset name',
    RulesetNameExists: 'A Ruleset already exists with that name',
    Transmission: 'Transmission',
    TestEmailUnableToSend: 'The test email was unable to be sent.',
    TextLabel: 'Text Label',
    TextareaLabel: 'Textarea Label',
    ThreeSixtyWorkloads: '360 Workloads',
  },
  Connectivity: {
    BothEntitiesCantBeIP: 'Both entities cannot be IP Addresses',
    CheckRules: 'Check Rules',
    ConsumerError: 'Consumer error',
    ConsumerMissing: 'Missing Consumer',
    Example22TCP: 'Example: 22 TCP',
    PortAndProtocol: 'Provider Port and Protocol',
    ProviderError: 'Provider error',
    ProviderProvider: 'Missing Provider',
    RulesAllowConnection: 'The Rules below allow this connection',
    RulesAllowConnectionFail: 'No Rules exist to allow this connection',
    RulesAllowConnectionFailMessage: 'Change the criteria or add a Rule to allow this connection',
    ServiceError: 'Service error',
    SelectWorkloadContainerWorkloadOrIP: 'Select Workload, Container Workload, or IP Address',
    VerifyRulesExist:
      'Verify if Rules exist that allow connections between Workloads, Container Workloads,  or IP addresses',
    VerifyRulesExistedge: 'Verify if Groups exist that allow connections between Workloads or IP addresses',
  },
  Containers: {
    PlatformVersion: 'Platform Version',
  },
  ContainerWorkloads: {
    All: 'All Container Workloads',
    View: 'View Container Workloads',
    ContainerID: 'Container ID',
    StartedAt: 'Started At',
    StoppedAt: 'Stopped At',
    ByCount: '{count, plural, =1 {Container Workload} other {Container Workloads}}',
    WithCount: '{count} {count, plural, =1 {Container Workload} other {Container Workloads}}',
    HostIpAddress: 'Host IP Address',
    Total: 'Total Container Workloads',
  },
  CoreServices: {
    CoreServices: 'Core Services',
    CoreService: 'Core Service',
    CoreServiceSettings: 'Core Services Settings',
    Accepted: 'Accepted',
    Rejected: 'Rejected',
    RecommendedCount: 'Recommended ({count})',
    AcceptedCount: 'Accepted ({count})',
    RejectedCount: 'Rejected ({count})',
    DetectionModel: 'Detection Model',
    Confidence: '{confidence}% confidence',
    PortMatch: 'Port Matching',
    TrafficFlows: 'Port-based ML',
    ProcessNames: 'Process-based ML',
    PortMatchInformation: 'Rule-based model based on connections to specific ports',
    TrafficFlowsInformation: 'Machine learning model based on connections to specific ports',
    ProcessNamesInformation: 'Machine learning model based on processes running on the server',
    ScannerDetectionInformation: 'Algorithmic model based on traffic patterns associated with scanner activity.',
    Peers: 'Peers',
    Current: 'Current',
    AcceptedCoreService: '{name} is accepted as a provider of the {service} core service',
    RejectedCoreService: '{name} is no longer recommended as a provider of the {service} core service',
    UnknownIP: 'Unknown IP',
    RecommendedNotification:
      'The {name} core service was detected on the following servers on {date}. Click to Accept or Reject the recommendations.',
    RecommendedNotificationNoTimestamp:
      'The {name} core service was detected on the following servers. Click to Accept or Reject the recommendations.',
    AcceptedNotification:
      'Edit Labels to distinguish core service providers from other workloads. Click "Reject" if a server no longer provides the core service.',
    RejectedNotification: 'If a server is rejected in error, click "Accept" to move it to the Accepted tab.',
    Reject: 'Reject',
    FollowUp: 'Follow Up',
    FollowUpDescription: 'Describe why a decision cannot be made, what further steps need to be taken, and by whom.',
    RejectDescription: 'Describe why this recommendation is rejected.',
    AcceptDescription: 'Describe why this recommendation is accepted',
    PeerCountSubtitle: 'Top Detected Processes by Number of Ports',
    PeerCountInfo: 'Top Detected Processes',
    PeerCountNoTraffic: 'No Traffic to Display',
    AcceptInfoMsg: 'An unmanaged workload is created automatically if the accepted server is an unknown IP address.',
    ErrorFetchingWorkload: 'Workload does not exist.',
    EditLabelsCheckBoxLabel:
      'Save edited Role and Application Labels as the default label assignments for workloads providing the {name} Service',
    Default: '(Default)',
    UseCurrentLabel: 'Use current {type} Label',
    Detection: 'Core Services Detection',
    DisabledDetails: 'Do not analyze traffic data for Core Service Detection',
    EnabledDetails: 'Analyze traffic data every two weeks to identify servers that provide Core Services',
    DefaultSettings: 'Default Settings',
    EditDefaultSettingsBanner:
      'These are default label assignments for workloads providing the {name} Service. Editing the default setting does not affect previously edited workload Labels.',
    EditDefaultSettingsLabel: 'Edit Default Settings',
    RejectAcceptedBanner:
      'It is recommended that you edit or remove Role and Application Labels assigned to the rejected server.',
    SettingsNotification:
      'The accuracy of Core Service Detection improves with the amount of traffic data collected. A minimum of 14 days is recommended for optimal results.',
    EmptyServices: 'No Services to Display',
    AcceptedError: 'An unexpected error occurred. The server could not be accepted as a provider of the Core Service',
    RejectAcceptedError:
      'An unexpected error occurred. The server could not be rejected as a provider of the Core Service',
    SetDefaultSuggestedError:
      'An unexpected error occurred. Could not set default suggested labels for this Core Service',
    EditLabelsError: 'An unexpected error occurred. Could not edit the labels for this Core Service',
    LastRunAtNotification: 'Core Service Detection algorithm was last run at {timestamp}',
    NoLastRunAtNotification: 'Core service detection algorithm has not run yet.',
    ScannerNotification:
      '{scannerStatus, select, true {Scanner Detection is {scannerDetectionSettings}.} false {Scanner Detection algorithm was last run at {scannerTimestamp}.}}',
    NoScannerNotification: 'Scanner detection algorithm has not run yet.',
    ScannerDetection: 'Traffic Pattern',
    ConfirmRejectAccepted: 'Are you sure you want to reject this Recommendation?',
    UnknownProcess: 'Unknown Process',
    CoreServicesDetector: 'Core Services Detector',
    RecommendedCoreService: 'Recommended Core Service',
    AcceptedCoreServices: 'Accepted Core Service',
    RejectedCoreServices: 'Rejected Core Service',
  },
  Dashboard: {
    Title: 'Title',
    AddWidget: 'Add Widget',
    DragNewWidget: 'Drag a widget to add it into the dashboard',
    LastSeven: 'Last 7 Days',
    LastFourteen: 'Last 14 Days',
    LastThirty: 'Last 30 Days',
    ActivePolicy: 'Active Policy',
    BlockedOrPotentiallyBlockedFlow: 'Blocked / Potentially Blocked Flow',
    DraftPolicyChanges: 'Draft Policy Changes',
    VenCountByEnforcementMode: 'Total VENs by Enforcement Mode',
    VenCountByOS: 'Active VENs by OS',
    VenCountByStatusOrHealth: 'Total VENs by Status / Health',
    VenCountByVersion: 'Active VENs by Version',
  },
  Landing: {
    InviteUsers: 'Invite Users',
    ViewDocumentation: 'View Documentation',
  },
  Error: {
    Unknown: 'Unknown Error',
    NetworkTimeout: 'The selected Illumination map is generating. Wait a few minutes, then refresh the page.',
    AppGroupTimeout: 'The App Group configuration is calculating. Wait a few minutes, then refresh the page.',
    DataStoreUnavailable: 'Traffic Datastore is in maintenance mode',
    ProvideErrorId: 'Provide this Error-ID when contacting your support team',
  },
  Events: {
    AddEventForwarding: 'Add Event Forwarding',
    AddRepository: 'Add Repository',
    Address: 'Address',
    AllowedTraffic: 'Allowed Traffic',
    APIEndpoint: 'API Endpoint',
    APIMethod: 'API Method',
    AuditableEvents: 'Auditable Events',
    ByEvent: 'by Event',
    ByGenerated: 'by Generated',
    BySeverity: 'by Severity',
    ByTimestamp: 'by Timestamp',
    CEF: 'CEF',
    Changes: 'Changes',
    ChangeType: 'Change Type',
    Configuration: 'Event Configuration',
    DaysCount: '{count, plural, =1 {# day} other {# days}}',
    DaysSetting: '{count, plural, =1 {day} other {days}}',
    Deactivated: 'Deactivated',
    DuplicateError: 'Repository update error',
    DuplicateMessage: 'A repository with the same address, port and protocol already exists',
    EditEventForwarding: 'Edit Event Forwarding',
    EditEventInformation: 'Changes to settings may take up to 5 minutes to take effect',
    EditRepository: 'Edit Repository',
    EncryptedDesc: 'Forwarded event data is encrypted using TLS',
    EmptyMessage: 'No matching Events to display',
    EventForwarding: 'Event Forwarding',
    Format: 'Event Format',
    Forwarding: 'Forwarding',
    Hostname: 'Hostname: {hostname}',
    HTTPStatusCode: 'HTTP Status Code',
    InvalidAddress: 'Invalid address',
    LandingMessage:
      'Select filter parameters then click "Go" to display filtered events. <br />Click "Go" without filter parameters to display most recent events. <br />Click one of preset filters below to display filtered events.',
    LastPairedAt: 'Last paired at',
    LEEF: 'LEEF',
    LocalRepositoryDesc: 'Forward events to local syslog service',
    ManagedSince: 'Managed Since',
    NotEncryptedDesc: 'Forwarded event data is not encrypted',
    OrganizationalEvents: 'Organizational Events',
    PotentiallyBlockedTraffic: 'Potentially Blocked Traffic',
    Process: 'Process: {process}',
    QuickFilter: 'Quick Filter',
    QuickFilterInfo: 'Quick filter is applied to the most recent 1000 events',
    QuickFilterClear: 'Clear filter',
    RemoveRepository: 'Remove Repository',
    Repository: 'Repository',
    RepositoryDeleteConfirm:
      'Are you sure you want to delete the selected {count, plural, =1 {repository} other {# repositories}}?',
    ResourceChange: 'Resource Change',
    RetentionPeriod: 'Retention Period',
    RetentionPeriodDesc: 'Audit events older than this are purged',
    RulesetHref: 'Ruleset Href',
    SecurityPrincipalHref: 'Security Principal Href',
    SecurityPrincipalType: 'Security Principal Type',
    ServiceAccount: 'Service Account',
    ServiceAccountHref: 'Service Account Href',
    Severity: 'Event Severity',
    SeverityDesc: 'Only audit events of this severity or higher are saved',
    StatusLogs: 'Status Logs',
    SystemHealthMessages: 'System Health Messages',
    TLS: 'TLS',
    TlSConfigureMessage: 'Once TLS is configured, TLS setting cannot be modified',
    TLSHelp: 'Please create a new repository, if TLS needs to be disabled',
    Types: {
      Organizational: 'Organizational',
    },
    UpdateError: 'Event setting update error',
    Config: {
      Errors: {
        Severity: 'Needs to match one of these {severity}',
        Format: 'Needs to be one of {format}',
        TLS: 'Needs to be {tls}',
        PCE: 'Needs to be one of these {pce}',
      },
    },
    SyslogDestUpdateError: 'Syslog destination settings update error',
    ValidTLS: "Ensure that TLS peer's server certificate is valid",
    Yesterday: 'Yesterday',
  },
  EventUtils: {
    AdminRecalc: 'Admin forced recalculation of policy',
    CreateGroup: 'Create a group',
    ModifyGroup: 'Modify a group by ID',
    DeleteGroup: 'Group Deleted',
    DenyRuleCreated: 'Deny rule created',
    DenyRuleDeleted: 'Deny rule deleted',
    DenyRuleUpdated: 'Deny rule updated',
    CorporateIpsSettingsUpdate: 'Set the corporate IPs of the org',
    DeletePairingProfileKeys: 'Delete all pairing keys of a pairing profile by ID',
    VENCloneActivated: 'VEN clone activated',
    VENCloneDetected: 'VEN clone detected',
    VENFetchedPolicy: 'VEN fetched policy',
    VENMaintenanceTokenGenerated: 'VEN tampering protection token has been generated',
    VENFirewallStateTableThresholdExceeded: "VEN's firewall statetable's threshold has been exceeded",
    VENGoodbye: 'Workload shutdown',
    VENHostUnknown: 'VEN host: unknown',
    VENHasTooManyInterfacesReported: 'VEN reported more interfaces than the PCE supports',
    VENMachineIdentifiers: 'VEN machine identifiers updated',
    VENMissingHeartbeatAfterUpgrade: 'VEN missing heartbeat after upgrade',
    VENMissedHeartbeatsCheck: 'VEN missed a few heartbeats',
    VENOfflineCheck: 'VEN marked offline',
    VENPaired: 'VEN paired',
    VENPropertiesUpdated: 'VEN properties updated',

    // Authentication
    VENAuthenticationMode: 'VEN in authentication recovery mode',
    VENsAuthenticationMode: 'VENs in authentication recovery mode',
    VENSelfSignedExpired: 'VEN self signed certificate expired',
    VENSelfSignedGracePeriod: 'VEN self signed certificate in grace period',
    VENUnauthenticatedClone: 'VEN unauthenticated clone detecte',
    VENRotateAuthSecret: 'VEN rotate authentication secret',

    // VEN Upgrade
    VENRequestUpgrade: 'VEN request upgrade',
    VENUpgradeRequested: 'VEN upgrade requested.',
    VENUpgradeSuccessful: 'VEN Upgrade Successful',
    VENUpgradeTimeOut: 'VEN Upgrade timed out.',
    VENClearConditions: 'Clear VEN conditions',
    VENSetDefaultVersion: 'Set a default VEN version',
    VENProcessFailure: 'VEN process failure',
    VENRefreshToken: 'VEN refreshed token',
    VENServiceUnavailable: 'VEN reported a service not running',

    // VEN maintenance token request
    VENMaintenanceTokenRequested: 'VEN tampering protection token requested',

    AccessRestrictionCreate: 'Create access restriction',
    AccessRestrictionDelete: 'Delete access restriction',
    AccessRestrictionUpdate: 'Update access restriction',

    SupportReportUpload: 'Support report',
    SupportReportRequestDelete: 'Support report request deleted',
    SupportReportRequestCreate: 'Support report request created',
    PCESupportReportRequestDelete: 'PCE support report request deleted',
    PCESupportReportRequestCreate: 'PCE support report request created',
    VENSuspended: 'VEN suspended',
    VENTampering: 'VEN firewall tampered',
    VENUnpaired: 'VEN unpaired',
    VENUpdateInteractiveUsers: 'VEN interactive users updated',
    VENUpdateIpTables: 'VEN updated existing iptables href',
    VENUpdateRunningContainers: 'VEN updated running containers',
    VENUploadedIptablessRules: 'VEN uploaded iptables rules',
    VENCompatibilityCheckReportUpdate: 'VEN compatibility check report updated',
    VENsUnpaired: 'VENs unpaired',
    APIKeyDeleted: 'API key deleted',
    APIKeyUpdated: 'API key updated',
    APIKeyCreated: 'API key created',
    AuthenticationSettingsUpdated: 'Authentication settings updated',
    VirtualServiceCreated: 'Virtual Service created',
    VirtualServiceDeleted: 'Virtual Service deleted',
    VirtualServiceUpdated: 'Virtual Service updated',
    VirtualServicesCreated: 'Virtual services bulk created',
    VirtualServiceNotCreated: 'Virtual Service not created',
    VirtualServicesUpdated: 'Virtual services bulk updated',
    ClusterCreated: 'Cluster created',
    ClusterDeleted: 'Cluster deleted',
    ClusterUpdated: 'Cluster updated',
    ContainerWorkloadInvalidPairingConfig: 'Container workload invalid pairing config',
    ContainerWorkloadUpdateServices: 'Container Workload Update Services',
    ContainerWorkloadNotCreated: 'Container workload not created',
    ContainerWorkloadProfileCreated: 'Container workload profile created',
    ContainerWorkloadProfileDeleted: 'Container workload profile deleted',
    ContainerWorkloadProfileUpdated: 'Container workload profile updated',
    ContainerClusterCreated: 'Container cluster created',
    ContainerClusterDeleted: 'Container cluster deleted',
    ContainerClusterUpdated: 'Container cluster updated',
    ContainerClusterDuplicateMachineID: 'Container cluster duplicate machine ID',
    ContainerClusterRegionMismatch: 'Container cluster region mismatch',
    DatabaseCleanupStarted: 'Database cleanup started',
    DatabaseCleanupCompleted: 'Database cleanup completed',
    DeleteExpireServiceAccountApiKeys: 'Delete expired service account API keys',
    DestinationCreated: 'Syslog destination created',
    DestinationDeleted: 'Syslog destination deleted',
    DestinationUpdated: 'Syslog destination updated',
    DomainCreated: 'Domain created',
    DomainDeleted: 'Domain deleted',
    DomainUpdated: 'Domain updated',
    EnforcementPointCreate: 'Enforcement point created',
    EnforcementPointDelete: 'Enforcement point deleted',
    EnforcementPointsSet: 'Enforcement point collection updated',
    EnforcementPointUpdate: 'Enforcement point updated',
    EssentialServiceRulesUpdated: 'Essential Service Rules updated',
    EventConfigUpdated: 'Event configuration updated',
    EventPruningCompleted: 'Event pruning completed',
    FirewallTampered: 'Firewall tampered',
    GlobalPolicySettingsUpdated: 'Global policy settings updated',
    HardLimitExceeded: 'Object creation hard limit exceeded',
    HardLimitRecoveryCompleted: 'Hard limit recovery completed',
    InternetNetworkCreated: 'Internet network created',
    IPListDeleted: 'IP list deleted',
    IPListUpdated: 'IP list updated',
    IPListCreated: 'IP list created',
    IPListsDeleted: 'IP lists deleted',
    IPTablesRuleDeleted: 'IP tables rule deleted',
    IPTablesRuleUpdated: 'IP tables rule updated',
    IPTablesRuleCreated: 'IP tables rules created',
    JobDeleted: 'Job Deleted',
    LabelCreated: 'Label created',
    LabelDeleted: 'Label deleted',
    LabelUpdated: 'Label updated',
    LabelsDeleted: 'Labels deleted',
    LabelDimensionCreated: 'Label dimension created',
    LabelDimensionUpdated: 'Label dimension updated',
    LabelDimensionDeleted: 'Label dimension deleted',
    LabelGroupDeleted: 'Label group deleted',
    LabelGroupUpdated: 'Label group updated',
    LabelGroupCreated: 'Label group created',
    LDAPConfigCreated: 'LDAP config created',
    LDAPConfigDeleted: 'LDAP config deleted',
    LDAPConfigUpdated: 'LDAP config updated',
    LDAPConfigVerifyConnection: 'LDAP config verify connection',
    LicenseDeleted: 'License deleted',
    LicenseUpdated: 'License updated',
    LocalUserAuthenticated: 'Local user authenticated',
    LocalUserProfileCreated: 'Local user profile created',
    LocalUserProfileDeleted: 'Local user profile deleted',
    LocalUserReinvited: 'Local user reinvited',
    LocalUserPasswordChanged: 'Local user password changed',
    LoginUserSessionCreated: 'Login user session created',
    LoginUserSessionTerminated: 'Login user session terminated',
    LostVENFound: 'Lost VEN found',
    NetworkCreated: 'Network created',
    NetworkUpdated: 'Network updated',
    NetworkDeleted: 'Network deleted',
    NetworkFuncCntlrActivated: 'Network function controller activated',
    NetworkFuncCntlrDeactivated: 'Network function controller deactivated',
    NetworkFuncCntlrDiscoveryComp: 'Network function controller discovery completed',
    NetworkFuncCntlrPolicyStatusUpdated: 'Network function controller policy status updated',
    NetworkFuncCntlrSLBStateUpdated: 'Network functional controller load balancer state updated',
    NetworkDeviceAckEnforcementInstructionsApplied: 'Network device Ack enforcement instructions Applied',
    NetworkDeviceAssignWorkload: 'Network device assign workload',
    NetworkDeviceUpdateWorkload: 'Network device update workload',
    NetworkDeviceDeleteWorkload: 'Network device delete workload',
    NetworkDeviceCreate: 'Network device create',
    NetworkDeviceDelete: 'Network device delete',
    NetworkDeviceUpdate: 'Network device update',
    NetworkDeviceWorkloadsBulkCreate: 'Integration workload bulk create',
    NetworkDeviceWorkloadsBulkUpdate: 'Integration workload bulk update',
    NetworkDevicesAckMultiEnforcementInstructionsApplied: 'Network devices Ack multi-enforcement instructions applied',
    NetworkEndpointCreate: 'Network endpoint create',
    NetworkEndpointDeleted: 'Network endpoint delete',
    NetworkEndpointUpdate: 'Network endpoint update',
    NetworkEnforcementNodeActivate: 'Network Enforcement Node activate',
    NetworkEnforcementNodeRequestPolicy: 'Network Enforcement Node request policy',
    NetworkEnforcementNodeDeactivated: 'Network Enforcement Node deactivated',
    NetworkEnforcementNodePolicyAck: 'Network Enforcement Node Policy Ack',
    NetworkEnforcementNodeMissedHeartBeatsCheck: 'Network Enforcement Node missed heartbeats check',
    NetworkEnforcementNodeMissedHeartBeats: 'Network Enforcement Node missed heartbeats',
    NetworkEnforcementNodeDegraded: 'Network Enforcement Node Degraded',
    NetworkEnforcementNodeClearConditions: 'Clear Network Enforcement Node conditions',
    NetworkEnforcementNodeUpdate: 'Network Enforcement Node update',
    OrgApiKeyDelete: 'API key deletion',
    OrgInfoCreated: 'Organization information created',
    OrgInfoUnmanaged: 'Organization changed to unmanaged',
    OrgInfoUpdated: 'Organization information updated',

    // Org migration
    OrgExtractCreate: 'Org extract created for Migration',
    OrgExtractUpdate: 'Org extract updated for Migration',
    OrgExtractDelete: 'Org extract deleted for Migration',
    OrgLoadCreate: 'Org load created for Migration',
    OrgLoadUpdate: 'Org load updated for Migration',
    OrgLoadDelete: 'Org load deleted for Migration',

    PairingProfileDeleted: 'Pairing profile deleted',
    PairingProfileUpdated: 'Pairing profile updated',
    PairingProfileCreated: 'Pairing profile created',
    PairingProfilesDeleted: 'Pairing profiles deleted',
    PairingProfileKeyGenerated: 'Pairing profile pairing key generated',
    PasswordPolicyCreated: 'Password policy created',
    PasswordPolicyDeleted: 'Password policy deleted',
    PasswordPolicyUpdated: 'Password policy updated',
    PCESessionCreated: 'PCE user session created',
    PCESessionTerminated: 'PCE user session terminated',
    RADIUSAuthChallenge: 'RADIUS auth challenge issued',
    RADIUSConfigDeleted: 'RADIUS configuration deleted',
    RADIUSConfigUpdated: 'RADIUS configuration updated',
    RADIUSConfigSecretVerified: 'RADIUS config shared secret verified',
    RADIUSConfigsCreated: 'RADIUS configurations created',
    RBACAuthSecPrincipalDeleted: 'RBAC auth security principal deleted',
    RBACAuthSecPrincipalUpdated: 'RBAC auth security principal updated',
    RBACAuthSecPrincipalCreated: 'RBAC auth security principal created',
    RBACPermissionDeleted: 'RBAC permission deleted',
    RBACPermissionUpdated: 'RBAC permission updated',
    RBACPermissionCreated: 'RBAC permission created',
    RemoteSyslogReachable: 'PCE external audit server is reachable',
    RemoteSyslogUnreachable: 'PCE external audit server is not reachable',
    RequestAuthenticationFailed: 'Request authentication failed',
    RequestAuthorizationFailed: 'Request authorization failed',
    RequestInternalServer: 'API request failed due to internal server error',
    RequestInvalid: 'Request Invalid',
    RequestServiceUnavailable: 'API request failed due to unavailable service',
    RequestUnknownServer: 'API request failed due to unknown server error',
    ResourceCreated: 'Login resource created',
    ResourceDeleted: 'Login resource deleted',
    ResourceUnmanaged: 'Login resource changed to unmanaged',
    ResourceUpdated: 'Login resource updated',
    RulesetCreated: 'Rule set created',
    RulesetDeleted: 'Rule set deleted',
    RulesetUpdated: 'Rule set updated',
    RulesetsDeleted: 'Rule sets deleted',
    VENUnsuspended: 'VEN Unsuspended',
    SAMLACSUpdated: 'SAML assertion consumer service  updated',
    SAMLConfigCreated: 'SAML configuration created',
    SAMLConfigDeleted: 'SAML configuration deleted',
    SAMLConfigUpdated: 'SAML configuration updated',
    SAMLConfigPceSigningCert: 'Saml config pce signing cert has been generated/rotated',
    SAMLSPCreated: 'SAML Service Provider created',
    SAMLSPDeleted: 'SAML Service Provider deleted',
    SAMLSPUpdated: 'SAML Service Provider updated',
    SecPoliciesCreated: 'Security policies created',
    SecPolicyPendingDeleted: 'Pending security policy deleted',
    SecPolicyRestored: 'Security policy restored',
    SecPolicyRestoredNotification: 'Restored security policy version',
    SecPolicyRuleCreated: 'Security policy rules created',
    SecPolicyRuleDeleted: 'Security policy rule deleted',
    SecPolicyRuleUpdated: 'Security policy rule updated',
    SecPolicyRulesDeleted: 'Security policy rules deleted',
    SecPrincipalDeleted: 'RBAC security principal deleted',
    SecPrincipalUpdated: 'RBAC security principal updated',
    SecPrincipalsBulkCreated: 'RBAC security principals bulk created',
    SecPrincipalsCreated: 'RBAC security principals created',
    SecureConnectGatewayCreated: 'Secure connect gateways created',
    SecureConnectGatewayDeleted: 'Secure connect gateway deleted',
    SecureConnectGatewayUpdated: 'Secure connect gateway updated',
    ServiceAccountCreated: 'Service Account created',
    ServiceAccountDeleted: 'Service Account deleted',
    ServiceAccountUpdated: 'Service Account updated',
    ExpireServiceAccountApiKeys: 'Service account API keys expiration successful',
    ServiceBindingCreated: 'Service binding created',
    ServiceBindingDeleted: 'Service binding deleted',
    ServiceBindingsDeleted: 'Service bindings deleted',
    ServiceCreated: 'Service created',
    ServiceDeleted: 'Service deleted',
    ServiceUpdated: 'Service updated',
    ServicesDeleted: 'Services deleted',
    SettingsUpdate: 'Settings Update',
    SetupActivePceCreate: 'Active PCE created',
    SetupStandbyPceCreate: 'Standby PCE created',
    SoftLimitExceeded: 'Object creation soft limit exceeded',
    SourceIP: 'Source IP',
    SLBDeleted: 'Server load balancer deleted',
    SLBUpdated: 'Server load balancer updated',
    SLBCreated: 'Server load balancers created',
    TLSChannelEstablished: 'TLS channel established',
    TLSChannelTerminated: 'TLS channel terminated',
    TrafficCollectorSettingCreate: 'Traffic Collector Setting Created',
    TrafficCollectorSettingDelete: 'Traffic Collector Setting Deleted',
    TrafficCollectorSettingUpdate: 'Traffic Collector Setting Updated',
    TrustedProxyIpsUpdated: 'Trusted Proxy IPs Updated',
    TempTableAutocleanupCompleted: 'Temp Table Auto Cleanup Completed',
    TempTableAutocleanupStartup: 'Temp Table Auto Cleanup Started',
    UserAuthTokenCreated: 'User auth token created',
    UserAcceptInvitation: 'User accepted invitation',
    UserCSRFValidationFailed: 'User CSRF validation failed',
    UserCreated: 'User created',
    UserDeleted: 'User deleted',
    UserInvited: 'User invited',
    UserLogin: 'User login',
    UserLoginFailed: 'User login failed',
    UserLogout: 'User logout',
    UserPasswordChanged: 'User password changed',
    UserPasswordChangedAt: 'User password changed at',
    UserPasswordChangeFailure: 'User password change failed',
    UserPasswordComplexityNotMet: 'User password complexity not met',
    UserPasswordExpired: 'User expired password used',
    UserPasswordResetCompleted: 'User password reset completed',
    UserPasswordResetRequested: 'User password reset requested',
    UserPasswordUpdated: 'User password updated',
    UserResetPassword: 'User reset password',
    UserSignIn: 'User session created',
    UserSignOut: 'User session terminated',
    UserUpdated: 'User updated',
    UserSynced: 'User synced',
    UserVerifyMFA: 'Verify two factor authentication code',
    UserInactiveLocalUsersPruneCheck: 'User inactive local users prune check',
    LocalUserDeletedForInactivity: 'Local user account deleted for inactivity',
    LocalUserUnableToDeleteForInactivity: 'Unable to Delete Inactive Local User',
    VENReleaseDeleted: 'VEN release deleted',
    VENReleaseDeployed: 'VEN release deployed',
    VENSettingsUpdate: 'VEN settings updated',
    VENPolicyDeployFailed: 'VEN policy deploy failed',
    VENPolicyDeployedSuccessfully: 'VEN policy deployed successfully',
    VirtualServerDeleted: 'Virtual server deleted',
    VirtualServerUpdated: 'Virtual server updated',
    VirtualServersCreated: 'Virtual servers created',
    VulnerabilityUpdated: 'Vulnerability updated',
    VulnerabilityDeleted: 'Vulnerability deleted',
    VulnerabilityCreated: 'Vulnerability created',
    VulnerabilityReportDeleted: 'Vulnerability report deleted',
    VulnerabilityReportUpdated: 'Vulnerability report updated',
    WorkloadApplyPolicy: 'Workload apply pending policy',
    WorkloadsBulkCreated: 'Workloads bulk created',
    WorkloadsBulkDeleted: 'Workloads bulk deleted',
    WorkloadsBulkUpdated: 'Workloads bulk updated',
    WorkloadFlowReportingFreq: 'Workload flow reporting frequency changed',
    WorkloadFlowReportingFrequencyUpdated: 'Workload flow reporting frequency updated',
    WorkloadLabelsApplied: 'Workload labels applied',
    WorkloadInterfaceDeleted: 'Workload interface deleted',
    WorkloadInterfaceUpdated: 'Workload interface network created',
    WorkloadInterfacesCreated: 'Workload interfaces created',
    WorkloadInterfacesUpdated: 'Workload interfaces updated',
    WorkloadsLabelsRemoved: 'Workloads labels removed',
    WorkloadNetworkRedetect: 'Workload network redetected',
    WorkloadOnline: 'Workload came back online, after being offline',
    WorkloadOfflineVenGoodbye: 'Workload offline after ven goodbye',
    WorkloadDuplicateInterfaceReported: 'Workload duplicate interface reported',
    WorkloadPartialPolicyDelivered: 'Workload partial policy delivered',
    WorkloadPolicyRecalc: 'Workload policy recalculated',
    WorkloadServiceReportsUpdated: 'Workload service reports updated',
    WorkloadSettingsUpdated: 'Workload settings updated',
    WorkloadsUnpaired: 'Workloads unpaired',
    WorkloadMismatchedInterfaces: 'Workload updated its mismatched interfaces',
    WorkloadUpdated: 'Workload updated',
    WorkloadsUpdated: 'Workloads updated',
    WorkloadNatRulesPresent: 'Workload has NAT rules present',
    EnforcementBoundariesRuleCreate: 'Enforcement Boundaries rule created',
    EnforcementBoundariesRuleUpdate: 'Enforcement Boundaries rule updated',
    EnforcementBoundariesRuleDelete: 'Enforcement Boundaries rule deleted',
    ContainerClusterChangeRate: 'Container Cluster Change Rate',
    LoginMSPTenantCreated: 'Login MSP tenant created',
    LoginMSPTenantDeleted: 'Login MSP tenant deleted',
    LoginMSPTenantUpdated: 'Login MSP tenant updated',
    OrgUnpairVENs: 'Unpair all VENs from org',
    OrgsVENCount: 'Get active VEN count for a list of orgs',
  },
  InstantSearch: {
    SearchByFilter: 'Search {filter} by name',
    TabToFilter: 'Tab to filter',
    RecentSearches: 'Recent Searches',
    ClearAll: 'Clear All',
  },
  Version: {
    ProvisionedBy: 'Provisioned By',
    ProvisionedOn: 'Provisioned On',
    WorkloadsAffected: 'Workloads Affected',
    WorkloadsAffectedCount: '{count, plural, =1 {# Workload Affected} other {# Workloads Affected}}',

    Detail: {
      NoRulesInScope: 'None of the provisioned objects in this Policy Version are within your assigned scopes',
      Version: 'Version #{version}',
      VersionVsPrevious: 'Version #{currentVersion} vs. Version #{previousVersion}',
      WorkloadsAffected: '{count, plural, =1 {Workload Affected} other {Workloads Affected}}',
      TallyLabel: {
        ChangedIPLists: '{count, plural, =1 {Changed IP List} other {Changed IP Lists}}',
        ChangedIPRanges: '{count, plural, =1 {Changed IP Range} other {Changed IP Ranges}}',
        ChangedVirtualServices: '{count, plural, =1 {Changed Virtual Service} other {Changed Virtual Services}}',
        ChangedLabelGroups: '{count, plural, =1 {Changed Label Group} other {Changed Label Groups}}',
        ChangedRulesets: '{count, plural, =1 {Changed Ruleset} other {Changed Rulesets}}',
        ChangedGroups: '{count, plural, =1 {Changed Group} other {Changed Groups}}',
        ChangedSecureConnect:
          '{count, plural, =1 {Changed Secure Connect Gateway} other {Changed Secure Connect Gateways}}',
        ChangedServices: '{count, plural, =1 {Changed Service} other {Changed Services}}',
        ChangedSettings: '{count, plural, =1 {Changed Setting} other {Changed Settings}}',
        ChangedOrganizationalPolicy: 'Changed Organizational Policy',
        ChangedVirtualServers: '{count, plural, =1 {Changed Virtual Server} other {Changed Virtual Servers}}',
        ChangedEnforcementBoundaries:
          '{count, plural, =1 {Changed Enforcement Boundaries Rule} other {Changed Enforcement Boundaries Rules}}',
      },
    },
    Restore: {
      PastVersionsToBeRevertedTitle: 'Past versions that will be reverted by restoring version {version}',
      SaveAsDraft: 'Save as Draft',
      SaveAsDraftTooltipContent: 'Save group changes',
      SaveAndProvisionTooltipContent: 'Save group changes and confirm provisioning details',
      UnableToSaveToDraft: 'Unable to save to draft',
      ExistingDraftMessage: 'Existing objects in the draft policy queue.',
      ExistingDraftAction: 'Revert draft policies before restoring a prior version.',
    },
  },
  Help: {
    Desc: {
      AccessRestrictions:
        'As an admin, you can restrict access to API Keys that are used to automate the PCE management tasks. Restrictions are based on IP addresses and are used to block API requests coming in from non-allowed IP addresses.',
      Rulesets: 'A collection of Rules that define allowed communications between Workloads based on their Scope.',
      Scope:
        'Applies Rules to a collection of Workloads that are identified by Label type (Application, Environment, & Location).',
      RoleLabel: 'Defines the function of the Workload (for example, Web or Database).',
      ApplicationLabel: 'Identifies the application instance of the Workload.',
      Status: 'Displays whether the Rule is enabled or disabled.',
      EnvironmentLabel: 'Identifies the network environment of the Workload (such as Staging or Production).',
      LocationLabel: 'Identifies the physical location of the Workload (such as AWS or US).',
      Providers:
        'Resource (such as a Workload, Virtual Service, or IP list) that processes requests from other resources.',
      Source: 'Workloads, services, or IP ranges that can initiate a connection to a source.',
      ProvidingService:
        'Resource defined by a Rule that allows specific types of communication (such MySQL) between providers and consumers.',
      Consumers:
        'Resource (such as a Workload, Virtual Service, or IP list) that can initiate connections to other resources or access a specified Service.',
      Destination: 'Workloads, services, or IP ranges that can initiate a connection to a destination.',
      SecureConnect: 'Dynamically encrypts Workload communications using IPsec.',
      DuplicateRuleset: 'Copy an existing Ruleset.',
      IntraScopeRules: 'Allow communication within a specific Scope.',
      ExtraScopeRules: 'Allow communication between applications.',
      CustomiptablesRules: 'Preserve any iptables configured on Linux hosts and add them to your policy as a Rule.',
      DraftVersion: 'Displays the proposed changes that must be provisioned before they are applied to the policy.',
      ActiveVersion: 'Displays the policy currently enforced by the PCE.',
      NoHelp: 'There is no help for this page. Click the link below to visit the support portal.',
      ReportedView: 'Shows current policy coverage as reported by Workloads.',
      IlluminationMap: 'Visualizes traffic flows between Workloads.',
      Enforcement:
        'Three states (Idle, Visibility Only, or Enforced) that determine whether the policy is applied and at what level.',
      ConnectedGroup: 'A collection of Workloads that communicates with another collection of Workloads.',
      DraftView: 'Previews expected policy coverage for unprovisioned changes.',
      TrafficLines: 'Represents traffic that is blocked (red) or allowed (green) or unknown (gray).',
      AppGroup: 'Traffic organized as a collection of Labels.',
      AppGroupList: 'View and edit App Groups.',
      ConsumingAppGroups: 'Applications that use Services and can initiate connections.',
      ProvidingAppGroups: 'Applications that provide Services and cannot initiate connections.',
      Services:
        'Ports, protocols, or processes discovered by the VEN or defined by the user that specify the Service type and communication methods used by Workloads.',
      IPRange: 'Set of IP ranges used to define a allowlist.',
      IPList: 'Set of IP addresses, an IP range, or CIDR blocks used to define a allowlist.',
      ProvisionStatus: 'Displays if changes have been made but not provisioned.',
      UserGroup:
        'Collection of users with access to the PCE that can be mapped to an Active Directory or used to control outbound access for specific Workloads.',
      SID: 'Security identifier, a unique identification associated with each user or user group in the Active Directory.',
      VirtualServers: 'A server shared by multiple owners.',
      SecuritySettings: 'Organization-wide configuration for features such as DHCP.',
      Provision:
        'Enacting any changes made to policy objects (Rulesets, IP Lists, Services, Label Groups, and security settings) by recalculating policy on the PCE and transmitting the changes to all VENs.',
      ProvisionEdge: 'Implements any changes made to policy objects.',
      Revert: 'Undoing any provisionable changes before they are provisioned.',
      Workload:
        'A discrete operating system instance running on a physical device, virtualization platform, or a cloud instance.',
      WorkloadEdge: 'Individual endpoint in your environment.',
      Unmanaged: 'Allow you to write policy for resources that are not paired with the PCE and that do not have a VEN.',
      UnmanagedEndpoint: 'An endpoint which is discovered but for which no policy has been configured.',
      Managed: 'Resources that are paired with the PCE and have a VEN.',
      ManagedEndpoint: 'An endpoint for which the policy is managed by Illumio Edge.',
      VenInfo: 'Installed on each workload and provides information about the Workload and enforces policy rules.',
      VenActions: 'Upgrade, unpair, refresh, and generate support bundles.',
      VenPairingProfiles: 'Allows you to apply certain properties to Workloads as they pair with the PCE.',
      VenPairingScripts:
        'Contains a unique pairing key at the end of the script that identifies the VEN securely so it can authenticate with the PCE.',
      VENConnectivity: 'Whether VEN is online or offline.',
      PolicySync: 'Applies recent active policy configurations to the Workload.',
      Attributes: 'Details about the Workload, such as VEN version, hostname, and uptime.',
      PairingProfile:
        'Configuration that allows you to apply properties such as Labels or policy state to Workloads paired with the PCE.',
      LoadBalancers: 'Distributes network or application traffic across servers.',
      Blocked: 'No Rules in the active policy allow the traffic and Workload is in Enforced policy state.',
      PotentiallyBlocked: 'No Rules in the active policy allow the traffic and Workload is in Test policy state.',
      OrgEvents: 'A log of all user actions that occur in the PCE, such as login/logout.',
      PolicyCheck:
        'Determines if Rules that allow communication between Workloads or a Workload and an IP address exist.',
      PortAndProtocol:
        'A port is an endpoint for TCP or UDP communications. A protocol is the set of rules used to send or receive communications.',
      Rule: 'Allows communication between multiple applications or entities providing Services and the applications or entities using the Services.',
      LabelGroup:
        'A collection of Labels that can be used in a Rule or Scope to apply the policy to all the Workloads using those Labels.',
      VirtualServices:
        'Allow you to associate a Label or set of Labels with processes or Services on Workloads to dynamically calculate Rules in your policy.',
      IntraScope: 'Allow communication within an App Group.',
      ExtraScope: 'Allow communication between different App Groups.',
      RuleCoverage: 'Percentage of total connections that are allowed by Rules.',
      Microsegmentation:
        'Allows all Workloads within an App group to communicate with other Workloads within the same App group across all ports.',
      Nanosegmentation:
        'Allows Workloads with a specific Role Label to communicate with Workloads that have other Role Labels on ports where there is observed traffic between the Roles.',
      Connection: 'Traffic summaries grouped by Role for traffic on different destination ports & protocols.',
      SegmentationTemplate:
        'Allows you to quickly write a security policy to allow traffic for commonly used applications using a pre-defined set of Services, Rulesets, Labels, Label Groups, and IP Lists.',
      VEScore: 'A calculated value based on the Vulnerability Score and the Exposure Score.',
      VEScoreMultipleAggregation:
        'It can be shown for an individual vulnerability on a port for a single workload or as a summation of all the V-E Scores for a Workload, Role, or App Group.',
      VEScoreWorkloadAggregation: 'For a Workload it is a summation of all V-E Scores on the Workload',
      VEScoreAppGroupAggregation: 'For an App Group it is a summation of all V-E Scores in the App Group',
      VulnerabilityScore:
        'The summation of severities of the vulnerabilities for an App Group, Role, or Workload where the individual vulnerability scores range between 0 and 10',
      ExposureScore: 'The E/W Exposure Score combined with the Northern Exposure',
      InternetExposure:
        'Indicates whether or not a vulnerable port is exposed to traffic from an IP List. Northern Exposure is enabled by rules allowing inbound traffic on that port.',
      EWExposure:
        '"East-West Exposure": A count of workloads that can use a vulnerable port with the currently provisioned rules',
      ProvidedTraffic:
        'Traffic that has been observed into a vulnerable port, and if it was allowed, blocked or potentially blocked',
      WorkloadCount: 'Number of workloads in the App Group with this vulnerability',
      VulnerabilityPortProtocol: 'The port and protocol associated with the vulnerability',
      CVEIds:
        'Unique public identifiers (designated by the NCF organization) for Common Vulnerabilities and Exposures (CVEs)',
      VulnerabilityName: 'The name of the vulnerability',
      IntraScopeConstrained:
        'These vulnerabilities will have some limited Intra-Scope E/W Exposure after creating this policy',
      IntraScopeBlocked: 'These vulnerabilities will have no Intra-Scope E/W Exposure after creating this policy',
      ExtraScopeConstrained:
        'These vulnerabilities will have some limited Extra-Scope E/W Exposure after creating this policy',
      ExtraScopeBlocked: 'These vulnerabilities will have no Extra-Scope E/W Exposure after creating this policy',
      InternetConstrained: 'These vulnerabilities will have some Northern Exposure after creating this policy',
      InternetBlocked: 'These vulnerabilities will have no Northern Exposure after creating this policy',
      IntraScopeVulnerabilityExposure:
        'The number of vulnerabilities which have either some intra-scope E/W Exposure or no intra-scope E-W Exposure.',
      ExtraScopeVulnerabilityExposure:
        'The number of vulnerabilities which have either some extra-scope E/W Exposure or no intra-scope E-W Exposure.',
      InternetVulnerabilityExposure:
        'The number of vulnerabilities which have either some Northern Exposure or no Northern Exposure.',
      ExposedVulnerabilities: 'A list of vulnerabilities which will have exposure based on this rule',
      Severity: 'Represents a range of Vulnerability Score values.',
      SeverityInfo: '0 = Info',
      SeverityLow: '0.1 to 4.0 = Low',
      SeverityMedium: '4.1 - 7.0 = Medium',
      SeverityHigh: '7.1 to 9.0 = High',
      SeverityCritical: '9.1 - 10 = Critical',
      PolicyGeneratorSeverity:
        'You can select which severity level to consider when mitigating vulnerabilities by selecting Auto Level in the Rule Configuration.',
      AppGroupMapSeverity:
        'You can select which severity level to consider when showing which traffic is going to vulnerable ports.',
      TotalWorkloadVEScore: 'The sum of all Vulnerability-Exposure scores for this Workload',
      TotalAppGroupVEScore: 'The sum of all Vulnerability-Exposure scores for this App Group',
      HighestVEScore: 'The highest individual Vulnerability-Exposure score for this Workload',
      SupportReports:
        'Generate diagnostic information for the selected Workload. The report can be downloaded from the Support Reports page.',
      PCESupportBundles:
        'Generate diagnostic information for this PCE. Displays individual node status until complete, then makes an aggregate support bundle available for download.',
      Explorer:
        'Analyze traffic flows for auditing, reporting, and troubleshooting purposes. You can view traffic by time, port, consumers, providers, and services. Explorer is not an interactive tool, so you cannot write rules using it.',
      IlluminationPlus:
        'Analyze traffic flows for auditing, reporting, and troubleshooting purposes. You can view traffic by time, port, consumers, providers, and services. Illumination Plus is an interactive tool. You can write rules from the Table View and connections tab in the Map View.',
      UserActivity: 'View the audit trail of user’s activity along with the permission and authentication details.',
      GlobalRoles:
        'Uses the ‘All Applications, All Environments, and All Locations’ scope. The scope for the Global roles cannot be changed.',
      GlobalRolesEdge: 'Includes four global roles that grant users access to perform operations.',
      ScopedRoles: 'Assign users to roles with specific scopes.',
      UsersAndGroups: 'Add or remove local users, external users, and external groups.',
      PasswordPolicy:
        'Set password policies such as password length, composition (required number and types of characters), and password expiration, re-use, and history.',
      Events:
        'Provides an ongoing log of all Organization events that occur in the PCE, which you can filter by severity, status, event type, timestamp and also export the list.',
      EventConfiguration: 'Configure the Severity, Retention Period, and Format for the events to be logged.',
      Versions: 'Restore to any previous version of the Ruleset.',
      VersionsEdge: 'Restores to previous version of the policy.',
      AppGroupType: 'Configure App Groups by selecting two or three matching Labels.',
      ExplorerSearchFormats:
        'View search results in the form of Parallel Coordinates, Table, or Unmanaged IP Addresses.',
      IlluminationPlusSearchFormats: 'View search results in the form of Table, Map or Mesh.',
      SSOConfig:
        'Configure a single sign-on (SSO) identity provider in the PCE to validate usernames and passwords against your own user management system.',
      RuleSearch: 'Search, analyze, and download Rules.',
      OfflineTimers: 'Set VEN Disconnect and Goodbye timers.',
      AppGroupDetailsRules:
        'Allow communication between multiple applications or entities in different Scopes or the same Scope.',
      PCEHealth:
        'General PCE health information such as, the PCE name, how long it has been in a running, and overall PCE health.',
      MyProfile: 'Set your profile and change your account password.',
      APIKeys: 'Allow authentication with Illumio for making API calls.',
      Provisioning:
        'On provisioning, the PCE recalculates any changes made to Rulesets, IP Lists, Services, Label Groups, and Security Settings, and transmits those changes to the VENs installed on your Workloads.',
      ProvisioningEdge: 'Recalculates changes made to groups, IP ranges, services, or policy.',
      AuthenticationSettings: 'PCE supports LDAP authentication with OpenLDAP and Active Directory.',
      Vens: 'PCE can be a repository for distributing, installing and upgrading the VEN software. PCE-based deployment of VENs replaces the external VEN repository.',
      Reports:
        'Useful when you want to share data with team members who do not have access to the PCE. You can download PCE objects in JSON and CSV formats.',
      Networks:
        'A PCE provides visibility and policy enforcement for workloads that live on isolated networks with overlapping IP address space.',
      SecureGateways:
        'Encrypts communications between managed workloads and the unmanaged workloads on the private subnet protected by the VPN gateway at the edge of the data center.',
      ContainerWorkloads: 'Represents a Pod in the PCE that is being secured by Illumio Core.',
      VirtualService:
        'Allow you to label processes or services on workloads. Illumination, Policy Generator, and Explorer support virtual services.',
      AppGroupPolicyGenerator:
        'Simplifies the policy creation process by recommending the optimal security policy for your App Groups.',
      AppGroupExplorer:
        "Queries the PCE's traffic database to analyze traffic flows for auditing, reporting, and troubleshooting purposes.",
      PolicySettings:
        'When Display Scopes in Rulesets is set to No, each ruleset applies to all workloads. When it is set to Yes, rulesets apply only in specified environments, locations, or applications. Provisioning Setting describes context before provisioning rules. By default, it is set to ‘No’. On changing it to ‘Yes’, makes providing a ‘Provision Note’ mandatory before provisioning rules. Also allows the order of Provider and Consumer columns to be changed across the UI.',
      OpenShift:
        'Enterprise container-based PaaS built on top of Docker and Kubernetes, which adds additional capabilities such as, software defined networking.',
      Kubelink:
        'A software component provided by Illumio for making integration between the PCE and the orchestration platform easier.',
      CCServices: 'Kubelink creates a virtual service in the PCE for services in the container cluster.',
      ContainerClusters: 'An object used to store all the information about a container cluster in the PCE.',
      ContainerWorkloadInIllumination:
        'After deploying containers, you can view container workloads and traffic associated with them in Illumination, App Group Map, Vulnerability Map, and Explorer.',
      ContainerInheritHostPolicy:
        'Enforces host policy for all the docker containers running on the host when enabled.',
      FirewallCoexistence:
        "Supplement Illumio's firewall with your organization's firewalls using Firewall Coexistence.",
      RejectConnections: 'Workload instructed by the PCE to reject the blocked connection.',
      StaticPolicy:
        'Control when updates to OS-level firewall rules are applied to your Workloads by adding static policy.',
      EssentialServiceRules:
        'Essential Service Rules are programmed on the VEN to allow normal network functionality and communication with the PCE.',
      IKECertificates:
        "The IKE Certificates may display either the full or partial Distinguished Name that appears in the Issuer's field in an end-entity certificate. This filter is used to identify the proper end-entity certificate in a list of certificates. To configure Admin Access, you must indicate a valid IKE Certificate issuer.",
      ServiceAccounts:
        'Service Accounts include API keys by default so that you can use them to perform programmatic operations. A user account is not required to use a Service Account. Any permissions assigned to a Service Account apply to its API key.',
      OrganizationalOutboundPolicy:
        'Allow and deny rules at the organizational level (apply to all groups) for outbound traffic.',
      APIKeySettings:
        'Set the expiration time for service account API keys, from 1 day to Never Expires. Specify how long expired service account API keys are retained in the PCE database, from 0 - 365 days.',
      Reporting:
        'Generate, download, and manage two types of recurring reports: Executive Summary reports and App Group Summary reports.',
      ReportType: 'Either an Executive Summary or App Group Summary report.',
      RecurringReports: 'Reports that are repeatedly generated on the following schedule: daily, weekly, or monthly',
      ExecutiveSummaryReport:
        'High-level reports designed to provide information to decision makers, such as an organization’s CISCO or VP if IT, about the overall deployment of Illumio within the organization’s computing environment.',
      AppGroupSummaryReport:
        'Reports designed for application owners (for example, members of your business applications group like your Oracle or ServiceNow app admins) or other people in your organization who need to understand the security of you applications, such as IT security auditors (for example, auditors of PCI or HIPA systems).',
      ReportRetentionPeriod:
        'Configure globally how long the PCE retains the PDF files generated for each report you add.',
      ReportRecurrence:
        'Removing a report from the Recurrence tab stops the report from running again. Select the report you want to stop generating and click Remove.',
      CoreServices:
        'Identify core services (such as DNS, Domain Controllers, NTP, and LDP) and use the suggested labels for them.',
      CoreServicesDetector:
        'A feature to detect and label workloads running core services based on the PCE analysis of traffic and process data.',
      DetectionModel:
        'The method that the PCE uses to detect core services: port matching, port-based machine learning (ML), or process-based ML. The methods are not configurable and the algorithms run continuously. Click the detection method to view more information.',
      PortMatch: 'Rule-based detection method based on connections to specific ports.',
      PortBased: 'Machine learning detection method based on connections to specific ports.',
      ProcessBased: 'Machine learning detection method based on processes running on the servers.',
      RecommendedCoreService:
        'PCE displays detected core services as recommendations for creating policy along with suggested labels. You can modify the policy and suggested labels.',
      AcceptedCoreService:
        'Displays the list of core service recommendations that were accepted. Accepted recommendations are labeled. If the accepted service is an unknown IP address, an unmanaged workload is created automatically.',
      RejectedCoreService:
        'Displays the list of core service recommendations that were rejected. When you reject a core service recommendation, that IP address is no longer recommended as a provider of the requested core service.',
      EnforcementBoundaries:
        'Sets of rules that determine where rules apply. Enforcement Boundaries can block traffic from communicating with workloads you specify, while still allowing you to progress toward a Zero Trust environment. Unlike firewall policies, boundaries provide a simple policy model that does not depend on rule order.',
      SelectiveEnforcementState:
        'Rules are enforced directionally for selected services when a workload is within the scope of an Enforcement Boundary. To apply an Enforcement Boundary to workloads, you must move the required workloads into the Selective Enforcement state.',
      EnforcementBoundaryWorkflow:
        'After creating an Enforcement Boundary, the PCE web console guides you through the process to review blocked connections and add any required rules (allowlist policy) to traverse the boundary.',
      EnforcementBoundaryBlockedConnections:
        'Page that displays the traffic flows blocked between workloads due to an Enforcement Boundary. Review the blocked connections and add rules for connections that must traverse the boundary.',
      AllowSelectedConnections:
        'Connections that you designate as being allowed to cross an Enforcement Boundary. After creating an Enforcement Boundary, you can select some or all connections that cross the boundary. The PCE web console then displays proposed rules for these connections that you can accept as is or modify.',
      MyManagedTenants:
        'My Managed Tenants allows Managed Services Providers (MSPs) to onboard their customers into Illumio Edge (SaaS) and then manage and administer Edge on their behalf.',
      LabelAndLabelGroupsExcept:
        'In Labels and Label Groups Except, select labels that define roles, applications, environments, and locations you want to exclude from the policy.',
      UseWorkloadSubnets:
        'Select Use Workload Subnets if you want the peers to get the workloads’ subnets instead of the individual IPs. The subnets will be determined by the IP address and netmask reported by the VEN.',
    },
    Title: {
      CustomiptablesRules: 'Custom iptables Rules',
      DraftVersion: 'Draft Version',
      ActiveVersion: 'Active Version',
      NoHelp: 'No Help Found',
      TrafficLines: 'Traffic lines',
      Rule: 'Rule',
      RuleCoverage: 'Rule Coverage',
      Microsegmentation: 'Microsegmentation',
      Nanosegmentation: 'Nanosegmentation',
      Connection: 'Connection',
      UserGuide: 'PCE Web Console Users Guide',
      OrganizationalOutboundPolicy: 'Organizational Outbound Policy',
      SelectiveEnforcement: 'Selective Enforcement State',
      EnforcementBoundaryWorkflow: 'Enforcement Boundary Workflow',
      AllowSelectedConnections: 'Allow All or Allow Selected Connections',
    },
    Menu: {
      ShowMore: 'Show More',
      HelpMenu: 'Help Menu',
      VisitSupportPortal: 'Visit Support Portal',
    },
  },
  IPLists: {
    Any: 'Any (0.0.0.0/0 and ::/0)',
    AddNew: 'Add a new IP List',
    AddNewRange: 'Add a new IP Range',
    CIDRBlock: 'CIDR block',
    DeleteConfirm: 'Are you sure you want to remove this IP List?',
    IPAddresses: 'IP Addresses',
    InvalidMessage: 'Please add a valid Name and IP Range.',
    TypeOrPasteAndEnter: 'Type or paste in one or more IP Addresses or wildcard FQDNs and then press enter',
    TypeOrPasteQualifiedName: 'Type or paste fully qualified names',
    TypeOrPasteIPs: 'Type or paste IP addresses',
    NoMatchData: 'Not matching IP Lists to display',
    NoData: 'No IP Lists to display',
    Remove: 'Remove IP List',
    Delete: 'Remove {count, plural, =1 {IP List} other {# IP Lists}}',
    IpName: 'New IP Name',
    EnableValidation: 'Disable validation of IP addresses and FQDNs',
    EnableValidationIPs: 'Disable validation of IP addresses',
    EnableValidationTooltip:
      'For performance reasons, it is recommended to disable real-time IP Address and FQDN validation when working with large sets of IP Addresses and FQDNs.',
    EnableValidationTooltipIps:
      'For performance reasons, it is recommended to disable real-time IP Address when working with large sets of IP Addresses.',
    SelectOneOrMore: 'Select one or more IP Ranges',
    SelectingAnyIPListTooltip: 'Selecting Any IP Range Selection will override IP List selection',
    SelectingIPListTooltip: 'Selecting an IP Address will override Any IP Range selection',
    Errors: {
      Edit: 'Unable to Edit IP List',
      Create: 'Unable to Create IP List',
      Ip4: 'Invalid IPv4',
      Ip4Fqdn: 'Invalid IPv4 or DNS Entry',
      FQDN: 'Invalid FQDN Entry',
      Experimental: 'Experimental Class E not allowed',
      RequestFacetFail: 'Request to check against IP allowed names is failing. Please try again',
    },
    List: {
      Error:
        '{count, plural, =1 {<span class="{className}">#</span> Error} other {<span class="{className}">#</span> Errors}} (Hover over an Error to see details)',
      DuplicateErrorLineMessage: '{duplicateMessage} duplicate at line {duplicateAt}',
      Exclusion:
        '{count, plural, =1 {<span class="{className}">#</span> Exclusion} other {<span class="{className}">#</span> Exclusions}}',
      Label: 'IP Address/Domain',
      Warning:
        '{count, plural, =1 {<span class="{className}">#</span> Warning} other {<span class="{className}">#</span> Warnings}}',
      Message: {
        RemoveConfirm: 'Are you sure you want to remove the selected IP {count, plural, =1 {List} other {Lists}}?',
        RemoveSuccess: '{count, plural, =1 {IP List has} other {# IP Lists have}} been successfully removed',
        RemoveResultTitle: 'Result of {count, plural, =1 {IP List} other {IP Lists}} removal',
        CannotDeleteAny: 'This is a default IP List and cannot be edited or removed.',
        CannotEditLargeLists: 'This IP List is too large to be edited',
        CannotDeleteBoundToRules:
          'Cannot remove {count, plural, =1 {IP List that is} other {# IP Lists that are}} referenced by an existing Rule',
        CannotDeleteBoundToSCG:
          'Cannot remove {count, plural, =1 {IP List that is} other {# IP Lists that are}} referenced by an existing SecureConnect Gateway',
        CannotDeleteBoundToEnforcementBoundary:
          'Cannot remove {count, plural, =1 {IP List that is} other {# IP Lists that are}} referenced by an existing Enforcement Boundary',
      },
      OverlappingAddresses: 'Overlapping Addresses',
      OverlappingFQDN: 'Overlapping FQDN',
      OverlappingInterfaces: 'Overlapping Interfaces',
      Range: 'Must add a containing IP range',
      ZeroValidity: "0.0.0.0 is valid address but it's not used as Any (0.0.0.0/0) and ::/0)",
    },
    Mixin: {
      CIDRBlock: 'CIDR Block',
      Comments: 'Comments',
      Excludes: 'Excludes',
      ExclusionInclusionText: 'The excluded IP address(es) must be within the included IP range.',
      ExclusionTip:
        'Use an exclamation point to <span class="{className}">exclude</span> an IP address, CIDR block or IP range.',
      FQDN: 'Fully Qualified Domain Name',
      HashTip: 'Use a hash symbol to indicate a line comment',
      HyphenTip: 'Use a hyphen to indicate an IP range',
      IPListDescription: 'Type a description for the new IP List',
      IPListName: 'Type a name for the new IP List',
      IPVersions: 'IPv4 or IPv6',
      Ranges: 'IP Ranges',
      SingleIP: 'Single IP',
      SlashTip: 'Use a slash to indicate a CIDR Block',
      WildcardMatches: 'Wildcard Matches',
      InstructionsExample: {
        CIDR: '192.168.100.0/24',
        CIDRV6: '2620:0:860:2::/64',
        Comment: '23.4.55.6 #Comment Text',
        Exclusion: '!192.168.100.0/30',
        ExclusionV6: '!3ffe:1900:4545:3:200:f8ff:fe21:67cf',
        IllumioFQDN: 'www.illumio.com',
        Range: '10.0.0.0-10.255.255.255',
        Single: '127.0.0.1',
        SingleV6: '2001:0db8:0a0b:12f0:0000:0000:0000:0001',
        Wildcard: '*.illumio.com',
      },
    },
  },
  LabelGroups: {
    New: 'New Label Group',
    AddNew: 'Add a new Label Group',
    Members: 'Label Group Members',
    NoMembers: 'No Members',
    AddMembers: 'Add new members',
    MemberExist: 'Label Group Members exists',
    Delete: 'Remove {count, plural, =1 {Label Group} other {# Label Groups}}',
    DeleteConfirm: 'Are you sure you want to remove this Label Group?',
    DeleteMembers: 'Remove Label Group Members',
    CreateError: 'Cannot Create Label Group',
    UpdateError: 'Cannot Update Label Group',
    DeleteMembersConfirm: 'Are you sure you want to remove the selected members from the Label Group?',
    Types: 'Label Group Types',
    Create: {
      Placeholder: {
        LabelGroupDescription: 'Type a description for the new Label Group',
        LabelGroupName: 'Type a name for the new Label Group',
        LabelGroupType: 'Choose a type for the new Label Group',
      },
    },
    List: {
      DeleteSelectedLabelGroups: 'Are you sure you want to remove the selected Label Groups?',
      RemoveSuccess: '{count, plural, =1 {Label Group has} other {# Label Groups have}} been successfully removed',
      RemoveResultTitle: 'Result of {count, plural, =1 {Label Group} other {Label Groups}} removal',
    },
  },
  Labels: {
    AddNew: 'Add a new Label',
    AlreadyDeleted: 'The label is already deleted',
    Application: 'Application Label',
    ApplicationLabels: 'Application Labels',
    Assign: 'Assign Labels',
    AssignVS: 'Create Virtual Service - Assign Labels',
    CreateVSPreview: 'Create Virtual Service - Preview',
    ClearMessage:
      'All {labelNames} Labels for {count, plural, =1 {Workload} other {<strong>#</strong> Workloads}} will be cleared.',
    Delete: 'Remove Label',
    Deleted: 'The label is deleted',
    DeleteConfirm: 'Are you sure you want to remove this Label?',
    DeleteSeveral: 'Remove Labels',
    Edit: 'Edit Labels',
    Excluded: 'All {type} except',
    CurrentLabelsInfo: 'The current labels are showed in gray',
    ProviderEdit: 'Edit Provider Labels',
    ConsumerEdit: 'Edit Consumer Labels',
    TooManySelected: 'Too Many Selected Workloads',
    AddScopeForStaticPolicy: 'Add Scope for Static Policy',
    EditScopeForStaticPolicy: 'Edit Scope for Static Policy',
    Environment: 'Environment Label',
    EnvironmentLabels: 'Environment Labels',
    Group: 'Label Group',
    Groups: 'Label Groups',
    GroupsCount: '{count, plural, =1 {# Label Group} other {# Label Groups}}',
    InUse: 'Labels in Use',
    InvalidLabel: 'Invalid Label',
    InvalidLabelCantSave: 'Cannot save invalid Label',
    Location: 'Location Label',
    LocationLabels: 'Location Labels',
    ModifyForUnmanagedWorkloads:
      'Assign Labels for {count, plural, =1 {Unmanaged Workload} other {<strong>#</strong> Unmanaged Workloads}}.',
    ModifyForWorkloads:
      'Modify Label assignments for {count, plural, =1 {Workload} other {<strong>#</strong> Workloads}}.',
    ModifyForWorkloadsEdge:
      'Modify Group assignments for {count, plural, =1 {Workload} other {<strong>#</strong> Workloads}}.',
    ModifyForWorkloadsEdgeUnmanaged: 'Unmanaged Workloads will not be moved into Enforcement of the Selected Group',
    NamePlaceholder: 'Label Name',
    New: 'New Label',
    NoMatchData: 'No matching Labels to display',
    NoData: 'No Labels to display',
    NoMatchGroupData: 'No matching Label Groups to display',
    NoDataGroup: 'No Label Groups to display',
    RoleLabels: 'Role Labels',
    Select: 'Select {multiple, select, false {Label} true {Labels}}',
    SelectGroup: 'Select Group',
    SelectType: 'Select {type} Label',
    SelectByName: 'Type or select a Label by name',
    SelectToFilterViews: 'Labels to filter',
    Type: 'Label type',
    RoleHint: 'Select a Role',
    ApplicationHint: 'Select an Application',
    EnvironmentHint: 'Select an Environment',
    LocationHint: 'Select a Location',
    AllowLabelsHnt: 'Allow any',
    Create: {
      Placeholder: {
        LabelName: 'Type a name for the new Label',
        LabelType: 'Select a type for the new Label',
      },
    },
    Detail: {
      CreatedAt: 'Created at',
    },
    List: {
      DeleteConfirm: 'Are you sure you want to delete the selected {count, plural, =1 {Label} other {Labels}}?',
      RemoveSuccess: '{count, plural, =1 {Label has} other {# Labels have}} been successfully removed',
    },
    Errors: {
      Edit: 'Unable to Edit Label',
      Create: 'Unable to Create Label',
      UnableToRemove: 'Unable to Remove Label',
    },
  },
  LabelSettings: {
    LabelIdentifier: 'Label Identifier',
    LabelLabelGroupTypes: 'Label and Label Group Types',
    InitialLetter: 'Initial Letter',
    LabelInUse: 'Label In Use',
    LabelGroupInUse: 'Label Group In Use',
    LabelInitial: 'Label Type Initial',
    Icon: 'Icon',
    IconPrefix: 'Icon + Prefix',
    IconSuffix: 'Icon + Suffix',
    CreateNewType: 'Create New Label Type',
    EditType: 'Edit Label Type',
    BackgroundColor: 'Background Color',
    ForegroundColor: 'Foreground Color',
    RemoveLabelType: 'Remove Label Type',
    RemoveWarningMessage:
      'Removing a label type decouples it from everywhere and not visible. Are you sure you want to remove the selected Label type?',
    RemoveResultTitle: 'Result of {count, plural, =1 {Label Type} other {# Label Types}} removal',
    RemoveSuccessfully:
      'The following {count, plural, =1 {Label Type has} other {# Label Types have}} been successfully removed',
    SelectIcon: 'Select Icon',
    InitialLimitError: 'Valid range 1-2 characters and must be unique',
    ReachLimitNumberNotification: 'You have reached the limit of {count} label types',
    LabelKeyTooLong: 'Key must not be more than 64 characters',
    LabelKeyDuplicated: 'Key already exists',
    LabelNameDuplicated: 'Name already exists',
    InitialDuplicated: 'Label Type Initial already exists',
    LabelKeyRequired: 'Please enter a valid Key',
    LabelNameRequired: 'Please enter a valid Name',
    InitialRequired: 'Please enter a valid Label Type Initial',
    BlankSpaceError: 'This field can not be blank space',
    LabelKeyDescription: 'Enter a unique key for the new Label Type. (used internally)',
    LabelInitialDescription:
      'The label type initial is used to create a unique icon for the labels.\n Maximum character limit (2 characters).',
    DisplayNameSingular: 'Display Name (singular)',
    DisplayNamePlural: 'Display Name (plural)',
  },
  LoadBalancers: {
    AddNew: 'Add a new Load Balancer',
    Delete: 'Remove Load {count, plural, =1 {Balancer} other {Balancers}}',
    DeleteConfirm:
      'Are you sure you want to remove the selected Load {count, plural, =1 {Balancer} other {Balancers}}?',
    DeviceNumber: '{count, plural, =1 {# (Standalone)} other {# (HA Pair)}}',
    Properties: 'Properties',
    FirstDeviceProperties: 'First Device',
    SecondDeviceProperties: 'Second Device',

    List: {
      RemoveSuccess: '{count, plural, =1 {Load Balancer has} other {# Load Balancers have}} been successfully removed',
      NotSupportedDesc:
        'Load balancer functionality is not enabled. Before you add a load balancer, pair a Network Enforcement Node with the load balancer functionality enabled with this PCE. For more information, see the {link}',
    },

    Errors: {
      Edit: 'Unable to Edit Load Balancer',
      Create: 'Unable to Create Load Balancer',
    },

    Create: {
      CreateDescription: 'Type a description',
      CreateManagement: 'Type the management IP or FQDN',
      CreateName: 'Type a name',
      CreatePassword: 'Type the Password',
      CreatePort: 'Type the Port Number',
      CreateUsername: 'Type the Username',
      HostTooLong: 'Hostname must contain fewer than {len, number} characters.',
      ManagementSame: 'Management IP/FQDN cannot be the same for both devices.',
      PasswordNotSame: 'Password is not the same',
      PasswordTooLong: 'Password must contain fewer than {len, number} characters.',
      UsernameTooLong: 'Username must contain fewer than {len, number} characters.',
    },
    Detail: {
      BadCertificate: 'Certificate could not be verified',
      BadCredentials: 'Incorrect Username or Password',
      BadRequest: 'Bad Request',
      CannotConnect: 'Cannot Connect',
      CannotResolve: 'Cannot Resolve',
      DeviceManagement: 'Management IP or FQDN',
      DupDevice: 'Duplicate Device',
      Manage: 'Manage',
      ManagementState: 'Management State',
      NumberOfDevices: 'Number of Devices',
      Software: 'Software',
      Successful: 'Successful',
      TLSVerified: 'TLS Verified',
    },
  },
  Map: {
    AppGroupDisabled: 'App Group Type must be set before App Groups can be generated and displayed',
    AppGroupConfigurationNotComplete: 'App Group configuration is not complete',
    TooManyConnectedGroups:
      'Exceeded limit of the number of Connected Groups that can be displayed.\n Open the App Group Map to view the Connected Groups.',
    NoLabeledWorkloads: 'App Groups will be generated as Workloads are Labeled',
    NoAppGroupsInScope: 'No App Groups are fully within your scope',
    AssignLabelsCluster:
      'To write a security policy for this connection, first navigate to Group detail page and assign Scope Labels to all Workloads.',
    AssignLabelsTraffic:
      'To write a security policy for this connection, first navigate to Group detail page and assign all Labels to source and target Workloads.',
    CantPerformOperation: 'Cannot Perform Operation',
    CantPerformOperationMessage: 'Your user account does not have sufficient privileges to perform this operation.',
    CantChangeLabelsOnContainers: 'Cannot change the labels of Container Workloads.',
    Count: 'Count',
    CreateRuleset: 'Create Ruleset',
    Discovered: 'Discovered',
    DiscoveredGroup: 'Discovered Group',
    Data: 'Illumination Map Data',
    DataGenerated: 'Map data refreshed: {time}',
    DataTooLarge: 'Map data is too large and has been truncated.',
    NewerDataIsAvailable: 'More up-to-date Map data is available.',
    DataAge: 'The Illumination map refreshed its data: {time}. To refresh the map now click Refresh.',
    DataRefresh:
      'Refreshing the map can take several minutes. Reload your browser to see updates while the map refreshes its data.',
    DoNotShowAgain: 'Do not show this again',
    SuperClusterDataRefresh: 'This will also sync data from all Supercluster members.',
    EditPolicyState: 'Edit Policy State',
    EditPolicyStateWorkloadsOnly:
      'Editing the Policy State affects only the Workloads in this group. The Policy State of Workloads bound to Virtual Services must be edited separately.',
    EnterAppGroup: 'Enter an App Group in the Search bar to view a map of connections',
    ExpandTrafficLinks: 'Expand Traffic Link',
    CollapseTrafficLinks: 'Collapse Traffic Link',
    SearchGroups: 'Search Groups',
    SearchConnectedGroups: 'Search Connected Groups',
    SearchAppGroups: 'Choose an App Group',
    ConsumingAppGroups: 'Consuming App Groups',
    ProvidingAppGroups: 'Providing App Groups',
    SearchConsumingAppGroups: 'Search Consuming App Groups',
    SearchProvidingAppGroups: 'Search Providing App Groups',
    ConnectedGroup: 'Connected Group',
    ConnectedGroups: 'Connected Groups',
    OtherConnectedGroups: 'Other Connected Groups',
    SearchIllumination: 'Search Illumination',
    SearchIpAddresses: 'Search IP Addresses',
    Illumination: 'Illumination map',
    LoadConnectedGroupData: 'Expand Connected Group',
    CollapseConnectedGroupData: 'Collapse Connected Group',
    JustNow: 'Just Now',
    LoadingData: 'Loading map data',
    RecalculatingData: 'Calculating map data',
    NoIPAddressesForAny: 'No addresses available for Any(0.0.0.0/0)',
    OverwriteEndpoint: 'Merge Rule - Overwrite {endpoint}',
    OverwriteEndpointMessage:
      "{endpoint} in the existing Rule will be overwritten because you have selected 'All Workloads'.",
    RecentlyViewedAppGroups: 'Recently Viewed App Groups',
    RequestLimit: 'Already Using Increased Update Rate',
    RequestLimitMessage:
      'It has been less than {minutes, plural, =1 {one minute} other {# minutes}} since the Workload update rate was increased for this Group. Increased update rate is still in effect.',
    TrafficLinks: 'Traffic Links',
    TryAgain: 'Try again after viewing the Detail Map.',
    NoTraffic: 'No traffic has been reported between workloads. Try again later.',
    ReportedView: 'Reported View',
    DraftView: 'Draft View',
    DraftViewBlocked: 'Draft View > Blocked',
    DraftViewAllowed: 'Draft View > Allowed',
    DraftViewPotentiallyBlocked: 'Draft View > Potentially Blocked',
    ExtraScopeConsumers: 'Extra Scope Consumers',
    ExtraScope: 'Extra Scope',
    Policy: 'Policy Map',
    PolicyBlocked: 'Policy > Blocked',
    PolicyAllowed: 'Policy > Allowed',
    PolicyPotentiallyBlocked: 'Policy > Potentially Blocked',
    ViewBoundWorkloads: 'View Bound Workloads',
    ViewBoundContainerWorkloads: 'View Bound Container Workloads',
    Vulnerability: 'Vulnerability Map',
    ViewConnectedGroups: 'View Connected Groups',
    AssignRoleOnLeveledMap: 'It may take a few minutes for this change to reflect correctly on the Map',
    PortVulnerabilities: '{count} Port {count, plural, =1 {Vulnerability} other {Vulnerabilities}}',
    CreateUnmanagedWorkload: 'Create Unmanaged Workload',
    CreateUnmanagedWorkloads: 'Create Unmanaged Workloads',
    NoPermission: 'The applicable rule is outside your user scope.',

    Fqdn: {
      Manage: 'Manage FQDN',
      AddPolicy: 'Add Policy FQDN',
      Placeholder: 'Type a Wildcard FQDN name',
      DescriptionPlaceholder: 'Type a description for this Wildcard FQDN',
      Hint: 'When used in a rule, allow connections to the following Wildcard FQDN definition',
      Examples: 'hostname.illumio.com, *.illumio.com',
    },

    Search: {
      ContainerWorkload: 'Container Workload: {workload}',
      Group: 'Group: {group}',
      Location: 'Location: {location}',
      WorkloadWithRole: 'Workload: {workload} ({role})',
      Workload: 'Workload: {workload}',
      VirtualService: 'Virtual Service: {virtualService}',
      VirtualServer: 'Virtual Server: {virtualServer}',
    },

    FlowVisibility: {
      HighDetail: 'High Detail',
      HighDetailDesc:
        'VEN logs allowed and blocked connection details (source IP, destination IP, protocol and source port and destination port)',
      LowDetail: 'Low Detail',
      LowDetailDesc:
        'VEN logs only blocked connection details (source IP, destination IP, protocol and source port and destination port), including all dropped packets',
      MixedDetail: 'Mixed Detail',
      NoDetail: 'No Detail',
      NoDetailDesc: 'VEN does not log traffic connection information',
      NoneDetail: 'VEN does not collect connection details',
      NoneDetailEdge: 'Illumio Edge does not log and display traffic information',
      BlockAllowed: 'VEN logs connection information for allowed, blocked and potentially blocked traffic',
      BlockAllowedEdge: 'Illumio Edge logs and display traffic information for allowed and potentially blocked traffic',
      BlockAllowedEdgeEnforce: 'Illumio Edge logs and display traffic information for allowed and blocked traffic',
      BlockTraffic: 'VEN logs connection information for blocked and potentially blocked traffic only',
      BlockTrafficEdge: 'Illumio Edge logs and display traffic information for blocked traffic',
      BlockPlusAllowed: 'Blocked + Allowed',
      EnhancedDataCollection: 'Enhanced Data Collection',
      EnhancedDataCollectionDesc:
        'VEN logs byte counts in addition to connection details for allowed, blocked, and potentially blocked traffic',
      LimitedEdge: 'Snapshot of flows from workloads is collected periodically',
      IllumioEnhancedDataDesc:
        'Enhanced Data Collection is a premium feature that allows you to monitor the amount of data transferred between workloads',
      IllumioEnhancedDataContact: 'Contact your Illumio sales representative',
    },
    LegendPanel: {
      Discovered: 'Cannot write Rules for Discovered Groups',
      UnknownRuleStatus: 'Rules not calculated',
      Legend: 'Legend',
      LoadingData: 'Loading rule data',
      PartiallyAllowed: 'Partially allowed by Rules',
      VulnerableTraffic: 'Vulnerable Traffic',
      PotentiallyBlockedVulnerableTraffic: 'Potentially Blocked Vulnerable Traffic',
      BlockedOrNotVulnerabile: 'Blocked or not Vulnerable',
    },
    PolicyVersionPanel: {
      ReportedViewDetails: 'Visualize policy coverage as reported by your Workloads',
      DraftViewDetails: 'Visualize draft policy to see what will happen when you provision changes',
      PolicyViewDetails: 'Show when traffic is blocked or allowed by policy',
      VulnerabilityViewDetails:
        'Show severity and exposure of workload vulnerabilities and when traffic is inbound to a vulnerable port.',
    },
    Traffic: {
      ClearTrafficMessage: 'Permanently delete traffic data for all links',
      ClearCounters: 'Clear Traffic Counters',
      ClearForApp: 'For all endpoints in this Group',
      ClearForRole: 'For all endpoints with matching Role labels in this Group',
      ClearForMissingRole: 'For all endpoints without a Role label in this Group',
      ClearForLocation: 'For all endpoints in this Location',
      ClearForOrg: 'For all endpoints in this Organization',
      TopServices: 'Top Services',
      ServiceCount: '{count, plural, =1 {Service} other {Services}}',
      Multicast: 'Multicast',
      Broadcast: 'Broadcast',
      Unicast: 'Unicast',
      CoreServices: 'DNS/DHCP/IPsec',
      Filter: {
        FilterByConnections: 'Filter by number of connections',
        AllowExtraGroup: 'Extra-Group',
        AllowIntraGroup: 'Intra-Group',
        IgnoreServices: 'Ignore Selected Services',
        NoResult: 'No results. Try different filters.',
        SinceLastProvision: 'Since Last Provision',
        BlockedNoRule: 'Blocked (No Rule)',
        PotentiallyBlockedNoRule: 'Potentially Blocked (No Rule)',
        AllowedByRule: 'Allowed by Rule',
      },
    },
    Workloads: {
      AddToGroup: 'Add Workload to Group',
      AddVirtualServerToGroup: 'Add Virtual Server to Group',
      AddVirtualServiceToGroup: 'Add Virtual Service to Group',
      AddToGroupMessage:
        "When a Workload is added to a Group, the Group's Application, Environment, and Location Labels are applied to the Workload.",
      AddVirtualServerToGroupMessage:
        "When a Virtual Server is added to a Group, the Group's Application, Environment, and Location Labels are applied to the Virtual Server.",
      AddToOtherGroup: 'Move Workloads to Other Group',
      AddToOtherGroupMessage:
        'This will impact the Rules associated with the Workloads. And, all of the Workloads will acquire the Application, Environment, and Location Labels of the new Group.',
      AffectedCount: '{count, plural, =0 {No Workloads} =1 {1 Workload} other {# Workloads}} affected',
      CantDiscovery: 'Cannot Add or Remove Workloads',
      CantDiscoveryMessage: 'You cannot add or remove Workloads from Groups in the Discovered state.',
      Limit: 'Too Many Workloads',
      LimitMessage:
        'The Workload update rate cannot be increased for this Group because it contains too many Workloads.',
      RemoveFromGroup: 'Remove Workloads from Group?',
      RemoveFromGroupMessage:
        'This will impact the Rules associated with the Workloads. And, all of the Workloads’ Application, Environment, and Location Labels will be removed',
      RemoveFromGroupNewRole: 'You can optionally select a new Role Label for the Workloads.',
      RemoveOneFromGroup: 'Remove Workload from Group',
      RemoveVirtualServerFromGroup: 'Remove Virtual Server from Group',
      RemoveVirtualServiceFromGroup: 'Remove Virtual Service from Group',
      RemoveOneFromGroupMessage:
        'When a Workload is removed from a Group, the Application, Environment, and Location Labels are removed from the Workload.',
      RemoveVirtualServerFromGroupMessage:
        'When a Virtual Server is removed from a Group, the Application, Environment, and Location Labels are removed from the Virtual Server.',
      RemoveVirtualServiceFromGroupMessage:
        'When a Virtual Service is removed from a Group, the Application, Environment, and Location Labels are removed from the Virtual Service.',
      TooManyToDisplay: 'Too many Workloads to display',
      AppGroupTooManyToDisplay: 'Too many Workloads in this App Group to display',
      GroupTooManyToDisplay: 'Too many Workloads in this group to display',
      MustHaveLocationLabels: 'Workloads must have Location Labels to be seen in this view',
      Role: '{count, plural, =0 {}  =1 {1 Role} other {# Roles}}',
      RoleWorkloads: '{count, plural, =0 {}  =1 {1 Workload} other {# Workloads}}',
      RoleTotalWorkloads: '{count, plural, =0 {}  =1 {1 Workload} other {# Total Workloads}}',
      RoleWorkloadsOfTotal: '{count, plural, =0 {}  =1 {1 Workload} other {# of {total} Workloads}}',
      RoleTotalWorkloadsOfTotal: '{count, plural, =0 {}  =1 {1 Workload} other {# of {total} Total Workloads}}',
      RoleVirtualServices: '{count, plural, =0 {}  =1 {1 Virtual Service} other {# Virtual Services}}',
      RoleContainerWorkloads: '{count, plural, =0 {}  =1 {1 Container Workload} other {# Container Workloads}}',
    },
    Locations: {
      LocationGroupsContains: 'This location contains {count} groups.',
      LocationGroupsLimits: 'Only 200 groups are shown here.',
      LocationGroupsFindGroup: "Use 'Search Groups' to view the map for a specific group.",
      LocationsExceedsMaximum:
        'The number of locations exceeds the maximum that can be displayed in Illumination. Please use the Search bar to select a location for viewing.',
    },
  },
  Menu: {
    AlarmsAndEvents: 'Alarms and Events',
    LoadBalancers: 'Load Balancers',
    Switches: 'Switches',
    Navigation: 'Navigation menu',
    Infrastructure: 'Infrastructure',
    ContainerClusters: '{multiple, select, false {Container Cluster} true {Container Clusters}}',
    Networks: '{multiple, select, false {Network} true {Networks}}',
  },
  MSP: {
    ActiveCoreVENs: 'Active Core VENs',
    ActiveEdgeVENs: 'Active Edge VENs',
    ActiveTenants: 'Active Tenants',
    ActiveVENs: 'Active VENs',
    AddNewTenant: 'Add New Tenant',
    AddProduct: 'Add {product}',
    ProductTenant: '{product} Tenant',
    ManageTenant: 'Manage Tenant',
    ManagedTenants: 'Managed Tenants',
    MyManagedTenants: 'My Managed Tenants',
    CustomerDomain: 'Customer Domain',
    MySubscription: 'My Subscription',
    Count: '{count, number}',
    DeleteConfirm:
      'Removing a tenant deletes all associated resources and unpairs any VENs that are paired.<br/>Are you sure you want to remove selected {count, plural, =1 {Tenant} other {# Tenants}}?',
    DeleteTitle: 'Remove {count, plural, =1 {Tenant} other {Tenants}}',
    DeletedSuccessfully: '{count, plural, =1 {tenant has} other {# tenants have}} been successfully removed',
    NoData: 'No tenants to display',
    WelcomeTitle: 'Welcome to Illumio Multi-tenant Portal',
    WelcomeMessage: 'You can create, view and manange tenants in this portal',
    WelcomeMessageLearnMore:
      "If you want to learn more functionality of the Illumio's Multi-tenant portal, please visit the help portal.",
    TenantManagement: 'Tenant Management',
    ServerVENs: 'Server VENs',
    EndpointVENs: 'Endpoint VENs',
    Hints: {
      CustomerDomain: 'Must be a globally unique name in the form of a domain (example.com)',
      CompanyURL: "The customer's company website URL",
    },
    Placeholder: {
      Name: 'Type a name for the new tenant',
      Domain: 'Type a domain for the new tenant',
      CompanyURL: "Type a company's URL for the new tenant",
      Address: 'Type an address for the new tenant',
      ZIPCode: 'Type a ZIP code for the new tenant',
      City: 'Type a city for the new tenant',
      PostalCode: 'Type a postal code for the new tenant',
      State: 'Select a state',
      Country: 'Select a country',
      Province: 'Type a state, province, or territory for the new tenant',
    },
    Errors: {
      Edit: 'Unable to Edit tenant',
      Create: 'Unable to Create tenant',
    },
  },
  States: {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },

  Illumio: {
    About: {
      Core: 'About Illumio Core',
      Edge: 'About Illumio Edge',
      MSP: 'About Illumio MSP',
      Xpress: 'About Illumio Xpress',
    },
    Terms: 'Illumio Terms and Agreements {link}',
    Copyright: 'Copyright © 2013-2023 Illumio, Inc. All Rights Reserved.',
    Logo: 'Illumio Logo',
    Name: 'Illumio',
    Patent: 'This product may be subject to one or more U.S. or foreign patents listed at {link}',
    Privacy: 'Illumio Privacy {link}',
    Welcome: 'Welcome to Illumio',
    Welcometitle: 'Welcome',
  },
  MultilineInput: {
    ShiftDeleteToDeleteRow: 'Use "shift-delete" to delete a row',
    TypeOrPaste: 'Type or Paste Input and press enter',
  },
  Network: {
    NetworkSummary: 'Network Summary',
    Datacenter: 'Datacenter',
    IpVersionIsRequired: 'IP Version is required',
    // Description:
    //   'The set of public IPs that are used by workloads to communicate with Illumio Edge on the corporate network. This information is required to identify whether a workload is connecting from inside or outside the corporate domain.',
    Errors: {
      Create: 'Unable to Create Network',
      Edit: 'Unable to Edit Network',
    },
    Message: {
      CannotDeleteBoundToVirtualService: 'Cannot delete Network while referenced by an active or draft Virtual Service',
      CannotDeleteBoundToWorkflowInterface: 'Cannot delete Network while referenced by a Workload Interface',
      RemoveConfirm: 'Are you sure you want to remove the selected {count, plural, =1 {Networks} other {Networks}}?',
      RemoveResultTitle: 'Result of {count, plural, =1 {Network} other {# Network}} removal',
      RemoveSuccess: '{count, plural, =1 {Network has} other {# Networks have}} been successfully removed',
      SystemDefaultIsReadOnly: 'This is a default Network and cannot be edited or removed.',
    },
    Delete: 'Remove {count, plural, =1 {Network} other {# Networks}}',
    SelectIPVersion: 'Select IP Version',
    SelectIPVersionFirst: 'First, Select an IP Version.',
    Subnets: 'Subnets',
    SystemDefined: 'System Defined',
    UserDefined: 'User Defined',
    ReservedNetworkNames: 'The names "Private" and "Internet" are reserved for System use.',
  },
  NavigationAlert: {
    Warning:
      'The next page seems to be taking longer than usual to load, continue waiting or press stop to cancel navigation',
    WarningInitial: 'This page seems to be taking longer than usual to load, please continue waiting',
    Error: 'Navigation has been cancelled due to an error',
    ErrorPage: 'The page failed to load due to an error',
    NoConnection: 'Navigation has been cancelled due to inability to establish connection to the PCE Server',
    NoConnectionPage: 'The page failed to load due to inability to establish connection to the PCE Server',
    Timeout:
      'The PCE server seems too busy to be reached, you could try again later or use another page in the meantime',
    ProvideRequestId: 'Provide this Request-ID when contacting your support team',
    Details: 'Navigation error details',
  },
  ObjectLimit: {
    ContactIllumioSupport: 'Contact Illumio support.',
    RequestId: 'Request-ID: {id}',
    HardLimitReached: '{object} hard limit reached',
    IllumioHardLimitReached: 'Illumio {object} hard limit reached',
  },
  ObjectSelector: {
    AdvancedOptions: 'Advanced Options(cmd+a)',
    FilteringTipsLabel: 'Filtering Tips({os, select, mac {cmd} windows {ctrl}}+i)',
    FilteringTipsContent: "Use 'in' to search in a category",
    FilteringTipsContentLabels:
      'Use prefix to search by label type. For example,\na: Application\ne: Environment\nLabel prefixes can be found in the Label Settings',
    LoadingValues: 'Loading Values...',
    MatchCount: '{name} – {count, number} of {total, number} Total',
    MatchedCount: '{name} – {matchedDisplayed, number} of {matchedTotal, number} Matched',
    ManyMatchCount: '{name} – {count, number} of {total, number}+ Total',
    MatchingResults: 'Matching Results',
    MatchingResult: 'Matching Result',
    NumMatchingResults: '{count, number} Matching Results',
    MatchingResultsDisabled: 'Cannot match *',
    NoMatchingResults: 'No matching results',
    RecentlyUsed: 'Recently Used. Type to find more...',
    SearchAllCategories: 'Search All Categories',
    ShowAllCategories: 'Show All Categories',
    ShowAllMatches: 'show all matches',
    TypeToShowMore: 'Type to show more',
    TypeToSearchObject: 'Type to search {object}',
    TypeToShowMoreObject: 'Type to show more {object}',
    TypeToShowObject: 'Type to show {object}',
  },
  OfflineTimers: {
    ConfirmChanges: 'Confirm Timer Setting Changes',
    CustomTimeoutIPCleanup: 'Custom Timeout - Wait a Specified Time before IP Cleanup',
    CustomTimeoutQuarantine: 'Custom Timeout - Wait a Specified Time before Quarantine',
    CustomNotificationQuarantine: 'Custom Timeout - Wait a Specified Time before warning',
    DaysNumber: 'Custom timeout - {count, plural, =1 {# day} other {# days}}',
    DecommissionSideNote:
      'Time period to wait (default = 15 minutes) after a managed workload sends a goodbye message to mark it offline.',
    DecommissionSideNoteEdge:
      'Time period to wait (default = one day) after a managed workload sends a goodbye message to mark it offline.',
    DecommissionTimer: 'Decommission and IP Cleanup Timer',
    DescTitle: 'With this setting the PCE will:',
    Disconnect: 'Disconnect and Quarantine Timer',
    DisconnectNotification: 'Disconnect and Quarantine Warning',
    DisconnectSideNote:
      'Time period to wait (default = one hour) with no heartbeat before a managed workload is marked offline.',
    DisconnectSideNoteEdge:
      'Time period to wait (default = one day) with no heartbeat before a managed workload is marked offline.',
    DisconnectNotificationSideNote:
      'Time period to wait (default = one-quarter of the Disconnect and Quarantine Timer) with no heartbeat before a warning is emitted',
    Errors: {
      DisconnectTimeout: 'Needs one of these 3600, -1, custom',
      GoodByeTimeout: 'Needs one of these seconds, minutes, hours, days, months',
      DisconnectNotification: 'Needs one of these -1, custom',
      CustomDisconnectUnit: 'Needs one of these 900, -1, custom',
      CustomDisconnectNotificationUnit: 'Needs one of these null, -1, custom',
      UninstallTimeout: 'Needs one of these 168, 1, custom',
    },
    HoursNumber: 'Custom timeout - {count, plural, =1 {# hour} other {# hours}}',
    IgnoreGoodbyeMessages: 'Ignore Goodbye messages from workloads,',
    FifteenMinutesCleanup:
      "Wait for 15 minutes before cleaning up those workloads' IP addresses from its active policy, and then",
    FifteenMinutes: 'Wait 15 Minutes before IP Cleanup - High Security {default}',
    KeepAllIPsAndNeverAutoRemove:
      'Keep all IP addresses in policy and never automatically remove unused IP addresses, and',
    KeepAllIPsAndNeverAutoCleanup:
      'Keep all IP addresses in policy and never automatically cleanup unused IP addresses, and',
    ListenGoodbye: 'Listen for Goodbye messages from the VEN, then',
    MinDisconnect: 'Minimum is {count, plural, =1 {# second} other {# seconds}}',
    MinGoodbye: 'Minimum is 0 seconds',
    MinutesNumber: 'Custom timeout - {count, plural, =1 {# minute} other {# minutes}}',
    Months: 'Months',
    MonthsNumber: 'Custom timeout - {count, plural, =1 {# month} other {# months}}',
    NeverCleanUp: 'Never clean up - Highest Availability',
    NeverDisconnectOrQuarantine: 'Never disconnect or quarantine workloads that fail to heartbeat,',
    NeverTimeout: 'Never Timeout or Quarantine - Highest Availability',
    PushPolicyRemainingDecommission:
      'Push an updated policy to the peer workloads that were previously allowed to communicate with the removed workloads.',
    PushPolicyRemainingDisconnect:
      'Push an updated policy to the peer workloads that were previously allowed to communicate with the quarantined workloads.',
    OfflineTimers: 'Offline Timers',
    OneHourWait: 'Wait One Hour before Timeout - High Security {default}',
    OneQuarterWait: 'Wait one-quarter of the Disconnect and Quarantine Timer - {default}',
    CleanUpQuarantineWorkloads: 'Remove the quarantined workloads IP addresses from its active policy, and then',
    NeverTimeoutOrQuarantine:
      'If the Disconnect and Quarantine Timer is set to "Never Timeout or Quarantine", a warning event will be emitted if 15 minutes have passed since the previous VEN heartbeat.',
    DisconnectTimer20minOrLess:
      'If the Disconnect and Quarantine Timer is set to 20 minutes or less, the warning will be emitted 5 minutes after the previous VEN heartbeat. In no case will a warning be emitted fewer than 5 minutes since the previous VEN heartbeat.',
    RequiresManualRemoval: 'Require a removal of those unused IP addresses.',
    SecondsNumber: 'Custom timeout - {count, plural, =1 {# second} other {# seconds}}',
    Title: 'PCE to Workloads Global Offline Timers',
    Wait1Hour:
      'Wait for an hour for the disconnected workloads to heartbeat and then quarantine those workloads that do not respond at the end of the hour, then',
    WaitForSpecifiedTime:
      'Wait for the specified time period for the disconnected workloads to heartbeat and then quarantine those workloads that do not respond at the end of that time period, then',
    WaitOneQuarter:
      'Wait one-quarter of the Disconnect and Quarantine Timer for the VEN to heartbeat before emitting a warning event indicating that the VEN has missed heartbeats. The VEN will appear in a warning state on the VEN pages.',
    WaitForSpecifiedTimeGoodbye:
      "Wait for the specified time period before cleaning up those workloads' IP addresses from its active policy, and then",
    WaitTime: 'Wait Time',
    WaitForSpecifiedNotification:
      'Wait for the specified time period for the VEN to heartbeat before emitting a warning event indicating that the VEN has missed heartbeats. The VEN will appear in a warning state on the VEN pages.',
    Warning:
      '- Selecting the {option} option or choosing a very high custom timeout degrades the security posture because the PCE will not remove IP addresses that belonged to workloads that have been disconnected from those that were allowed to communicate with the disconnected workloads. You will need to remove the disconnected workloads from the PCE to ensure that their IPs are removed from the policy.',
    CustomWaitTimeError: 'Wait time must be a minimum of {count, plural, =1 {# second} other {# seconds}}',
    CustomNotificationError: 'This setting must be less than the value of the Disconnect and Quarantine Timer',
    CustomDisconnectError: 'This setting must be higher than the value of the Disconnect and Quarantine Warning',
    NotificationBodyHint:
      '- selecting a longer warning timer may reduce the time between when the warning is emitted and when the workload is removed from policy, leading to potential connectivity issues.\nIllumio recommends that the warning is set to at least 10 minutes (2 VEN heartbeat intervals) so that transient connectivity issues between the PCE and VEN do not result in a large number of warning events.',
    NeverTimeoutOption: 'Never Timeout or Quarantine',
    NeverCleanUpOption: 'Never Clean up',
    WaitTimeRequired: 'Wait time field is required',
    CustomNotificationRequirement:
      'This setting must be greater than 5 minutes (300 seconds) and less than the value of the Disconnect and Quarantine Timer.',
    DecommissionIpCleanupNote:
      'Note: when the wait time is set to a value lower than 15 minutes (900 seconds), this option offers the highest level of security.',
    UninstallTimer: 'VEN Uninstall Timers',
    UninstallTimeout: 'VEN Uninstall Timeout',
    SevenDayUninstallWait: 'Wait 7 days before timeout {default}',
    CustomUninstallWait: 'Custom Timeout - Wait a Specified Time before Uninstall Timeout',
    WillWaitSevenDaysUninstall:
      'Wait 7 days after a VEN is unpaired from the PCE before timing out the VEN unpair request.',
    UninstallSideNote: 'Time period to wait (default = 7 days) with no heartbeat before a VEN Uninstalls itself',
    UninstallIfHeartBeatInSevenDays:
      'If the VEN heartbeats anytime within the 7-day interval, the VEN will be instructed to uninstall itself. Other API requests from this VEN in this time interval will fail.',
    MustManuallyUninstallVenAfterSevenDays:
      'After 7 days, the VEN record may be completely removed from the PCE. All API requests from this VEN will fail, and the VEN must be manually uninstalled',
    WaitForSpecifiedAfterUnpairedNotification:
      'Wait for the specified time period after a VEN is unpaired from the PCE before timing out the VEN unpair request.',
    SpecifiedVenHeartbeatIntervalSideNote:
      'If the VEN heartbeats anytime within the specified interval, the VEN will be instructed to uninstall itself. Other API requests from this VEN in this time interval will fail.',
    SpecifiedTimeVenRecordRemovedSideNote:
      'After the specified time period, the VEN record may be completely removed from the PCE. All API requests from this VEN will fail, and the VEN must be manually uninstalled.',
    MinUninstall: 'Minimum is 1 hour.',
    CustomWaitTimeErrorHours: 'Wait time must be a minimum of {count, plural, =1 {# hour} other {# hours}}',
    UninstallBodyHintWarning:
      '- after the specified time period, API requests from VENs unpaired from the PCE will fail, leading to frequent “authentication failed” errors. The VEN must be manually uninstalled from the host. Use a longer timeout if VENs are often unpaired from the PCE but hosts are offline for an extended time before coming back online.',
  },
  PairingProfiles: {
    AddNew: 'Add a new Pairing Profile',
    VenRelease: 'Current Default - {venVersion}',
    CommandLineOverrides: 'Command Line Overrides',
    CustomTimeRange: 'Custom Time Range',
    Delete: 'Remove Pairing {count, plural, =1 {Profile} other {Profiles}}',
    DeleteConfirm:
      'Are you sure you want to remove {count, plural, =1 {the selected Pairing Profile} other {# Pairing Profiles}}?',
    InitialVenVersion: 'Initial VEN Version',
    CreateWithVEN: 'Install the selected VEN version. A VEN version must be selected',
    Install: 'Install the selected VEN version',
    Key: 'Key',
    KeyGen: 'Generate key',
    KeyMaxAge: 'Maximum Key Age',
    KeyUsage: 'Key Usage & Lifespan',
    LabelAssignment: 'Label Assignment',
    LastUse: 'Last Used On',
    NoMatchData: 'No matching Pairing Profiles to display',
    NoData: 'No Pairing Profiles to display',
    OneUse: 'One Use',
    OneWorkloadCanPaired: 'Only one Workload can be paired using this Pairing Profile',
    Pair: 'Pair',
    PairingKeys: 'Pairing Keys (active)',
    Pairing: 'Pairing',
    Pause: 'Pause Pairing',
    Profile: 'Pairing Profile',
    Profiles: 'Pairing Profiles',
    Resume: 'Resume Pairing',
    SinglePairing: 'Single pairing',
    Start: 'Start Pairing',
    Status: 'Pairing Status',
    Stop: 'Stop Pairing',
    Unlimited: 'unlimited',
    UnlimitedUses: 'Unlimited Uses',
    UnlimitedTime: 'No time limit set for pairing Workloads with this Pairing Profile',
    UnlimitedWorkloadCanPaired: 'Unlimited Workloads can be paired using this Pairing Profile',
    TLSWarning:
      'If you are pairing an older OS that does not support TLS 1.2, then the pairing script may fail to communicate with the PCE. In case of failure, you may upgrade your OS to support TLS 1.2 or use alternate means of pairing',
    WhenKeyGenerated: 'Starting from when the key is generated',
    VersionErrorMessage: 'VEN release {version} is supported only on endpoints',
    WindowsVersionErrorMessage:
      'VEN release {version} is supported only on endpoints. The “-endpoint true” option must be specified in the pairing script prior to installation',

    Errors: {
      Stop: 'Unable to Stop Pair',
      Start: 'Unable to Start Pair',
      Edit: 'Unable to Edit Pairing Profile',
      Create: 'Unable to Create Pairing Profile',
    },

    Detail: {
      AllowRequests: 'Allow pairing requests from all valid keys generated by this Pairing Profile',
      DenyRequests: 'Deny pairing requests from all keys generated by this Pairing Profile',
      URI: 'URI',
    },
    List: {
      Message: {
        DeleteConfirm:
          'Are you sure you want to remove the selected Pairing {count, plural, =1 {Profile} other {Profiles}}?',
      },
    },
    Mixin: {
      AddValidLifespan: 'Please add a valid Key Lifespan.',
      CanSetLabel: '{labelTypeName} Label can be set',
      KeyLifespan: 'Key Lifespan',
      UsesPerKey: 'Uses Per Key',
      Placeholder: {
        PairingProfileDescription: 'Type a description for the new Pairing Profile',
        PairingProfileName: 'Type a name for the new Pairing Profile',
      },
    },
    Workloads: {
      AllowCustomLabels: 'Allow custom Labels',
      AllowCustomLabelsDesc: 'Allow the following Workload Label assignment during script execution',
      AllowPolicyAssignmentDesc: 'Allow Enforcement to be set during script execution',
      MacOS: 'macOS Pairing Script',
      Dependencies: 'Dependencies',
      GeneratedOn: 'Generated On',
      InitialState: 'Initial Workload State',
      Lifespan: 'Lifespan',
      Linux: 'Linux OS Pairing Script',
      LockLabelAssignment: 'Lock Label assignment',
      LockLabelAssignmentDesc: 'Deny Workload Label assignment during script execution',
      LockPolicyAssignmentDesc: 'Deny Enforcement changes during script execution',
      LockSomeLabels: 'Lock some of the Labels',
      LockSomeLabelsDesc: 'Deny Label assignment changes to the following Labels: {labels}',
      OperatingSystems: 'Operating Systems',
      PairingKeySettings: 'Pairing Key Settings',
      PairingScripts: 'Pairing Scripts',
      PickProfile: 'Pick a Pairing Profile',
      PickGroup: 'Pick an Install Script',
      RemainingUses: 'Remaining Uses',
      RequiredPackages: 'Required OS Packages',
      SourcePairingProfile: 'Source Pairing Profile',
      SupportedVersions: 'Supported Versions',
      Windows: 'Windows OS Pairing Script',
    },
  },
  EnforcementBoundary: {AddNew: 'Add a new Enforcement Boundary'},
  PasswordPolicy: {
    PasswordPolicy: 'Password Policy',
    PasswordPolicyEdit: 'Password Policy Edit',
    PasswordRequirements: 'Password requirements',
    PasswordExpirationRetention: 'Password expiration and reuse',
    ExpirationDescription: 'The password will expire...',
    ReuseHistoryDescription: 'Do not allow password to be reused until after...',
    SimilarityDescription: 'Do not allow a password unless it changes...',
    RequirementIncreasedWarning:
      'Password requirements increased. All Users will need to reset their password at next login.',
    SessionTimeout: 'Session Timeout',
    SessionTimeoutDescription: 'Session will timeout...',
    SessionTimeoutWarning: 'Session Timeout Warning',
    Logout: 'Log Out',
    ContinueSession: 'Continue Session',
    LogoutWarningDescription:
      'You will be logged out automatically{seconds, plural, =0 {} =1 { in {number} second} other { in {number} seconds}}',
    Errors: {
      Integer: 'Value must be an integer',
      Number: 'Value must be a number',
      Required: 'Value cannot be empty',
    },
    PolicyKeys: {
      MinimumLength: 'Minimum length',
      CharacterCategories: 'Character categories',
      MinimumCharacters: 'Minimum characters per category',
      Expiration: 'Expiration',
      ReuseHistory: 'Reuse History',
      Similarity: 'Similarity',
      Timeout: 'Timeout',
    },
    ValidHint: {
      RangeXtoY: 'Valid range {rangeStart, number} - {rangeEnd, number}',
      RequiredXorMore: 'Required {count, number} or more',
    },
    ComplexityChars: {
      Lowercase: 'a-z',
      Uppercase: 'A-Z',
      Number: '0-9',
      Symbol: '!@#$%^&*<>?',
    },
    CharactersLower: 'characters',
    DaysLower: 'days',
    PasswordChanges: 'password changes',
    CharactersAndPositions: 'characters and positions from current password',
    MinutesLower: 'minutes',
  },
  PCE: {
    FQDN: 'FQDN',
    Started: 'PCE started',
    Stopped: 'PCE stopped',
    ItemUnderDifferentPCE: 'This is managed by a different PCE.',
  },
  Policy: {
    ActiveVersion: 'Active version',
    AllowRuleRemoveError: 'Unable to delete {count, plural, =1 {# allow rule} other {# allow rules}}',
    ConfirmRemove: 'Are you sure you want to remove {count, plural, =1 {this rule} other {these # rules}}?',
    DenyRuleRemoveError: 'Unable to delete {count, plural, =1 {# deny rule} other {# deny rules}}',
    EditRules: 'Edit Organizational Policy',
    RemoveRule: 'Remove Rule',
    YouViewingActiveVersion: 'You are viewing the active version',
    YouViewingActiveVersionVService: 'You are viewing the active version of Virtual Service',
    YouViewingActiveVersionVServer: 'You are viewing the active version of Virtual Server',
    BuildDesc: 'Build Rules without events',
    ChangeFailed: 'Failed to apply policy changes',
    Check: 'Policy Check',
    Configuration: 'Policy Configuration',
    EditDraftVersion: 'Edit the draft version.',
    IPListHasUnsavedChangesPending: 'The IP list has unsaved changes pending',
    ViewingDraftVersion: 'Viewing draft version',
    YouViewingDraftVersion: 'You are viewing the draft version',
    YouViewingDraftVersionVService: 'You are viewing the draft version of Virtual Service',
    YouViewingDraftVersionVServer: 'You are viewing the draft version of Virtual Server',
    RulesetHasUnsavedChangesPending: 'The ruleset has unsaved changes pending',
    YouViewEditVService: 'You are editing Virtual Service',
    IdleDesc: "The VEN is installed but no changes have been made to Workload's firewall or iptables settings",
    IdleDescIllumio: "Illumio does not control this workload's firewall settings",
    Inbound: 'Inbound Policy',
    Mixed: 'Mixed',
    UnmanagedDesc: 'Unmanaged Workloads do not have a VEN installed. Workload state does not apply.',
    UIColumnOrder: 'UI Column Order',
    ProviderFirst: 'Display Provider Column First',
    ConsumerFirst: 'Display Consumer Column First',
    SourceFirst: 'Display Source Column First',
    DestinationFirst: 'Display Destination Column First',
    ProviderConsumerOrder: 'Provider & Consumer Order',
    SourceDestinationOrder: 'Source & Destination Order',
    Objects: 'Policy Objects',
    OldVersion: 'Old version',
    OldVersionDesc: 'You are viewing version {version}',
    Outbound: 'Outbound Policy',
    NoOutboundDeny: 'There are no outbound deny rules defined',
    NoOutboundAllow: 'There are no outbound allow rules defined',
    NoFilteredRules: 'No rules found to match the filter',
    InUseByOrganization: 'In Use By Organization Policy',
    Organization: 'Organization Policy',
    Organizational: 'Organizational Policy',
    ReadOnlyVersion: 'Read Only version',
    ReadOnlyMode: 'Outbound policies for any group are set up in the Organizational Policy',
    RequiredProcessesNotRunning: 'Required processes not running',
    SecurityPolicyStageFailed: 'Security policy stage failed',
    SecureConnectionFailed: 'Failed to establish one or more SecureConnect connections',
    SelectNetworkProfile: 'Select a Network Profile',
    Settings: 'Policy Settings',
    RequireProvisionNote: 'Require Provision Note',
    State: 'Policy State',
    UpdateMode: 'Policy Update Mode',
    StateChangeCant: 'Cannot Change Policy State',
    StateChangeConfirm: 'Confirm Workload Enforcement Change',
    VisibilityChangeConfirm: 'Confirm Workload Visibility Change',
    StateChangeMessage:
      '<b data-tid="{countTid}">{count, number}</b> of <b data-tid="{countTid}">{total, number}</b> will change to  <b>{title}</b>',
    ManagedStateChangeMessage:
      '<b data-tid="{countTID}">{count, plural, =1 {# Workload} other {# Workloads}}</b> will be changed to <b data-tid="{titleTID}">{title}</b> mode',
    SuspendedMessage: 'VEN Suspended',
    TamperingRevertFailed: 'Failed to revert policy tampering',
    TestDesc: 'Test Rules and log events',
    UnnamedDataCenter: 'Unnamed Datacenter',
    UnknownLocation: 'Unknown Location',
    UnknownDataCenterAndLocation: 'Unnamed Datacenter, Unknown Location',
    UnmanagedWorkloadsNotChangeCount:
      '{count} {countNumber, plural, =1 {Unmanaged Workload} other {Unmanaged Workloads}} will not change.',
    ViewActiveVersion: 'View the active version.',
    ViewDraftVersion: 'View the draft version.',
    ViewingActiveVersion: 'This list shows only active Rules',
    ViewingActiveVersionEdge:
      'This list shows only active rules for domain interfaces. For non-domain interfaces, all outbound traffic is allowed and all inbound traffic is blocked',
    PolicyUpdatesExist: 'There are changes to the policy or referenced policy objects',
    BindingsWithoutProvision: 'Bindings can be changed without having to provision the changes',
  },
  Firewall: {
    NoMatch: 'No matching Scopes to display',
    ScopeRequired: 'Scope is required',
    Mode: 'Firewall Coexistence Mode',
    DuplicatedScopesAndLabelsForPolicyState: 'Static Policy with the same Scope has already been configured',
    DuplicatedScopesAndLabelsForFirewallCoexistence:
      'Cannot create another Firewall Coexistence Mode with the overlap Scope, irrespective of the Primary Firewall option selected',
    PolicyStateAllError: 'At least one named label must be selected to make Workload Static',
    CoexistenceAllError: "Scope cannot be 'All Workloads' when selecting Enforced or Illuminated policy state.",
    StaticPolicyAllConfirmation: "Adding 'All Workloads' will remove all other Static Policy Scopes.",
    Coexistence: {
      Title: 'Firewall Coexistence',
      DefaultMode: 'Illumio Core is the exclusive firewall',
      ModeDesc:
        'Illumio Core is the exclusive firewall by default.\nConfigure firewall coexistence as needed for all workloads and/or specific labels.',
      ModeDescEdge:
        'Illumio Edge co-exists with your existing firewall. If there’s a Windows firewall and an Illumio Edge VEN on an endpoint, traffic is allowed only if both the firewalls allow it.',
      IllumioAsPrimaryFirewall: 'Illumio Core is Primary Firewall',
      SetIllumioAsPrimaryFirewall: 'Set Illumio Core as the Primary Firewall',
      SetIllumioAsNotPrimaryFirewall: 'Do not set Illumio Core as the Primary Firewall',
      AddScopeForFirewallCoexistence: 'Add Scope for Firewall Coexistence',
      EditScopeForFirewallCoexistence: 'Edit Scope for Firewall Coexistence',
      BuildTestEnforced: 'Build / Test / Enforced',
      BuildTest: 'Build / Test',
      AllOnlyWarning: "'All Workloads' cannot coexist with other Scopes and Labels",
      AllConfirmation: "Adding 'All Workloads' will remove all other coexistence Scopes.",
      NoData: 'Illumio is the exclusive firewall. There are no label settings for firewall coexistence',
    },
  },
  Port: {
    InvalidRange: 'Invalid port range',
    InvalidPortPortRange: 'Invalid Port/Port Range',
    InvalidPortValue: 'Invalid port value',
    InvalidTypeCodeValue: 'Invalid type/code value',
    InvalidTypeValue: 'Invalid type value',
    InvalidCodeValue: 'Invalid code value',
    InvalidValue: 'Invalid value',
    IpAddressPlaceHolder: 'E.g. 192.168.100.1',
    CIDRBlockPlaceHolder: 'E.g. 192.168.100.1/20',
    EmptyProcessName: 'Empty process name',
    InvalidProcess: 'Invalid process',
    InvalidServiceName: 'Invalid service name',
    ServiceNameRequiresQuotes: 'Service name requires quotes',
    EmptyProcessorService: 'Empty process or service',
    Errors: {
      Invalid: 'Needs to be {port}',
    },
    Missing: 'Missing port',
    Overrides: 'Override ports',
    OverridesOptional: 'Override ports (Optional)',
    Port: 'Port',
    Ports: 'Ports',
    PortRange: 'Port Range',
    Protocol: 'Port/Protocol',
    ProtocolProcess: 'Provider Port/Protocol/Process',
    PortProcessOnly: 'Port/Process',
    PortProcess: 'Provider Port/Process',
    ConsumerProcess: 'Consumer Process',
    ProviderProcess: 'Provider Process',
    ProviderPortProcessService: 'Provider Port Process',
    PortProtocolProcessService: 'Services Name',
    PortProtocolProcessServiceIsNot: 'Services Name is not',
    ProtocolInvalid: 'Invalid port/protocol combination',
    ProcessNamedSchedule: 'Schedule',
    ProcessServiceRequiresQuotes: 'Process or service requires quotes',
    ProcessPath: 'Process path',
    ToAddPortProtocol: 'To add ports and protocols',
    ToAddPortProtocolProcess: 'To add ports, protocols, and processes/services:',
    ToAddProcessAndWindowsService:
      'To add process and Windows services, use quotation marks and the full path for processes',
    WindowsServiceExample: 'c:\\windows\\myprocess.exe',
    WindowsServiceExampleUNC: '\\\\windows\\process\\myprocess.exe',
    WindowsServiceExampleWithSystemEnvVariable: '%systemroot%\\system32\\myprocess.exe',
    PortProtocolPlaceHolder: 'E.g. 22, 514 UDP, ICMP',
    PortRangePlaceHolder: 'E.g. 71-74, 80-90 TCP',
    WindowsServicePlaceHolder: 'E.g. myprocess',
    ProcessNamePlaceHolder: 'E.g. c:\\windows\\myprocess.exe',
  },
  Protocol: {
    Any: 'Any',
    CFTP: 'CFTP',
    GRE: 'GRE',
    ICMP: 'ICMP',
    ICMPv6: 'ICMPv6',
    IGMP: 'IGMP',
    IPIP: 'IPIP',
    IPPC: 'IPPC',
    IPv4: 'IPv4',
    IPv6: 'IPv6',
    KRYPTOLAN: 'KRYPTOLAN',
    RDP: 'RDP',
    RVD: 'RVD',
    SATEXPAK: 'SAT-EXPAK',
    SMP: 'SMP',
    TCP: 'TCP',
    UDP: 'UDP',
    ESP: 'ESP',
    AH: 'AH',
    DCCP: 'DCCP',
    OSPF: 'OSPF',
    SCTP: 'SCTP',
  },
  Provision: {
    All: 'Provision All',
    AllChangesActiveCurrent: 'All policy changes are active and current.',
    CannotProvision:
      'Not Acceptable. One or more specified objects either do not exist, or do not have a draft version.',
    Changes: 'Provision Changes',
    Confirm: 'Confirm & Provision',
    ConfirmSave: 'Confirm & Save',
    ConfirmChanges: 'Confirm ruleset changes',
    ConfirmChangesWillBeSaved: 'Are you sure? Your changes to the ruleset will be saved',
    ContinuingInBackgroundMsg: 'Provisioning will continue in the background. Track progress on the {pageName} page.',
    Dependencies: 'Provision Dependencies',
    FollowingItemsAlso: 'The following items will also be provisioned',
    FollowingItemsNeed:
      'The selected items have dependencies that need to be provisioned before your items can be provisioned. Please contact an Admin to resolve this issue.',
    Versions: '{multiple, select, false {Policy Version} true {Policy Versions}}',
    EntitiesNumber: '{count, plural, =1 {# Entity} other {# Entities}}',
    Item: 'Item',
    Note: 'Provision Note',
    Now: 'Provision Now',
    PendingAddition: 'Addition Pending',
    PendingDeletion: 'Deletion Pending',
    PendingModification: 'Modification Pending',
    Added: 'Added',
    Modified: 'Modified',
    DraftChanges: 'Draft Changes',
    DraftChangeCount: 'Draft Changes ({count, number})',
    Pending: 'Pending Provision',
    PendingProvisioning: 'Changes Pending Provisioning',
    PendingNote: 'This is the draft policy. Provision to make it the active version.',
    ProvisioningPushesToVEN: 'The PCE recalculates policy and sends it to impacted VENs when you provision.',
    ProvisioningPushesToVENWithoutDependencies:
      'You cannot partially provision resources with more than 500 dependencies. All changes must be provisioned at the same time.',
    RevertingDiscardDraft: 'Reverting will discard all draft changes',
    RequiredBy: 'Required by',
    Review: 'Provision Review',
    SelectedItems: 'Provision selected items',
    Status: 'Provision Status',
    UpToDate: 'Up to date',
    SynchronizingPolicyChanges:
      'Synchronizing policy changes for {count, plural, =1 {one Workload} other {# Workloads}}...',
    ListenOnlyModePolicyChanges:
      'Policy changes for {count, plural, =1 {one Workload} other {# Workloads}} will be synchronized when Listen Only mode is disabled.',
    ListenOnlyModeMessage: 'PCE Listen Only mode is enabled.',
    SynchronizingCount: '{count, plural, =1 {1 Workload} other {# Workloads}} synchronizing...',
    ListenOnlyModeSynchronizeCount: '{count, plural, =1 {1 Workload} other {# Workloads}} pending synchronization',
    ViewLastCommit: 'View the last commit',
    ViewPolicyVersions: 'View Policy Versions',
    ProvisioningInfoMessage:
      'During this process, you can navigate to another page and policy synchronization will continue.',
    ProvisioningComplete: 'Provisioning complete',
    SavingComplete: 'Saving complete',
    ProvisioningStatus: 'Provisioning Status',
    ProvisioningInProgress: '{ellipsis, select, false {Provisioning in progress} true {Provisioning in progress ...}}',
    SavingInProgress: '{ellipsis, select, false {Saving in progress} true {Saving in progress ...}}',
    ProvisioningNoProgress: 'No provisioning in progress',
    ProvisioningDidNotAffect: 'Changes that were provisioned did not affect any Workloads',
    ProvisioningCompleteSynced: 'All policy changes are active and current',
    Unable: 'Unable to Provision',
    UnsavedIPListConfirmTitle: 'Confirm IP list changes',
    UnsavedIPListConfirmMessage: 'Are you sure? Your changes to the IP list will be saved',
    ListenOnlyMember: 'Members PCEs in listen only mode will not receive updated policy.',
    Settings: 'Provisioning',
    TallyLabel: {
      IPLists: '{count, plural, =1 {IP List} other {IP Lists}}',
      IPRanges: '{count, plural, =1 {IP Range} other {IP Ranges}}',
      VirtualServices: '{count, plural, =1 {Virtual Service} other {Virtual Services}}',
      LabelGroups: '{count, plural, =1 {Label Group} other {Label Groups}}',
      Rulesets: '{count, plural, =1 {Ruleset} other {Rulesets}}',
      SecureConnect: '{count, plural, =1 {Secure Connect Gateway} other {Secure Connect Gateways}}',
      Settings: '{count, plural, =1 {Setting} other {Settings}}',
      EnforcementBoundaries: '{count, plural, =1 {Enforcement Boundaries Rule} other {Enforcement Boundaries Rules}}',
    },
    Revert: {
      All: 'Revert All',
      Dependencies: 'Revert Dependencies',
      DiscardChangesToItems: 'Discard changes to the selected items',
      DiscardRulesetChanges: 'Discard ruleset changes',
      DiscardIPListChanges: 'Discard IP list changes',
      FollowingItems: 'The following items will also be reverted',
      Now: 'Revert Now',
      RemoveOrSelectDependencies: 'Either remove the dependencies, or go back and select those dependencies to revert.',
      Review: 'Revert Review',
      SelectedItemsHaveDependencies: 'The selected items have dependencies that you did not select to revert.',
      Unable: 'Unable to Revert',
    },
  },
  User: {
    AccessError: 'Access not authorized',
    AccessForbidden: 'Access denied or Access not authorized',
    Empty: 'PCE user/domain not yet configured',
    EmptyOrgs: 'User {name} does not have any permissions in this Organization',
  },
  RBAC: {
    AccessManagement: 'Access Management',
    AccessWizard: 'Access Wizard',
    AddPrincipals: 'Add Principals',
    AddNewPrincipalAndRole: 'Add a new Principal and Role',
    ChooseScope: 'Choose a Scope',
    ConfirmGrantedAccess: 'Confirm Granted Access',
    ConfirmLeaveTitle: 'Confirm Leave',
    DefaultReadOnly: 'Default Viewer',
    EditScope: 'Edit Scope',
    GrantAccess: 'Grant Access',
    GrantedAccess: 'Granted Access',
    GlobalRoles: 'Global Roles',
    NoPrincipalsAndRolesData: 'No Principals and Roles to display',
    NoRolesToDisplay: 'No Roles to display',
    NotValidScope: 'Not a valid scope',
    NoPermissionChange: 'All the permissions you selected already exist. No permission changes will be made.',
    CantHaveAllScope: 'Some or all of the selected roles cannot have All scope',
    PartialAppGroupScope:
      'Currently, the App Group Type is set to “{type}”. Because this RBAC scope includes a label type that is outside the App Group Type, principals assigned to this scope will be unable to view any App Groups. To resolve this, remove the outside label types from the RBAC scope.\nIf you require RBAC scopes with additional label types, principals can still use Illumination Plus to view App Groups.',
    PartialAppGroupScopeLoc:
      'Currently the App Group Type is set to “{type}”. Because this RBAC scope includes a specific location label, principals assigned to this scope will be unable to view any App Groups. To resolve this, set the RBAC scope Location to “All Locations”.',
    PleaseRemoveLocationLabel:
      'If you require RBAC scopes with specific locations, go to the App Groups List page and set the App Group Type to “Application | Environment | Location”.',
    PleaseSelectScope: 'Please select a scope',
    PleaseSelectRole: 'Please select a role',
    PleaseSelectPrincipal: 'Please select a principal',
    PleaseChangeRole: 'Please make a role change',
    PleaseChangePrincipal: 'Please select a new principal',
    Principals: 'Principals',
    PrincipalsAndRoles: 'Principals and Roles',
    PrincipalsTypeCount: {
      LocalUsers: '{count, plural, =0 {}  =1 {1 Local User} other {# Local Users}}',
      ExternalUsers: '{count, plural, =0 {}  =1 {1 External User} other {# External Users}}',
      ExternalGroups: '{count, plural, =0 {}  =1 {1 External Group} other {# External Groups}}',
    },
    PermissionsRemove: {
      NumPrincipals: '{count, plural, =1 {1 Principal} other {# Principals}}',
    },
    RemoveAccess: 'Remove Access',
    RemoveAccessToScopes: 'Remove Access to Scopes',
    RemoveAccessToScopesWarning: 'All access to the scopes will be removed',
    RemoveAccessWarning: 'All access will be removed',
    ScopeAccessRemoved: 'The access to the scopes was removed',
    ScopesAndRoles: 'Scopes and Roles',
    ScopeChangeWarning: 'The principals will no longer have access to the previous scope',
    SelectAPrincipal: 'Select a principal',
    SelectRoles: 'Select Roles',
    RemovePermissions: {
      Success: '{count, plural, =1 {Permission has} other {# Permissions have}} been successfully removed',
    },
    ManageWarning: 'You will not be able to manage this workload after this action.',
    ServiceAccounts: {
      AddNew: 'Add a new Service Account',
      NoServiceAccountsData: 'No Service Accounts to display',
      RolesAndScopes: 'Roles and Scopes',
      Placeholder: {
        ServiceAccountName: 'Type a name for the new Service Account',
        ServiceAccountDescription: 'Type a description for the new Service Account',
      },
      DeleteTitle: 'Remove {count, plural, =1 {Service Account} other {Service Accounts}}',
      DeleteConfirm:
        'Are you sure you want to remove {count, plural, =1 {this Service Account} other {# selected Service Accounts}}?',
      DeletedSuccessfully:
        '{count, plural, =1 {Service Account has} other {# Service Accounts have}} been successfully removed',
      DeletedAPIKeysSuccessfully: '{count, plural, =1 {API Key has} other {# API Keys have}} been successfully removed',
      CannotHaveAllScope: "{role} cannot have 'All' scope",
      CanOnlyHaveAllScope: "Global role can only have 'All' scope",
      DuplicatePermissions: 'Cannot have duplicate Roles and Scopes',
      APIKeysExpiresIn: 'API Key Expires in',
      ExpirationTimeText: 'Expiration cannot exceed the organization setting',
      Errors: {
        APIKeyCreate: 'Unable to Create API Key',
      },
    },
    WorkloadManager: 'Manage Workloads and Pairing Profiles that match scope.',
    UsersActivity: {
      Duration: 'Duration',
      LastLogin: 'Last Login',
      LoginType: 'Login Type',
      LoginTime: 'Login Time',
      LoginIP: 'Login IP',
      UserActivity: 'User Activity',
      EffectiveGroups: 'Effective Groups',
      NoData: 'No User Activities to display',

      Detail: {
        NoDataToDisplay: 'No Permissions to display',
      },
    },
    ExternalGroup: {
      AddNew: 'Add a new External Group',
      NoData: 'No External Groups to display',
      NoMatchData: 'No Matching External Groups to display',
    },
    ExternalUser: {
      AddNew: 'Add a new External User',
      NoData: 'No External Users to display',
      NoMatchData: 'No Matching External Users to display',
    },
    LocalUser: {
      AddNew: 'Add a new Local User',
      NoData: 'No Local Users to display',
      NoMatchData: 'No Matching Local Users to display',
    },
    ScopedRole: {
      AddNew: 'Add a new Scoped Role',
      NoData: 'No Scoped Roles to display',
    },
  },
  Role: {
    Admin: 'Admin',
    Collapse: 'Collapse Role',
    FullRulesetManager: 'Full Ruleset Manager',
    GlobalOrgOwner: 'Global Organization Owner',
    GlobalOrgOwnerSubtext: 'Manage all resources, users, and Security Settings',
    GlobalAdminSubtext: 'Manage all resources and security settings. Cannot manage users and roles',
    GlobalReadOnlySubtext:
      'Read-only access to all resources. Additional Ruleset modification and provisioning capabilities may be added by adding roles to the user.',
    GlobalAdmin: 'Global Administrator',
    GlobalPolicyObjectProvisioner: 'Global Policy Object Provisioner',
    GlobalReadOnly: 'Global Viewer',
    GroupCollapse: 'Collapse Group Roles',
    GroupExpand: 'Expand Group Roles',
    Expand: 'Expand Role',
    Label: 'Role Label',
    LimitedRulesetManager: 'Limited Ruleset Manager',
    Owner: 'Owner',
    RulesetManagement: 'Ruleset Management',
    RulesetManager: 'Ruleset Manager',
    RulesetProvisioner: 'Ruleset Provisioner',
    RulesetViewer: 'Ruleset Viewer',
    WorkloadManager: 'Workload Manager',
    Set: 'Set Role',
    ClickToSelect: 'Click to Select Role',
  },
  Rule: {
    Add: 'Add Rule',
    Merge: 'Merge Rules',
    MergeMessage:
      'A Rule exists with the same Service but different {endpoint}.  This Rule will be merged with the existing Rule.',
    View: 'View Rule',
    Validation: {
      CorrectAllErrors: 'Cannot save. Correct all errors in the Rules.',
      DuplicateScopesNotAllowed: 'Duplicate Scopes are not allowed',
      IPListsCantBeProviderAndConsumer: 'Cannot use IP Lists for both Providers and Consumers.',
      NamedAppLabelCantBeUsed: "Cannot use a named Application Label in a Rule unless the Scope uses ''{use}''.",
      NamedAppLabelIsUsed: "A named Application Label is used in a Rule. This Scope must use ''{use}''.",
      NamedEnvLabelCantBeUsed: "Cannot use a named Environment Label in a Rule unless the Scope uses ''{use}''.",
      NamedEnvLabelIsUsed: "A named Environment Label is used in a Rule. The Scope must use ''{use}''.",
      NamedLocLabelCantBeUsed: "Cannot use a named Location Label in a Rule unless the Scope uses ''{use}''.",
      NamedLocLabelIsUsed: "A named Location Label is used in a Rule. The Scope must use ''{use}''.",
      ProcessServicesCantWithIPListProvider: 'Process-based Services cannot be used with IP List providers',
      RemoveUnpairedWorkloads: 'Cannot save. Remove unpaired Workloads in red.',
      RemoveUnpairedWorkloadsInRed: 'Remove unpaired Workloads in red.',
      RequiresProviderServiceConsumer: 'Cannot save. A Rule requires a Provider, Service, and Consumer.',
      RequiresProviderConsumer:
        'Select {type, select, providers {Providers} consumers {Consumers}} except IP Lists or named Workloads',
      UseWorkloadSubnetWithLabelsOrAllWorkloads:
        "You must use a Label, Label Group, or 'All Workloads' with 'Use Workload Subnets'",
      RequiresLabel: 'Cannot save. A Custom iptables Rule requires a Label.',
      SecureConnectCantWithIPListBoundVirtual:
        "SecureConnect is not supported for Rules that use IP Lists, 'Uses Virtual Services and Workloads', or 'Uses Virtual Services only' for Providers or Consumers.",
      ScopeLabelInRulesetWithAllLabel:
        "Cannot create a Scope with a named Label when a Scope already exists with a corresponding 'All' Label.\nYou can edit the existing Scope to include a named Label in place of 'All'.",
      ScopeRequiresLabels: 'Cannot save. A Scope requires 3 Labels or Label Groups.',
      UserGroupsWithLabelOrWorkload: 'You must use a Label, Label Group, or Workload with a User Group',
      UserGroupsCantWithIPListConsumer: 'Cannot use User Groups if IP Lists is a Consumer.',
      StatelessWithSecureConnect: 'SecureConnect is not supported with Stateless Rules.',
      StatelessWithMachineAuth: 'Machine Authentication is not supported for Stateless Rules.',
      StatelessWithLabelGroup: 'Label Group in Consumers with a Stateless Rule is not supported',
      StatelessWithIPList: 'IP Lists in Providers with a Stateless Rule is not supported',
      StatelessInvalidConsumers:
        'A Stateless Rule can only have the following types of Consumer: Any IP List plus All Workloads, or a Label (maximum one of a specific type) , or an individual item',
      MachineAuthWithAllServices: 'Machine Authentication is not supported for Rules that use All Services',
      MachineAuthWithNullService:
        'Machine Authentication is not supported for Rules that use From Providers for Services',
      MachineAuthWithNullUbService:
        'Machine Authentication is not supported for Rules that use IP Lists, ‘Uses Virtual Services and Workloads’, or ‘Uses Virtual Services only’ for Providers or Consumers.',
      SecureConnectWithNullUbService:
        "SecureConnect is not supported for Rules that use IP Lists, 'Uses Virtual Services and Workloads', or 'Uses Virtual Services only' for Providers.",
      SecureConnectWithSecurityPrincipal: 'SecureConnect cannot be used with Security Principal.',
      ProviderIpListWithMachineAuth:
        'Machine Authentication is not supported for Rules that use IP Lists for Providers.',
      ConsumerIpListWithMachineAuth:
        'Machine Authentication is not supported for Rules that use IP Lists for Consumers.',
      ProviderWithIpListScopedUser:
        'Users with Scoped Roles cannot create Extra-Scope Rules which have IP Lists as Providers.',
      ProviderWithIpListWorkload:
        "Cannot use IP Lists or Workloads if you select 'Uses Virtual Services only' for a Provider.",
      ProviderIpListWindowsService: 'Cannot use Windows Services as a Providing Service when IP Lists is the Provider.',
      ProviderIpListVirtualServiceVirtualServerWorkload:
        "Cannot use IP Lists, Virtual Services, Virtual Servers, or Workloads along with 'Uses Virtual Services and Workloads' for Providers.",
      ConsumerWithVirtualService:
        "To use Virtual Services for Consumers, you must first select 'Uses Virtual Services only'.",
      BothVirtualServicesOnlyVirtualServicesWorkloads:
        "Cannot use 'Uses Virtual Services and Workloads' and 'Uses Virtual Services only' together for Consumers.",
      ConsumerIpListWorkload:
        "Cannot use IP Lists or Workloads if you select 'Uses Virtual Services only' for a Consumer.",
      ConsumerIpListVirtualServiceVirtualServerWorkload:
        "Cannot use IP Lists, Virtual Services, or Workloads along with 'Uses Virtual Services and Workloads' for Consumers.",
      ProvidingServicesVirtualServices: "Cannot use a Providing Service if 'Uses Virtual Services only' is a Provider.",
      ProviderWithVirtualService:
        "To use Virtual Services or Virtual Servers for Providers, you must first select 'Uses Virtual Services only'.",
      ContainerHostProviders:
        "Only Labels, Workloads, or All Workloads can be used as Providers if 'Container Host' is the Consumer.",
      ContainerHostItself: "Cannot use 'Container Host' along with any other Consumer.",
      ContainerHostSecureConnectMachineAuthStateless:
        "Cannot use SecureConnect, Stateless Rules, or Machine Authentication in a Rule if 'Container Host' is the Consumer.",
      ContainerHostWindowsService: "Cannot use Windows services with 'Container Host'.",
      ContainerHostNonTcpService: "Cannot use non TCP Port or Port Range with 'Container Host'.",
      NonCorporateMustUseIpList:
        "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' is only supported for Rules that contain IP List(s) as Consumers or Providers",
      CantUseSecureConnectWithNonDefaultNetwork:
        "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' cannot be used with 'SecureConnect'",
      CantUseMachineAuthWithNonDefaultNetwork:
        "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' cannot be used with 'Machine Authentication'",
      NetworkTypeMustBeDefaultCorporate:
        "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' cannot be used with the 'Use Virtual Services only' option as Consumers or Providers",
      ConsumerMustHaveOnlyIPLists:
        "Consumers must have only IP List(s) with 'Non-Corporate Networks (Endpoints Only)' or 'All Networks'",
      ProviderMustHaveOnlyIPLists:
        "Providers must have only IP List(s) with 'Non-Corporate Networks (Endpoints Only)' or 'All Networks'",
      LabelWithWarning:
        'The highlighted labels or label groups are used in the ruleset scope and in this rule. This may result in the rule being excluded from the policy.',
      InvalidFormat: 'Invalid format',
      InvalidParameter: 'Ip table rules contains Invalid parameter',
      InvalidChainName: 'Ip table rules contains Invalid chain name',
      InvalidTableName: 'Ip table rules contains Invalid table name',
      InvalidCharacter: 'Ip table rules contains Invalid character',
      OverlappingRules: 'Ip table rules contains Overlapping rules',
      CannotUseWorkloadSubnetsWithRuleOptions:
        "SecureConnect and Machine Auth cannot be used with 'Use Workload Subnets'",
    },
  },
  PolicyGenerator: {
    PolicyGenerator: 'Policy Generator',
    PolicyGeneratorTag: '(Policy Generator)',
    AppGroupLevel: 'App Group Level',
    AppGroupDisabled: 'App Group Type must be set before the Policy Generator can be used',
    StartPolicyGenerator: 'Start Policy Generator',
    IllumioGenerated: 'Illumio Generated',
    PolicyGeneratorRuleset: 'Default Policy Generator Ruleset',
    MakePolicyGeneratorRuleset: 'Make Default Policy Generator Ruleset',
    ConfigureRules: 'Configure Rules',
    RulesetScope: 'Ruleset Scope',
    IntraScopeRules: 'Intra-Scope Rules',
    ExtraScopeRules: 'Extra-Scope Rules',
    SelectAppGroup: 'Select App Group',
    ExistingRules: 'Connections with Existing Rules',
    Find: 'Find',
    FilterPlaceholder: 'Type to Search for Labels, Ports, Protocols, or Transmission Type',
    FilterIpPlaceholder: 'Type to Search for Labels, Ports, Protocols, IP Addresses, or Transmission Type',
    Included: 'Included',
    Excluded: 'Excluded',
    IncludeAll: 'Include All',
    ExcludeAll: 'Exclude All',
    IncludeSome: 'Include {count}',
    ExcludeSome: 'Exclude {count}',
    MustHaveRoleLabel: 'Workloads must have a Role Label to write Rules',
    AutoDescription: 'Automatically generated using the Illumio Policy Generator',
    ServiceAutoDescription:
      'Automatically generated using the Illumio Policy Generator, based on traffic seen for "{processName}" process "{serviceName}" service.',
    NoConnections: 'No Connections Available',
    AllExcluded: 'If all connections are excluded, no Rules will be written.',
    ServiceName: 'Service - {port} {protocol}',
    ServiceNameProtocol: 'Service - {protocol}',
    WindowsServiceInstructions: 'Cut and paste the following to use the process or service:',
    WindowsServiceName: 'Windows Service - {port} {protocol}',
    WindowsServiceNameProtocol: 'Windows Service - {protocol}',
    WindowsServiceHelp: '"c:\\\\<ADD PATH HERE>\\\\{process}"',
    Connections: '{count, plural, =0 {0 Connections} =1 {1 Connection} other {# Connections}}',
    Scopes:
      '{count, plural, =0 {<strong>0</strong> Scopes} =1 {<strong>1</strong> Scope} other {<strong>#</strong> Scopes}}',
    Rules:
      '{count, plural, =0 {<strong>0</strong> Rules} =1 {<strong>1</strong> Rule} other {<strong>#</strong> Rules}}',
    Services:
      '{count, plural, =0 {<strong>0</strong> Services} =1 {<strong>1</strong> Service} other {<strong>#</strong> Services}}',
    NewRules: '{count, plural, =1 {<strong>1</strong> New Rule} other {<strong>#</strong> New Rules}}',
    NewServices:
      '{count, plural, =0 {<strong>0</strong> New Services} =1 {<strong>1</strong> New Service} other {<strong>#</strong> New Services}}',
    NewIpLists:
      '{count, plural, =0 {<strong>0</strong> New Ip Lists} =1 {<strong>1</strong> New Ip List} other {<strong>#</strong> New Ip Lists}}',
    NewRulesets:
      '{count, plural, =0 {<strong>0</strong> Rulesets} =1 {<strong>1</strong> Ruleset} other {<strong>#</strong> Rulesets}}',
    SaveRules: 'Save Rules?',
    RulesWillBeSaved: '{count, plural, =0 {No Rules} =1 {1 Rule} other {# Rules}} will be saved',
    RulesWillBeDeleted: '{count, plural, =0 {No Rules} =1 {1 Rule} other {# Rules}} will be deleted',
    RulesetsWillBeSaved: '{count, plural, =0 {No Rulesets} =1 {1 Ruleset} other {# Rulesets}} will be saved',
    ServiceCreationWarning: '{count, plural, =0 {No Services} =1 {1 Service} other {# Services}} will be saved',
    IpListCreationWarning: '{count, plural, =0 {No Ip Lists} =1 {1 Ip List} other {# Ip Lists}} will be saved',
    ServiceWaitTime: 'It will take approximately {count, plural, =1 {1 minute} other {# minutes}} to complete',
    MissingLabels: 'Incomplete Labels',
    LargeServices: 'Large Number of Services',
    LargeServicesWarning: 'The large number of specified Services will result in a large number of Rules.',
    LargeServicesContinue: 'Are you sure you want to continue? Or would you like to write All Services Rules?',
    TooManyRules: 'Too Many Rules',
    TooManyRulesMessage:
      'The number of Rules exceeds the number allowed for a Ruleset. We recommend using more broad options.',
    TooManyConnections: 'Too many connections to generate rules.',
    TooManyConnectionsSelectFewer: 'Too many connections to generate rules, please select fewer Extra-Scope App Groups',
    Addresses: 'Addresses',
    SegmentMultipleAppGroups: 'Segment {count, plural, =0 {} =1 {App Group} other {Multiple App Groups}}',
    EditService: 'Edit Service',
    EditServiceUseAlways: 'Apply Changes to all matching ports',
    AddScopes: 'Add Scopes',
    AllTrafficNotAllowed: 'Not All Traffic Allowed',
    AllTrafficNotAllowedDescription:
      'Some traffic intended to be allowed by this rule will not be covered by this Service',
    DisabledRulesets: 'Rulesets Deleted or Disabled',
    ProvisionDeletedRulesets: 'Provision All Deleted Rulesets',
    RevertDeletedRulesets: 'Revert All Deleted Rulesets',
    DisabledRulesetsInstructions:
      'The following {count, plural, =1 {Ruleset is} other {Rulesets are}} <strong>disabled</strong>, <br/> and must be enabled to add new Rules:',
    DeletedRulesetsInstructions:
      'The following {count, plural, =1 {Ruleset is} other {Rulesets are}} <strong>deleted</strong>, <br/> and must be Provisioned or Reverted to add new Rules:',
    IncludedConnections: 'Included {count, plural, =1 {Connection} other {Connections}}',
    ExcludedConnections: 'Excluded  {count, plural, =1 {Connection} other {Connections}}',
    Constrained: 'Constrained',
    LastCalculated: 'Last Calculated',
    WithConnections: 'With Connections',
    WithoutConnections: 'Without Connections',
    IncompleteCoverage: 'Incomplete Coverage',
    ClickToViewAddresses: 'Click to view IP Addresses',
    ClickToViewVulnerabilites: 'Click to view exposed Vulnerabilities',
    ConfirmMergeRules:
      'Editing this Service allows you to merge rules. Do you want to proceed? To reverse the merge, refresh your browser and edit the Services again.',
    CalculationInProgress: 'Calculation in Progress',
    DisabledNotice: 'Policy Generator is Disabled',
    PortProtocolProcess: '{port} {protocol} {process, select, false {} unknown {} other {- {process}}}',
    MergeConsumers: 'Merge rules with common Provider and Service',
    MergeServices: 'Merge rules with common Provider and Consumer',
    PortServices: 'Use the port/protocol in a rule',
    RuleConstruction: 'Rule Construction',
    RuleMerging: 'Rule Merging',
    ResetRules: 'Reset Rules',
    ServiceUse: 'If a Service exists for a port/protocol, the Service is used in a rule',
    UseBroadServices: 'Allow the use of Services that include more than one port/protocol',
    BroadServiceWarning: 'This could result in rules for traffic outside the included connections',
    ServiceSubstitution: 'If a Service cannot be substituted for a port/protocol',
    UseAllServices: 'Allow traffic on all Services',
    ResetRulesReason:
      'This service covers multiple flows, to edit this service you sill need to reset your edits and start again.',
    Spinner: {
      GeneratingRuleCoverage: 'Generating Rule Coverage',
      CalculatingRuleCoverage: 'Calculating Rule Coverage',
      ExploringData: 'Loading Traffic',
      ExploringAsyncData:
        'Please wait to view results. You can start another query before this one is complete. Click Results to view or export the results of completed queries within 24 hours.',
      LocatingAppGroups: 'Locating App Groups',
      CalculatingFlowData: 'Calculating Flow Data',
      FindingConnections: 'Finding Connections',
      RecalculatingFlowData: 'Recalculating Flow Data',
      CalculatingVulnerabilityData: 'Calculating Vulnerability Data',
      GeneratingRules: 'Generating Rules {percent, select, 0 {} other {{percent, number}%}}',
      CreatingServices: 'Creating Services',
      CreatingIpLists: 'Creating IpLists',
      CreatingRules: 'Creating Rules',
    },
    Select: {
      Intro: {
        Body: 'The <span class="{className}">Illumio Policy Generator</span> allows you to write <span class="{className}">Rules</span> for uncovered <span class="{className}">connections</span> of <span class="{className}">traffic</span> in your <span class="{className}">App Groups</span>',
      },
      SelectAppGroup: 'Select an App Group to build Rules',
      SearchAppGroup: 'Search App Group',
      Steps: {
        Select: 'Select the App Group',
        Configure: 'Configure Intra-Scope Or/And Extra-Scope Rules',
        Secured: 'App Groups are secured',
      },
    },
    Grid: {
      Connections: '{count, plural, =1 {1 Connection} other {# Connections}}',
      Sessions: '{count, plural, =1 {1 Flow} other {# Flows}}',
      Coverage: 'Coverage',
      More: 'More',
      MorePortProtocol: '+ {numPortsProtocols, number} More',
      NumberSelected: '{subtitle} ({count, number} selected)',
      PortsProtocolsProcesses: 'Ports + Protocols + Processes',
      ConnectionWithRules: '{count, plural, =1 {1 Connection} other {# Connections}} with Rules',
      CompleteCoverage: '100% Covered',
      RulesetInclusion: 'Ruleset Inclusion',
      Title: '2. Review Connections',
      Subtitle: 'Rules will be generated for',
      Connection: 'connections',
      NoFilter: 'At least one transmission type must be selected',
      MoreRules: '{count, plural, =1 {1 More Rule} other {# More Rules}}',
      ExposedVulnerabilityCount: '{count, plural, =1 {# Exposed Vulnerability} other {# Exposed Vulnerabilities}}',
      CoveredByOtherRuleset:
        'All connections are allowed by the Rules in Rulesets that are not created using the Policy Generator.',
      TitleDetails: {
        AppAll: '(Organized by App Group)',
        AppSpec: '(Organized by Port + Protocol)',
        RoleAll: '(Organized by Role and App Group)',
        RoleSpec: '(Organized by Role + Port and Protocol)',
      },
    },
    IntraScope: {
      IntraScopeRuleConfiguration: 'Intra-Scope Rule Configuration',
      IntraScopeRulePreview: 'Intra-Scope Rule Preview',
      ConfigureIntraScope: 'Configure Intra-Scope',
      NewIntraScopeRules: '{count, plural, =1 {1 New Intra-Scope Rule} other {# New Intra-Scope Rules}}',
      DeleteIntraScopeRules: '{count, plural, =1 {Delete 1 Intra-Scope Rule} other {Delete # Intra-Scope Rules}}',
      Options: {
        Title: '1. Choose Intra-Scope Rule Configuration',
        AutoLevel: {
          Title: 'Auto Level',
          Body: 'Vulnerability Mitigation: Eliminate or reduce the exposure of vulnerable ports',
        },
        Ringfencing: {
          Body: 'Microsegmentation: Allow all Workloads to talk across all Services',
        },
        TierToTier: {
          Title: 'Role Level - All Services',
          Body: 'Divide Workloads by Role and allow them to talk on all Services',
        },
        Microsegmentation: {
          Title: 'Role Level - Specified Services',
          Body: 'Nanosegmentation: Divide Workloads by Role and specific Services',
        },
      },
      IntraScopeConnections: 'Intra-Scope Connections',
    },
    ExtraScope: {
      NumConsumingAppGroup: '{count, plural, =1 {1 Consuming App Group} other {# Consuming App Groups}}',
      ExtraScopeAppGroupChoose: 'Extra-Scope App Group Selection',
      ChooseExtraScope: 'Choose Consuming',
      ConfigureExtraScope: 'Configure Extra-Scope',
      ExtraScopeRulePreview: 'Extra-Scope Rule Preview',
      NewExtraScopeRules: '{count, plural, =1 {1 New Extra-Scope Rule} other {# New Extra-Scope Rules}}',
      DeleteExtraScopeRules: '{count, plural, =1 {Delete 1 Extra-Scope Rule} other {Delete # Extra-Scope Rules}}',
      Options: {
        Title: '1. Choose Extra-Scope Rule Configuration for {count, plural, =1 {1 App Group} other {# App Groups}}',
      },
    },
    IpList: {
      NumIpLists: '{count, plural, =1 {1 IP List} other {# IP Lists}}',
      IpListChoose: 'IP List Selection',
      ChooseIpList: 'Choose IP List',
      ConfigureIpList: 'Configure IP List',
      IpListRulePreview: 'IP List Rule Preview',
      Options: {
        Title: '1. Choose IP List Rule Configuration',
      },
    },
    ServiceRuleConfig: 'Service Rule Configuration',
    Options: {
      AllServices: {
        Body: 'Write rules for all services by {level, select, auto {Auto} role {Role} appgroup {App Group}}',
      },
      SpecifiedServices: {
        Title: 'Specified Services',
        Body: 'Write rules for individual services by {level, select, auto {Auto} role {Role} appgroup {App Group}}',
      },
    },
    RingFence: {
      ChooseAppGroups: 'Choose App Groups',
      Description: 'Allows all Workloads to talk across all Services within each App Group',
      RingFenceRulePreview: 'App Group Segmentation - Rule Preview',
      NumAppGroups: '{count, plural, =1 {1 App Group} other {# App Groups}}',
      CreatingRulesets: 'Creating Rulesets',
      NewRulesets: '{count, plural, =1 {# Ruleset} other {# Rulesets}} each with',
      Contents: '1 Scope and 1 Intra-Scope Rule',
      Multiple: 'Multiple',
    },
    PreviewRules: 'Preview Rules',
    ExtraScopeConnections: 'Extra Scope Connections',
    IpListConnections: 'IPList Scope Connections',
  },
  AppGroupCoverage: {
    LastCalculated: 'Last Calculated: {when, date, L_HH_mm_ss}',
    RuleCoverage: '{val, number, percent} Rule Coverage',
    RulesetDisabled: 'The default policy generator ruleset is {disabled}. You must make it active to add rules.',
    RuleCoverageStrong: '<strong>{val, number, percent}</strong> Rule Coverage',
    BuildNewRuleset: 'Building New Ruleset:',
    AddToRuleset: 'Adding to Ruleset:',
    NewObjectInRuleset: 'Adding New Objects to Ruleset',
    DeletingRulesInRuleset: 'Deleting Rules from Ruleset',
    ExistObjectInRuleset: 'Existing Objects in Ruleset',
    NewExtraRuleCoverageStrong: '<strong>{val, number, percent}</strong> New Extra-Scope Rule Coverage',
    NewIntraRuleCoverageStrong: '<strong>{val, number, percent}</strong> New Intra-Scope Rule Coverage',
    NewIpListRuleCoverageStrong: '<strong>{val, number, percent}</strong> New IP List Rule Coverage',
    IntraScopeRuleCoverage: '<strong>Intra-Scope - {val, number, percent}</strong> Rule Coverage',
    ExtraScopeRuleCoverage: '<strong>Extra-Scope - {val, number, percent}</strong> Rule Coverage',
    IpListRuleCoverage: '<strong>IP List - {val, number, percent}</strong> Rule Coverage',
    ConnectionWithRules: '{count, plural, =1 {Connection} other {Connections}} with Rules',
    ConnectionWithoutRules: '{count, plural, =1 {Connection} other {Connections}} without Rules',
    IntraScope: 'Intra-Scope',
    ExtraScope: 'Extra-Scope',
    StartWithIntra: 'Start with Intra-Scope',
    StartWithExtra: 'Start with Extra-Scope',
    StartWithIpList: 'Start with IP Lists',
    AppendIntraRules: 'Append Intra-Scope Rules',
    AppendExtraRules: 'Append Extra-Scope Rules',
    AppendIPListRules: 'Append IP Lists Rules',
    ReplaceIntraRules: 'Replace Intra-Scope Rules',
    ReplaceExtraRules: 'Replace Extra-Scope Rules',
    ReplaceIPListRules: 'Replace IP Lists Rules',
    Recalculate: 'Recalculate?',
    RecalculateConfirm: 'Recalculating the connections might take a few minutes.',
    NoConnectionsFound: 'No Connections Found',
    NoConnections: 'No Connections',
    TooManyWorkloadsInAppGroup: 'Too Many Workloads in App Group',
    CoverageNotCalculated: 'Rule Coverage not calculated',
    MoreRecentRulesMessage: 'Your rules or traffic have been updated more recently than our calculations',
    NeverCalculatedMessage: 'The rule coverage for this App Group have never been calculated',
    GoToAppGroupRules: 'View Rules',
    NoRuleset: 'Rule coverage is provided by rulesets that were not created by Policy Generator',
    TheRuleset: 'The Ruleset',
    RulesetAvailableForPolicyGenerator: 'is available to be used for the Policy Generator.',
    UseThisRuleset: 'Use this Ruleset',
  },
  RBSuccessMessage: {
    PolicyGeneratorSuccessful: 'Policy Generator Successful',
    AddedToDraftRules: 'Added to Draft Rules',
    Desc: 'Rules have been successfully generated and added to your Draft Rules. You must provision these Rules to apply them to Workloads.',
    SelectAnotherAppGroup: 'Select Another App Group',
    ContinueWithGroup: 'Continue with App Group',
    SelectAnAppGroup: 'Select An App Group',
    GoToDraftRules: 'Go to Draft Rules',
    GoToAppGroupMap: 'Go to App Group Map',
  },
  RuleSearch: {
    Access: 'Access',
    ReadOnly: 'Read-only',
    Editable: 'Editable',
    ResetFilters: 'Reset Filters',
    CreatedAtUc: 'Created At',
    UpdatedAtUc: 'Updated At',
    Columns: 'Columns',
    Extra: 'Extra',
    DraftRules: 'Draft Rules',
    ActiveRules: 'Active Rules',
    CreatedIn: 'Created in the',
    UpdatedIn: 'Updated in the',
    CustomCreatedAtRange: 'Custom Created at range',
    CustomUpdatedAtRange: 'Custom Updated at range',
    Basic: 'Basic',
    Advanced: 'Advanced',
    BasicDescription: 'Search all attributes',
    AdvancedDescription: 'Search by Provider, Consumer, or both',
    ExactResults: 'Exact Results',
    ExactResultsDescription: 'Exact match of selected filters',
    AllResults: 'All Results',
    AllResultsDescription: 'Any match of selected filters',
    MaxEntitiesError: 'Up to 8 filter attributes allowed per search.',
    Placeholders: {
      ProvidersOrConsumers: 'Filter by Labels and Rule attributes',
      Providers: 'Filter by Providers and Rule attributes',
      Consumers: 'Filter by Consumers',
    },
    WindowsProcessName: 'Windows process name',
    WindowsServiceName: 'Windows service name',
    RulesetName: 'Ruleset name',
  },
  Rulesets: {
    ActionDiscardPendingChanges: 'Taking this action will discard pending changes.',
    Add: 'Add Ruleset',
    AddTo: 'Add to Ruleset',
    AddRulesTo: 'Add Rules to Ruleset',
    AllowRules: 'Allow Rules',
    AllowDescription: 'Allow services and/or IP Range traffic',
    AllowLongDescription: 'Rules to allow traffic to any applicable corporate addresses',
    AndRules: 'Rulesets and Rules',
    CreateOrSelect: 'Select or Create a new Ruleset',
    DefaultRule: 'Default Rule',
    DefaultRuleDescription:
      'Default rule to allow all outbound traffic to any other addresses not referenced in the allow or deny rules above',
    Delete: 'Remove {count, plural, =1 {Ruleset} other {# Rulesets}}',
    DeleteConfirm: 'Are you sure you want to remove the selected Rulesets?',
    DeleteSeveral: 'Remove Rulesets',
    DenyRules: 'Deny Rules',
    DenyDescription: 'Deny services and/or IP Range traffic',
    DenyLongDescription: 'Rules to deny traffic to any applicable internet addresses',
    Disabled: 'Disabled Ruleset',
    DisableRuleset: 'Disable Ruleset',
    DuplicateRuleset: 'Duplicate Ruleset',
    EditRuleset: 'Edit Ruleset',
    FilterScopes: 'Filter Scopes',
    PartOfApplication: 'One or more of the selected Rulesets is part of an application',
    ScopedUserRuleset:
      'You can only view Rulesets that match the scopes assigned to you. Rules outside your scope that affect your applications can be viewed in the {pageName} page.',
    // ScopesAndRules: 'Scopes and Rules',
    View: 'View Rulesets',
    // RuleWithUpairedWorkload:
    //   'This Ruleset cannot be duplicated because it references unpaired Workloads. Please remove this reference, and then try again.',
    RemoveRuleset: 'Remove Ruleset',
    ReorderRules: 'Reorder Rules',
    SaveOrder: 'Save Order',
    RoleLabelNotSupported:
      'The Role Label is not supported for Scopes. Use the Application, Location, and Environment Labels only.',
    DeleteRuleset: 'Are you sure you want to remove this Ruleset?',
    RulesetActions: 'Ruleset Actions',
    // RulesetWarning: 'This Ruleset is part of an application.',
    // DeleteRuleset: 'Are you sure you want to remove this Ruleset?',
    UpdateSuccess: '{count, plural, =1 {Ruleset has} other {# Rulesets have}} been successfully updated',
    RemoveResultTitle: 'Result of {count, plural, =1 {Ruleset} other {Rulesets}} removal',
    UpdateResultTitle: 'Result of {count, plural, =1 {Ruleset} other {Rulesets}} status update',
    NoMatchData: 'No matching Rulesets to display',
    NoData: 'No Rulesets to display',
    AddNew: 'Add a new Ruleset',
    CreateNew: 'Create New Ruleset',
    TypeName: 'Type Name of New Ruleset',
    // SomeInformationNotInAssignedScopes:
    //   'Some information was withheld from this page because it is not within your assigned scopes.',
    NewProposedBoundaryRuleset:
      'The Enforcement Boundary Ruleset contains rules to allow blocked connections to cross the Boundary. Rules can be edited prior to saving the Ruleset.',
    NewProposedRuleset:
      'Rules allowing the connections will be added to the selected Ruleset. Rules can be edited prior to saving the Ruleset.',
    EnforcementBoundaryRuleset: 'Proposed Enforcement Boundary Ruleset',
    ProposedRuleset: 'Proposed Ruleset',
    SettingsNotification:
      'During rule generation, rules with common elements are merged to reduce the number of rules. Options selected below are used to perform these optimizations.',
    ServiceCreation: 'Service Creation',
    ServiceSubstitution: 'If no existing Service matches a port/protocol',
    ProvisionRuleset: 'Provision Ruleset',
    RevertRuleset: 'Revert Ruleset',
    EnableRuleset: 'Enable Ruleset',
    RulesetDeletedHeader: 'Ruleset is pending deletion',
    RulesetDeletedInstructions:
      'You have two options to continue with rule generation: (1) Provision the ruleset. The existing ruleset will be deleted and a new ruleset created. (2) Revert the ruleset to its last provisioned state.',
    RulesetDisabledHeader: 'Ruleset is disabled',
    RulesetDisabledInstructions: 'To continue with rule generation, you must enable the ruleset',
    SomeSelectedFlowsAreNotCoveredByRules:
      'Some selected flows are not covered by Rules because they are not within the scope of the Ruleset.',
    SearchAllRulesets: 'Search All Rulesets',
    Summary: 'Ruleset Summary',

    PolicySettingsPage: {
      DisplayScopesInRulesets: 'Display Scopes in Rulesets',
      ScopesInRulesets: 'Scopes in Rulesets',
      ScopesInRulesetsYes: 'Optional scope selection in Rulesets is available by default',
      ScopesInRulesetsNo:
        'Rulesets apply to all workloads by default. Scope selection is still available in the Ruleset Action menu',
      DisplayScopesSettingInfo:
        "Rulesets apply to all workloads unless a scope is specified. Scope will limit the Ruleset's rules to only apply to workloads that match a selected set of labels",
    },

    CreatePage: {
      CopyOf: 'Copy of {name}',
      DuplicateName: 'Duplicate Ruleset Name',
      Placeholder: {
        AppScope: 'Choose an Application Label for the new Ruleset Scope',
        EnvScope: 'Choose an Environment Label for the new Ruleset Scope',
        LocScope: 'Choose a Location Label for the new Ruleset Scope',
        RulesetDescription: 'Type a description for the new Ruleset',
        RulesetName: 'Type a name for the new Ruleset',
      },
    },
    Rules: {
      Added: 'ADDED',
      AddNote: 'Add Note',
      EditNote: 'Edit Note',
      DeleteNote: 'Delete Note',
      AdditionPending: 'ADDITION PENDING',
      AdditionProposed: 'Addition Proposed',
      ConfirmRemoveScopes: 'Are you sure you want to remove the selected Scopes?',
      ConfirmRuleDelete: 'Are you sure you want to remove the selected {count, plural, =1 {Rule} other {Rules}}?',
      DuplicateRuleNotif: 'This will duplicate an existing Rule',
      DeleteRules: 'Remove {count, plural, =1 {Rule} other {Rules}}',
      DeleteScopes: 'Delete Scopes',
      Deleted: 'DELETED',
      DeletionPending: 'DELETION PENDING',
      DeleteProposed: 'Delete Proposed',
      UpdateProposed: 'Update Proposed',
      Proposed: 'Proposed',
      FilterRules: 'Filter Rules',
      Inbound: 'Inbound Rules',
      InboundAdminAccessRules: 'Inbound Admin Access Rules',
      Modified: 'MODIFIED',
      ModifiedPending: 'MODIFICATION PENDING',
      ModifiedProposed: 'MODIFICATION PROPOSED',
      NoRules: 'No Rules in the Ruleset',
      Outbound: 'Outbound Rules',
      ProvidingService: 'Providing Service',
      ProvidingProcess: 'Providing Process',
      CorporateNetwork: 'Corporate Network',
      NonCorporateNetworks: 'Non-Corporate Networks (Endpoints Only)',
      AllNetworks: 'All Networks',
      PortOrPortRange: 'Port/Port Range',
      ProvidingUsername: 'Providing Username',
      DestinationService: 'Destination Service',
      DestinationServices: 'Destination Services',
      DestinationUsername: 'Destination Username',
      ConsumingService: 'Consuming Service',
      ConsumingProcess: 'Consuming Process',
      ConsumingUsername: 'Consuming Username',
      SourceProcess: 'Source Process',
      SourceUsername: 'Source Username',
      SourceService: 'Source Service',
      RemoveAllScopes: 'Rulesets must contain at least one Scope. Add a second Scope and delete the first.',
      ReverseError:
        'Rule reversal is not supported for Rules with Container Host, Virtual Services, User Groups, or Virtual Servers.',
      SelectApplication: 'Select Application Label',
      SelectEnvironment: 'Select Environment Label',
      SelectLocation: 'Select Location Label',
      SelectService: 'Select Service',
      TooManyRules:
        'For Rulesets with more than 500 Rules, changes to Rules are not highlighted inline. For best display results, we recommend splitting Rulesets with more than 500 Rules into multiple Rulesets.',
      IndexColumnLabel: 'No.',
      NotePlaceholder: 'Type a note for the Rule',
      DerivedFromProviderVirtualServices: 'Derived from Provider Virtual Services',
      ReadonlyService: 'Rules cannot be edited in the UI for inline services without port or port-ranges defined.',
      IntraToExtra: 'Rule changed from Intra-Scope to Extra-Scope.',
      ExtraToIntra: 'Rule changed from Extra-Scope to Intra-Scope.',
      NoInboundRules: 'No Inbound Rules to display',
      NoOutboundRules: 'No Outbound Rules to display',
      NoInboundRulesWorkload: 'No Inbound Rules apply to this workload',
      NoOutboundRulesWorkload: 'No Outbound Rules apply to this workload',
      NoForwardRulesWorkload: 'No Forward Rules apply to this workload',
      NoCustomRulesWorkload: 'No Custom Iptables Rules apply to this workload',
      RuleOptions: 'Rule Options',
      SelectRuleOptions: 'Select Rule Options',
      LabelAndLabelGroups: 'Labels and Label Groups',
      LabelAndLabelGroupsExcept: 'Labels and Label Groups Except',
      SelectingReplacesConfirmation: 'Selecting {resourceName} replaces {selectedResourcesNames}. Are you sure?',
      SelectingContainerHostConfirmation: 'Selecting Container Host replaces any other Consumer. Are you sure?',
      AddServiceConfirmation: "Adding a new Service replaces 'All Services'. Are you sure?",
      ResourceDeleted:
        'The rule is referring to a {object, select, labels {Label} label_groups {Label Group} services {Service} virtual_services {Virtual Service} virtual_servers {Virtual Server} ip_lists {IP List} other {{object}}} {resourceName} that is deleted. Edit the rule to remove references to {resourceName}',
      SelectingLabelConfirmation:
        '{selectingInclude, select, true {Including} false {Excluding}} {typeName} Label replaces {selectingInclude, select, true {Excluded} false {Included}} {typeName} Labels. Are you sure?',
      UseWorkloadSubnets: 'Use Workload Subnets',
      AddedScope: 'Added Scope',
      RemovedScope: 'Removed Scope',
      UnchangedScope: 'Unchanged Scope',
      NoEntityUsed:
        'No {object, select, workloads {Workloads} services {Services} iplists {IP Lists} userGroups {User Groups} labels {Labels and Label Groups} virtualServices {Virtual Services} virtualServers {Virtual Servers} other {{object}}} in this Ruleset Rules',
      NoMatchingRules:
        'There are no {type, select, intrascope {Intra-Scope} extrascope {Extra-Scope} iptables {Custom iptables} overrideDeny {Override Deny}} Rules that match the filter criteria. Clear filter to see all the rules',
      NoRulesDefined:
        'There are no {type, select, intrascope {Intra-Scope} extrascope {Extra-Scope} iptables {Custom iptables} overrideDeny {Override Deny}} Rules defined',
      UseAdvancedOptions:
        "To add Label exclusion (All Labels and Label Groups except), Virtual Services, Virtual Servers and related options use 'Advanced Options'.",

      ExtraScope: {
        Title: 'Extra-Scope Rule',
        Add: 'Add Extra-Scope Rule',
        AddDesc: 'Allow traffic between Groups or entities',
        NoData: 'No Extra-Scope Rules to display',
        NoMatch: 'No matching Extra-Scope Rules',
        Rules: '{count, plural, =0 {No Extra-Scope Rules} =1 {1 Extra-Scope Rule} other {# Extra-Scope Rules}}',
        IPListWarning:
          'To allow outbound access to IP Lists, create an Intra-Scope Rule to prevent the Rule from being applied to a larger set of Workloads.',
      },
      Header: {
        NoMatchingScopes: 'No matching Scopes',
      },
      IntraScope: {
        Title: 'Intra-Scope Rule',
        Add: 'Add Intra-Scope Rule',
        AddDesc: 'Allow tier-to-tier, Role-to-Role, or IP List traffic',
        NoData: 'No Intra-Scope Rules to display',
        NoMatch: 'No matching Intra-Scope Rules',
        Rules: '{count, plural, =0 {No Intra-Scope Rules} =1 {1 Intra-Scope Rule} other {# Intra-Scope Rules}}',
      },
      IpTables: {
        Add: 'Add Custom iptables Rule',
        AddDesc: 'Integrate existing Linux iptables in a Ruleset',
        Actors: 'Receivers',
        NoData: 'No custom iptables Rules to display',
        NoMatch: 'No matching custom iptables Rules',
        Rules:
          '{count, plural, =0 {No Custom iptables Rules} =1 {1 Custom iptables Rule} other {# Custom iptables Rules}}',

        Form: {
          IpVersion: 'IP Version',
          StatementsPlaceholder: 'Type or paste a custom iptables Rule',
        },
        Grid: {
          Statements: 'iptables Rules applied to Scope',
        },
      },
    },
    Scopes: {
      AddScope: 'Add Scope',
      DuplicateScope: 'Duplicate Scope',
      DuplicateScopeText: 'The scope is a duplicate. Select a different {labelNames}',
      EmptyScopeSubtitle: 'Add a scope to limit the ruleset to apply to workloads that match a selected set of labels',
      ScopesCannotBeAdded: 'Scopes cannot be added currently since the rules defined refer to all label types',
      ScopesCreatedByRuleBuilder:
        'The ruleset is generated by Illumination Map/Policy Generator. Scopes cannot be added',
      RemoveLastScope: 'Remove Last Scope',
      RemoveLastScopeWarning:
        'Removing the last scope will result in the rules potentially applying across all managed wokloads. Are you sure?',
      ScopesCount:
        '{count, plural, =1 {# Scope} other {# Scopes}} {matched, select, true {Matched } false {}}- Each scope must include <span class="{className}">{labelNames}</span> Labels',
      ScopeModifiedDetails: 'Scope modified - Details',
    },
  },
  SecureConnect: {
    NoData: 'No SecureConnect Gateways to display',
    MustBeUnique: 'All rules must be unique.',
  },
  SecureGateway: {
    List: {
      Message: {
        RemoveSuccess:
          '{count, plural, =1 {SecureConnect Gateway has} other {# SecureConnect Gateways have}} been successfully removed',
        RemoveResultTitle:
          'Result of {count, plural, =1 {SecureConnect Gateway} other {SecureConnect Gateways}} removal',
      },
    },
    Name: '{name}',
    AddValidCertificateAuthID: 'Please enter a valid Issuer DN',
    AddValidPreSharedKey: 'Please enter a valid pre-shared key (PSK) for the gateway',
    AddIdleTimeoutMin: 'Please enter a valid Idle Timeout Duration Value',
    AddValidAuthMode: 'Please enter a valid Authentication Mode',
    AccessibleBy: 'Accessible by',
    AddNew: 'Add SecureConnect Gateway',
    CertificateAuthorityID: 'Certificate Authority ID',
    CertificateIssuerNameExample: 'Example: "C=US, O=Acme Inc., CN=Acme Inc. CA"',
    CertificateRsaSig: 'Certificate (rsa-sig)',
    EnterCertificateIssuerName: 'Enter the Certificate Issuer Name using the following format',
    EnterSubjectName:
      'Enter the Subject Name for the Gateway certificate using the following format for IKE peer authentication.',
    EnterWorkloadDN:
      'Enter the DN to identify the certificate used on the Workloads (when using multiple certificates for the Workloads).',
    GatewayIPAddress: 'Gateway IP Address',
    GatewaySubjectDN: 'Gateway Subject DN',
    IdleTimeout: 'Idle Timeout (Minutes)',
    IPsecConfiguration: 'IPsec Configuration',
    IssuerDistinguishedName: 'Issuer Distinguished Name (DN)',
    LinuxIKEv2NAT: 'Linux IKEv2 only NAT-T',
    LinuxIKEv2DPD: 'Linux IKEv2 only DPD',
    LinuxVersion: 'Linux Version',
    LocalID: 'Local ID',
    PreSharedKey: 'Pre-shared Key',
    RemoteID: 'Remote ID',
    RulesEditInfo:
      'Rules below include Protected IP Lists. When not restricted to specific labels the scope expands to all workloads.',
    PublicKey: 'public key',
    ProtectedIPList: 'Protected IP List',
    SecureConnect: 'SecureConnect Gateways',
    SecureConnectDelete: 'Remove {count, plural, =1 {SecureConnect Gateway} other {SecureConnect Gateways}}',
    SecureConnectDeleteConfirm:
      'Are you sure you want to remove {count, plural, =1 {this SecureConnect Gateway} other {# selected SecureConnect Gateways}}?',
    SubjectNameExample: 'Example: "C=US, CN=vpn.acmeinc.com"',
    TunnelMode: 'Tunnel mode',
    TypeGatewayName: 'Type the name of the Gateway (required)',
    TypeGatewayDesc: 'Type the description of the Gateway',
    TypeGatewaySubjectDN: 'Type the Gateway Subject DN',
    TypePreSharedKey: 'Type the pre-shared key (PSK) for the Gateway (required)',
    TypeIdleTimeout: 'Type the idle timeout duration (in minutes; must be a positive integer)',
    TypeIPAddress: 'Type the IP address for the Gateway (required)',
    TypeIssuerDN: 'Type the Issuer DN (Required)',
    TypeWorkloadSubjectDN: 'Type the Workload Subject DN',
    VPNConfiguration: 'VPN Configuration',
    WorkloadSubjectDN: 'Workload Subject DN',
    WorkloadSubjectDNExample: 'Example: "C=US, O=Acme Inc."',
    WindowsVersion: 'Windows Version',
  },
  SegmentationTemplates: {
    BadAttempt: 'Please select a file or choose "Cancel"',
    BadFileType: 'File type not supported. Please select a .json file or choose "Cancel"',
    CancelInstallation: 'Cancel Installation',
    Content: 'Content:',
    Dependencies: 'Dependencies and Prerequisites',
    Edited: 'edited',
    EULAAcceptance: 'EULA Acceptance',
    EULAAcceptanceCheck:
      'By checking this box, you acknowledge that you have read, understand, and agree to be bound by the',
    EULALink: 'Illumio, Inc. End User License Agreement.',
    EULAText1:
      'Illumio Segmentation Templates are designed to provide useful functionality for the deployment and operation of the Policy Compute Engine.',
    EULAText2: 'Illumio Segmentation Templates are provided as is, with limited support.',
    EULAText3:
      'Illumio is not responsible for, and expressly disclaims all liability for or damages of any kind arising out of use, reference to, or reliance on these tools.',
    FileError: 'The selected file is not a valid Segmentation Template. Please choose another file.',
    ForWindows: 'For Windows',
    ForLinux: 'For Linux',
    Install: 'Install',
    Installing: 'Installing',
    InvalidVersion: 'Because a newer version of this template is installed, this template is read-only.',
    IpListCount: '{ipListCount, plural, =0 {} =1 {# IP list} other {# IP lists}}',
    LabelCount: '{labelCount, plural, =0 {} =1 {# label} other {# labels}}',
    LabelGroupCount: '{labelGroupCount, plural, =0 {} =1 {# label group} other {# label groups}}',
    LastUpdated: 'Last Updated:',
    Load: 'Load',
    LoadFile: 'Load File',
    LogIn: 'Log In to Support Portal',
    NamingConflict:
      'The selected template cannot be installed because objects with the following names already exist. Rename these objects and try again.',
    New: 'NEW',
    Overwrite: 'Overwrite',
    PreserveChanges: 'Preserve Changes',
    ReadOnlyError:
      'The following objects have been deleted and are read only.  You must either revert or provision these changes before installing this template.',
    RulesetCount: '{rulesetCount, plural, =0 {} =1 {# ruleset} other {# rulesets}}',
    SegmentationTemplate: 'Segmentation Template',
    SegmentationTemplates: 'Segmentation Templates',
    SupportLoginConfirmation:
      'To use Segmentation Templates, log in to the Illumio Support Portal or load a Segmentation Templates file.',
    ServiceCount: '{serviceCount, plural, =0 {} =1 {# Service} other {# Services}}',
    UndeletableObjects:
      'Some policy objects were not deleted during Uninstall because they are in use or are dependencies of other objects. These objects can be deleted manually later.',
    Uninstall: 'Uninstall',
    UninstallTemplate: 'Uninstall Segmentation Template',
    UninstallWarning:
      'Uninstalling this Segmentation Template will permanently delete all associated Labels, Label Groups, Services, Rule Sets, and IP Lists',
    Universal: 'Universal',
    Update: 'Update',
    UploadText1:
      'To use this feature, first download the Segmentation Templates file to your computer from the Illumio Support Portal. (An internet connection and login credentials are required.)',
    UploadText2: 'Then open the file in your browser. Click "Choose File" below to select the file and click "Load".',
    Version: 'Version:',
    WhatsNew: "What's New",
  },
  Services: {
    AddNew: 'Add a new Service',
    AddSuggested: 'Add a suggested Service',
    AddValidNameAndService: 'Please add a valid Name and Service.',
    Create: 'Create Service',
    Delete: 'Remove {count, plural, =1 {Service} other {# Services}}',
    DeleteConfirm: 'Are you sure you want to remove this Service?',
    DeleteSeveral: 'Remove Services',
    DeleteSeveralConfirm: 'Are you sure you want to remove the selected Service(s)?',
    DuplicateMessage:
      'A service of this name already exists. Its recommended to use a different name for a new service',
    NoServices: 'No Services to display. Try a different filter.',
    PolicyServices: 'Policy Services',
    ServiceDefinitions: 'Service Definitions',
    SelectOneOrMore: 'Select one or more Services',
    SelectingAllServicesTooltip: 'Selecting All Services will override Service selection',
    SelectingServicesTooltip: 'Selecting a Service will override All Services',
    OverlappingPorts: 'Overlapping Ports',
    OverlappingTypeCode: 'Overlapping Type/Code',
    OverlappingServiceDefinitions: 'Overlapping Service Definitions',
    WindowsService: 'Windows Service',

    Edit: {
      Message: 'Please add a valid Name and Port Range before saving.',
      Info: {
        PortInstruction: 'To add ports, use a hyphen to indicate the port range:',
        ProtocolInstruction: 'To add protocol, use one of the supported protocol names:',
        ICMPInstruction: 'To add ICMP with type and code, use <type> / <code>:',
        ProcessPathInstruction: 'To add process path, use the full path or % for wildcard:',
        ProcessNameInstruction: 'To add process name, use the Windows process name without .exe or path:',
        InstructionsExample: {
          ProcessName: 'myprocess\nMy Schedule',
        },
      },
    },
    Errors: {
      Edit: 'Unable to Edit Service',
      Create: 'Unable to Create Service',
    },
    List: {
      RemoveConfirm: 'Are you sure you want to remove the selected {count, plural, =1 {Service} other {Services}}?',
      RemoveSuccess: '{count, plural, =1 {Service has} other {# Services have}} been successfully removed',
      CannotDeleteBoundToRules:
        'Cannot remove {count, plural, =1 {Service that is} other {# Services that are}} referenced by an existing Rule',
      CannotDeleteBoundToBPS:
        'Cannot remove {count, plural, =1 {Service that is} other {# Services that are}} referenced by an existing Virtual Service',
      CannotDeleteBoundToEnforcementBoundary:
        'Cannot remove {count, plural, =1 {Service that is} other {# Services that are}} referenced by an existing Enforcement Boundary',
      CannotDeleteAny: 'This is a default Service and cannot be edited or removed.',
    },
    Mixin: {
      Instructions: 'When the Service is used in a Rule, allow the following:',
      PortsAndProtocols: 'Ports & Protocols',
      PortsProtocolsAndProcesses: 'Ports, Protocols, Processes and Services',
      AtLeastOnePort: 'At least one port is required.',
      AtLeastOneProcess: 'At least one port or process is required.',
      PortOrProcessIsRequired: 'Port or Process is required',
      ProcessIsRequired: 'Process is required',

      Os: {
        Instructions:
          'Specify the operating systems this Service must be running on in order for it to take effect when used in a Rule',
        Title: 'Operating System',
        All: {
          Subtitle: 'Specify ports and protocols',
          Title: 'All Operating Systems: Port-Based',
        },
        Windows: {
          Subtitle: 'Specify ports and protocols, processes, or Windows services',
          Title: 'Windows: Process/Service-Based',
        },
        WindowsOutbound: {
          Subtitle: 'Specify process name or process path',
          Title: 'Windows Outbound: Process/Service-Based',
        },
      },
      Placeholder: {
        ServiceDescription: 'Type a description for the new Service',
        ServiceName: 'Type a name for the new Service',
        ServicePorts:
          'Type or paste a port and protocol, a process or service, or a port and protocol with a process or service',
      },
    },
  },
  Settings: {
    CertificateIssuer: 'Certificate Issuer',
    CertificateSecondaryText: 'Fields in the name of Certificate Issuer that will be matched',
    DataCenter: 'Data Center',
    EssentialServiceRule: 'Essential Service Rule',
    EssentialServiceRules: 'Essential Service Rules',
    EssentialServiceRulesDesc:
      'The following Essential Service Rules are programmed on every VEN to allow normal network functionality and communication with the PCE.',
    ESPEncriptionAndIntegrity: 'ESP Encryption and Integrity',
    IntervalSecondaryText: 'Minimum interval is 7 Days',
    IKEAuth: 'IKE Auth',
    IKEAuthentication: 'IKE Authentication',
    IKECertificates: 'IKE Certificates',
    IKEVersion: 'IKE version',
    IKEv12DHGroup: 'IKEv1/2 DH Group',
    IKEv12PRF: 'IKEv1/2 PRF',
    IKEv12Integrity: 'IKEv1/2 Integrity',
    IKEv1L2TP: 'IKEv1/L2TP',
    IKEv2: 'IKEv1/IKEv2',
    IPv6Traffic: 'IPv6 Traffic',
    OrgDataCenterIsDisabled: 'Your Organization data center is <span class="{className}">disabled</span>.',
    PKI: 'PKI',
    PSK: 'PSK',
    RotationInterval: 'Self Signed Certificate Rotation Interval',
    PublicCloudConfiguration: 'Public Cloud Configuration',
    SALifetime: 'SA Lifetime',
    SAIdleTimeout: 'SA Idle Timeout',
    SAIdleTimeoutDuration: '3 to 5 minutes',
    SecureConnect: 'Secure Connect',
    TransportMode: 'Transport Mode',
    VensVersionsHigher: 'VENs (versions 20.2.0 and higher)',
    VensVersionsLower: 'VENs (versions lower than 20.2.0)',
    VensRecoveryTitle: 'Certificate Rotation',
    VensIKEAuthenticationInfo:
      'VENs will report and use the certificate whose Issuer Name matches the following for IKE Authentication',
    VensRotationIntervalError: 'The minimum Self Signed Certificate Rotation Interval is 7 Days',
    VensRotationIntervalIntegerError: 'Self Signed Certificate Rotation Interval should be an integer',
    VensRecoveryInfo:
      'For a high level of security, its recommended to set the Self Signed Certificate Rotation Interval to 30 Days or less. This setting applies only to VENs that were paired using VEN version 21.5.0 or higher',
    VensRecoveryHelp:
      'VENs will require a maintenance token to perform operations such as stop, suspend and unpair. This can be disabled in Settings - VEN Operations.',
    VenOperationSetting: 'Settings - VEN Operations',
    GenerateMaintenanceToken: 'Generate Maintenance Token',
    TamperProtectionTitle: 'Tampering Protection',
    TamperProtectionInfo:
      'When set to Yes, the VEN will require a maintenance token to perform operations such as stop, suspend, and unpair. You can generate a VEN maintenance token for all VENs on the VEN List page or for any specific VEN on the VEN Detail page.',
    TamperProtectionMaintenanceToken: 'Require VEN Maintenance Token',
    WorkloadsHostedInCloud:
      'Your Workloads are hosted by a <span class="{className}">cloud service provider</span> that does not provide a private network.',
    WorkloadsHostedInPrivate:
      'Your Workloads are hosted by a <span class="{className}">private data center</span>, or with a cloud service provider that provides a private network',
    WorkloadsHostedInCloudWithPrivate: 'Custom setting: <span class="{className}">Cloud Private Network</span>.',
    WorkloadsHostedInPrivateOnly: 'Custom setting: <span class="{className}">Single Network</span>.',
    AuthenticationType: 'Authentication Type',
    NATDetection: 'NAT Detection',
    NotNetworkDetectionMode:
      'You are using advanced cloud network detection settings. Contact Illumio Support for more information.',
    PrivateDataCenter: 'Private Data Center or Public Cloud with 1:1 NAT (Default)',
    PrivateDataCenterDescription:
      'For Workloads in a known public cloud (e.g. AWS or Azure), the public IP address of the Workload as seen by the PCE will be distributed along with the IP addresses of interfaces on the Workload. Use this setting only if there are no shared SNAT IP address(es) for egress traffic from public cloud Workloads.',
    PublicCloudWithSNAT:
      'Public Cloud with SNAT / NAT Gateway (recommended setting if using a NAT gateway in AWS or Azure or the default outbound access in Azure)',
    PublicCloudWithSNATDescription:
      'The PCE will ignore the public IP address of the Workload in policy computation. This setting should be used in environments where Workloads in a known public cloud (e.g. AWS or Azure) that connect to other Workloads or the PCE (outside the VPC or cloud) via an SNAT IP address or SNAT pool (one example is the NAT Gateway in AWS), as the public IP seen by the PCE is not specific to any Workload. Only the IP addresses of network interfaces on the Workload (which are usually private IP addresses) will be distributed in the policy.',
    UseCertificatesForAuthentication: 'Use Certificates for authentication:',
    UsePreShared: 'Use Pre-Shared Keys for authentication',
    ValidCertificateValues:
      '<span class="{className}">Can contain any of the following short name formats of object identifiers:</span></br>DC or domainComponent, C or countryName, O or organizationName, OU or organizationalUnitName, CN or commonName.',
    CertificateExample: 'C=US, O=Illumio, OU=UX, CN=issuer@foo.com',
    BlockAction: {
      AddScopeForRejectConnections: 'Add Scope for Reject Connections',
      AllConfirmation: "Adding 'All Workloads' will remove all other Reject Connections Scopes.",
      BlockedConnectionAction: 'Blocked Connection Action',
      BlockedConnectionActionDesc:
        'The default blocked connection action is drop. Workloads that match these labels will reject blocked inbound connections.',
      DuplicatedScopesAndLabels: 'Reject Connections with the same scope has already been configured',
      EditScopeForRejectConnections: 'Edit Scope for Reject Connections',
    },
    Edit: {
      AllowIPv6Traffic: 'Allow IPv6 traffic',
      BlockIPv6Traffic: 'Block IPv6 traffic',
      IPv6TrafficAllowedBasedOnPolicy: 'Allowed based on policy',
      IPv6TrafficAllAllowed: 'All IPv6 traffic allowed',
      IPv6TrafficBlockedInEnforced: 'Blocked only in Enforced state. Always allowed on AIX and Solaris Workloads',
      SelectValuesBeforeSaving: 'Please select values for the settings before saving.',
      PolicyConfiguration: {
        PolicyUpdateModeDesc:
          'Adaptive applies policy changes to the Workload as soon as you Provision. Static stages policy changes on the Workload, but does not apply them until you decide to manually push them.',
      },
      ModifyScope: {
        PrimaryFirewallRequired: 'Primary Firewall is required',
        Errors: {
          PolicyStateOption: 'Needs at least one of these: all, enforced, illuminated',
          PrimaryFirewall: 'Needs at least one of these: yes, no',
        },
      },
      UpdateErrorTitle: 'Error updating Settings',
      UpdateError: 'Check your Scopes configuration for overlapping Scopes and try again.',
    },
    LoopbackInterfaces: {
      ConfigAllError: 'At least one named label must be selected',
      DuplicatedScopesAndLabels: 'A Loopback Interface Policy with the same Scope has already been configured',
      Title: 'Loopback Interfaces',
      LoopbackInterfacesDescription:
        "Scopes where Loopback Interfaces participate in the policy Workloads that match the scope will apply policy on Loopback Interfaces. Loopback Interface's IP addresses will be distributed to peers.",
      EditScopeForLoopbackInterfaces: 'Edit Scope for Loopback Interfaces Policy',
      AddScopeForLoopbackInterfaces: 'Add Scope for Loopback Interfaces Policy',
      NoData: 'There are no scopes set up to enable Loopback Interfaces',
      AllConfirmation: "Adding 'All Workloads' will remove all other Loopback Interfaces Scopes.",
    },
    Containers: {
      ContainersPolicy: 'Containers Policy',
      ContainerSettings: 'Container Settings',
      ContainerInheritHostPolicy: 'Container Inherit Host Policy',
      ContainerInheritHostPolicyDesc:
        'Illumio Core enforces the host policy for all the docker containers running on the host if enabled.',
      AddScopeForContainerInheritHostPolicy: 'Add Scope for Container Inherit Host Policy',
      EditScopeForContainerInheritHostPolicy: 'Edit Scope for Container Inherit Host Policy',
      ConfigInstruction: 'You need to select at least one named label to make containers inherit',
      DuplicatedScopesAndLabels: 'A Container Inherit policy with the same Scope has already been configured',
      ConfigAllError: 'At least one named label must be selected to make containers inherit',
      UnmanagedDesc: 'Container workloads in this namespace are ignored by PCE',
      ManagedDesc: 'Container workloads in their namespace are managed by PCE',
      AllConfirmation: "Adding 'All Workloads' will remove all other Containers Policy Scopes.",
      AnnotationLabelHint:
        'Any container annotation label is accepted by default. You can choose to restrict container annotations to one or more labels if needed, or assign your own label',
      AnnotationLabelTooltip:
        '"Use Container Annotations" gives the ability to container platform administrators to set the Labels on pods and services using annotations in the manifest files, within the allowed list defined by the Illumio admin.\n\n"Assign Label" unidirectionally sets the Label selected on pods and services within this namespace/project. This label cannot be overwritten with container annotations.',
    },
    IPForwarding: {
      Title: 'IP Forwarding',
      AddScope: 'Add Scope for IP Forwarding',
      AllConfirmation: "Adding 'All Workloads' will remove all other IP Forwarding Scopes.",
      Description:
        'IP forwarding is blocked by default unless the host is part of a Container Cluster. Linux Workloads that match these scopes will allow all forwarded traffic. Illumio Core does not provide visibility of forwarded traffic.',
      DuplicatedScopesAndLabels: 'IP Forwarding with the same scope has already been configured',
      EditScope: 'Edit Scope for IP Forwarding',
      NoData: 'There are no scopes set up to enable IP forwarding',
    },
  },
  Static: {
    FourOhFour: {
      CouldNotFindPage: 'We could not find the page you are looking for.',
      LetUsKnow: 'If you reached this page, please let us know at {email} so we can fix it.',
      PageNotFound: '404 Page not found',
    },
    ResourceNotFound: {
      CodeResourceNotFound: '404 Resource not found',
      ResourceNotFound: 'Resource not found.',
      StartOver: 'Start over.',
    },
  },
  SupportReports: {
    Failed: 'Support Bundle Failed',
    NewReportOverwritesExistingReport:
      'Generating a new bundle will overwrite the existing one. Do you want to continue?',
    Generating: 'Generating ...',
    NoData: 'Select a VEN to generate a VEN support bundle',
    PageName: 'VEN Support Bundles',
    RequestedByAt: 'Requested by {user} at {when, date}',
    SupportReport: 'VEN Support Bundle',
    SupportReportBeingGenerated: 'The support bundle is being generated.',
    SupportReportWasGenerated: 'The support bundle has been successfully generated.',
    SupportReports: "Bundle can't be Generated",
  },
  SupportBundles: {
    SupportBundles: 'Support Bundles',
  },
  PCESupportBundles: {
    PageName: 'PCE Support Bundles',
    GeneratePCESupportBundle: 'Generate PCE Support Bundle',
    FilterEventsBy: 'Filter events by:',
    BasicContent: 'Basic Content',
    OptionalContent: 'Optional Content',
    PCEHostSystemInventory: 'PCE Host and System Inventory',
    PCEHostSystemInventoryDesc: 'Includes basic hardware and software information',
    PCEHealthCheck: 'PCE Health Check',
    PCEHealthCheckDesc: 'Includes information on system health and environment health',
    LogCollection: 'Log Collection',
    LogCollectionDesc: 'Collect PCE software logs for analysis by Illumio',
    DeleteTitle: 'Remove {count, plural, =1 {PCE Support Bundle} other {PCE Support Bundles}}',
    DeleteConfirm:
      'Are you sure you want to remove {count, plural, =1 {this PCE support bundle} other {# selected PCE support bundles}}?',
    DeletedSuccessfully:
      '{count, plural, =1 {PCE support bundle has} other {# PCE support bundles have}} been successfully removed',
    Errors: {
      GenerateSupportReport: 'Unable to generate PCE support bundle',
    },
    Status: {
      Error: 'Unable to run support report command. Check for installation or resource issues.',
      Busy: 'Unmanaged support report in progress.',
      Retry: 'Issue creating file UUID. Please try again.',
      Purged: 'This file has been removed.',
      Failed: 'Unable to complete bundle. Check disk space against expected bundle size.',
      Partial: 'Incomplete support bundle ready for download.',
      Missing: 'Unable to find report. Check disk space.',
      Rejected: 'Unable to transfer report. Check report size.',
      Blocked: 'Unable to transfer report. Check connectivity to fileserver.',
      Unknown: 'Unable to decode status.',
      Pending: 'Waiting for all operations to complete.',
      Cancelled: 'This request has been cancelled.',
      Corrupt: 'Archive damaged. Check disk space.',
    },
  },
  Reports: {
    AppGroupSummary: 'App Group Summary',
    AppGroupSummaryReport: 'App Group Summary Report',
    ExecutiveSummary: 'Executive Summary',
    ExecutiveSummaryReport: 'Executive Summary Report',
    Downloads: 'Downloads',
    Recurrence: 'Recurrence',
    NoData: 'No reports to display',
    SchedulesNoData: 'No schedules to display',
    AddReport: 'Add Report',
    RunQuery: 'Run Query',
    SavedFilter: 'Saved Filter',
    SelectFilter: 'Select a filter',
    RemoveSchedule: 'Remove Schedule',
    RemoveScheduleConfirm:
      'Are you sure you want to remove the selected schedule? The associated report will no longer be generated.',
    ReportType: 'Report Type',
    TimeRange: 'Time Range',
    Quarterly: 'Quarterly',
    Monthly: 'Monthly',
    Weekly: 'Weekly',
    Daily: 'Daily',
    Retention: 'Retention',
    RetentionDays: 'Days (Maximum {days})',
    RecurringReports: 'Recurring Reports',
    ReportRetentionPeriod: 'Report Retention Period',
    ReportRecurrence: 'Report Recurrence',
    ReportEmailCheckbox: 'Email me a copy of the report',
    ReportEmailCheckboxCSV: 'Email me a copy of the CSV file',
    ScheduledTime: 'Scheduled Time',
    Schedules: 'Schedules',
    NextScheduledTime: 'Next Scheduled Time',
    ScheduledTimeErrorMessage: 'The selected time occurs in the past.',
    SavedFilterErrorMessage: 'A filter is required',
    EmailCopyTo: 'Email Copy To',
    ExplorerExport: 'Illumination Plus/Explorer Export',
    ExplorerExportInstructionsPart1: 'You can run a previously saved',
    ExplorerExportInstructionsPart2:
      'filter and export the results to a CSV file on a recurring schedule. If you edit the filter, subsequent recurrences of the exported file will continue to use the original version of the filter.',
  },
  Exports: {
    PageName: 'Exports',
    ViewAllExports: 'View All Exports',
    ContainingAll: 'Containing All',
    NoData: 'No Exports to display',
    NewExport: 'New Export',
    Delete: 'Remove {count, plural, =1 {Export} other {Exports}}',
    DeleteConfirm:
      'Are you sure you want to remove the {count, plural, =1 {selected Export} other {# selected Exports}}?',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Export has} other {# Exports have}} been successfully deleted',
    Formatted: 'Formatted as',
    FileName: 'File name',
  },
  Switches: {
    Manufacturer: 'Manufacturer',
    Model: 'Model',
    SwitchName: 'Switch Name',
    SwitchIp: 'Switch IP',
    EnforcementNode: 'Enforcement Node',
    NENHostname: 'NEN hostname',
    Delete: 'Remove {count, plural, =1 {Switch} other {# Switches}}',
    DeleteConfirm: 'Are you sure you want to remove the {count, plural, =1 {Switch} other {# Switches}}?',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Switch has} other {# Switches have}} been successfully deleted',
    CannotDelete: 'The following {count, plural, =1 {Switch is} other {# Switches are}} cannot be deleted.',
    NoMatchData: 'No matching Switches to display',
    NoData: 'No Switches to display',
    NoInterfaceData: 'No Interfaces to display',
    AddInterfaces: 'Add Interfaces',
    SwitchNameMustBeUnique: 'Switch Name must be unique',
    SwitchIPMustBeUnique: 'Switch IP must be unique',
    Form: {
      SwitchNamePlaceholder: 'Type a switch name',
      IfIndexPlaceholder: 'Type ifIndex',
      SwitchIpPlaceholder: '0.0.0.0',
      InterfaceTitle: 'Interface {count, number}',
      SelectWorkloads: 'Select Workloads',
      MonitorTraffic: 'Monitor Traffic',
      TotalInterfaces: 'Total Interfaces',
    },
    GenerateACLs: 'Generate ACLs',
    DownloadACLs: 'Download ACLs',
    MarkApplied: 'Mark Applied',
    AclGeneratedAt: 'ACL Generated at',
    AppliedAt: 'Applied At',
    AclGenerationPending: 'ACL Generation Pending',
    Modals: {
      CreateErrorTitle: 'Create switch error',
      EditInterfacesError: 'Edit interfaces error',
    },
    List: {
      Message: {
        DeleteConfirm: 'Are you sure you want to remove the selected {count, plural, =1 {Switch} other {Switches}}?',
        WorkloadsManagedDeleted: 'All workloads managed by the switch will be removed as well.',
      },
    },
    AddNew: 'Add a new Switch',
  },
  SystemSettings: {
    Events: 'System Events',
    InterfaceName: 'Interface Name',
    KeyValue: 'Only key-value format is supported',
    Network: 'Network',
    TestEmailSentSuccess:
      'Test email was sent to  <span class="{className}">{email}</span>, check your email for validation.',

    LogForwarding: {
      Severity: {
        Info: 'Informational',
      },
    },
    NetworkEdit: {
      LogBackIn: 'You will be required to log back in.',
      NetworkSettingsApplied: 'Your network settings have been applied.',
    },
  },
  TrafficEvents: {
    AllEvents: 'All Events',
    BlockedEvents: 'Blocked Events',
    Direction: 'Direction',
    FirstTime: 'First Time',
    FlowCount: 'Flow Count',
    LastTime: 'Last Time',
    List: 'Traffic Events',
    PotentiallyBlockedEvents: 'Potentially Blocked Events',
    Singular: 'Traffic Event',
    TrafficBlockedByEnforcement: 'Traffic that was blocked by policy enforcement.',
    TrafficBlockedWhenEnforced: 'Traffic that will be blocked when policy is enforced.',
    TrafficEventsCount: '{count, plural, =0 {no Traffic Events} =1 {# Traffic Event} other {# Traffic Events}}',
  },
  Explorer: {
    DashboardTitle: 'Inbound Traffic for Group:',
    CannotGetTrafficData: 'Cannot get traffic data',
    NoInboundTraffic: 'No Inbound Traffic for this Group',
    NoOutboundTraffic: 'No Outbound Traffic for this Group',
    NoTrafficForTimeInterval: 'No Traffic for selected time interval',
    ViewPolicy: 'View Policy',
    FilterAlreadyExists: 'This filter property already exists in the filter. Try adding a different one',
    ClearFilters: 'Clear Filters',
    AddressHelp: 'Enter an IP Address or CIDR',
    CreateNewService: 'Create a new Service',
    Disabled: 'Explorer is Disabled',
    NoTraffic: 'No Traffic available for selected filters.',
    AppGroupDisabled: 'App Group Type must be set before the Explorer can be used',
    ServiceDoesNotExist: 'If a Service does not exist for a port/protocol',
    FQDNNotSupported: 'The IP List select contains an FQDN which is not supported as a Consumer Filter',
    SelectFilters: 'Select filter parameters.',
    SelectNewFilters: 'Select new filter parameters.',
    SelectNewDraftPolicy: 'Select new draft policy filter',
    LoadingDraftPolicy: 'Loading draft policy',
    ClickGo: 'Then click "Go" to display traffic.',
    ClickRun: 'Then click "Run" to display traffic.',
    CreateWorkloadsComplete: 'Creating Workloads Complete',
    CreateVSComplete: 'Creating Virtual Services Complete',
    WorkloadsCreated:
      '{count, plural, =0 {No Workloads} =1 {# Unmanaged Workload} other {# Unmanaged Workloads}} Created',
    VSCreated: '{count, plural, =0 {No Virtual Services} =1 {# Virtual Service} other {# Virtual Services}} Created',
    AddressesExisting:
      'The following {count, plural, =0 {No addresses} =1 {# address} other {# addresses}} were not saved, because they are used by existing Workloads:',
    CreateWorkloads: 'Create Unmanaged Workloads...',
    Table: 'Table',
    ParallelCoordinates: 'Parallel Coordinates',
    Format: 'Format',
    Encap: 'Encap',
    ReportedBy: 'Reported by',
    Reporter: 'Reporter',
    SourceInclude: 'Included Consumers',
    TargetInclude: 'Included Providers',
    OrIncludeCP: 'Included Consumers Or Providers',
    OrIncludePC: 'Included Providers Or Consumers',
    OrExcludeCP: 'Excluded Consumers Or Providers',
    OrExcludePC: 'Excluded Providers Or Consumers',
    PortsInclude: 'Included Services',
    SourceExclude: 'Excluded Consumers',
    TargetExclude: 'Excluded Providers',
    PortsExclude: 'Excluded Services',
    ConsumerIP: 'Consumer IP',
    ProviderIP: 'Provider IP',
    ConsumerIPList: 'Consumer IPList',
    ProviderIPList: 'Provider IPList',
    SourceIPList: 'Source IP List',
    DestinationIPList: 'Destination IP List',
    ConsumerIPAddresses: 'Consumer IP Addresses',
    SourceIPAddresses: 'Source IP Addresses',
    ProviderIPAddresses: 'Provider IP Addresses',
    DestinationIPAddresses: 'Destination IP Addresses',
    NumFlows: 'Num Flows',
    CreateVirtualService: 'Create Virtual Service',
    ConsumerName: 'Consumer Name',
    ConsumerNames: 'Consumer Names',
    SourceNames: 'Source Names',
    SourceName: 'Source Name',
    SourceHostname: 'Souce Hostname',
    SourceGroup: 'Source Group',
    SourceProcessService: 'Source Process / Service',
    ConsumerLabels: 'Consumer Labels',
    SourceGroups: 'Source Groups',
    ConsumerHostname: 'Consumer Hostname',
    ConsumerRole: 'Consumer Role',
    ConsumerEnv: 'Consumer Env',
    ConsumerApp: 'Consumer App',
    ConsumerLoc: 'Consumer Loc',
    ConsumerRoles: 'Consumer Roles',
    SourceRoles: 'Source Roles',
    ConsumerEnvironments: 'Consumer Environments',
    ConsumerApplications: 'Consumer Applications',
    ConsumerLocations: 'Consumer Locations',
    ConsumerProcessService: 'Consumer Process/Service',
    ConsumerEnforcement: 'Consumer Enforcement',
    ConsumerCount: '{count, plural, =0 {no Consumers} =1 {# Consumer IP} other {# Consumer IPs}}',
    ProviderCount: '{count, plural, =0 {no Providers} =1 {# Provider IP} other {# Provider IPs}}',
    ProviderName: 'Provider Name',
    DestinationNames: 'Destination Names',
    ProviderNames: 'Provider Names',
    DestinationName: 'Destination Name',
    DestinationHostname: 'Destination Hostname',
    DestinationProcessService: 'Destination Process/Service',
    ProviderHostname: 'Provider Hostname',
    DestinationGroup: 'Destination Group',
    ProviderLabels: 'Provider Labels',
    ProviderRoles: 'Provider Roles',
    DestinationRoles: 'Destination Roles',
    DestinationGroups: 'Destination Groups',
    ProviderEnvironments: 'Provider Environments',
    ProviderApplications: 'Provider Applications',
    ProviderLocations: 'Provider Locations',
    ProviderRole: 'Provider Role',
    ProviderEnv: 'Provider Env',
    ProviderApp: 'Provider App',
    ProviderLoc: 'Provider Loc',
    ProviderEnforcement: 'Provider Enforcement',
    ProviderProcessService: 'Provider Process/Service',
    ResolveUnknownFqdns: 'Resolve Unknown FQDNs',
    IncompleteTrafficFlows: 'Incomplete Traffic Flows',
    FirstDetected: 'First Detected',
    ScopedUserWarningExplorer: 'The Explorer data has been filtered to show only traffic applicable to your scope.',
    ScopedUserWarning: 'The illumination plus data has been filtered to show only traffic applicable to your scope.',
    LabelGroupWarning:
      'The list of blocked connections may be incomplete due to the size or complexity of the Label Groups.',
    SortByPortNumber: 'Sort By Port Number',
    SortByProcessName: 'Sort By Process Name',
    SortByNumberOfFlows: 'Sort By Number of Flows',
    ConsumerSelections: 'Consumer Selections:',
    ProviderSelections: 'Provider Selections:',
    SourceSelections: 'Source Selections:',
    DestinationSelections: 'Destination Selections:',
    ICMPType: 'ICMP Type: {type}',
    ICMPCode: 'ICMP Code: {code}',
    DaysAgo: '{count, plural, =0 {no Days} =1 {# Day} other {# Days}} Ago',
    HoursAgo: '{count, plural, =0 {no Hours} =1 {# Hour} other {# Hours}} Ago',
    WeeksAgo: '{count, plural, =0 {no Weeks} =1 {# Week} other {# Weeks}} Ago',
    MonthsAgo: '{count, plural, =0 {no Months} =1 {# Month} other {# Months}} Ago',
    LastDay: 'Last Day',
    LastDays: 'Last {count, plural, =0 {no Days} =1 {24 Hours} other {# Days}}',
    LastHours: 'Last {count, plural, =0 {no Hours} =1 {Hour} other {# Hours}}',
    LastWeeks: 'Last {count, plural, =0 {no Weeks} =1 {Week} other {# Weeks}}',
    LastMonths: 'Last {count, plural, =0 {no Months} =1 {Month} other {# Months}}',
    CustomRange: 'Custom Range',
    Time: 'Time',
    Saved: 'Saved',
    Recent: 'Recent',
    LoadFilter: 'Load Filter',
    SaveFilter: 'Save Filter',
    SaveFilterAs: 'Save Filter As',
    EnterName: 'Enter a name for the saved filter',
    ConsumerIs: 'Consumer is',
    ConsumerIsNot: 'Consumer is not',
    ProviderIs: 'Provider is',
    ProviderIsNot: 'Provider is not',
    ConsumerOrProviderIs: 'Consumer OR Provider is',
    ConsumerOrProviderIsNot: 'Consumer OR Provider is not',
    TotalConnections: 'Total Connections',
    Closed: 'Closed',
    TimedOut: 'Timed Out',
    IncludeConsumers: 'Include in Consumers',
    ExcludeConsumers: 'Exclude in Consumers',
    IncludeProviders: 'Include in Providers',
    ExcludeProviders: 'Exclude in Providers',
    IncludeConsumersOrProviders: 'Include Consumers Or Providers',
    ExcludeConsumersOrProviders: 'Exclude Consumers Or Providers',
    IncludeServices: 'Include in Services',
    ExcludeServices: 'Exclude in Services',
    ByDraftPolicy: 'by Draft Policy',
    NoDraftPolicy: 'No Draft Policy',
    DefaultPolicy: 'by Default Policy',
    ExternalPolicy: 'External Policy',
    ByRule: 'by Rule',
    ByBoundary: 'by Boundary',
    AcrossBoundary: 'across Boundary',
    NoRule: 'no Rule',
    AllPolicyDecisions: 'All Policy Decisions',
    PolicyDecisions: 'Policy Decisions',
    CalculateBasedOnLabelsDescription: 'Aggregate connections based on Consumer/Provider Labels.',
    CalculateWorkloadBasedRulesDescription: 'Show connections between individual Consumer/Provider pairs.',
    CalculateBasedOnLabelsDraft: 'Quick Draft Rules',
    CalculateWorkloadBasedRulesDraft: 'Deep rule analysis',
    CalculateBasedOnLabelsDescriptionDraft: 'Determine Policy Decisions using Label-set rules only.',
    CalculateWorkloadBasedRulesDescriptionDraft: 'Perform deep rule analysis to determine Policy Decisions.',
    TooManyFlowsDoYouWantToContinue:
      'Calculating the complete deep draft view may take a long time, do you want to continue?',
    TopPorts: 'Top Ports',
    TopSources: 'Top Sources',
    TopDestinations: 'Top Destinations',
    BlockedFlows: 'Blocked Flows',
    Policy: 'Policy',
    ViewAllTraffic: 'View All Traffic',
    ViewAllTrafficText: 'Click on the "View All Traffic" button to view all traffic in the Explorer',
    FlowCount: '{count, plural, =0 {No flows} =1 {1 Flow} other {# Flows}}',
    ResetToDefault: 'Reset To Default',
    MoreItems: '+ {numLists, number} More',
    QuickDraft: 'Label-Set Connections',
    DeepDraft: 'Individual Connections',
    Results: 'Results',
    LoadResults: 'Load Results',
    EmptyFilter: 'Empty Filter',
    RunAt: 'Run At',
    ResultsAvailable: 'Results Available',
    ConnectionsDisplayed: '({connections, number} displayed)',
    ResultsSettings: 'Results Settings',
    ExcludeWorkloadsFromIpList: 'Exclude Workloads from IP List Query',
    IncludeWorkloads: 'Include Workloads',
    ResultsSettingsInfoMessageExplorer:
      'For queries spanning a long time period, increasing the maximum number of connections provides more complete results at the expense of slower performance. If the number of connections returned from the database exceeds the maximum displayed in Explorer, all connections can be viewed in the exported CSV file.',
    ResultsSettingsInfoMessage:
      'For queries returning a large number of matches, increasing the maximum number of connections provides more complete results at the expense of slower performance. If the number of connections returned from the database exceeds the maximum displayed in Illumination Plus, all connections can be viewed by stepping through the results.',
    MaxNumberOfConnections: 'Maximum Number of Connections',
    ReturnedFromDB: 'Returned from Database{numOfClusters, plural, =0 {} =1 {} other { per Region}}',
    DisplayedInExplorer: 'Displayed in Explorer',
    DisplayedInIlluminationPlus: 'Displayed in Illumination Plus',
    ValidRange: 'Valid range: {min, number} - {max, number}',
    WrongValidRange: 'Number is not in valid range',
    NumOfMatchingFlowsExceedsConfMax: '* Number of matching connections exceeds the configured maximum',
    EditInParenthesis: '(Edit)',
    SuperClusterWarning: 'Incomplete results were received from member PCE "{region}"',
    IncompleteResults: 'Incomplete Results',
    SwapConsumersProviders: 'Swap Providers and Consumers',
    MemberShowsPartialPCEData:
      'Results based on data for this PCE only. To see results from all members, please run the query on the Leader.',
    DeprecationNoticePart1: 'We plan to deprecate Explorer and encourage users to access the new',
    DeprecationNoticePart2:
      'that incorporates the latest improvements which includes support for new label types, table, map and graph view',
    DeletePolicyTitle: 'Delete Policy',
    DeletePolicyMessage: 'Are you sure you want to delete this Policy?',
    AllowAllConnections: 'Allow All Connections...',
    AllowFilteredConnections: 'Allow Filtered Connections',
    AllowSelectedConnections: 'Allow Selected Connections...',
    AllowAllConnectionsMessage:
      '{count, plural, =0 {No connections} =1 {# connection} other {# connections}} will be blocked by the Enforcement Boundary when it is provisioned. {count, plural, =0 {} other {To allow all of these connections, click <span class="{className}">Allow All Connections</span>.}}',
    AllowFilteredConnectionsMessage:
      '{count, plural, =0 {No filtered connections} =1 {# filtered connection} other {# filtered connections}} will be blocked by the Enforcement Boundary when it is provisioned. {count, plural, =0 {} other {To allow all of these connections, click <span class="{className}">Allow Filtered Connections</span>.}}',
    EnableRuleWritingWithDraftAndLabels: 'Choose Draft View and Label-set Connections to enable Rule writing',
    EnableRuleWritingWithDraftView: 'Choose Draft View to enable Rule writing',
    EnableRuleWritingWithLabelBased: 'Choose Label-set Connections to enable Rule writing',
    SelectConnectionsToStartWritingRules: 'Select Connections to start writing Rules',
    ClickToStartWritingRules: 'Click to start writing Rules',
    RefreshDraft: 'Refresh Draft Policy',
    RefreshPolicy: 'Refresh Policy',
    NoBlockedConnections: 'No Blocked Connections',
    TrafficDatabaseStorage: 'Traffic Database Storage',
    TrafficDatabaseStorageDaysAvailable: '{days, number} days of data available for queries',
  },
  TrafficParameters: {
    AddTimeToTimestamp: 'Add Time to TS(Temp)',
    AnyRuleCoverage: 'Any Rule coverage',
    CollapsedThreshold: 'Collapsed Role Threshold',
    ExplorerSearchFormats: 'Explorer Search Formats',
    IlluminationPlusSearchFormats: 'Search Formats',
    ConfigTraffic: 'Configurable Traffic Parameters',
    Days: '{count, plural, =0 {no Days} =1 {# Day} other {# Days}}',
    MaxServicesPerRule: 'Max Services Per Rule',
    DefaultNumberOfAddresses: 'Default ({count, plural, =0 {no Addresses} =1 {# Address} other {# Addresses}})',
    DefaultNumberOfIPLists: 'Default ({count, plural, =0 {no IP Lists} =1 {# IP List} other {# IP Lists}})',
    DefaultNumberOfPorts: 'Default ({count, plural, =0 {no Ports} =1 {# Port} other {# Ports}})',
    DefaultNumberOfRoles: 'Default ({count, plural, =0 {no Roles} =1 {# Role} other {# Roles}})',
    DefaultNumberOfWorkloads: 'Default ({count, plural, =0 {no Workloads} =1 {# Workload} other {# Workloads}})',
    DefaultConnectedLinks: 'Default ({count, plural, =0 {no Links} =1 {# Link} other {# Links}})',
    DefaultPercentOfRuleCover: 'Default ({proportion, number, percent} Rule coverage)',
    DefaultNumberOfClusters: 'Default ({count, plural, =0 {no Groups} =1 {# Group} other {# Groups}})',
    DefaultNumberOfLocations: 'Default ({count, plural, =0 {no Locations} =1 {# Location} other {# Locations}})',
    DefaultTruncationLimit: 'Default ({count, plural, =0 {no Nodes} =1 {# Node} other {# Node}})',
    DefaultLinksForRules: 'Default  ({count, plural, =0 {no Links} =1 {# Link} other {# Links}})',
    DefaultTime: 'Default (No time added)',
    DefaultDnsIplistMapping: 'Default (Map All IP List flows with FQDNs as IP Lists)',
    DefaultServicesPerRule: 'Default ({count, plural, =1 {# Service} other {# Services}})',
    MapAllFqdnsToDomains: 'Map All IP List flows with FQDNs as FQDNs',
    DnsIplistMapping: 'FQDN Mapping',
    DefaultShowConnectionState: 'Default (Do not show Connection State)',
    ShowConnectionState: 'Show Connection State',
    ShowExplorerConnectionState: 'Explorer Connection State',
    DoNotShowExplorerServices: 'Do Not Show Explorer Policy Services',
    DefaultShowExplorerServices: 'Default (Show Explorer PolicyServices)',
    ExplorerServices: 'Explorer Services',
    KiloNumber: '{count, number}K',
    NumClusters: '{count, plural, =0 {no Groups} =1 {# Group} other {# Groups}}',
    NumLocations: '{count, plural, =0 {no Locations} =1 {# Location} other {# Locations}}',
    Months: '{count, plural, =0 {no Months} =1 {# Month} other {# Months}}',
    MaxClustersForLinks: 'Max Groups For Links',
    LightGreenThreshold: 'Light Green Line Threshold',
    LocationLevel: 'Always Location Level',
    LocationThreshold: 'Location View Threshold',
    MaxAddressesPerLink: 'Max Addresses Per IP Link',
    MaxExplorerLinksForDeepRules: 'Max Explorer Links for Deep Rules',
    MaxOverlappingIP: 'Max Overlapping IP Lists',
    MaxPortsPerLink: 'Max Ports Per Link',
    MaxWorkloads: 'Max Workloads Displayed',
    MaxWorkloadsPerRole: 'Max Workloads Expanded Per Role',
    MaxWorkloadsPerGroup: 'Max Workloads Expanded Per Group',
    MaximumRolesExpandable: 'Maximum Roles Expandable',
    MaxClustersPerLocation: 'Max Groups Per Location',
    MaxLocations: 'Max Locations',
    NoLimit: 'No Limit',
    ResetPositions: 'Reset Positions',
    TrafficParameters: 'Traffic Parameters',
    ViewLevel: 'View Level Threshold',
    Weeks: '{count, plural, =0 {no Weeks} =1 {# Week} other {# Weeks}}',
    Years: '{count, plural, =0 {no Years} =1 {# Year} other {# Years}}',
    DoNotAlwaysRebuild: 'Do not Always Rebuild',
    RebuildAlways: 'Always Rebuild (Up to 100 Workloads)',
    DefaultRebuildStale: 'Rebuild Stale Data for < 50 workloads',
    DoNotRebuildStale: 'Rebuild Only On Demand',
    RebuildStale: 'Rebuild Stale Data',
    StaleData: 'Stale Data',
    FreshData: 'Fresh Data',
    MaxAppGroupsForRuleCoverage: 'App Group Rule Coverage',
    MaxLinksForConnectedAppGroupRuleCoverage: 'Draft Connected App Group Links',
    DefaultMaxAppGroupRuleCoverage: 'Auto load Rule Coverage < 200 App Groups',
    AppGroups: '{count, plural, =0 {no App Groups} =1 {# App Group} other {# App Groups}}',
    AlwaysRebuildWorkloads: 'Rebuild Role Expansion',
    MaxNodesBeforeTruncation: 'Max Nodes before truncation',
  },
  UserGroups: {
    Add: 'Add User Group',
    AddNew: 'Add a new User Group',
    Instructions: 'User Groups allow user-based access to specific entities',
    Remove: 'Remove {count, plural, =1 {User Group} other {User Groups}}',
    RemoveConfirm:
      'Are you sure you want to remove the selected {count, plural, =1 {User Group} other {# User Groups}}?',
    SID: 'SID',
    NoMatchData: 'No matching User Groups to display',
    NoData: 'No User Groups to display',

    Create: {
      SIDExample: 'Use the format S-<unique number> to type in an SID',
      Placeholder: {
        UserGroupName: 'Type a name for the new User Group',
        UserGroupSID: 'Type a SID for the new User Group',
      },
      Validation: {
        InvalidName: 'User Group must have a name defined',
        InvalidSIDFormat: 'User Group SID format is invalid',
        InvalidSIDLength: 'User Group SID is too long',
      },
    },

    RemoveModal: {
      TitleConfirmation: 'Remove {count, plural, =1 {User Group} other {# User Groups}}',
      Confirm:
        'Are you sure you want to remove the {count, plural, =1 {selected User Group} other {# selected User Groups}}?',
    },

    Errors: {
      Edit: 'Unable to Edit User Group',
      Create: 'Unable to Create User Group',
    },
  },
  Users: {
    Accessibility: 'Accessibility',
    AccessScopesRoles: 'Access Scopes and Roles',
    AccessRoles: 'Access Roles',
    Add: 'Add User',
    AddExternalGroup: 'Add External Group',
    AddExternalUser: 'Add External User',
    AddLocalUser: 'Add Local User',
    AddRole: 'Add Role',
    AddGlobalRole: 'Add Global Role',
    AddScopedRole: 'Add Scoped Role',
    CannotEditExternalGroup: 'Cannot edit external group',
    CannotEditUser: 'Cannot Edit User',
    CannotRemoveUser: 'Cannot Remove User',
    CantLockExternalUser: 'This external user can not be locked because they have not logged into the PCE yet.',
    ColorNormal: {
      Title: 'Normal vision',
      Message: 'Optimize the color palette for normal vision',
    },
    ColorMode: 'Color Mode',
    ColorDeficient: {
      Title: 'Color vision deficiency',
      Message: 'Optimize the color palette for Deuteranopia, Protanopia, and Tritanopia vision',
    },
    Convert: 'Convert User',
    ConvertLocalUserWarning: 'The user will be converted to an external user and keep their granted access to scopes',
    ConvertLocalUserWarningWithoutScope: 'The user will be converted to an external user',
    ConvertSuccessExternalUser:
      'User <span class="{className}">{username}</span> was successfully converted to a external user.',
    DefaultPolicyVersion: 'Default Policy Version',
    EditGroup: 'Edit Group',
    EditExternalGroup: 'Edit External Group',
    EditExternalUser: 'Edit External User',
    EditRole: 'Edit Role',
    EditUser: 'Edit User',
    EmailOrUsername: 'Email or Username',
    EmailOrUsernameRequired: 'Email or Username is required',
    ExternalGroup: 'External Group',
    ExternalGroupRequired: 'External Group is required',
    ExternalGroups: 'External Groups',
    ExternalGroupAddFormHint:
      "Must match the group's memberOf attribute set in your IdP\nExamples: Sales or CN=Sales,OU=West,DC=MyDomain,DC=com",
    ExternalUsers: 'External Users',
    FilterByEmailUsername: 'Filter by Email or Username',
    GlobalOwnerSublabel: 'Manage all resources, users and Security Settings.',
    GlobalOwnerSublabelLimited: 'Manage all resources and users.',
    GlobalAdminSublabel: 'Manage all resources and Security Settings. Cannot manage users and roles.',
    GlobalAdminSublabelLimited: 'Manage all resources. Cannot manage users.',
    GlobalPolicyObjectProvisionerSublabel:
      'Provision Services, IP Lists, Label Groups, and Security Settings. Read-only access to all other resources.',
    GlobalPolicyObjectProvisionerSublabelLimited:
      'Provision Services and IP Ranges. Read-only access to all other resources.',
    LocalUsers: 'Local Users',
    LockExternalUser: 'Lock External User',
    LockLocalUser: 'Lock Local User',
    LockUserWarning: 'Locking a user prevents them from logging in but does not change their granted access',
    LockToggleSuccess: 'User <span class="{className}">{username}</span> was successfully {lockUnlock}.',
    Locked: 'locked',
    Unlocked: 'unlocked',
    LoginFailedCountExceeded: 'User login failure count exceeded',
    Logout: 'Logout',
    ManagingOrganization: 'Managing Organization',
    ManagingOrganizationId: 'Managing Organization ID',
    MyOrganization: 'My Organization',
    MyOrganizationId: 'My Organization ID',
    MyProfile: 'My Profile',
    MyRoles: 'My Roles',
    NameRequired: 'Name is required',
    EmailRequired: 'Email is required',
    NoAllLabels: 'The Scope is too broad. Scopes that have no Labels are not supported.',
    PassClickToChange: 'Click to change your user account password',
    Personal: 'Personal',
    ReadOnlyUser: 'Read Only User',
    ReadOnlySublabel: 'Global read-only access to all resources',
    ReadOnlyUserDescOn: 'All users without role membership have read-only access',
    ReadOnlyUserDescOff: 'All users without role membership cannot log in',
    AlreadyExists: 'User already exists',
    ReAddUserTitle: 'Re-add removed user',
    ReAddUserMessage: 'User {username} was removed. Do you want to re-invite this user as a local user?',
    ConvertExternalUserMessage:
      '{username} is currently an external user. Do you want to re-invite this user as a local user?',
    RemoveExternalUsersWarning:
      "{count, number} External User's permissions will be removed and revoked from this organization",
    RemoveExternalGroupPermissions: 'Remove External Group Permissions',
    RemoveExternalUserPermissions: 'Remove External User Permissions',
    RemoveExternalGroupsWarning:
      "{count, number} External Group's permissions will be removed and revoked from this organization",
    RemoveExternalUser: 'Remove External User',
    RemoveLocalUserPermissions: 'Remove Local User Permissions',
    RemoveLocalUsersWarning:
      "{count, number} Local User's permissions will be removed and revoked from this organization",
    Remove: 'Remove User',
    ResetPassword: 'Reset Password',
    RoleChangedSuccess:
      'The role for user <span class="{className}">{username}</span> was successfully changed to <span class="{className}">{rolename}</span>.',
    RulesetProvisionerSublabel: 'Provision Rulesets that match the scope.',
    RulesetManagerSublabel: 'Manage Rulesets that match the scope and Rules where the Provider matches the scope.',
    RulesetViewerSublabel:
      'Read-only access to Rules that match the scope. Does not permit editing of Rulesets and Rules.',
    LimitedRuleWritingSublabel:
      'Manage Rulesets that match the scope and Rules where the Provider and Consumer match the scope.',
    SessionTimeOut: 'Your session has timed out. Log in to continue.',
    SessionsExceeded: 'Maximum users reached',
    UnlockExternalUser: 'Unlock External User',
    UnlockLocalUser: 'Unlock Local User',
    User: 'User',
    UserAdded: 'User <span class="{className}">{username}</span> was successfully added.',
    UserRemovedFromOrg: 'User <span class="{className}">{username}</span> was successfully removed from organization.',
    UsersAndGroups: 'Users and Groups',
    Users: 'Users',

    APIKeys: {
      AuthenticationUsername: 'Authentication Username',
      CanManageCredentials: 'You can manage and recreate these credentials at any time.',
      Create: 'Create API Key',
      Created: 'API Key Created',
      CredentialsDownload: 'Download Credentials',
      Edit: 'Edit API Key',
      Explorer: 'API Explorer',
      HereCredentialsAvailableToDownload: 'This is the only time these credentials will be available to download.',
      KeyID: 'Key ID',
      MyAPIKeys: 'My API Keys',
      Name: 'API Keys',
      NoKeys: 'No API Keys',
      OrgId: 'Org ID',
      DeletedSuccessfully:
        'The following {count, plural, =1 {API Key has} other {# API Keys have}} been successfully deleted',
      Delete: 'Remove {count, plural, =1 {API Key} other {API Keys}}',
      DeleteConfirm:
        'Are you sure you want to remove the {count, plural, =1 {selected API Key} other {# selected API Keys}}? If the API key is deleted, the API authentication cannot be used.',
      RemoveResultTitle: 'Result of {count, plural, =1 {API Key} other {# API Keys}} removal',
      UserID: 'User ID',
    },
    Invite: {
      AlreadyInOrg: 'This user already exists in the Organization',
      GroupAlreadyInOrg: 'This group already exists in the Organization',
      Link: 'Invitation Link',
      LinkDirectlyToUser: 'This link can be sent directly to the user',
      Reinvited: 'User <span class="{className}">{username}</span> was successfully re-invited.',
      Reinvite: 'Re-Invite',
      ReinviteUser: 'Re-Invite User',
      ReinviteWarning: 'A new invitation will be sent to the user',
      UserAlreadyInOrg:
        "You've invited a user that is already part this organization. A new invitation will be sent to this user.",
      UserInDifferentOrg:
        "You've invited a user that is part of a different organization within Illumio. This is currently not supported. Try again with a different email address.",
    },
    SSOConfig: {
      ActiveDirectory: 'Active Directory Federation Services',
      AssertionConsumerUrl: 'Assertion Consumer URL',
      AuthenticationMethod: 'Authentication Method',
      AzureAD: 'Azure AD',
      ForceReauthentication: 'Force Re-authentication',
      InfoFromIdentityProvider: 'Information from Identity Provider',
      InfoForIdentityProvider: 'Information for Identity Provider',
      IdentityProvidersDesc:
        'You can use one of the following identity providers for authenticating users with the PCE',
      IssuerUrl: 'Issuer URL',
      LogoutLandingURL: 'Logout Landing URL',
      LogoutUrl: 'Logout URL',
      Name: 'SSO Config',
      NameIDFormat: 'NameID Format',
      Okta: 'Okta',
      OneLogin: 'OneLogin',
      PasswordProtected: 'Password Protected Transport',
      PingIdentity: 'Ping Identity',
      RemoteLoginURL: 'Remote Login URL',
      SAMLIdentityProviderCertificate: 'SAML Identity Provider Certificate',
      SAMLVersion: 'SAML Version',
      SSOMethod: 'SSO method',
      Unspecified: 'Unspecified',
      SSOConfig: 'Single Sign-On Configuration',
      SignSAMLRequest: 'Sign SAML Request',
      PCESAMLCertificate: 'PCE SAML Certificate',
      PCESAMLCertificateExpiresAt: 'PCE SAML Certificate Expires At',
      DownloadCertificate: 'Download Certificate',
      GenerateCertificate: 'Generate Certificate',
      Rotate: 'Rotate',
      RotateCertificate: 'Rotate Certificate',
      RotateCertificateMessage:
        'When you rotate PCE SAML Certificate, it needs to be updated in your Identity Provider. Please log in to your Identity Provider and update your certificate',
    },
  },
  VEN: {
    Architecture: 'Architecture',
    CaCertificate: 'CA Signed Certificate',
    ContainerCluster: 'Container Cluster',
    Condition: 'Health Condition',
    Health: 'Health',
    DisconnectedBefore: 'Disconnected Before',
    Distribution: 'Distribution',
    EnforcementNodeType: 'Enforcement Node Type',
    Filename: 'VEN Filename',
    Host: 'Host',
    Library: 'VEN Library',
    IndividualEndpoints: 'Individual endpoints in your environment',
    NoData: 'No VENs to display',
    OSVersion: 'OS Version',
    VEN: 'Virtual Enforcement Node (VEN)',
    CVEN: 'Containerized Virtual Enforcement Node (C-VEN)',
    EVEN: 'Endpoint Virtual Enforcement Node (Endpoint VEN)',
    VENOperation: 'VEN Operations',
    Unpaired: 'Unpaired',
    PairingScript: 'Pairing Script',
    VENLibraryWarningMessage:
      'VEN release {version} is supported only on endpoints and should not be installed on servers',
    Filter: {
      Min: 'Min',
      Max: 'Max',
      SelectAVersion: 'Select a Version',
    },
    Upgrade: {
      ConfirmUpgrade: 'Confirm Upgrade',
      Title: 'Upgrade VENs',
      UpgradeAll: 'Upgrade All',
      UpgradeFiltered: 'Upgrade Filtered',
      UpgradeSelected: 'Upgrade Selected',
      UpgradeInProgress: 'Upgrading',
      UpgradeStartedOnDate: 'VEN Upgrade of {count, plural, =1 {# VEN} other {# VENs}} started on {datetime}.',
      VensThatQualify: 'VENs that qualify for an upgrade to {version} release',
      VensMatchFilter: '{n} VENs match the selected filter criteria',
      VensSelected: '{n} VENs are selected',
      VensWillBeUpgraded: '{n, plural, =0 {No VENs} =1 {# VEN} other {# VENs}} will be upgraded.',
      ZeroVenReleaseBundles: 'No VEN release bundles',
      UpgradePending: 'Upgrade Pending',
      UpgradeWarningMessage:
        'VEN release {version} is supported only on endpoints and cannot be used to upgrade server VENs',
    },
    SelfCertificate: 'Self Signed Certificate',
    SetRecoveryMode: 'Set Authentication Recovery Mode',
    VenMaintenanceTokenModalDescription: 'Maintenance Token for {name} has been generated at {time}',
    VenMaintenanceTokenModalExpire: 'The Token expires on {time}',
    VenMaintenanceTokenExpire: 'Token Expiration',
    SetRecoveryModeInteWarning: 'Recovery Mode duration should be an integer',
    SetRecoveryModeReqWarning:
      'Recovery Mode duration needs to be in the range of  1 hr to 30 Days. Enter a suitable duration',
    SetRecoveryModeHourWarning: 'Recovery Mode duration needs to be in the range of  1 to 720 hours.',
    SetRecoveryModeDayWarning: 'Recovery Mode duration needs to be in the range of  1 to 30 days.',
    SetRecoveryModeError: 'Unable to Set Authentication Recovery Mode',
    RecoveryModeDuration: 'Recovery Mode Duration',
    RecoveryModeSecondaryText: 'Duration can be 1 Hour to 30 Days',
    RecoveryModeSecondaryPlaceholder: '1 Hour to 30 Days',
    RecoveryModeDescription:
      'Authentication Recovery Mode is typically used when restoring VENs from backup and poses an elevate security risk by allowing the VEN to provide old credentials.',
    RecoveryModeSecondDescription: 'Are you sure you want to Set Authentication Recovery Mode for the selected VEN(s)?',
    Supported: 'Supported OS Versions',
    ActivationType: 'Activation Type',
    EditError: 'Unable to Edit VEN',
    Kerberos: 'Kerberos',
    Suspend: 'Suspend',
    LastHeartbeat: 'Last Heartbeat',
    PairWithPairingProfile: 'Add with Pairing Profile',
    PairVENWithPairingProfile: 'Pair VEN with Pairing Profile',
  },
  VirtualServers: {
    All: 'All Virtual Servers',
    SLBDevice: 'SLB Device',
    Unassociated: 'Unassociated',
    NoData: 'No Virtual Servers to display',
    VIP: 'VIP',
    VIPPort: 'VIP Port Number',
    VIPProto: 'VIP Protocol',
    VirtualServerName: 'Type a name for the new Virtual Server',
    ModeManagedEnforced: 'Managed: Enforced',
    ModeManagedNotEnforced: 'Managed: Not Enforced',
    WorkloadsAllowedTraffic:
      'Workloads matching selected Labels are allowed to receive traffic as members of this Virtual Server. Note, Workloads columns showing these labels are hidden by default.',
    ByCount: '{count, plural, =1 {Virtual Server} other {Virtual Servers}}',
    ConfirmUnmanageServer:
      'Are you sure you want to stop managing {count, plural, =1 {this Virtual Server with this Load Balancer} other {# Virtual Servers with following Load Balancers}}?',
    ConfirmManageServer:
      'Are you sure you want to Manage {count, plural, =1 {this Virtual Server} other {# Virtual Servers}}?',
    Total: 'Total Virtual Servers',

    Detail: {
      EditMembers: 'Edit Members',
      EnforcedDesc: 'All traffic flows to Virtual Server not allowed by Rules are blocked',
      LabelDefinedMembers: 'Label-defined Members',
      NotEnforcedDesc: 'All traffic flows to Virtual Server are allowed',
      NoMembersDefined: 'No Members Defined',
      RoutingMethod: 'Routing Method',
      ServerLoadBalancer: 'Server Load Balancer',
      ServerLoadBalancers: 'Server Load Balancers',
      UnassociatedDesc: 'Connectivity to Server Load Balancer has been lost',
      UnmanageVirtualServer: 'Unmanage Virtual {count, plural, =1 {Server} other {# Servers}}',
      ManageVirtualServer: 'Manage Virtual {count, plural, =1 {Server} other {# Servers}}',
    },
    Errors: {
      Edit: 'Unable to Edit Virtual Server',
      Remove: 'Unable to Unmanage Virtual Server',
    },
    List: {
      SLB: 'SLB',
      BoundToRules:
        'The following {count, plural, =1 {Virtual Server is} other {# Virtual Servers are}} bound to rules',
      UnmanagedSuccess:
        'The following {count, plural, =1 {Virtual Server has} other {# Virtual Servers have}} been unmanaged successfully',
    },
    ProvidersEdit: {
      AllLabelsError:
        'At least one Label must be a resource other than "All." Selecting "All" for all four Label types is not supported.',
      SelectAppPlaceholder: 'Select an Application for the Virtual Server Members',
      SelectEnvPlaceholder: 'Select an Environment for the Virtual Server Members',
      SelectLocPlaceholder: 'Select a Location for the Virtual Server Members',
      SelectRolePlaceholder: 'Select a Role for the Virtual Server Members',
      SpecifyAllLabels: 'All four Labels must be specified',
    },
    SummaryEdit: {
      PolicyEnforced: 'The virtual server policy is enforced',
      PolicyNotEnforced: 'The virtual server policy is not enforced',
    },
  },
  Health: {
    PCEHealth: 'PCE Health',
    SuperclusterHealth: 'Supercluster Health',
    PCEFunction: 'PCE Function',
    NodeRunlevel: 'Node Runlevel',
    NodeUptime: 'Node Uptime',
    NodeStatus: 'Node Status',
    CPUUsage: 'CPU Usage',
    Complete: 'Complete',
    CoreNodeHealth: 'Core Node Health',
    DataNodeHealth: 'Data Node Health',
    CoordinatorNodeHealth: 'Coordinator Node Health',
    WorkerNodeHealth: 'Worker Node Health',
    MemoryUsage: 'Memory Usage',
    DiskUsage: 'Disk Usage',
    DatabaseReplication: 'Database Replication',
    DatabaseReplicationLag: 'Database Replication Lag',
    PolicyDatabase: 'Policy Database',
    ReportingDatabase: 'Reporting Database',
    TrafficDatabase: 'Traffic Database',
    CoordinatorNode: 'Coordinator Node {role, select, primary {(Primary)} replica {(Replica)} other {}}',
    CoordinatorNodes: 'Coordinator Nodes',
    WorkerNode: 'Worker Node {role, select, primary {(Primary)} replica {(Replica)} other {}}',
    WorkerNodes: 'Worker Nodes',
    FailedServices: 'Failed Services',
    FailedOptionalServices: 'Failed Optional Services',
    DegradedServices: 'Degraded Services',
    PCENodeHealth: 'PCE Node Health',
    PCEServiceAlerts: 'PCE Service Alerts',
    PCEService: 'PCE Service',
    Normal: 'Normal',
    NotRunning: 'Not running',
    CoreNode: 'Core Node',
    Replica: 'Replica',
    Primary: 'Primary',
    Node: 'Node {role, select, primary {(Primary)} replica {(Replica)} other {}}',
    ShowDetails: 'Show Details',
    HideDetails: 'Hide Details',
    RequiredServicesNotRunning: '{count, plural, =1 {# Required service} other {# Required services}} not running',
    OptionalServicesNotRunning: '{count, plural, =1 {# Optional service} other {# Optional services}} not running',
    ServicesDegraded: '{count, plural, =1 {# Service} other {# Services}} Degraded',
    DaysHrsMinSec:
      '{days, plural, =0 {} =1 {# day} other {# days}} {hours, plural, =0 {} =1 {# hour} other {# hours}} {minutes, plural, =0 {} =1 {# minute} other {# minutes}} {seconds, plural, =0 {} =1 {# second} other {# seconds}}',
    Weeks: '{weeks, plural, =0 {} =1 {WEEK} other {WEEKS}}',
    Days: '{days, plural, =0 {} =1 {DAY} other {DAYS}}',
    Hours: '{hours, plural, =0 {} =1 {HOUR} other {HOURS}}',
    Minutes: '{minutes, plural, =0 {} =1 {MIN} other {MINS}}',
    Secs: '{seconds, plural, =0 {} =1 {SEC} other {SECS}}',
    VirtualAppliance: 'Virtual Appliance',
    SNC: 'SNC',
    Partial: 'Partial',
    ErrorUnableToGetToNodeStatus: 'Error: Unable to get node status',
    Percent: '{val, number, percent}',
    DiskUsageLocation: '{val, number, percent} {location}',
    Leader: 'Leader',
    Member: 'Member',
    Seconds: '{seconds, plural, =0 {# seconds} =1 {# second} other {# seconds}}',
    Supercluster: 'Supercluster',
    SuperclusterReplication: 'Supercluster Replication',
    SuperclusterIlluminationSync: 'Supercluster Illumination Sync',
    SyncStatus: 'Sync Status',
    LastSyncUnsuccessful: 'Last sync unsuccessful',
    LastSyncSuccessful: 'Last sync successful',
    LastSuccessfulSync: 'Last Successful Sync',
    LastUnsuccessfulSync: 'Last Unsuccessful Sync',
    ReplicationLag: 'Replication Lag',
    SecondsBehindCluster:
      '{seconds, plural, =0 {# seconds} =1 {# second} other {# seconds}} behind <strong>{cluster}</strong> {unreachable, select, true {(Unreachable)} false {}}',
    Ago: '{time} ago',
    ListenOnlyMode: 'Listen Only Mode',
    PceInListenOnlyMode: 'PCE has been in Listen Only mode since {time}',
    PceInRollingUpgrade: 'Supercluster upgrade in progress',
    CompletedCount: '{count} Completed',
    PendingCount: '{count} Pending',
    UpgradeStatus: 'Upgrade Status',
    ViewPCEHealth: 'View PCE Health',
    RollingUpgrade: 'Health unavailable until upgraded',
    Notifications: {
      NodeRunlevelNotFive: '{node} not running at runlevel 5',
      NodeCpuStatus: '{node} CPU is in {status} status',
      NodeDiskStatus: '{node} disk is in {status} status',
      NodeMemoryStatus: '{node} memory is in {status} status',
      NodeServicesStatus: '{node} services are in {status} status',
      NodeNetworkReplicationStatus: 'Network Replication is in {status} status',
    },
    UnableToGetLastGeneratedTS: 'Unable to get last health check timestamp',
    UpSinceDate: 'Up since {when, date, L_hh_mm_ss}',
    Metrics: {
      TrafficDBMigrationBody: 'Some traffic flows may be missing from Explorer.',
      TrafficDBMigrationDetails: 'See PCE Health for more details.',
      TrafficDBMigrationTitle: 'Traffic database migration in progress',
    },
  },
  DateTimeInput: {
    SelectFromPlaceholder: 'Select or anytime (blank)',
    SelectToPlaceholder: 'Select or now (blank)',
    From: 'From',
    To: 'To',
    Anytime: 'Anytime',
    SelectedTime: '{when, date, L_HH_mm}',
    Now: 'Now',
    Later: 'Later',
    LastPolicyProvisioned: 'Last Policy Provisioned - {when, date, L_hh_mm_ss}',
    CustomTime: 'Custom Time',
    SelectDateAndTime: 'Select Date and Time',
    ListenOnlyEnableTime: 'Listen Only Enabled - {when, date, L_hh_mm_ss}',
    Last1Hour: 'Last 1 hour',
    Last24Hours: 'Last 24 hours',
    Last7days: 'Last 7 days',
    Last30days: 'Last 30 days',
    InvalidTimeRange: 'Invalid time range selected',
    LimitedTimeRange: 'Time range is limited to {count, plural, =1 {1 day} other {# days}}',
    ShortDays: {
      SunSat: 'S',
      Mon: 'M',
      TuesThurs: 'T',
      Wed: 'W',
      Fri: 'F',
    },
  },
  FlowCollectionFilters: {
    ProtocolRequired: 'Protocol is required for drop action',
    ProtocolHint: 'Protocol can be either TCP, UDP, ICMP or ICMPv6',
    PortHint: 'Select a port between 0 and 65535. Enter -1 for Any Port',
    IPAddressHint:
      'Select an IP Address in the format 255.255.255.255<br>Class E IP addresses (240.0.0.0-255.255.255.254) are not permitted<br>For Any IP Address type 0.0.0.0/0',
    TypeIPOrCIDR: 'Type IP address or CIDR block',
    TypePort: 'Type Port',
    DestinationIP: 'Destination IP Address',
    Drop: 'Drop',
    Aggregate: 'Aggregate',
    InvalidPortNo: 'Invalid Port Number',
    NoFilters: 'No Flow Collection',
    TakeAction: 'Take the following Action:',
    MatchCondition: 'When traffic matches the following conditions:',
    MatchingDrop: 'Do not store matching flow information',
    MatchingAggregate:
      'If a broadcast or multicast traffic flow is received by multiple workloads, aggregate all reported instances of that flow into a single record in the traffic database, and discard the destination workload information',
    DeleteConfirm: 'Are you sure you want to remove {count, plural, =1 {flow collection} other {# flow collections}}?',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Flow Collection Filter has} other {# Flow Collection have}} been successfully deleted',
    Delete: 'Remove {count, plural, =1 {Flow Collection Filter} other {Flow Collection}}',
    RemoveResultTitle: 'Result of {count, plural, =1 {flow collection} other {# flow collections}} removal',
    Errors: {
      Edit: 'Unable to Edit Flow Collection',
      Create: 'Unable to Create Flow Collection',
    },
  },
  Workloads: {
    ApplyPolicy: 'Apply Policy',
    ActiveSyncing: 'Active (Syncing)',
    AKI: 'Authority Key Identifier',
    WorkloadName: 'Workload Name',
    LastAppliedPolicy: 'Last Applied Policy',
    AddUnmanaged: 'Add Unmanaged Workload',
    All: 'All Workloads',
    AutoRefresh: 'Refresh the workload list every 30 seconds',
    ByCount: '{count, plural, =1 {Workload} other {Workloads}}',
    WithCount: '{count} {count, plural, =1 {Workload} =0 {Workload} other {Workloads}}',
    Clear: 'Clear',
    ClearSuspension: 'Clear Suspension',
    ClearSuspensionForWorkload: 'Clear the VEN Suspension Error for this Workload:',
    Compatible: 'Compatible',
    Configuration: 'Workload Configuration',
    ConfirmVENSuspension: 'Confirm VEN Suspension',
    ConfirmVENSuspensionClear: 'Confirm VEN Suspension Clear',
    Created: 'Workload created',
    DefaultGateway: 'Default Gateway',
    Deleted: 'Workload deleted',
    Edit: 'Edit Workloads',
    ErrorState:
      'VEN Suspension Mode places the Workload in the Error state and prevents the PCE from disabling communication with other Workloads',
    FullEnforcement: 'Full Enforcement',
    Full: 'Full',
    FullEnforcementInbound:
      'Rules are enforced for all inbound and outbound services. Traffic not allowed by a Rule is blocked',
    HeartbeatLastReceived: 'Heartbeat Last Received',
    IdlePolicyStateInfo: 'To change Enforcement, move the workload into a group.',
    InboundEnforcement: 'Inbound Enforcement',
    Interfaces: 'Interfaces',
    InvalidStateToApplyPolicy:
      'Unable to update policy state for workloads in Idle. Move workload into a group to assign policy state.',
    Ignored: 'Ignored',
    IssuerName: 'Issuer Name',
    MachineAuthenticationId: 'Machine Authentication ID',
    MachineAuthenticationExample: 'Machine Authentication ID Example:',
    MajorIncompatibilities: 'Major incompatibilities found',
    ManagedInterfaces: 'Managed interfaces will be included in policy configuration provided by PCE',
    ManagedIgnored:
      'Ignored interfaces will NOT be included in policy configuration provided by the PCE. Traffic will continue to flow through the interface uninterrupted.',
    PossibleIncompatibilities: 'Possible incompatibilities found',
    NoIncompatibilities: 'No incompatibilities',
    NoDomainInterfaces: 'No Domain Interfaces',
    NoInterfaces: 'No Interfaces',
    NoChanges: 'No changes will be made to the VEN itself',
    NoMatchData: 'No matching Workloads to display',
    NotValidBefore: 'Not Valid Before',
    NotValidAfter: 'Not Valid After',
    Pair: 'Pair Workloads',
    PCEAction: 'PCE Action',
    PolicySync: 'Policy Sync',
    PublicIP: 'Public IP',
    ProcessesNotification: 'These are processes that are listening for incoming connections - Last updated at {time}',
    RecalculatePolicy:
      'If the VEN remains in Suspension Mode, the PCE may mark this Workload as Offline and recalculate policy accordingly',
    Release: 'Release',
    OSRelease: 'OS Release',
    RemoveSuspensionOnPCE: 'This action only removes this Workload from VEN Suspension Mode on the PCE',
    Suspend: 'Mark as Suspended',
    SubjectName: 'Subject Name',
    IPCIDR: 'IP/CIDR',
    InterfacePlaceholder: 'E.g. eth0.public',
    IPCIDRPlaceholder: 'E.g. 10.0.10.1/24',
    ProcessName: 'Process Name',
    ErrorTotal: '{count, plural, =1 {# Workload} other {# Workloads}} in Error',
    SuspensionTotal: '{count, plural, =1 {# Workload} other {# Workloads}} in Suspension',
    ErrorSuspensionTotal:
      '{error, plural, =1 {# Workload} other {# Workloads}} in Error, {suspended, plural, =1 {# Workload} other {# Workloads}} in Suspension.',
    Total: 'Total Workloads',
    UnableToCommunicate: 'The VEN will be unable to communicate with the PCE until the VEN comes out of Suspension',
    VENConnectivity: 'VEN Connectivity',
    View: 'View Workloads',
    VENStillSuspended: 'This does not remove the VEN itself from Suspension Mode',
    VenSuspensionNotice: 'This Workload will be marked as in VEN Suspension Mode on the PCE:',
    WorkloadsNumber: '{count, plural, =0 {no Workloads} =1 {One Workload} other {# Workloads}}',
    WorkloadToEnforcementNotification: 'Workload will be moved into <b>{enforcement}</b> and <b>{visibility}</b>',
    WorkloadToEnforcementNotificationCS: 'Workload will be moved into <b>{enforcement}</b>',
    UnmanagedCantUnpair: 'Cannot Unpair Unmanaged Workloads',
    UnmanagedCantUnpairDesc: 'Unmanaged Workloads can only be managed using the Illumio REST API',
    UpdateAllWorkloads: 'Update All Static Workloads',
    UpdateSelectedWorkloads: 'Update Selected Workloads',
    Selective: 'Selective',
    RulesIdle:
      'Idle Workloads do not receive Segmentation Rules. Change the Enforcement Mode of the Workload to receive Segmentation Rules',
    EnforcementBoundary: 'Enforcement Boundary',
    ViewEnforcementBoundary: 'View Enforcement Boundary',
    ViewEnforcementBoundaries: 'View Enforcement Boundaries',
    EnforcementBoundaries: 'Enforcement Boundaries',
    EnforcementBoundariesIdle:
      'Idle Workloads do not receive Enforcement Boundaries. Change the Enforcement Mode of the Workload to receive Enforcement Boundaries',
    EnforcementBoundariesVersionLow:
      'The workload is not managed by a VEN that supports Enforcement Boundaries. Upgrade the VEN to version 21.2.0 or above for Enforcement Boundaries',
    EnforcementBoundariesActiveVersion: 'This list shows only active enforcement boundaries',
    EnforcementBoundariesInboundNoFound: 'No Inbound Enforcement Boundaries found',
    EnforcementBoundariesOutboundNoFound: 'No Outbound Enforcement Boundaries found',
    EnforcementBoundariesSelectedInbound:
      'Rules are enforced only for selected inbound services when workload is within scope of a Enforcement Boundaries Rule',
    OutboundEnforcement: 'Outbound Enforcement',
    SetEnforcement: 'Set enforcement',
    EnforceBoundariesEdgeDes: 'Rules are enforced for all services. Illumio Edge blocks traffic not allowed by a rule',
    StagedPolicyAllStaticPolicyWorkloads: 'Staged Policy will be applied immediately on all static policy Workloads',
    StagedPolicyWillBeApplied:
      'Staged Policy will be applied immediately on {count, plural, =-1 {all Selected Workloads} =0 {0 Selected Workloads} =1 {1 Selected Workload} other {# Selected Workloads}}',
    StagedPolicyWillBeAppliedWithLabel:
      'Staged Policy will be applied immediately on all static policy Workloads with the following labels',
    ThisProcessCanTakeAFewMinutesToTakeEffect: 'This process can take a few minutes to take effect.',
    RefreshThePageToSeeTheUpdatedPolicySyncStatus: 'Refresh the page to see the updated Policy Sync status.',
    VisibilityOnlyReports: 'No traffic is blocked by policy',
    VisibilityOnlyReportsEdge: 'Illumio Edge does not block any traffic',
    StaticWorkloads: 'Static Workloads',
    AdaptiveWorkloads: 'Adaptive Workloads',
    PolicyLastApplied: 'Policy Last Applied',
    PolicyLastReceived: 'Policy Last Received',
    LastHeartbeatReceived: 'Last Heartbeat Received',
    UnavailableViewableOn: 'Unavailable. Viewable on {workloadPce}.',
    UnavailableCheckHost: 'Unavailable. Check {workloadPce}.',
    ThisPCE: '{fqdn} (this PCE)',
    VenToPCEAuthentication: {
      Title: 'VEN to PCE Authentication',
      PKICertificateId: 'PKI Certificate ID',
      PKICertificateIdExamples: 'PKI Certificate ID Examples:',
      PKICertificateIdExampleWithDN: 'Distinguished Name (DN) in the subject field of the certificate:',
      PKICertificateIdExampleWithSAN: 'DNS name in the Subject Alternative Name (SAN) field of the certificate:',
    },
    Create: {
      Placeholder: {
        MachineAuthenticationId: 'Type a Machine Authentication ID',
        SPN: 'Type a SPN to authenticate VEN using Kerberos',
        WorkloadName: 'Type a name for the new Unmanaged Workload',
        WorkloadHostName: 'Type a Hostname',
        WorkloadLocation: 'Type a Location',
        WorkloadOSFamily: 'Type an OS Family',
        WorkloadPublicIP: 'Type a Public IP. Can be a Load Balancer or Router connected to the host',
        WorkloadRelease: 'Type an OS Release',
        PKICertificateId: 'Type a PKI Certificate ID to authenticate VEN using a X.509 certificate',
      },
    },
    List: {
      AddWorkloadWithoutVEN: 'Add Workload without installing VEN',
      AskAgainCheck: "Don't ask me again",
      ConfirmAffectMultipleWorkloads:
        'This operation will affect multiple Workloads. Are you sure you want to continue?',
      EditMultipleLabels: 'Edit Labels for Multiple Workloads',
      PairInstallVEN: 'Pair Workload and install VEN',
      PairWithPairingProfile: 'Pair Workload with Pairing Profile',
    },
    EditLabels: {
      MultipleLabels: 'Multiple {type} Labels',
      WorkloadsModified: '{count, plural, =1 {# Workload was} other {# Workloads were}} successfully modified',
      WorkloadsCannotModified: '{count, plural, =1 {# Workload} other {# Workloads}} cannot be modified: {message}',
      MovingWorkloadGroupGeneric:
        '{managed} {count, plural, =0 {}  =1 {Workload} other {Workloads}} will be moved into the following Enforcement:',
      MovingWorkloadIntoGroup:
        '{managed} {count, plural, =0 {}  =1 {Workload} other {Workloads}} will be moved into the Enforcement of the selected group shown below:',
    },
    Errors: {
      Edit: 'Unable to Edit Workload',
      Create: 'Unable to Create Workload',
    },
    Remove: {
      TitleConfirmation: 'Remove {count, plural, =1 {Unmanaged Workload} other {# Unmanaged Workloads}}',
      TitleResult: 'Result of {count, plural, =1 {Unmanaged Workload} other {# Unmanaged Workloads}} removal',
      Confirm:
        'Are you sure you want to remove the {count, plural, =1 {selected Unmanaged Workload} other {# selected Unmanaged Workload}}? Policy will no longer be calculated for {count, plural, =1 {that Workload} other {these Workloads}}',
      Success: '{count, plural, =1 {Workload has} other {# Workloads have}} been successfully removed',
    },
    Unpair: {
      Title: 'Unpair {count, plural, =1 {VEN} other {# VENs}}',
      Action: 'Uninstalls the selected VEN(s).',
      Type: {
        Header: 'Pairing Method',
        KerberosPKI: 'Paired using Kerberos or PKI',
        PairingKey: 'Paired using pairing key',
      },
      Mode: {
        EdgeType: 'Visibility Only/Enforced',
        Type: 'Visibility Only/Selective/Full',
      },
      Actions: {
        Header: 'Remove or Unpair Actions',
        KerberosPKI: 'The associated workloads will become unmanaged and keep labels ana IP addresses.',
        PairingKey: 'Removes policy for the associated workloads.',
        FirewallChoice: 'Policies are configured into the host firewall based on the option selected below.',
        Idle: 'No changes to the host firewall.',
      },
      SelectFirewallState: 'Select final firewall status',
      IdleNotApply: '-(does not apply to Idle workloads)',
      Options: {
        OperatingSystems: {
          LinuxAIXSolaris: 'Linux / AIX / Solaris',
          AIXSolaris: 'AIX / Solaris',
          Linux: 'Linux',
          Windows: 'Windows',
          LinuxMacOS: 'Linux / MacOS',
          All: 'All operating systems',
        },
        First: {
          Title: 'Remove Illumio policy - Default',
          TitleEdge: 'Remove Illumio policy',
          LinuxDesc: 'Removes Illumio policy and retains the coexistent firewall rules.',
          AIXSolarisDesc: 'Removes Illumio policy and reverts firewall rules to the pre-pairing state.',
          WindowsDesc: 'Removes Illumio WFP filters and activates Windows Firewall.',
          WindowsEdgeDesc: 'Removes Illumio WFP filters',
        },
        Second: {
          Title: 'Open all ports',
          Desc: 'Leaves all ports open.',
        },
        Third: {
          Title: 'Close all ports except remote management',
          LinuxDesc: 'Temporarily allows only SSH/22 until system is rebooted.',
          WindowsDesc: 'Allows only RDP/3389 and WinRM/5985, 5986.',
        },
      },
    },
    RemoveDialog: {
      RemoveNow: 'Remove Now',
      Title: 'Remove and Unpair Workloads',
      Content: {
        Type: {
          Header: 'Workload Type',
        },
        Actions: {
          Unmanaged: 'Remove entirely from PCE',
          KerberosPKI: 'Keep in PCE as unmanaged with labels after unpairing VEN',
          PairingKey: 'Remove entirely from PCE after unpairing and uninstalling VEN',
          Idle: 'Remove the VEN and make no other changes',
        },
        SelectedCount: '{selected, plural, =1 {# workload} other {# workloads}} selected',
        SelectedUninstall:
          '{selected, plural, =1 {# VEN managed workload} other {# VEN managed workloads}} to be uninstalled.',
        SelectFirewallState: 'Select the uninstall firewall state:',
        Options: {
          First: {
            Title: 'Remove Illumio policy (recommended)',
          },
        },
      },
    },
    Status: {
      NotInstalled: 'Not Installed',
      UnmanagedNotInstalled: 'Unmanaged (VEN not installed)',
      Offline: 'Offline',
      Suspended: 'Suspended',
      ErrorAndSuspended: 'Error and Suspended',
      WarningAndSuspended: 'Warning and Suspended',
      Syncing: 'Syncing',
      Uninstalling: 'Uninstalling',
      ErrorCount: '{count, plural, =1 {Error} other {Errors}}',
      WarningCount: '{count, plural, =1 {Warning} other {Warnings}}',
    },
    Summary: {
      Machine: 'Machine',
      KerberosServicePrincipalName: 'Kerberos Service Principal Name',
      TenMinuteWait: 'Your bundle has been requested. This may take up to 10 minutes.',
      Uptime: 'Uptime',
      VENVersion: 'VEN Version',
      ForwardRules: 'Forward Rules',
      RemotePeers: 'Remote Peers',
      ErrorMessage: 'Error Message',
    },
    IncompatibilityTypes: {
      IPSec: 'IPSec',
      IPv4Forwarding: 'IPv4 forwarding enabled',
      IPv4ForwardingPacketCount: 'IPv4 forwarding packet count',
      MulticastIncomingPacketCount: 'Multicast incoming packet count',
      MulticastOutgoingPacketCount: 'Multicast outgoing packet count',
      IpTablesRuleCount: 'iptables rule count',
      IPv4GlobalScope: 'IPv4 global scope',
      IPv6GlobalScope: 'IPv6 global scope',
      IPv6ActiveConnectionCount: 'IPv6 active connections count',
      IpTablesEnabled: 'iptables enabled',
      Ip6TablesEnabled: 'ip6tables enabled',
      Ip6TablesRuleCount: 'ip6tables rule count',
      IPv6Enabled: 'IPv6 enabled',
      IPv6ForwardingEnabled: 'IPv6 forwarding enabled',
      RoutingTableConflict: 'Routing table conflict',
      TeredoTunnelingEnabled: 'Teredo tunneling enabled',
      IPv4Enabled: 'IPv4 enabled',
      UnwantedNics: 'Unsupported NICs',
      UnwantedRoles: 'Unsupported roles',
      MtuSize: 'MTU size',
      UnwantedSoftware: 'Non-Illumio security software',
      GroupPolicy: 'Group policy',
      UnwantedWFProviders: 'Non-Illumio WFP providers',
    },
  },
  Vulnerability: {
    Before: 'Before',
    After: 'After',
    Calculating: 'Calculating...',
    ImportTime: 'Import Time',
    Traffic: 'Vulnerability Traffic',
    All: 'All Vulnerabilities',
    ExposureScore: 'Vulnerability Exposure Score',
    BlockedTraffic: 'Blocked Vulnerable Traffic',
    Score: 'Vulnerability Score',
    EWExposure: 'E/W Exposure',
    InternetExposure: 'Northern Exposure',
    CVEIds: 'CVE-IDs',
    VEScore: 'V-E Score',
    VulnerabilityExposureScore: 'Vulnerability Exposure Score (V-E Score)',
    TotalVEScore: 'Total V-E Score',
    HighestVEScore: 'Highest V-E Score',
    HighestVulnerability: 'Highest Vulnerability',
    ExposedVulnerabilities: 'Exposed Vulnerabilities',
    MitigateVulnerabilities: 'Mitigate Vulnerabilities',
    MitigatedVulnerabilities:
      '{count, plural, =0 {Mitigated Vulnerability} =1 {1 Mitigated Vulnerability} other {# Mitigated Vulnerabilities}}',
    MaxSeverity: 'Max Severity',
    Exposed: 'Exposed Vulnerability',
    Vulnerabilities: '{count, plural, =0 {No Vulnerabilities} =1 {1 Vulnerability} other {# Vulnerabilities}}',
    IntraScopeExposure: 'Intra-Scope Exposure',
    ExtraScopeExposure: 'Extra-Scope Exposure',
    IntraScopeMitigation: 'Intra-Scope Vulnerability Mitigation',
    ExtraScopeMitigation: 'Extra-Scope Vulnerability Mitigation',
    InternetMitigation: 'Northern Vulnerability Mitigation',
    Workloads: 'Vulnerable Workloads',
    ProvidedTraffic: 'Provided Traffic (Reported)',
    Reduced: 'Reduced',
    Eliminated: 'Eliminated',
    BoxInfo:
      '{state, select, open {Reduced} closed {Eliminated}} {type, select, intra {Intra-Scope} extra {Extra-Scope} ipList {Internet}} exposure from {count} "{severity, select, 0 {Info} 1 {Low} 2 {Medium} 3 {High} 4 {Critical}}" severity vulnerabilities',
  },
  Forms: {
    SelectOption: 'Select an option',
  },
  EnforcementBoundaries: {
    MixedEnforcement: 'Mixed Enforcement',
    BlockedConnections: 'Enforcement Boundary Blocked Connections',
    EnfRulesCount: '{count} {count, plural, =1 {rule} other {rules}}',
    RulesDesc: 'No rules exist to allow connections across the Boundary',
    BlockedConnectionCount: '{count} {count, plural, =1 {connection} other {connections}}',
    BlockedConnectionDesc: 'These connections will be blocked when the Enforcement Boundary is provisioned',
    NoBlockedConnectionDesc: 'No connections will be blocked by this boundary',
    Errors: {
      Edit: 'Unable to Edit Enforcement Boundaries Rule',
      Create: 'Unable to Create Enforcement Boundaries Rule',
      MatchingWorkloadsSelective: 'No workloads are selectively enforced',
      MatchingWorkloadsFullEnforcement: 'No workloads are fully enforced',
      MatchingWorkloadsVisibility: 'No workloads are visible only',
      MatchingWorkloadsIdle: 'No workloads are idle',
      ConsumerProviders: 'Cannot use IP Lists for both Consumers and Providers',
    },
    Warning: {
      FQDN: 'FQDN is not supported. The PCE allows adding FQDNs to IP lists; however, it drops the FQDN component when the Enforcement Boundary results in an outbound deny rule to an IP list with FQDNs.',
    },
    RulesCount: '{count, plural, =1 {# Enforcement Boundaries Rule} other {# Enforcement Boundaries Rules}}',
    RulesProvision:
      '{count, plural, =1 {# Enforcement Boundaries Rule} other {# Enforcement Boundaries Rules}} added to the Enforcement Boundary Ruleset allow connections to cross the boundary. Be sure to provision these rules before provisioning the boundary.',
    Remove: 'Remove Enforcement Boundaries Rule',
    DeleteConfirm: 'Are you sure you want to remove this Enforcement Boundaries Rule?',
    Rules: 'Enforcement Boundaries Rules',
    ProvidingServices: 'Providing Services',
    UpdateSuccess:
      '{count, plural, =1 {Enforcement Boundary has} other {# Enforcement Boundaries have}} been successfully updated',
    UpdateResultTitle:
      'Result of {count, plural, =1 {Enforcement Boundary} other {Enforcement Boundaries}} status update',
    NoMatchData: 'No matching Enforcement Boundaries Rules to display',
    NoData: 'No Enforcement Boundaries Rules to display',
    List: {
      DeleteConfirm:
        'Are you sure you want to remove the selected Enforcement {count, plural, =1 {Boundary} other {Boundaries}}?',
    },
    WorkloadsInScope: 'Workloads in Scope',
    WorkloadEnforcementDesc: 'This workload is within the scope of {count}',
    WorkloadEnforcementFollowingServices: 'The following services are selectively enforced.',
    SelectiveEnforcement: 'Selective Enforcement',
    WorkloadsInScopeCount:
      '<span class="{className}">{count} {count, plural, =1 {workload} other {workloads}}</span> in {scopeType} {mode}',
    WorkloadsInScopeDesc: {
      Selective:
        'Traffic that matches the Enforcement Boundary is blocked unless allowed by a Rule. Unmatching is not blocked.',
      Full: 'All traffic to and from these workloads is blocked unless allowed by Rule.',
      Visibility: 'No traffic is blocked by policy.',
      Idle: 'VEN does not control workload firewalls.',
      SupportEnforcement:
        '{context} <span class="{className}">These workloads must be set to Selective Enforcement to support Enforcement Boundary</span>',
      Mode: 'mode',
      Total:
        '<span class="{className}">{count} {count, plural, =1 {workload} other {workloads}}</span> are within the scope of the Enforcement Boundary',
    },
    Description:
      'An Enforcement Boundary is defined by a scope consisting of Consumer, Provider, and Service. When an Enforcement Boundary is provisioned, connections that match the scope are blocked. A blocked connection can be allowed to cross the Enforcement Boundary by writing a Rule.',
  },
  ComponentForm: {
    DisplayDisabledExample: 'Display Disabled Example',
    EnableDisabledExample: 'Enabled Example',
    DisableExample: 'Disable Example',
    OtherExamples: 'Other Examples',
    HorizontalRadios: 'Horizontal Radios',
    VerticalRadios: 'Vertical Radios',
    MinimumOneItem: 'Minimum one item',
    AdvancedExamples: 'Advance Examples',
    EnabledOptions: 'Enabled Options',
    ComplexRadio: 'Complex Radio',
    SelectExample: 'Select Example',
    EnterUniqueName: 'Enter a unique name no more than {count} characters',
    DynamicFormRadio: 'Dynamic with FormRadio',
    UncontrolledElements: 'Uncontrolled Elements',
    TextareaRowsCols: 'FormTextarea with rows, cols',
    FormTextareaLimited: 'FormTextarea limited Formik props - no errors',
    Themr: 'Themr',
    UploadFile: 'Upload File',
    NonRestrictingFileUpload: 'Non Restricting File Upload',
    RestrictingFileUpload: 'Restricting File Upload',
    CidrIpv4: 'Cidr and Ipv4',
    IPListEditor: 'IP List Editor',
    MaxIpAllowed: 'Max Allowed IP Inputs',
  },
  Tooltip: {
    Location: 'Location:',
    Enforcement: 'Enforcement:',
    Group: 'Group:',
    GroupBy: 'Group by',
    ParentGroup: 'Parent Group',
    ViewGroup: 'View Group',
    GroupCount: '{count, plural, =1 {Group} other {Groups}}: <span class="{className}">{count}</span>',
    WorkloadCount: '{count, plural, =1 {Workload} other {Workloads}}: <span class="{className}">{count}</span>',
    EndpointCountHighlight:
      '{count, plural, =1 {<span class="{className}">{type}:</span>} other {<span class="{className}">{type}s:</span>}} {count}',
    EnforcementCount: '{count, plural, =0 {} other {{count} {type}}}',
    ConnectedWorkloadCount:
      'Connected {count, plural, =1 {Workload} other {Workloads}}: <span class="{className}">{count}</span>',
    Role: 'Role:',
    RoleCount: '{count, plural, =1 {Role} other {Roles}}: <span class="{className}">{count}</span>',
    PolicyState: 'Policy State:',
    IPList: 'IP List:',
    Fqdns: 'FQDNs:',
    ContainerWorkload: 'Container Workload:',
    ContainerWorkloadCount:
      '{count, plural, =1 {Container Workload} other {Container Workloads}}: <span class="{className}">{count}</span>',
    VirtualService: 'Virtual Service:',
    VirtualServiceCount:
      '{count, plural, =1 {Virtual Service} other {Virtual Services}}: <span class="{className}">{count}</span>',
    VirtualServer: 'Virtual Server:',
    VirtualServerCount:
      '{count, plural, =1 {Virtual Servers} other {Virtual Servers}}: <span class="{className}">{count}</span>',
    OSWorkload: 'OS Workload:',
    Services: 'Services :',
    NoLabelData: 'No Label Data',
  },
  VersionMismatch: {
    Header: 'Incompatible PCE UI Version',
    Incompatible: 'The PCE UI Version is incompatible with the PCE software',
    Upgrade:
      'You can upgrade the PCE UI that appears in the PCE web console independently from the nodes in the PCE cluster.',
    However:
      'However, the PCE UI version must match the base version of the PCE software; for example, {uiVersion} must match PCE {pceVersion}',
    UpgradeInfo: 'Upgrade the PCE UI or PCE software so that the versions are compatible',
    UI: 'UI Version',
    PCE: 'PCE Version',
    SeeGuide: 'See the PCE Operations Guide',
  },
  Edge: {
    Endpoint: 'Endpoint',
    Endpoints: 'Endpoints',
    ViewEndpoints: 'View Endpoints',
    DenyRule: 'Deny Rule',
    ManagedEndpoint: 'Managed Endpoint',
    UnmanagedEndpoint: 'Unmanaged Endpoint',
    DiscoveredWorkloads:
      '<span class="{className}">{count}</span> Discovered {count, plural, =1 {Workload} other {Workloads}}',
    ProductName: 'Illumio Edge',
    ServiceViewModalTitle: 'Service - {serviceName}',
    ServiceEditModalTitle: 'Edit Service - {serviceName}',
    IPRangeModalTitle: 'IP Range - {ipRangeName}',
    GroupModalTitle: 'Group - {groupName}',
    Provided: 'Destination in Group',
    Consumed: 'Source in Group',
    NotConsumed: 'Source not in Group',
    Explorer: {
      SourceInclude: 'Included Sources',
      TargetInclude: 'Included Destinations',
      OrIncludeCP: 'Included Sources Or Destinations',
      OrIncludePC: 'Included Destinations Or Sources',
      OrExcludeCP: 'Excluded Sources Or Destinations',
      OrExcludePC: 'Excluded Destinations Or Sources',
      SourceExclude: 'Excluded Sources',
      TargetExclude: 'Excluded Destinations',
    },
    GlobalNetwork: {
      CorporateNatPublicIpsDesc:
        "When using Network Address Translation, this set of IPs appear for communication between Workloads of the corporate network and services on the Internet (including Illumio Edge). This information is required to identify the interfaces on Endpoint Workloads that are connected to the corporate network vs. interfaces that connect to the Internet via an external network (e.g. home or public network) when the information cannot be obtained directly from the Endpoint Workload's operating system.",
    },
    InstallScript: {
      Name: 'Install Script',
      NamePlaceholder: 'Type a name for this Install Script',
      DescriptionPlaceholder: 'Type a description for this Install Script',
      Source: 'Source Install Script',
      GroupAssignment: 'Group Assignment',
      AllowRequests: 'Allow pairing requests from all valid keys generated by this Install Script',
      DenyRequests: 'Deny pairing requests from all valid keys generated by this Install Script',
      RemoveConfirm: 'Are you sure you want to remove this Install Script?',
      Remove: 'Remove Install Script',
      UnlimitedEnpointsCanBePaired: 'Unlimited Endpoints can be paired using this Install Script',
      OneEndpointCanBePaired: 'Only one Endpoint can be paired using this Install Script',
      UnlimitedTime: 'No time limit set for pairing Endpoints with this Install Script',
    },
    SelectSourceIPRanges: 'Select Source IP Ranges',
    SourceIPRanges: 'Source IP Ranges',
    NetworkProfile: 'Network Profile',
    GroupNameShouldBe: 'Group Name should be no longer than 255 characters',
    GroupAlreadyExists: 'Group with this name already exists',
    GroupSetupInstruction:
      'Note: Discovered workloads are not in any group yet. To apply policy to the discovered workloads below, select workloads and move to a group using the “Move to Group” action.',
    MoveToGroup: 'Move to Group',
    Steps: {
      SelectServices: 'Select Services',
      PreviewConfirm: 'Preview and Confirm',
    },
    WelcomePage: {
      WelcomeMessage: 'Welcome to <span class="{className}">Illumio Edge</span>',
      ClickGetStarted:
        'Click <strong>Get Started</strong> to create your first group and define security rules for a set of workloads.',
      ClickAddGroup:
        'Click <strong>Add new Group</strong> to create a group and define security rules for a set of workloads.',
      GoToHome: 'Go To Home Page',
      ImageCall: 'Get started by defining a security policy for the workloads in your network',
    },
    ServiceSelection: {
      AddNewService: 'Add New Service',
      AddNew: 'Add New',
      NameOfGroupTooltip:
        'Specify a name for this group of endpoints. A group can encompass a department (e.g., Finance), or any other logical grouping of endpoints.',
      PageTitle:
        'Specify a name for the group and select incoming services to allow. Click the arrow buttons to add or remove services to the group',
      NameOfGroup: 'Name of Group',
      TypeNameForGroup: 'Type a name for the new Group',
      TypeShowMatching: 'Type to show matching services',
      IncomingServicesToAllow: 'Incoming Services to allow',
      ServicesList: '{count, plural, =0 {none selected} =1 {# Service} other {# Services}}',
      EmptyBanner: 'Select an Incoming Service to begin',
      IncomingServicesTooltip:
        'If you use services in your environment that are not found in the "All Services" list, Click on "Add New" to create a new service',
      RemoveWarningMessage:
        'Services shown below are new or edited. Removing them from the group will discard changes.',
      AreYouSureYouWantToRemove: 'Are you sure you want to remove services?',
      ThereAreMultipleServices: 'There are multiple services with the same name.',
      DoYouWantToAddService: 'Do you want to add all to the group "{groupName}" anyway?',
      DoYouWantToEditService: 'Do you want to edit the service anyway?',
      ServicesMatched: '{count} Matched',
      ServicesMatchedWithFilter: '{selectCount} Selected, {count} Matched',
      ServicesWithFilter: '{selectCount} of {count} Selected',
    },
    IPRangeConfig: {
      AddNewIPRangeTooltip: 'Click ‘Add New’ to create new IP Ranges.',
      ApplyIPRangeTooltip:
        'Choose ‘Allowed IP Range’ for one or more Incoming Service(s) and click ‘Apply IP Range’ to permit those IP Ranges for the selected Incoming Service(s).',
      ApplyIPRange: 'Apply IP Range',
      ApplyIPRanges: 'Apply IP Ranges to Selected Services?',
      AddIPRanges: 'Add IP Ranges for selected services',
      ChooseIPRangeToProtect: 'Select IP Ranges to allow',
      IPRangeNotAllowed: 'An IP Range with the same name already exists. Try a different name',
      PageTitle: 'Set an incoming IP Range for each incoming service',
      ReplaceIPRanges: 'Replace IP Ranges for selected services',
    },
    PreviewConfirm: {
      PageTitle:
        'Preview and confirm changes. Press "Provision" to provision the group or "Save as Draft" to save the draft version of the group',
      HereSummaryCreated: 'Summary of the rules created or modified:',
      GroupSettingsComplete: 'The group settings for new Group {groupName} are complete.',
      ProvisionModalTitle: 'Group {groupName} - Provision',
      AssociateWorkloads: 'Do you want to provision the group to associated workloads now?',
      ServicesWillBeCreated: 'Services will be created:',
      IPRangesWillBeCreated: 'IP Ranges will be created:',
      ServicesWillUpdated: 'Services that will be updated:',
      EmptyGroupMessage:
        'There are no security rules to allow incoming services. The group allows all outgoing services by default.',
    },
    GroupDashboard: {
      NotUsed: 'Not Used',
      UsedBy: 'Used By',
      AdminGroupMessage:
        'This group is allowed to connect to endpoints in groups using specific services configured via their Admin Access Policy',
      NoDataMatching: 'You have filters applied. Clear filters to view all the Groups',
      PairVens: 'Add VENs',
      PairVensTooltip: 'Add more VENs to this group',
      YouHave:
        'You have Security Rules set up for {count, plural, =1 {<span class="{className}">#</span> Group} other {<span class="{className}">#</span> Groups}} of Workloads',
      EditPairing: 'Edit Install Script',
      RevokeScript: 'Revoke Existing Install Script',
      DisabledScriptTooltip: 'Install script actions are disabled for a read only access',
      StoppedPairing:
        'Pairing requests are currently stopped.<br/>Select "Start pairing" to allow pairing requests for valid keys',
    },
    Group: {
      MustContainGroup: "Source Groups can't contain only User Groups.",
      MustClearDeletedUserGroups: 'Deleted User Groups must be cleared to enable save.',
      NoMatchingData: 'No Matching Rules',
      NoData:
        'There are no security rules to allow incoming services. The group allows all outgoing services by default',
      NoAdminRulesData:
        'There are no security rules to allow access to endpoints in this group from any Admin Groups in your environment. Click on "Edit Admin Access" to add any rules',
      RemoveGroupTitle: 'Remove "{groupName}"',
      RemoveGroupContent: 'Are you sure you want to remove the group "{groupName}"?',
      UnableToRemove: 'We are unable to remove the group because of the following:',
      InUseByEndpoint: 'The group is used by one or more Endpoints:',
      InUseByPolicy: 'The group is used in one or more of the following policies:',
      UnknownError: 'An unexpected error occurred deleting the group.',
    },
    Help: {
      Desc: {
        ExplorerSearchFormats: 'View search results in the form of parallel coordinates or table.',
      },
    },
    Workloads: {
      Support: 'Contact Illumio Support',
      MaxLimitWorkloadFound:
        'We have discovered {count, plural, =1 {# Workload} other {# Workloads}}. You have reached the limit of {total, plural, =1 {# Workload} other {# Workloads}}. There may be additional Workloads beyond the limit that remain un-discovered',
      NewDiscovered:
        'You have {count, plural, =0 {no new Workloads} =1 {<span class="{className}">#</span> new Workload} other {<span class="{className}">#</span> new Workloads}} discovered by Crowdstrike',
      CrowdstrikeAID: 'Crowdstrike AID',
    },
    SourceNetwork: 'Source [Network]',
    DestinationNetwork: 'Destination [Network]',
    SourceNetworkProfile: 'Source Network Profile',
    AdminGroup: {
      AdminAccess: 'Admin Access',
      EditAdminAccess: 'Edit Admin Access',
      MarkAdminGroup: 'Mark as Admin Group',
      ClearAdminGroup: 'Clear as Admin Group',
      AdminConfirmTitle: '{groupName} - Mark as Admin Group',
      ClearAdminConfirmTitle: '{groupName} - Clear as Admin Group',
      EmptyGroupMessage:
        'There are no security rules to allow access to endpoints in this group from any Admin Groups in your environment.',
      AdminConfirmMessage:
        'Once marked as Admin Group, <b>{groupName}</b> Group is allowed to connect to endpoints in groups using specific services configured via their Admin Access policy.',
      ClearAdminConfirmMessage: 'Do you want to clear <b>{groupName}</b> Group as Admin Group?',
      AllowedAdminGroups: 'Allowed Admin Groups',
      SelectAdminGroups: 'Select Admin Groups',
      ServiceSelection: {
        PageTitle:
          'Specify a name for the group and select incoming services to allow for Admin Access. Click the arrow buttons to add or remove services to the group',
      },
      WarningIKESettingsNotEnabled:
        'IKE Certificates is missing. This setting is required to provide Admin Access to Groups.',
      SetupAdminGroups:
        'You have no Admin Groups setup currently. To mark a Group as an Admin Group, Click on the Dots menu on any selected Group in the Groups list and select the menu "Mark as Admin Group"',
      AdminAccessEditDisabled:
        'The Admin Access policy allows Admin Groups inbound access to endpoints in this Group. You have no Admin Groups setup currently. To mark a group as an Admin Group, click on the dots menu on any selected group in the Groups list and select the option "Mark as Admin Group"',
      GoToIKESettings: 'Go to IKE Settings',
      AllowedAdminAccess: {
        PageTitle: 'Select Admin Groups to allow for each incoming services',
        EncryptTraffic: 'Encrypt Traffic',
        ApplyGroup: 'Apply Group',
        AddAdminGroup: 'Add Admin Groups for selected services',
        ReplaceAdminGroup: 'Replace Admin Groups for selected services',
        ApplyAdminGroup: 'Apply Admin Groups to Selected Services?',
        ApplyAdminGroupTooltip:
          'Choose ‘Admin Group’ for one or more Incoming Service(s) and click ‘Apply Admin Group’ to permit those Admin Group for the selected Incoming Service(s).',
      },
      GroupReferencesTitle: 'Group References',
      GroupsAdministered: '<strong>{groupName}</strong> is used to administer the following Groups:',
      ModifyReferences:
        'To modify references, click on Admin Access tab for the referenced Groups to modify the policy',
      RevokeInstallScriptContent:
        'Are you sure you want to revoke install script for the group <strong>{groupName}</strong>?',
      RevokeInstallScript: 'Revoke Install Script for <strong>{groupName}</strong>',
      GroupHelpTextSubtitle:
        'Displays a list of groups, admin groups (identifiable with a blue badge), provisioning status, and the number of endpoints in a group.',
      GroupWizardHelpTextSubtitle:
        'Helps create groups, designate admin groups, indicate which admin group can communicate with a target group, and provides an option to encrypt traffic.',
    },
    Port: {
      PortProcess: 'Destination Port/Process',
    },
  },
  WhatsNew: {
    NewAnnouncement: 'New Announcement!',
    IsReleased: '{product} {version} is released',
    SeeWhatsNew: "See What's New!",
    WhatsNewIn: "What's New in {product}",
    RulesForNonDomainAdapters:
      'This PCE now supports policy enforcement on endpoints for non-corporate network traffic. {ruleset} - Click to view new ruleset added to preserve compatibility with policies written on 22.2 and earlier PCEs.',
  },
  Pill: {
    Tooltip: {
      ViewObject:
        'View {object, select, group {Group} service {Service} iplist {IP List} ipRange {IP Range} userGroup {User Group} labelGroup {Label Group} virtualService {Virtual Service} virtualServer {Virtual Server}}',
      UserGroupIsDeleted: 'User Group is deleted',
      UpdateTypeTooltip:
        '{object, select, service {Service} iplist {IP List} labelGroup {Label Group} virtualService {Virtual Service} virtualServer {Virtual Server}} is in {updateType, select, create {Addition} update {Modification} delete {Deletion}} pending status',
    },
  },
  GlobalNetwork: {
    GlobalIPs: 'Corporate Public IPs',
    CorporateNatPublicIpsDesc:
      "When using Network Address Translation, this set of IPs appear for communication between Workloads of the corporate network and services on the Internet. This information is required to identify the interfaces on Endpoint Workloads that are connected to the corporate network vs. interfaces that connect to the Internet via an external network (e.g. home or public network) when the information cannot be obtained directly from the Endpoint Workload's operating system.",
  },
  EssentialServiceRules: {
    Inbound: 'Essential Service Rules - Inbound',
    Outbound: 'Essential Service Rules - Outbound',
    Modal: {
      Title: 'Edit Essential Service Rule',
      ConfirmationTitle: 'Editing Essential Service Rule',
      ConfirmationMessage:
        'Essential Service Rules are programmed on the VEN to allow normal network functionality and communication with the PCE. Improperly editing a rule could cause a disruption of service. Are you sure you want to continue?',
      Purpose: 'Purpose',
      SourcePort: 'Source Port',
      Notification: {
        DNS: 'Use extreme caution when editing this rule. Misconfiguring, or not maintaining, this rule could impact the ability of the VEN to communicate with the PCE and to DNS - undoing this rule may require manual intervention to re-establish connectivity to the PCE and to DNS. Use this function with extreme caution!',
        DHCP: 'Use extreme caution when editing this rule. Misconfiguring this rule, or not maintaining this rule could impact the ability of a workload to receive an IP address.',
        Traceroute:
          'Use extreme caution when editing this rule. If this rule is configured incorrectly, traceroute may not be available.',
        ICMPv6:
          'Use extreme caution when editing this rule. If this rule is misconfigured, or if it is not maintained, Workloads will be unable to use IPv6.',
      },
    },
    Ingress: {
      DHCPv4: 'Required for DHCPv4 replies',
      DHCPv6: 'Required for DHCPv6 replies',
      Traceroute: 'Required for inbound traceroute',
      ICMPv6: 'Required for inbound ICMPv6',
    },
    Egress: {
      DNS: 'Required for outbound DNS',
      DHCPv4: 'Required for outbound DHCPv4',
      DHCPv6: 'Required for outbound DHCPv6',
      Traceroute: 'Required for outbound traceroute',
      ICMPv6: 'Required for outbound ICMPv6',
      PCE: 'Required for HTTPS communication from the Workload to the PCE',
      EventService: 'Required for real-time communication between the Workload and the PCE',
    },
    IPSec: 'Enabled for SecureConnect and Machine Authentication connections',
  },
  IlluminationMap: {
    FQDNExample: 'e.g. www.example.com',
    IPAddressOrCIDRExample: 'e.g. {ipAddress} or {cidrBlock}',
    IPListExample: 'Input the name of an IP list',
    TransmissionExample: 'Broadcast, Multicast, Unicast',
    CollapsedGroups: 'collapsed groups',
    ConsumerAndOrProvider:
      'Consumer {or, select, true {OR} false {AND}} Provider {not, select, true {is not} false {}}',
    ShowExclusionFilters: 'Show Exclusion Filters',
    ShowReportedPolicyDecisionFilter: 'Show Reported Policy Decision Filter',
    LabelSet: 'Label Set',
    LabelSets: 'Label Sets',
    GroupByRole: 'Group by Role',
    GroupByApplication: 'Group by Application',
    GroupByEnvironment: 'Group by Environment',
    GroupByLocation: 'Group by Location',
    ConsumerOrProvider: 'Consumer or Provider{not, select, true {is not} false {}}',
    SaveFilterNameExists: 'A filter with that name already exists. This will overwrite the existing filter.',
    SaveFilterNamePlaceholder: 'Input a name',
    AssignedForUnmanagedWorkloads:
      'Assign Labels for {count} {count, plural, =1 {Unmanaged Workload} other {Unmanaged Workloads}}',
    CreatedForUnmanagedWorkloads:
      '{count} {count, plural, =1 {Unmanaged Workload} other {Unmanaged Workloads}} Created. Do you want to recalculate the table without the newly created workloads?',
    CreateUnmanagedWorkloadInstruction: 'To create unmanaged workloads, first select an IP List.',
    DoNotRecalculate: "Don't Recalculate",
    ConsumerIPs: 'Consumer IPs',
    ProviderIPs: 'Provider IPs',
    ViewUnmanagedIPAddresses: 'View Unmanaged IP Addresses',
    UnmanagedWorkloadError:
      'An unexpected error occurred. Could not create {count, plural, =1 {unmanaged workload} other {unmanaged workloads}}.',
    UnmanagedConsumerIP: 'Unmanaged Consumer IP',
    UnmanagedProviderIP: 'Unmanaged Provider IP',
    AutoGrouping: 'Auto Grouping',
    AutoGroupingSettings: 'Auto Grouping Settings',
    MaxUngroupedItems: 'Grouping threshold',
    AutoGroupingMessage:
      'In large scale graphs, grouping is applied by default to improve readability. Grouping is applied in the order shown below. Use drag-and-drop to rearrange the order.',
    MapWarning:
      'User-defined Label Types are not supported in the Illumination Map. Use Illumination Plus to see a graph with user-defined Label Types',
    PolicyGeneratorWarning:
      'User-defined Label Types are not supported in the Policy Generator. Use Illumination Plus to create policy with user-defined Label Types',
    PaginationPageInfo: '{offsetStart, number} - {offsetEnd, number} of {flowsCount, number} connections',
    PaginationPageInfoShort: '{offsetStart, number} - {offsetEnd, number} of {flowsCount, number}',
    PaginationNextPrevInfo: 'Load {direction, select, next {older} previous {more recent}} connections',
    Top: 'Top',
    AxisValue: 'Axis Value',
    NumberOfFlows: 'Number of Flows',
    SortBy: 'Sort By',
    Mesh: 'Mesh',
    ParallelCoordinatesInfo1: 'Click on an axis value to drill down.',
    ParallelCoordinatesInfo2: 'Drag along an axis (brush) to filter on that dimension.',
    ParallelCoordinatesInfo3: 'Drag an axis title to reorder the axes.',
    FilteringTips: 'Filtering Tips',
    ClearBrush: 'Clear Brush',
    ViewTable: 'Analyze a traffic log',
    ViewGlobalMap: 'View a map of my traffic',
    ViewMesh: 'Visualize traffic flows',
    LandingPageInstructions:
      'Select filter parameters, then click "Run" to display traffic. Or select an option below.',
    ChooseFromResultsOrFilter:
      'Select from the cached results below. \n Or select filter parameters, then click "Run" to display traffic.',
    RecentResults: 'Recently Run Results',
    AppGroupMapClassic: 'Classic App Group Map',
    AppGroupMapIlluminationPlus: 'Illumination Plus App Group Map',
    IlluminationClassic: 'Illumination Classic',
    ContinueUsingIlluminationClassic: 'Continue using Illumination Classic in addition to Illumination Plus',
    IlluminationPlusDescription:
      'The new Illumination Plus map replaces Illumination Classic. Illumination Plus supports more than 4 Label Types and offers many new features.',
    OtherElements: 'Other Elements',
    Elements: 'Elements',
    CircularLayout: 'Make good use of available space.',
    OrganicLayout: 'Reduces overlaps in Label Sets, groups and traffic lines.',
    TieredLayout: 'Highlights the source/destination relationship between Label Sets and groups.',
  },
};
