/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import LabelStore from './LabelStore';
import Constants from '../constants';

let user = null;
//userActivityUser is a user with the user_activity representation
//This representation exposes certain user properties like presence_status which are used on the User Activity Pages
let userActivityUser = null;
let colorBlindMode = 'normal';
let defaultPolicyVersion = 'reported';
let explorerMaxResults = 5000;
let explorerMaxDownloadResults = 100_000;
let defaultAppMap = 'appMap';
let defaultIllumination;

// A edge boolean constant as source of truth to toggle edge and non-edge features.
export const edge = __TARGET__ === 'edge';

export default createApiStore(['USERS_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.USERS_GET_INSTANCE_SUCCESS:
        if (action.options && action.options.query && action.options.query.representation === 'user_activity') {
          userActivityUser = action.data;
        } else {
          user = action.data;
        }

        break;

      case Constants.KVPAIRS_GET_INSTANCE_SUCCESS:
        if (action.options.params.key === 'settings' && action.data) {
          colorBlindMode = action.data.colorBlind || colorBlindMode;
          defaultPolicyVersion = action.data.defaultPolicyVersion || defaultPolicyVersion;
          explorerMaxResults = action.data.explorerMaxResults || explorerMaxResults;
          explorerMaxDownloadResults = action.data.explorerMaxDownloadResults || explorerMaxDownloadResults;
          defaultAppMap =
            action.data.defaultAppMap || (LabelStore.getLabelTypes().length > 4 ? 'illuminationPlus' : 'appMap');
          defaultIllumination = action.data.defaultIllumination;
        }

        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getSpecified() {
    return user;
  },

  getUserActivityUser() {
    return userActivityUser;
  },

  getColorBlindMode() {
    return colorBlindMode;
  },

  getDefaultPolicyVersion() {
    return defaultPolicyVersion;
  },

  getExplorerMaxResults() {
    return explorerMaxResults;
  },

  getExplorerMaxDownloadResults() {
    return explorerMaxDownloadResults;
  },

  getDefaultAppMap() {
    return defaultAppMap;
  },

  getDefaultIllumination() {
    return defaultIllumination;
  },
});
