/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import Constants from '../constants';
import {TrafficStore, ExplorerStore, SessionStore} from '.';
import {createApiStore} from '../lib/store';
import {ServiceUtils, IpUtils} from '../utils';
import {aggregateLinks} from '../utils/Explorer/ExplorerUtils';
import dispatcher from '../actions/dispatcher';

const DOWNLOAD_EVENT = 'download';
const BOUNDARY = 'boundary';
const INTRA = 'intra';
const EXTRA = 'extra';

const defaultFilter = JSON.stringify({
  global: {
    [intl('Map.Traffic.Multicast')]: ['multicast'],
    [intl('Map.Traffic.Broadcast')]: ['broadcast'],
  },
});

const includeFilters = JSON.parse(localStorage.getItem('app_group_filters') || '{}');
let excludeFilters = JSON.parse(localStorage.getItem('app_group_exclusion_filters') || defaultFilter);

const boundary = {};
let vizLinks = [];
let tableLinks = [];
let aggregatedLinks = [];
let ipTraffic = [];
let domainTraffic = [];
let group = {};
let matchedServices = {};
let ipLists = {};
let services = {services: [], ports: [], processes: []};
let excludeServices = {services: [], ports: [], processes: []};
let unmanagedServices = {services: [], ports: [], processes: []};
let unmanagedExcludedServices = {services: [], ports: [], processes: []};
let domainServices = {services: [], ports: [], processes: []};
let domainExcludedServices = {services: [], ports: [], processes: []};
let groups = [];
let addresses = [];
let unmanagedAddresses = [];
let domainAddresses = [];
let entities = [];
let excludeEntities = [];

function getAppFilters() {
  const invertedExclusionFilters = {};
  const excludeFilter = excludeFilters[group.href];

  const transmissionOptions = {
    [intl('Map.Traffic.Unicast')]: 'unicast',
    [intl('Map.Traffic.Multicast')]: 'multicast',
    [intl('Map.Traffic.Broadcast')]: 'broadcast',
  };

  if (excludeFilter) {
    //Reverse the exclusion filters
    if (excludeFilter.hasOwnProperty(intl('Common.ServiceMore'))) {
      invertedExclusionFilters[intl('Common.ServiceIsNotMore')] = excludeFilter[intl('Common.ServiceMore')];
    }

    Object.keys(transmissionOptions).forEach(option => {
      if (!excludeFilter.hasOwnProperty(option)) {
        invertedExclusionFilters[option] = true;
      }
    });
  }

  return {...includeFilters[group.href], ...invertedExclusionFilters};
}

function getFilteredLinksByEntities(links) {
  if (!group) {
    return;
  }

  const appFilters = getAppFilters();
  const entityFilter =
    appFilters && appFilters[intl('Common.EntitiesMore')] && appFilters[intl('Common.EntitiesMore')].length ? 1 : 0;
  const entityExclusionFilter =
    appFilters && appFilters[intl('Common.EntitiesIsNotMore')] && appFilters[intl('Common.EntitiesIsNotMore')].length
      ? 1
      : 0;

  if (
    (!appFilters.hasOwnProperty(intl('Common.EntitiesMore')) ||
      (appFilters.hasOwnProperty(intl('Common.EntitiesMore')) && !entityFilter)) &&
    (!appFilters.hasOwnProperty(intl('Common.EntitiesIsNotMore')) ||
      (appFilters.hasOwnProperty(intl('Common.EntitiesIsNotMore')) && !entityExclusionFilter))
  ) {
    return links;
  }

  return links.filter(link => {
    let filtered = false;

    if (appFilters[intl('Common.EntitiesMore')]) {
      filtered = appFilters[intl('Common.EntitiesMore')].some(filter => {
        const names = [link.dst_name || link.dst_hostname, link.src_name || link.src_hostname];

        return names.includes(filter.name);
      });
    }

    if (appFilters[intl('Common.EntitiesIsNotMore')]) {
      filtered ||= !appFilters[intl('Common.EntitiesIsNotMore')].some(filter => {
        const names = [link.dst_name || link.dst_hostname, link.src_name || link.src_hostname];

        return !names.every(name => name !== filter.name);
      });
    }

    return filtered;
  });
}

function getFilteredLinksByAddress(links) {
  if (!group) {
    return;
  }

  const appFilters = getAppFilters();
  const groupFilter =
    appFilters && appFilters[intl('Common.IPAddressesMore')] && appFilters[intl('Common.IPAddressesMore')].length
      ? 1
      : 0;

  if (
    !appFilters.hasOwnProperty(intl('Common.IPAddressesMore')) ||
    (appFilters.hasOwnProperty(intl('Common.IPAddressesMore')) && !groupFilter)
  ) {
    return links;
  }

  return links.filter(
    link =>
      groupFilter &&
      appFilters[intl('Common.IPAddressesMore')].some(filter => {
        let ips = [link.dst_ip, link.src_ip];

        if (link.address) {
          ips = Array.isArray(link.address) ? link.address : [link.address];
        }

        return ips.some(
          ip =>
            ip === filter.href ||
            (filter.href.includes('/') && IpUtils.isIpContainedInSubnetString({fromIp: ip}, {fromIp: filter.href})),
        );
      }),
  );
}

function getFilteredLinksByGroup(links, type) {
  if (!group) {
    return;
  }

  const edge = SessionStore.isEdge();
  const appFilters = getAppFilters();
  const groupFilter =
    appFilters && appFilters[intl('Common.AppGroupsMore')] && appFilters[intl('Common.AppGroupsMore')].length ? 1 : 0;

  if (
    !appFilters.hasOwnProperty(intl('Common.AppGroupsMore')) ||
    (appFilters.hasOwnProperty(intl('Common.AppGroupsMore')) && !groupFilter)
  ) {
    return links;
  }

  return links.filter(
    link =>
      groupFilter &&
      appFilters[intl('Common.AppGroupsMore')].some(
        filter =>
          ((!edge || type === BOUNDARY || link.dst_scope === INTRA) && link.src_group === filter.href) ||
          ((!edge || type === BOUNDARY || link.src_scope === INTRA) && link.dst_group === filter.href),
      ),
  );
}

const isMatching = (link, service) => {
  const connection = {
    port: link.port,
    protocol: link.protocol || ServiceUtils.lookupProtocol(link.proto),
    processName: link.processName || link.process_name,
    windowsService: link.windowsService || link.windows_service_name,
    osType: link.windowsService || link.windows_service_name ? 'windows' : 'linux',
  };

  if (!service.type) {
    const value = Object.values(connection).join(' ');

    if (service.values[value]) {
      return true;
    }
  } else if (service.type === 'port') {
    if (service.name.trim() === `${ServiceUtils.getPort(link) || ''} ${connection.protocol}`.trim()) {
      return true;
    }
  } else if (!SessionStore.isEdge() && service.type === 'process') {
    if (service.name === connection.processName || service.name === connection.windowsService) {
      return true;
    }
  } else if (service.type === 'process') {
    if (service.name === connection.processName) {
      return true;
    }
  } else if (SessionStore.isEdge() && service.type === 'windowsService') {
    if (service.name === connection.windowsService) {
      return true;
    }
  }

  return false;
};

const catagorizeServices = filters =>
  (filters || []).reduce((result, filter) => {
    result[filter.type] ||= [];
    result[filter.type].push(filter);

    return result;
  }, {});

function getFilteredLinksByService(links) {
  if (!group) {
    return;
  }

  const appFilters = getAppFilters();
  const serviceFilter =
    appFilters && appFilters[intl('Common.ServiceMore')] && appFilters[intl('Common.ServiceMore')].length ? 1 : 0;
  const serviceExclusionFilter =
    appFilters && appFilters[intl('Common.ServiceIsNotMore')] && appFilters[intl('Common.ServiceIsNotMore')].length
      ? 1
      : 0;

  if (
    (!appFilters.hasOwnProperty(intl('Common.ServiceMore')) ||
      (appFilters.hasOwnProperty(intl('Common.ServiceMore')) && !serviceFilter)) &&
    (!appFilters.hasOwnProperty(intl('Common.ServiceIsNotMore')) ||
      (appFilters.hasOwnProperty(intl('Common.ServiceIsNotMore')) && !serviceExclusionFilter))
  ) {
    return links;
  }

  if (SessionStore.isEdge()) {
    // Edge matches services categories as an AND
    const serviceFilters = catagorizeServices(serviceFilter && appFilters[intl('Common.ServiceMore')]);
    const serviceExclusionFilters = catagorizeServices(
      serviceExclusionFilter && appFilters[intl('Common.ServiceIsNotMore')],
    );

    return links.filter(link => {
      const portLinks = link.portObject ? Object.values(link.portObject) : [link];

      return portLinks.some(
        portLink =>
          (!serviceFilter ||
            Object.values(serviceFilters).every(serviceType =>
              serviceType.some(service => isMatching(portLink, service)),
            )) &&
          (!serviceExclusionFilter ||
            !Object.values(serviceExclusionFilters).every(serviceType =>
              serviceType.some(service => isMatching(portLink, service)),
            )),
      );
    });
  }

  // ASP matches services categories as an OR
  return links.filter(link =>
    (link.portObject ? Object.values(link.portObject) : [link]).some(
      portLink =>
        (!serviceFilter || appFilters[intl('Common.ServiceMore')].some(service => isMatching(portLink, service))) &&
        (!serviceExclusionFilter ||
          !appFilters[intl('Common.ServiceIsNotMore')].some(service => isMatching(portLink, service))),
    ),
  );
}

function getFilteredLinks(links, type) {
  if (!group) {
    return;
  }

  const edge = SessionStore.isEdge();
  const appFilters = getAppFilters();
  const noServiceFilters = {...appFilters};

  delete noServiceFilters[intl('Common.ServiceMore')];
  delete noServiceFilters[intl('Common.ServiceIsNotMore')];
  delete noServiceFilters[intl('Common.EntitiesMore')];
  delete noServiceFilters[intl('Common.EntitiesIsNotMore')];
  delete noServiceFilters[intl('Common.AppGroupsMore')];
  delete noServiceFilters[intl('Common.IPAddressesMore')];

  const filterLength = noServiceFilters ? Object.keys(noServiceFilters).length : 0;

  if (type !== BOUNDARY && (!appFilters || !filterLength)) {
    return links;
  }

  const policy = [];

  [
    intl('Common.Allowed'),
    intl('Common.PotentiallyBlocked'),
    intl('Common.Blocked'),
    intl('Common.Unknown'),
    intl('Common.BlockedByBoundary'),
    intl('Common.PotentiallyBlockedByBoundary'),
  ].forEach(policyDecision => {
    if (appFilters[policyDecision]) {
      if (policyDecision === intl('Common.BlockedByBoundary')) {
        policyDecision = intl('Common.Blocked');
      } else if (policyDecision === intl('Common.PotentiallyBlockedByBoundary')) {
        policyDecision = intl('Common.PotentiallyBlocked');
      }

      policy.push(policyDecision);
      delete noServiceFilters[policyDecision];
    }
  });

  const transmission = [];

  [intl('Map.Traffic.Unicast'), intl('Map.Traffic.Multicast'), intl('Map.Traffic.Broadcast')].forEach(cast => {
    if (appFilters[cast]) {
      transmission.push(cast);
      delete noServiceFilters[cast];
    }
  });

  const rfc1918Ranges = ['10.0.0.0/8', '172.16.0.0/12', '192.168.0.0/16'];
  const ipIsInRFC1918Range = ip =>
    rfc1918Ranges.some(range => IpUtils.isIpContainedInSubnetString({fromIp: ip}, {fromIp: range}));

  return links.filter(link => {
    const remainingFilters = {...noServiceFilters};

    if (
      type === BOUNDARY &&
      (link.src_type === intl('Common.VirtualServices') || link.dst_type === intl('Common.VirtualServices'))
    ) {
      return false;
    }

    // Remove deleted workload flows from the boundaries
    if (type === BOUNDARY && ((link.src_workload && !link.src_href) || (link.dst_workload && !link.dst_href))) {
      return false;
    }

    if (policy.length && !policy.includes(link.policy)) {
      return false;
    }

    if (
      (appFilters[intl('Common.BlockedByBoundary')] || appFilters[intl('Common.PotentiallyBlockedByBoundary')]) &&
      !link.boundaryDecision
    ) {
      return false;
    }

    if (policy.length === filterLength) {
      // If all the filters are policy filters we are done
      return true;
    }

    if (transmission.length && !transmission.includes(link.dst_transmission || link.transmission)) {
      return false;
    }

    if (policy.length + transmission.length === filterLength && type !== BOUNDARY) {
      // If all the filters are policy/transmission filters we are done
      return true;
    }

    if (type === 'domain') {
      // Ignore the rest of the filters
      return true;
    }

    if (edge) {
      if (
        (appFilters[intl('Common.Provided')] || appFilters[intl('Common.ProviderWorkload')]) &&
        link.dst_scope !== 'intra'
      ) {
        return false;
      }

      if (
        (appFilters[intl('Common.Consumed')] || appFilters[intl('Common.ConsumerWorkload')]) &&
        link.src_scope !== 'intra'
      ) {
        return false;
      }

      if (appFilters[intl('Common.NotProvided')] && link.dst_scope === 'intra') {
        return false;
      }

      if (appFilters[intl('Common.NotConsumed')] && link.src_scope === 'intra') {
        return false;
      }
    } else {
      // Flows with workloads as the provider
      if (
        appFilters[intl('Common.Provided')] &&
        !appFilters[intl('Common.Consumed')] &&
        ((link.direction ? link.direction !== intl('Common.Inbound') : group && link.dst_scope === EXTRA) ||
          (link.src_scope === INTRA && type !== BOUNDARY && !appFilters[intl('AppGroupCoverage.IntraScope')]))
      ) {
        return false;
      }

      if (
        appFilters[intl('Common.Consumed')] &&
        !appFilters[intl('Common.Provided')] &&
        ((link.direction ? link.direction !== intl('Common.Outbound') : group && link.src_scope === EXTRA) ||
          (link.dst_scope === INTRA && !appFilters[intl('AppGroupCoverage.IntraScope')]))
      ) {
        return false;
      }
    }

    if (type === 'unmanaged') {
      // Ignore the rest of the filters
      return true;
    }

    delete remainingFilters[intl('Common.Consumed')];
    delete remainingFilters[intl('Common.Provided')];
    delete remainingFilters[intl('Common.NotConsumed')];
    delete remainingFilters[intl('Common.NotProvided')];
    delete remainingFilters[intl('Common.ConsumerWorkload')];
    delete remainingFilters[intl('Common.ProviderWorkload')];

    if (!Object.keys(remainingFilters).length) {
      return true;
    }

    if (
      (appFilters[intl('Common.IPList')] || appFilters[intl('Common.IPList')]) &&
      (link.src_type === intl('Common.IPList') || link.dst_type === intl('Common.IPList'))
    ) {
      return true;
    }

    if (appFilters[intl('PCE.FQDN')] && link.dst_domain) {
      return true;
    }

    if (
      appFilters[intl('Common.RFC1918')] &&
      !link.dst_domain &&
      ((link.src_type === '' && ipIsInRFC1918Range(link.src_ip)) ||
        (link.dst_type === '' && ipIsInRFC1918Range(link.dst_ip)) ||
        (link.src_ip_lists &&
          link.src_ip_lists.filter(
            list =>
              list.name && (list.name.toLowerCase().includes('rfc1918') || list.name.toLowerCase().includes('private')),
          ).length) ||
        (link.dst_ip_lists &&
          link.dst_ip_lists.filter(
            list =>
              list.name && (list.name.toLowerCase().includes('rfc1918') || list.name.toLowerCase().includes('private')),
          ).length))
    ) {
      return true;
    }

    if (
      appFilters[intl('Common.Internet')] &&
      !link.dst_domain &&
      ((link.src_type === '' && !ipIsInRFC1918Range(link.src_ip)) ||
        (link.dst_type === '' && !ipIsInRFC1918Range(link.dst_ip)) ||
        (link.src_ip_lists && link.src_ip_lists.filter(list => list.name === 'Internet').length) ||
        (link.dst_ip_lists && link.dst_ip_lists.filter(list => list.name === 'Internet').length))
    ) {
      return true;
    }

    delete remainingFilters[intl('Common.IPList')];
    delete remainingFilters[intl('Common.IPList')];
    delete remainingFilters[intl('PCE.FQDN')];
    delete remainingFilters[intl('Common.Internet')];

    if (!Object.keys(remainingFilters).length) {
      return false;
    }

    if (
      (appFilters[intl('AppGroupCoverage.IntraScope')] && link.src_scope !== EXTRA && link.dst_scope !== EXTRA) ||
      appFilters[intl('Common.Consumed')] ||
      appFilters[intl('Common.Provided')]
    ) {
      return true;
    }

    // Make sure both ends are managed and the direction is correct
    if (
      appFilters[intl('AppGroupCoverage.ExtraScope')] &&
      (link.src_scope === EXTRA || link.dst_scope === EXTRA) &&
      link.src_group !== intl('Common.IPAddress') &&
      link.src_group !== '' &&
      link.dst_group !== intl('Common.IPAddress') &&
      link.dst_group !== '' &&
      ((appFilters[intl('Common.Consumed')] && appFilters[intl('Common.Provided')]) ||
        (link.src_scope === INTRA && !appFilters[intl('Common.Provided')]) ||
        (link.dst_scope === INTRA && !appFilters[intl('Common.Consumed')]))
    ) {
      return true;
    }

    return false;
  });
}

function getAddressess(links) {
  return Object.values(
    links.reduce((result, link) => {
      if (link.address) {
        if (Array.isArray(link.address)) {
          link.address.forEach(address => (result[address] = {type: 'ipAddress', href: address, name: address}));
        } else {
          result[link.address] = {type: 'ipAddress', href: link.address, name: link.address};
        }
      } else {
        result[link.src_ip] = {type: 'ipAddress', href: link.src_ip, name: link.src_ip};
        result[link.dst_ip] = {type: 'ipAddress', href: link.dst_ip, name: link.dst_ip};
      }

      return result;
    }, {}),
  );
}

function getGroups(links, type) {
  return Object.values(
    links.reduce((result, link) => {
      if (
        (type === BOUNDARY && link.src_group) ||
        (link.dst_scope === INTRA && link.src_group) ||
        (group?.name === 'global' && link.src_group)
      ) {
        result[link.src_group] = {type: 'appGroups', href: link.src_group};
      }

      if (
        (type === BOUNDARY && link.dst_group) ||
        (link.src_scope === INTRA && link.dst_group) ||
        (group?.name === 'global' && link.dst_group)
      ) {
        result[link.dst_group] = {type: 'appGroups', href: link.dst_group};
      }

      return result;
    }, {}),
  );
}

function getEndpointEntities(links) {
  return Object.values(
    links.reduce((result, link) => {
      if (link.dst_name || link.dst_hostname) {
        const name = link.dst_name || link.dst_hostname;

        result[name] = {
          type: 'entities',
          name,
          key: link.dst_type,
          icon: 'workload',
          href: name,
        };
      }

      if (link.src_name || link.src_hostname) {
        const name = link.src_name || link.src_hostname;

        result[name] = {
          type: 'entities',
          name,
          key: link.src_type,
          icon: 'workload',
          href: name,
        };
      }

      return result;
    }, {}),
  );
}

function getIPLists(links) {
  return Object.values(
    links.reduce((result, link) => {
      if (link.dst_ip_lists && link.dst_ip_lists.length) {
        link.dst_ip_lists.forEach(list => {
          result[list.name] = {
            type: 'ipLists',
            name: list.name,
            key: link.dst_type,
            icon: 'iplist',
            href: list.href,
          };
        });
      }

      if (link.src_ip_lists && link.src_ip_lists.length) {
        link.src_ip_lists.forEach(list => {
          result[list.name] = {
            type: 'ipLists',
            name: list.name,
            key: link.src_type,
            icon: 'iplist',
            href: list.href,
          };
        });
      }

      return result;
    }, {}),
  );
}

function getServiceKey(service) {
  return [service.port, service.proto, service.process_name, service.windows_service_name].join(',');
}

function getServices(links) {
  if (!links) {
    return;
  }

  const services = {};
  const ports = {};
  const processes = {};
  const windowsServices = {};

  links.forEach(link => {
    const portObjects = link.portObject && Object.values(link.portObject);
    const portsList = link.portObject ? portObjects.map(portObject => getServiceKey(portObject)) : [link.serviceKey];

    portsList.forEach((portLink, index) => {
      const linkObject = link.portObject ? portObjects[index] : link;
      const connection = {
        port: linkObject.port,
        protocol: linkObject.protocol || ServiceUtils.lookupProtocol(linkObject.proto),
        processName: linkObject.processName || linkObject.process_name,
        windowsService: linkObject.windowsService || linkObject.windows_service_name,
        osType: linkObject.windowsService || linkObject.windows_service_name ? 'windows' : 'linux',
      };
      const connectionValues = Object.values(connection);
      const value = connectionValues.join(' ');

      if (matchedServices[portLink]) {
        matchedServices[portLink].forEach(service => {
          if (!services[service.href]) {
            services[service.href] = {
              name: service.name,
              href: service.href,
              value: `${service.name},${value}`,
              values: {[service.name]: true, [value]: true},
            };
          } else if (!services[service.href].values[link.serviceKey]) {
            services[service.href].value = `${services[service.href].value},${value}`;
            // Keep track of the added values for quick lookup
            services[service.href].values[value] = true;
          }
        });
      }

      const {processName, windowsService, protocol} = connection;
      const port = `${ServiceUtils.getPort(link) || ''} ${protocol}`;

      ports[port] = {type: 'port', name: port, value: port, href: port, port: connection.port, protocol};

      if (processName) {
        processes[processName] = {type: 'process', name: processName, value: processName, href: processName};
      }

      if (windowsService) {
        windowsServices[windowsService] = {
          type: 'windowsService',
          name: windowsService,
          value: windowsService,
          href: windowsService,
        };
      }
    });
  });

  return {
    services: Object.values(services),
    ports: Object.values(ports),
    processes: Object.values(processes),
    windowsServices: Object.values(windowsServices),
  };
}

function addFilter({filter, value, href, exclude}) {
  const filters = exclude ? excludeFilters : includeFilters;

  if (filter === intl('Common.ReportedPolicy')) {
    return;
  }

  filters[href] ||= {};

  if (!value && !filters[href][filter]) {
    filters[href][filter] = null;
  }

  if (value) {
    filters[href][filter] ||= [];
    filters[href][filter].push(value);
  }
}

function removeFilter({filter, href, exclude}) {
  const filters = exclude ? excludeFilters : includeFilters;

  if (!filters[href]) {
    return;
  }

  delete filters[href][filter];
}

function removeMultiFilter({filter, value, href, exclude}) {
  const filters = exclude ? excludeFilters : includeFilters;

  if (!filters[href] || !filters[href][filter]) {
    // Remove the key
    if (filters[href][filter] === null) {
      delete filters[href][filter];
    }

    return;
  }

  const index = filters[href][filter].indexOf(value);

  // Without a spicific value, remove the last item
  if (!value && filters[href][filter].length) {
    filters[href][filter].splice(-1, 1);
  }

  // Verify the item exists before splicing
  if (index !== -1) {
    filters[href][filter].splice(index, 1);
  }

  if (!filters[href][filter].length) {
    delete filters[href][filter];
  }
}

function saveFilters(exclude) {
  const stripFilters = Object.keys(exclude ? excludeFilters : includeFilters).reduce((filterResult, group) => {
    const groupFilters = exclude ? excludeFilters[group] : includeFilters[group];

    filterResult[group] = Object.keys(groupFilters).reduce((result, key) => {
      if (groupFilters[key]) {
        result[key] = groupFilters[key];
      }

      return result;
    }, {});

    return filterResult;
  }, {});

  localStorage.setItem(exclude ? 'app_group_exclusion_filters' : 'app_group_filters', JSON.stringify(stripFilters));
}

function getLinksFromFilteredLinks(allLinks, filteredLinks) {
  return filteredLinks.map(link => allLinks[link?.index]);
}

function filterLinks(href) {
  const type = boundary[href] && BOUNDARY;

  group = TrafficStore.getAppGroupNode(href) || boundary[href];

  if (href === 'global') {
    group = {name: 'global', href};
  }

  if (!group) {
    tableLinks = [];
    vizLinks = [];

    return;
  }

  //Calculate table links
  tableLinks = getFilteredLinks(ExplorerStore.getAppGroupTableLinks(href), type);

  const tableLinksByLabel = getFilteredLinksByGroup(tableLinks, type);
  const tableLinksByLabelAndEntities = getFilteredLinksByEntities(tableLinksByLabel);
  const tableLinksByService = getFilteredLinksByService(tableLinks);
  const tableLinksByServiceAndEntities = getFilteredLinksByEntities(tableLinksByService);
  const tableLinksByServiceAndLabel = getFilteredLinksByService(tableLinksByLabel);
  const tableLinksByServiceLabelAndAddress = getFilteredLinksByAddress(tableLinksByServiceAndLabel);

  // Get all the available services before filtering by service
  services = getServices(getFilteredLinksByAddress(tableLinksByLabelAndEntities));
  // Get all the available groups before filtering by label
  groups = getGroups(getFilteredLinksByAddress(tableLinksByServiceAndEntities), boundary[href] && BOUNDARY);
  // Get all the available addresses before by address
  addresses = getAddressess(getFilteredLinksByGroup(tableLinksByServiceAndEntities, type));

  entities = getEndpointEntities(tableLinksByServiceLabelAndAddress);

  // Complete the filtering
  tableLinks = getFilteredLinksByEntities(tableLinksByServiceLabelAndAddress);
  vizLinks = getLinksFromFilteredLinks(ExplorerStore.getAppGroupVizLinks(href), tableLinks);
  excludeServices = getServices(tableLinks);
  excludeEntities = getEndpointEntities(tableLinks);

  // Get all the available ip lists
  ipLists = getIPLists(tableLinks);

  if (!SessionStore.isEdge()) {
    //Calculate Unmanaged IP links
    ipTraffic = getFilteredLinks(ExplorerStore.getAppGroupIpLinks(href), 'unmanaged');

    const ipLinksByService = getFilteredLinksByService(ipTraffic);

    unmanagedServices = getServices(getFilteredLinksByAddress(ipTraffic));
    unmanagedAddresses = getAddressess(ipLinksByService);
    ipTraffic = getFilteredLinksByAddress(ipLinksByService);
    unmanagedExcludedServices = getServices(ipTraffic);

    //Calculate Domain links
    domainTraffic = getFilteredLinks(ExplorerStore.getAppGroupDomainLinks(href), 'domain');

    const domainLinksByService = getFilteredLinksByService(domainTraffic);

    domainServices = getServices(getFilteredLinksByAddress(domainTraffic));
    domainAddresses = getAddressess(domainLinksByService);
    domainTraffic = getFilteredLinksByAddress(domainLinksByService);
    domainExcludedServices = getServices(domainTraffic);
  }
}

// To be merged with the ExplorerStore
export default createApiStore(
  [
    'TRAFFIC_FLOWS_TRAFFIC_ANALYSIS_QUERIES_',
    'ENFORCEMENT_BOUNDARIES_GET_INSTANCE_',
    'TRAFFIC_FLOWS_ASYNC_QUERIES_DOWNLOAD_',
  ],
  {
    dispatchHandler(action) {
      switch (action.type) {
        case Constants.TRAFFIC_FLOWS_TRAFFIC_ANALYSIS_QUERIES_SUCCESS:
        case Constants.TRAFFIC_FLOWS_ASYNC_QUERIES_DOWNLOAD_SUCCESS:
          if (action.options.type === 'appGroup') {
            dispatcher.waitFor([ExplorerStore.dispatchToken]);
            matchedServices = ExplorerStore.getMatchedServices();
            filterLinks(action.options.href);
            aggregatedLinks = aggregateLinks(tableLinks);

            this.emitdownloadAction();
          }

          break;

        case Constants.ENFORCEMENT_BOUNDARIES_GET_INSTANCE_SUCCESS:
          const href = `boundaries${action.data.href.split('/').pop()}`;

          boundary[href] = {name: action.data.name, href};
          break;

        case Constants.ADD_TRAFFIC_EXPLORER_APPGROUP_FILTER:
          addFilter(action.data);
          saveFilters(action.data.exclude);

          if (!action.data.hasOwnProperty('value') || action.data.value) {
            filterLinks(action.data.href);
            aggregatedLinks = aggregateLinks(tableLinks);

            this.emitdownloadAction();
          }

          break;

        case Constants.REMOVE_TRAFFIC_EXPLORER_APPGROUP_FILTER:
          removeFilter(action.data);
          saveFilters(action.data.exclude);

          if (!action.data.hasOwnProperty('value') || action.data.value) {
            filterLinks(action.data.href);
            aggregatedLinks = aggregateLinks(tableLinks);
            this.emitdownloadAction();
          }

          break;

        case Constants.REMOVE_MULTI_TRAFFIC_EXPLORER_APPGROUP_FILTER:
          removeMultiFilter(action.data);
          saveFilters(action.data.exclude);

          if (!action.data.hasOwnProperty('value') || action.data.value) {
            filterLinks(action.data.href);
            aggregatedLinks = aggregateLinks(tableLinks);

            this.emitdownloadAction();
          }

          break;

        case Constants.RESET_TRAFFIC_EXPLORER_APPGROUP_FILTER:
          excludeFilters = JSON.parse(defaultFilter);
          saveFilters(true);
          break;

        case Constants.SEC_POLICY_RULE_COVERAGE_CREATE_SUCCESS:
        case Constants.SEC_POLICY_RULES_FOR_SCOPE:
        case Constants.UPDATE_FOR_All:
        case Constants.NETWORK_TRAFFIC_UPDATE_FOR_All:
        case Constants.SEC_POLICY_RULES_FOR_ALL:
        case Constants.SEC_POLICY_RULES_FOR_WORKLOAD:
        case Constants.APP_GROUP_SUMMARY_GET_SUCCESS:
          dispatcher.waitFor([ExplorerStore.dispatchToken]);

          const ruleCoverageType = ExplorerStore.getRuleCoverageType();
          const appGroupTableLinks = ExplorerStore.getAppGroupTableLinks();

          if (
            action !== Constants.SEC_POLICY_RULE_COVERAGE_CREATE_SUCCESS ||
            ruleCoverageType === 'visible' ||
            appGroupTableLinks.every(
              link =>
                link.rules ||
                link.src_name === intl('Common.DeletedWorkload') ||
                link.dst_name === intl('Common.DeletedWorkload'),
            )
          ) {
            // Get updated rule data
            tableLinks = getLinksFromFilteredLinks(appGroupTableLinks, tableLinks);
            vizLinks = getLinksFromFilteredLinks(ExplorerStore.getAppGroupVizLinks(), vizLinks);
            aggregatedLinks = aggregateLinks(tableLinks);
          }

          break;

        case Constants.SERVICES_GET_COLLECTION_SUCCESS:
          matchedServices = {};
          break;

        default:
          return true;
      }

      this.emitChange();

      return true;
    },

    emitdownloadAction() {
      this.emit(DOWNLOAD_EVENT);
    },

    addDownloadActionListener(callback) {
      this.on(DOWNLOAD_EVENT, callback);
    },

    removeDownloadActionListener(callback) {
      this.removeListener(DOWNLOAD_EVENT, callback);
    },

    getTableLinks: href => (!href || href === (group && group.href) ? tableLinks : []),

    getAggregatedTableLinks: href => (!href || href === (group && group.href) ? aggregatedLinks : []),

    getVizLinks: href => (!href || href === (group && group.href) ? vizLinks : []),

    getIpLinks: href => (!href || href === (group && group.href) ? ipTraffic : []),

    getDomainLinks: href => (!href || href === (group && group.href) ? domainTraffic : []),

    getFilters: href => includeFilters[href] || {},

    getExclusionFilters: href => excludeFilters[href] || {},

    getIpLists: href => (href === (group && group.href) ? ipLists : []),

    getServices: href => (href === (group && group.href) ? services : {services: [], ports: [], processes: []}),

    getExcludeServices: href =>
      href === (group && group.href) ? excludeServices : {services: [], ports: [], processes: []},

    getUnmanagedServices: href =>
      href === (group && group.href) ? unmanagedServices : {services: [], ports: [], processes: []},

    getUnmanagedExcludeServices: href =>
      href === (group && group.href) ? unmanagedExcludedServices : {services: [], ports: [], processes: []},

    getDomainServices: href =>
      href === (group && group.href) ? domainServices : {services: [], ports: [], processes: []},

    getDomainExcludeServices: href =>
      href === (group && group.href) ? domainExcludedServices : {services: [], ports: [], processes: []},

    getGroups: href => (href === (group && group.href) ? groups : []),

    getAddresses: href => (href === (group && group.href) ? addresses : []),

    getUnmanagedAddresses: href => (href === (group && group.href) ? unmanagedAddresses : []),

    getDomainAddresses: href => (href === (group && group.href) ? domainAddresses : []),

    getEntities: href => (href === (group && group.href) ? entities : []),

    getExcludeEntities: href => (href === (group && group.href) ? excludeEntities : []),

    getBoundaryName: href => boundary[href]?.name,

    getGroup: href => href === (group && group.href),
  },
);
