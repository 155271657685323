/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from 'intl';

export default React.createClass({
  render() {
    const timestamp = (
      <tr className="MapInfoPanel-Row MapInfoPanel-Row--TopBorder" data-tid="map-info-panel-row">
        <td className="MapInfoPanel-Row-Label MapInfoPanel-Row-Display" data-tid="map-info-panel-row-label">
          {intl('BlockedTraffic.List.LastDetected')}
        </td>
        <td />
        <td
          className="MapInfoPanel-Row-Value MapInfoPanel-Row-Display MapInfoPanel-Row-Left-Small"
          data-tid="map-info-panel-row-value"
        >
          {this.props.timestamp}
        </td>
      </tr>
    );

    return (
      <div>
        <table className="MapInfoPanel">
          <tbody>{timestamp}</tbody>
        </table>
      </div>
    );
  },
});
