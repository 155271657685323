/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

import React from 'react';
import intl from 'intl';
import {Button, Spinner} from '..';
import {RestApiUtils} from '../../utils';
import StoreMixin from '../../mixins/StoreMixin';
import TrafficDatabaseMetricsStore from '../../stores/TrafficDatabaseMetricsStore';
import Constants from '../../constants';

function getStateFromStores() {
  return {
    metrics: TrafficDatabaseMetricsStore.getMetrics(),
    status: TrafficDatabaseMetricsStore.getStatus(),
  };
}

const TrafficDBStorageMonitor = React.createClass({
  mixins: [StoreMixin([TrafficDatabaseMetricsStore], getStateFromStores)],

  getInitialState() {
    return {
      detailsOpen: false,
    };
  },

  componentWillMount() {
    this.fetchTrafficDatabaseStats();
  },

  async fetchTrafficDatabaseStats() {
    return RestApiUtils.trafficDBMonitor.get();
  },

  handleButtonMouseEnter() {
    this.setState({detailsOpen: true});
  },

  handleButtonMouseLeave() {
    this.setState({detailsOpen: false});
  },

  render() {
    const {detailsOpen, status, metrics} = this.state;
    const sizeGB = isNaN(metrics.sizeGB) ? 0 : metrics.sizeGB;
    const sizeGBLimit = isNaN(metrics.sizeGBLimit) ? 0 : metrics.sizeGBLimit;
    const days = metrics.days;
    const displayDaysAvailable = !(sizeGBLimit === 0 || isNaN(days) || sizeGB === 0);
    const usedPercent = sizeGBLimit === 0 ? 0 : sizeGB / sizeGBLimit;
    const showWarning = usedPercent >= 0.85;

    return (
      <div
        className={[
          'Explorer-TrafficDBStorageMonitor',
          detailsOpen ? 'Explorer-TrafficDBStorageMonitor-Open' : '',
        ].join(' ')}
      >
        <Button
          icon="database"
          type="nofill"
          content="icon-only"
          customClass="Explorer-TrafficDBStorageMonitor-Button"
          onClick={this.handleClearFilters}
          onMouseEnter={this.handleButtonMouseEnter}
          onMouseLeave={this.handleButtonMouseLeave}
        />

        {showWarning ? <div className="Explorer-TrafficDBStorageMonitor-Status">!</div> : null}

        <div
          className={[
            'Explorer-TrafficDBStorageMonitor-Details',
            detailsOpen ? 'Explorer-TrafficDBStorageMonitor-Details-Open' : '',
          ].join(' ')}
        >
          <div className="Explorer-TrafficDBStorageMonitor-Details-Pointer" />

          <div className="Explorer-TrafficDBStorageMonitor-Details-ContentWrapper">
            {status === Constants.STATUS_BUSY ? (
              <Spinner />
            ) : (
              <div>
                <h3>{intl('Explorer.TrafficDatabaseStorage')}</h3>
                <div className="Explorer-TrafficDBStorageMonitor-Details-Chart">
                  <div
                    className={[
                      'Explorer-TrafficDBStorageMonitor-Details-Chart-Fill',
                      showWarning ? 'Explorer-TrafficDBStorageMonitor-Details-Chart-Fill-Warning' : '',
                    ].join(' ')}
                    style={{width: `${usedPercent * 100}%`}}
                  />
                </div>
                {displayDaysAvailable ? <p>{intl('Explorer.TrafficDatabaseStorageDaysAvailable', {days})}</p> : null}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
});

export default TrafficDBStorageMonitor;
