/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';

let dropdownValues = {};
let isLoaded = false;

function setDropdownValues(prefix, key, query, data) {
  key ||= 'all';
  dropdownValues[prefix + key + '-' + query] = data;
}

// Trade-off between having messy logic here or having a lot of logic in the ObjectSelector.jsx
// (ideally the data structure should be multiple levels deep, but OS only supports flat Objects for dropdownValues)
// type can either be 'labels', 'labelGroups' or key like 'app', 'loc' or combination like 'labels-loc'
function resetDropdownValues(type) {
  if (type) {
    // if a type string is specified, use that to delete the specific keys
    Object.keys(dropdownValues).forEach(key => {
      if (key.includes(type)) {
        delete dropdownValues[key];
      }
    });
  } else {
    // otherwise, delete everything
    dropdownValues = {};
  }
}

export default createApiStore(['LABELS_AUTOCOMPLETE_', 'LABEL_GROUPS_AUTOCOMPLETE'], {
  dispatchHandler(action) {
    const query = action.options && action.options.query;

    switch (action.type) {
      case Constants.LABELS_AUTOCOMPLETE_SUCCESS:
        // If 'label_group' is present in the query, do not store it in the store
        // since it only returns a subset of labels and label groups which are relevant
        // to the sent label_group id
        if (!query.label_group) {
          setDropdownValues('labels-', query.key, query.query, action.data);
        }

        isLoaded = true;
        break;

      case Constants.LABEL_GROUPS_AUTOCOMPLETE_SUCCESS:
        if (!query.label_group) {
          setDropdownValues('labelGroups-', query.key, query.query, action.data);
        }

        isLoaded = true;
        break;

      case Constants.RESET_FACET_VALUES:
        if (action.data === 'labelList') {
          resetDropdownValues(action.query);
        }

        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getDropdownValues() {
    return dropdownValues;
  },

  isLoaded() {
    return isLoaded;
  },
});
