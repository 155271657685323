/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {createSelector} from 'reselect';
import stringify from 'safe-stable-stringify';
import {portUtils, generalUtils} from 'utils';

export const ProtocolMap = createSelector([], () => ({
  '-1': intl('Protocol.Any'),
  '1': intl('Protocol.ICMP'),
  '2': intl('Protocol.IGMP'),
  '4': intl('Protocol.IPv4'),
  '6': intl('Protocol.TCP'),
  '17': intl('Protocol.UDP'),
  '27': intl('Protocol.RDP'),
  '41': intl('Protocol.IPv6'),
  '47': intl('Protocol.GRE'),
  '50': intl('Protocol.ESP'),
  '58': intl('Protocol.ICMPv6'),
  '62': intl('Protocol.CFTP'),
  '64': intl('Protocol.SATEXPAK'),
  '65': intl('Protocol.KRYPTOLAN'),
  '66': intl('Protocol.RVD'),
  '67': intl('Protocol.IPPC'),
  '94': intl('Protocol.IPIP'),
  '121': intl('Protocol.SMP'),
}));

// All Protocols which are used in Rules
export const LimitedReverseProtocolMap = {
  [intl('Protocol.TCP')]: 6,
  [intl('Protocol.UDP')]: 17,
  [intl('Protocol.ICMP')]: 1,
  [intl('Protocol.IGMP')]: 2,
  [intl('Protocol.GRE')]: 47,
  [intl('Protocol.ICMPv6')]: 58,
  [intl('Protocol.IPIP')]: 94,
};

export const icmpCodeMap = {
  0: 'Echo Reply',
  3: 'Destination Unreachable',
  5: 'Redirect',
  8: 'Echo Request',
  9: 'Router Advertisement',
  10: 'Router Selection',
  11: 'Time Exceeded',
  12: 'Parameter Problem',
  13: 'Timestamp',
  14: 'Timestamp Reply',
  40: 'Photuris',
  42: 'Extended Echo Request',
  43: 'Extended Echo Reply',
};

export const icmpv6CodeMap = {
  1: 'Destination Unreachable',
  2: 'Packet Too Big',
  3: 'Time Exceeded',
  4: 'Parameter Problem',
  128: 'Echo Request',
  129: 'Echo Reply',
  130: 'Multicast Listener Query',
  131: 'Multicast Listener Report',
  132: 'Multicast Listener Done',
  133: 'Router Solicitation',
  134: 'Router Advertisement',
  135: 'Neighbor Solicitation',
  136: 'Neighbor Advertisement',
  137: 'Redirect Message',
  138: 'Router Renumbering',
  139: 'ICMP Node Information Query',
  140: 'ICMP Node Information Response',
  141: 'Inverse Neighbor Discovery',
  142: 'Inverse Neighbor Discovery',
  144: 'Home Agent Address Discovery',
  145: 'Home Agent Address Discovery',
  146: 'Mobile Prefix Solicitation',
  147: 'Mobile Prefix Advertisement',
  157: 'Duplicate Address Request Code Suffix',
  158: 'Duplicate Address Confirmation Code Suffix',
  160: 'Extended Echo Request',
  161: 'Extended Echo Reply',
};

export const lookupICMPCode = (code, type) => (type === 'ICMPv6' ? icmpv6CodeMap[code] : icmpCodeMap[code]);

export function isPortValidForProtocol(protocol) {
  return (
    protocol === -1 ||
    protocol === 6 ||
    protocol === 17 ||
    protocol === 'TCP' ||
    protocol === 'UDP' ||
    protocol === 'any'
  );
}

export const RegexPortProtocolMap = createSelector([], () => ({
  protocolNumber: /(^\b(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$)/,
  portProtocol: /(^\b(0|[1-9]\d*)\b\s+(t|tc|tcp|u|ud|udp)$)/i,
  port: /(^\b(0|[1-9]\d*)\b)$/,
  protocol: /^(U|UD|UDP)$|^(T|TC|TCP)$|^(I|IC|ICM|ICMP)$/,
  protocolWithoutICMP: /^(U|UD|UDP)$|^(T|TC|TCP)$/,
  portRange: /(^\b(0|[1-9]\d*)\b)\s*-\s*(\d+)\s*$/,
  portRangeProtocol: /(^\b(0|[1-9]\d*)\b\s*-\s*\d+\s+(t|tc|tcp|u|ud|udp)$)/i,
  protocolOnly: /(^(I|IC|ICM|ICMP|ICMPV|ICMPV6)$|^(G|GR|GRE)$|^(I|IP|IPI|IPIP)$|^(IG|IGM|IGMP)$|^(IPV|IPV4|IPV6)$)/,
  protocolOnlyIncludingTCPUDP:
    /(^(I|IC|ICM|ICMP|ICMPV|ICMPV6)$|^(G|GR|GRE)$|^(I|IP|IPI|IPIP)$|^(IG|IGM|IGMP)$|^(IPV|IPV4|IPV6)$)|^(U|UD|UDP)$|^(T|TC|TCP)$|^(I|IC|ICM|ICMP)$/,
  // (?![\s"']): negative lookahead to validate that process path does not start with whitespace/quotes
  // (.+\\): matches a group of one or more character except linebreak followed by a backslash
  // (.+\.exe): process path must end with .exe
  processRegex: /^(?![\s"'])(.+\\)+(.+\.exe)$/i,
}));

const multipleSlashRegex = /(?!^)\\{2,}/g; //skip the first double slashes (UNC format)

export const isValidProcessName = value =>
  !multipleSlashRegex.test(value) && RegexPortProtocolMap().processRegex.exec(value);

// Internet Control Message Protocol version 6 is icmpv6. Retired icmp6.
export const ReverseProtocolMap = Object.keys(ProtocolMap()).reduce(
  (result, key) => ({
    ...result,
    [ProtocolMap()[key].toUpperCase()]: Number(key),
  }),
  {},
);

export const reverseLookupProtocol = protocol => {
  if (!protocol || protocol === intl('Protocol.Any')) {
    return -1;
  }

  if (_.isNumber(protocol)) {
    return protocol;
  }

  return ReverseProtocolMap[protocol.toUpperCase()] || Number(protocol);
};

/**
 * return protocol name by id, if not found return number instead
 * @param protocol
 */
export const lookupProtocol = inputProtocol => {
  const protocol = reverseLookupProtocol(inputProtocol);

  if (_.isNaN(protocol)) {
    return inputProtocol.toString().toUpperCase();
  }

  return ProtocolMap()[protocol];
};

export const getServicePortsString = value =>
  (Array.isArray(value) && value.map(port => portUtils.stringifyPortObjectReadonly(port)).join(', ')) ||
  intl('Common.ALL');

export const getServicePortsSortString = value => {
  if (value && value.length) {
    const services = value.map(port => portUtils.stringifyPortObjectSort(port)).join(', ');

    if (services) {
      return services;
    }
  }

  return '';
};

export const lookupRegexPortProtocol = type => RegexPortProtocolMap()[type];

export const getPortAndProtocolString = (portObj = {}) => {
  let portProto = '';

  if (generalUtils.isNumberOrString(portObj.icmp_type)) {
    portProto += portObj.icmp_type;

    if (generalUtils.isNumberOrString(portObj.icmp_code)) {
      portProto = `${portProto}/${portObj.icmp_code}`;
    }
  }

  if (!_.isNil(portObj.port) && portObj.port !== -1) {
    portProto += portObj.port;

    const protocol = portObj.proto || portObj.protocolNum;

    portProto = isPortValidForProtocol(protocol) ? portProto : '';
  }

  if (!_.isNil(portObj.to_port)) {
    portProto += '-' + portObj.to_port;
  }

  const protocol = portObj.protocol || portObj.proto;

  if (protocol) {
    // If port exists then add an empty space before adding protocol value to portProto string
    portProto = portProto ? `${portProto} ` : portProto;

    if (protocol === -1) {
      portProto = intl('Common.ALL');
    } else {
      portProto += lookupProtocol(protocol);
    }
  }

  return portProto;
};

export const getOs = (pversionObj = {}, isConsumer = false) => {
  return __ANTMAN__ && (pversionObj.windows_egress_services || isConsumer)
    ? 'windows_egress'
    : pversionObj.windows_services
    ? 'windows'
    : 'linux';
};

/**
 * Get rows for Grid in service view page
 */
export const getServiceDefinitionRows = (serviceDef = [], isWindowsOutbound = false) => {
  const showPortProto = !__ANTMAN__ || (__ANTMAN__ && !isWindowsOutbound);

  return serviceDef.map(item => ({
    key: stringify(_.omitBy(item, def => def === null)),
    data: {
      ...item,
      ...(showPortProto && {
        portProto: getPortAndProtocolString(item),
      }),
    },
  }));
};

/**
 * Omit null values, and/or process_name, service_name
 */
export const sanitizePortProto = portProto =>
  Object.entries(portProto).reduce((result, [def, value]) => {
    if (value === null || def === 'process_name' || def === 'service_name') {
      return result;
    }

    result[def] = value;

    return result;
  }, {});

/**
 * validate acceptable strings that represent ports with protocols
 * @param key
 * @param query
 */
const validatePortSearch = (key, query) => {
  if (portUtils.isValidPort(query)) {
    const queryNum = Number(query);
    let result = [
      {value: `${queryNum} ${intl('Protocol.TCP')}`, detail: {proto: 6, port: queryNum}},
      {value: `${queryNum} ${intl('Protocol.UDP')}`, detail: {proto: 17, port: queryNum}},
    ];

    if (portUtils.isValidIcmpTypeCode(queryNum)) {
      // Add ICMP protocol options only when entered number is a valid ICMP type i.e. in range 0-255
      result = [
        ...result,
        {value: `${queryNum} ${intl('Protocol.ICMP')}`, detail: {proto: 1, icmp_type: queryNum}},
        {value: `${queryNum} ${intl('Protocol.ICMPv6')}`, detail: {proto: 58, icmp_type: queryNum}},
      ];
    }

    return result;
  }

  // Query string contains port with protocol chars, in this case add the matching protocol option to drop down list
  const values = query.split(/\s+/).map(item => item.trim());

  if (portUtils.portProtocolRegex.tcpPortProtocol.test(query) && portUtils.isValidPort(values[0])) {
    return [{value: `${values[0]} ${intl('Protocol.TCP')}`, detail: {proto: 6, port: Number(values[0])}}];
  }

  if (portUtils.portProtocolRegex.udpPortProtocol.test(query) && portUtils.isValidPort(values[0])) {
    return [{value: `${values[0]} ${intl('Protocol.UDP')}`, detail: {proto: 17, port: Number(values[0])}}];
  }

  // Query string contains ICMP type with protocol chars, in this case add the matching protocol option to drop down list
  if (portUtils.portProtocolRegex.icmpv6PortProtocol.test(query) && portUtils.isValidIcmpTypeCode(values[0])) {
    return [{value: `${values[0]} ${intl('Protocol.ICMPv6')}`, detail: {proto: 58, icmp_type: Number(values[0])}}];
  }

  if (portUtils.portProtocolRegex.icmpPortProtocol.test(query) && portUtils.isValidIcmpTypeCode(values[0])) {
    return [
      {value: `${values[0]} ${intl('Protocol.ICMP')}`, detail: {proto: 1, icmp_type: Number(values[0])}},
      {value: `${values[0]} ${intl('Protocol.ICMPv6')}`, detail: {proto: 58, icmp_type: Number(values[0])}},
    ];
  }

  // If Query contains both ICMP type and code, validate both type and code and add options to dropdown list
  const [type, code] = values[0].split('/');

  if (code) {
    if (portUtils.isValidIcmpTypeCode(code) && portUtils.isValidIcmpTypeCode(type)) {
      if (portUtils.portProtocolRegex.icmpv6PortProtocol.test(query)) {
        return [
          {
            value: `${values[0]} ${intl('Protocol.ICMPv6')}`,
            detail: {proto: 58, icmp_type: Number(type), icmp_code: Number(code)},
          },
        ];
      }

      return [
        {
          value: `${values[0]} ${intl('Protocol.ICMP')}`,
          detail: {proto: 1, icmp_type: Number(type), icmp_code: Number(code)},
        },
        {
          value: `${values[0]} ${intl('Protocol.ICMPv6')}`,
          detail: {proto: 58, icmp_type: Number(type), icmp_code: Number(code)},
        },
      ];
    }
  }

  return [];
};

/**
 * validate acceptable strings that represent port ranges with UDP/TCP protocol
 * @param key
 * @param query
 */
const validatePortRangeSearch = (key, query) => {
  const result = [];

  const ranges = query.split('-').map(item => item.trim());

  if (ranges.length === 2 && portUtils.isValidPort(ranges[0])) {
    const valueStart = Number(ranges[0]);

    if (portUtils.isValidPort(ranges[1])) {
      // Query string contains only port and no protocol, in this case add both UDP and TCP protocol options in dropdown
      const valueEnd = Number(ranges[1]);

      if (valueStart < valueEnd) {
        const range = `${valueStart} - ${valueEnd}`;

        result.push(
          {
            value: `${range} ${portUtils.portProtocolMap().tcp.text}`,
            detail: {proto: portUtils.portProtocolMap().tcp.value, port: valueStart, to_port: valueEnd},
          },
          {
            value: `${range} ${portUtils.portProtocolMap().udp.text}`,
            detail: {proto: portUtils.portProtocolMap().udp.value, port: valueStart, to_port: valueEnd},
          },
        );
      }

      return result;
    }

    const values = ranges[1].split(/\s+/).map(item => item.trim());

    if (portUtils.isValidPort(values[0])) {
      // Query string contains port with protocol chars, in this case add the matching protocol option to drop down list
      const valueEnd = Number(values[0]);
      const range = `${valueStart} - ${valueEnd}`;

      if (valueStart < valueEnd) {
        if (portUtils.portProtocolRegex.tcpPortProtocol.test(ranges[1])) {
          result.push({
            value: `${range} ${portUtils.portProtocolMap().tcp.text}`,
            detail: {proto: portUtils.portProtocolMap().tcp.value, port: valueStart, to_port: valueEnd},
          });
        } else if (portUtils.portProtocolRegex.udpPortProtocol.test(ranges[1])) {
          result.push({
            value: `${range} ${portUtils.portProtocolMap().udp.text}`,
            detail: {proto: portUtils.portProtocolMap().udp.value, port: valueStart, to_port: valueEnd},
          });
        }
      }
    }
  }

  return result;
};

const protocolRegexMap = createSelector([], () => ({
  gre: {proto: 47, text: intl('Protocol.GRE'), regex: /^(g|gr|gre)$/i},
  icmp: {proto: 1, text: intl('Protocol.ICMP'), regex: /^(i|ic|icm|icmp)$/i},
  icmpv6: {proto: 58, text: intl('Protocol.ICMPv6'), regex: /^(i|ic|icm|icmp|icmpv|icmpv6)$/i},
  igmp: {proto: 2, text: intl('Protocol.IGMP'), regex: /^(i|ig|igm|igmp)$/i},
  ipip: {proto: 94, text: intl('Protocol.IPIP'), regex: /^(i|ip|ipi|ipip)$/i},
  ipv4: {proto: 4, text: intl('Protocol.IPv4'), regex: /^(i|ip|ipv|ipv4)$/i},
  ipv6: {proto: 41, text: intl('Protocol.IPv6'), regex: /^(i|ip|ipv|ipv6)$/i},
}));

/**
 * validate acceptable strings that represent protocol without port.
 * @param key
 * @param query
 */
export const validateProtocolOnly = (key, query) => {
  const result = [];

  Object.values(protocolRegexMap()).forEach(({proto, text, regex}) => {
    if (regex.test(query)) {
      result.push({
        value: text,
        detail: {proto},
      });
    }
  });

  return result;
};

/**
 * Function accepts selector query (letters and numbers) and convert them to Port, Port and Protocol, and Protocol-Only strings.
 * Returns Objects of format expected by API.
 *
 * Examples of acceptable input/display strings:
 * 135 TCP
 * 540 TCP
 * GRE
 * 80 TCP
 * 42 UDP
 * ICMP
 * ICMPv6
 * IGMP
 * 133 ICMPv6
 * IPv4
 * 80-220
 *
 * Corresponding object representations:
 *  [
 *    { "port": 135, "proto": 6 },
 *    { "port": 540, "proto": 6 },
 *    { "proto": 47 },
 *    { "port": 80, "proto": 6 },
 *    { "port": 42, "proto": 17 },
 *    { "proto": 1 },
 *    { "proto": 58 },
 *    { "proto": 2 },
 *    { "icmp_type": 133, "proto": 58 },  // special handling
 *    { "proto": 4 },
 *  ]
 *
 * Port range representation -- supported?
 *    {
 *      "port": 80,
 *      "toPort": 220
 *    }
 */
export const validatePortAndOrProtocol = (key, query) => {
  if (lookupRegexPortProtocol('protocolOnly').test(_.toUpper(query))) {
    return validateProtocolOnly(key, query);
  }

  if (lookupRegexPortProtocol('portRange').test(query) || lookupRegexPortProtocol('portRangeProtocol').test(query)) {
    return validatePortRangeSearch(key, query);
  }

  return validatePortSearch(key, query);
};

/**
 * 0 ICMP subsumes 0/7 ICMP - "all 0 codes"; so should show overlap. (same for ICMPv6)
 * ICMP subsumes all the ICMPv4 rules; so all ICMPv4 rules should show overlap. (same for ICMPv6)
 * ICMP type/code like 110/112, 110/113 can exist (same type, but different codes)
 * however exact same ICMP type/code cannot.
 * also exact same ICMP type/code can exist for different IP protocols (IPv4 and IPv6).
 * also checks for duplicate only "icmp" of "icmpv6" values
 */
export const areICMPPortRangesOverlapping = (row, rows) => {
  const portProtoObj = row.data.portProto?.[0]?.detail ?? {};
  const {proto, icmp_type: type = null, icmp_code: code = null} = portProtoObj;

  // codesByType is an object containing array of codes by type (same proto as current row):
  // i.e. {type1: [array of codes in type 1], type2: [array of codes in type 2]...}
  // e.g. {110: [112, 113], 0: [null, 7], null: [null]} where null is empty code/type
  const codesByType = rows.reduce((codesByType, item) => {
    const portProtoObj = item.data.portProto?.[0]?.detail ?? {};
    const {icmp_type: type = null, icmp_code: code = null} = portProtoObj;

    if (item.key !== row.key && portProtoObj.proto === proto) {
      //consider other rows having same proto either ICMP/ICMPv6
      codesByType[type] ??= [];
      codesByType[type].push(code);
    }

    return codesByType;
  }, {});

  if (_.isEmpty(codesByType)) {
    // No other row has an entry with same ICMP protocol, so no overlap
    return false;
  }

  if (type === null || codesByType.hasOwnProperty(null)) {
    // A null type indicates presence of ICMP/ICMPv6 protocol without type/code which subsumes all other combinations
    // Show an overlap if either current entry has type as null Or any other row entry has type as null
    return true;
  }

  // Type and Protocol is same, look for overlaps in code
  const codeArr = codesByType[type] ?? [];

  // show overlap if either:
  // 1. same code exists in other ICMP entry or
  // 2. there is an entry with null code ('0 ICMP' subsumes '0/7 ICMP', '0 ICMP' entry will have null code)
  // 3. current row entry has null code and there are other entries with nonzero code for the same type
  return codeArr.includes(code) || codeArr.includes(null) || (code === null && codesByType.hasOwnProperty(type));
};

export const arePortRangesOverlapping = (oldRange, newRange) => {
  const oldPortProtoObj = oldRange.data.portProto?.[0]?.detail ?? {};
  const newPortProtoObj = newRange.data.portProto?.[0]?.detail ?? {};

  if (oldPortProtoObj.proto !== newPortProtoObj.proto) {
    return false;
  }

  if (oldPortProtoObj.port === -1 || newPortProtoObj.port === -1) {
    //-1 means all, so if the protocol, process, and service match its overlapping
    return true;
  }

  if (oldPortProtoObj.port === undefined && newPortProtoObj.port === undefined) {
    //if no port defined, then check for overlapping process/service name
    const {service_name: oldService, process_name: oldProcess} = oldRange.data;
    const {service_name: newService, process_name: newProcess} = newRange.data;

    if (oldService !== newService || oldProcess !== newProcess) {
      return false;
    }
  }

  if (_.isNil(oldPortProtoObj.to_port) && _.isNil(newPortProtoObj.to_port)) {
    return oldPortProtoObj.port === newPortProtoObj.port;
  }

  if (_.isNil(oldPortProtoObj.to_port)) {
    return oldPortProtoObj.port >= newPortProtoObj.port && oldPortProtoObj.port <= newPortProtoObj.to_port;
  }

  if (_.isNil(newPortProtoObj.to_port)) {
    return newPortProtoObj.port >= oldPortProtoObj.port && newPortProtoObj.port <= oldPortProtoObj.to_port;
  }

  return oldPortProtoObj.port <= newPortProtoObj.to_port && newPortProtoObj.port <= oldPortProtoObj.to_port;
};

export const isIcmp = protocol =>
  protocol === 1 || protocol === 58 || protocol === intl('Protocol.ICMP') || protocol === intl('Protocol.ICMPv6');

export function getPort(connection) {
  return isPortValidForProtocol(connection.protocol || connection.proto) ? connection.port : '';
}

/**
 * validates service definitions for overlapping ports/protocols
 */
export const validateServiceDefinitions = (row = {}, rows = []) => {
  if (rows.length < 2) {
    // Overlap validation not needed in case of Single/Empty definitions
    return null;
  }

  const {portProto} = row.data;
  const portProtoObj = portProto?.[0]?.detail ?? {};

  if (isIcmp(portProtoObj.proto)) {
    if (areICMPPortRangesOverlapping(row, rows)) {
      return intl('Services.OverlappingTypeCode');
    }

    return null;
  }

  if (rows.filter(service => service.key !== row.key).some(service => arePortRangesOverlapping(row, service))) {
    return intl('Services.OverlappingServiceDefinitions');
  }

  return null;
};

const osOptions = [
  {value: 'linux', label: intl('Services.Mixin.Os.All.Title'), subLabel: intl('Services.Mixin.Os.All.Subtitle')},
  {
    value: 'windows',
    label: intl('Services.Mixin.Os.Windows.Title'),
    subLabel: intl('Services.Mixin.Os.Windows.Subtitle'),
  },
];

const osConsumerOptions = [
  {
    value: 'windows_egress',
    label: intl('Services.Mixin.Os.WindowsOutbound.Title'),
    subLabel: intl('Services.Mixin.Os.WindowsOutbound.Subtitle'),
  },
];

const allOsOptions = [...osOptions, ...osConsumerOptions];

export const getOsOptions = (controlled, isConsumer) => {
  if (__ANTMAN__) {
    if (!controlled) {
      return allOsOptions;
    }

    if (isConsumer) {
      return osConsumerOptions;
    }
  }

  return osOptions;
};
