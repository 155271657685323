/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import React, {PropTypes} from 'react';
import ObjectSelector from './ObjectSelector.jsx';
import {IpUtils, RestApiUtils, WorkloadUtils} from '../utils';

const facetCollection = {
  workload: RestApiUtils.workloads,
  containerworkload: RestApiUtils.containerWorkloads,
};

export default React.createClass({
  propTypes: {
    onChange: PropTypes.func.isRequired,
    allowPartial: PropTypes.bool,
  },

  getInitialState() {
    return {
      items: {},
      dropdownValues: {},
      facetMap: {
        [intl('Common.Workload')]: 'workload',
        [intl('Common.ContainerWorkload')]: 'containerworkload',
        [intl('Common.IPAddress')]: 'ipaddress',
      },
    };
  },

  getFacetValues(facet, query, maxResults) {
    if (!facet) {
      return;
    }

    if (facet === 'ipaddress') {
      if (IpUtils.validateIPAddress(query || '')) {
        const dropdownValues = _.cloneDeep(this.state.dropdownValues);

        dropdownValues[`${facet}-${query}`] = {
          matches: query
            ? [
                {
                  key: facet,
                  value: query,
                },
              ]
            : [],
          num_matches: query ? 1 : 0,
        };
        this.setState({dropdownValues});
      }

      return;
    }

    if (this.state.dropdownValues[`${facet}-${query}`]) {
      return;
    }

    query ||= '';
    maxResults ||= 25;
    facetCollection[facet]
      .autocomplete(undefined, {
        query,
        max_results: maxResults,
      })
      .then(response => {
        const dropdownValues = _.cloneDeep(this.state.dropdownValues);

        dropdownValues[`${facet}-${query}`] = response.body;
        this.setState({dropdownValues});
      });
  },

  addItem(item, value) {
    const items = _.cloneDeep(this.state.items);

    items[item] = value;
    this.props.onChange(value);
    this.setState({items});
  },

  removeItem(item) {
    const items = _.cloneDeep(this.state.items);

    this.props.onChange(undefined, items[item]);
    delete items[item];
    this.setState({items});
  },

  returnValue(value, facet) {
    if (facet === intl('Common.IPAddress')) {
      return (value && value.value) || '';
    }

    return WorkloadUtils.friendlyName(value);
  },

  render() {
    const {addItem, getFacetValues, removeItem, returnValue} = this;
    const {items} = this.state;
    let {placeholder} = this.props;

    if (items[intl('Common.Workload')] || items[intl('Common.ContainerWorkload')] || items[intl('Common.IPAddress')]) {
      placeholder = '';
    }

    const initialValues = Object.keys(this.state.facetMap);
    const props = {
      dropdownValues: this.state.dropdownValues,
      items,
      facetMap: this.state.facetMap,
      onChange: _.noop,
      showFacetItems: initialValues,
      initialValues,
      addItem,
      getFacetValues,
      placeholder,
      removeItem,
      returnValue,
      loadingHints: {
        [intl('Common.IPAddress')]: intl('ObjectSelector.TypeToShowObject', {object: intl('Common.IPAddress')}),
      },
    };

    props.allowOne = true;

    return <ObjectSelector {...props} />;
  },
});
