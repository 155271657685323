/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import intl from 'intl';
import React from 'react';
import cx from 'classnames';
import {State} from 'react-router';
import {findDOMNode} from 'react-dom';
import update from 'react-addons-update';
import LinkComponent from './Link.jsx';
import InternetComponent from './Internet.jsx';
import NodeVisualization from '../../utils/Node';
import RenderUtils from '../../utils/RenderUtils';
import actionCreators from '../../actions/actionCreators';
import GraphTransformStore from '../../stores/GraphTransformStore';
import {MapPageStore} from '../../stores';

let dragged = false;
const grayFill = '#7f8c8d';

function getStateFromStores() {
  return {
    mapLevel: MapPageStore.getMapLevel(),
  };
}

export default React.createClass({
  mixins: [State],

  getInitialState() {
    return getStateFromStores();
  },
  componentDidMount() {
    this.d3Wrapper = d3.select(findDOMNode(this));

    if (GraphTransformStore.getInteractionType() === 'select' && this.props.whileDragNode && this.props.afterDragNode) {
      this.d3Wrapper.call(NodeVisualization.dragNode, this.whileDrag, this.afterDrag);
    }

    this.d3Wrapper
      .datum(this.props.data)
      .call(_.bind(NodeVisualization.enter, NodeVisualization))
      .call(_.bind(NodeVisualization.update, NodeVisualization));
  },

  componentDidUpdate() {
    this.d3Wrapper.datum(this.props.data).call(_.bind(NodeVisualization.update, NodeVisualization));

    if (GraphTransformStore.getInteractionType() === 'select' && this.props.whileDragNode && this.props.afterDragNode) {
      this.d3Wrapper.call(NodeVisualization.dragNode, this.whileDrag, this.afterDrag);
    } else {
      this.d3Wrapper.on('mousedown.drag', null);
    }
  },

  afterDrag() {
    if (!dragged || GraphTransformStore.getInteractionType() === 'move') {
      dragged ||= false;

      return;
    }

    // only call this function when something really dragged.
    if (dragged) {
      const data = {
        type: this.props.data.type,
        subType: this.props.data.subType,
        href: this.props.data.href,
        labels: this.props.data.labels,
        x: Number(this.props.data.x.toFixed(2)), // Round to 2 decimal points so that
        y: Number(this.props.data.y.toFixed(2)), // we store a smaller number
      };

      this.props.afterDragNode(data);
    }
  },

  handleHoverNode(evt) {
    const {type, subType, unmanaged} = this.props.data;

    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (this.props.hoverNode) {
      this.props.hoverNode(this.props.data);
    }

    let toolTipType;

    if (type === 'workload') {
      if (subType === 'container') {
        toolTipType = 'ContainerWorkload';
      } else if (unmanaged) {
        toolTipType = 'UnmanagedWorkload';
      } else {
        toolTipType = 'OSWorkload';
      }
    } else if (type === 'virtualService') {
      if (subType === 'virtual_server') {
        toolTipType = 'virtualServer';
      } else {
        toolTipType = 'virtualService';
      }
    } else {
      toolTipType = this.props.data.type;
    }

    const vulnerability = this.props.data.vulnerability;

    if (!this.tooltip && !this.props.data.isLegend) {
      actionCreators.showMapTooltip({
        type: toolTipType,
        tooltipInfo: {...this.props.data.tooltipInfo, vulnerability},
        location: {x: evt.pageX, y: evt.pageY},
      });

      this.tooltip = true;
    }
  },

  handleUnhoverNode() {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (this.props.unhoverNode && !this.props.data.selected) {
      this.props.unhoverNode(this.props.data);
    }

    this.tooltip = false;
    actionCreators.hideMapTooltip();
  },

  handleSelectNode(evt) {
    // Suppress click if it's the end of a drag event
    if ((evt && evt.defaultPrevented) || GraphTransformStore.getInteractionType() !== 'select') {
      return;
    }

    if (!this.props.data.identifier) {
      return;
    }

    // for unconnected node, the clusterHref is null
    const nodes = [
      {
        type: this.props.data.type,
        href: this.props.data.href,
        clusterHref: this.props.data.cluster ? this.props.data.cluster.href : null,
      },
    ];

    if (this.props.data.selected) {
      actionCreators.unselectComponent(nodes);
    } else {
      actionCreators.updateComponentSelection(nodes);
    }

    if (this.props.hoverNode) {
      this.props.hoverNode(this.props.data);
    }
  },

  whileDrag(x, y) {
    if (GraphTransformStore.getInteractionType() === 'move') {
      return;
    }

    const node = update(this.props.data, {
      $merge: {x, y, drag: true},
    });

    dragged = true;
    this.props.whileDragNode(node, node);
  },
  handleContextMenu(evt) {
    evt.preventDefault();

    actionCreators.showMapMenu({
      type: 'node',
      data: {...this.props.data},
      location: {x: evt.pageX, y: evt.pageY},
    });

    /* Hide the tooltip on right click */
    this.tooltip = false;
    actionCreators.hideMapTooltip();
  },

  render() {
    const {data} = this.props;
    const links =
      this.props.data.links &&
      this.props.data.links.map(link => (
        <LinkComponent
          key={link.identifier}
          data={{groupTransform: [this.props.data.x, this.props.data.y], ...link}} // add groupTransform for node
          hoverLink={this.props.hoverLink}
          unhoverLink={this.props.unhoverLink}
        />
      ));
    const internets =
      this.props.data.internets &&
      this.props.data.internets.map(internet => (
        <InternetComponent
          key={internet.identifier}
          data={internet}
          hoverInternet={this.props.hoverInternet}
          unhoverInternet={this.props.unhoverInternet}
        />
      ));

    const nodeWorkloadsIsReadable = data.isLegend ? true : data.caps && data.caps.workloads.includes('read');

    const nodeType = cx({
      'il-node-rect': true,
      'il-node-vs': this.props.data.type === 'virtualService',
    });

    const nodeName = cx({
      'il-vulnerability-name': this.props.data.mapPolicyVersion === 'vulnerability',
    });

    const secondaryTextType = cx({
      'il-node-text-secondary': true,
      'il-node-name': this.props.data.mapPolicyVersion === 'vulnerability',
      'il-node-text-white': this.props.textWhite,
    });

    const vulnerabilityExposureScoreClass = cx('il-node-vulnerability', {
      'None': data.vulnerabilitySeverity === 'none',
      'Vulnerability-Text--low': data.vulnerabilitySeverity === 'low',
      'Vulnerability-Text--medium': data.vulnerabilitySeverity === 'medium',
      'Vulnerability-Text--high': data.vulnerabilitySeverity === 'critical',
      'Vulnerability-Text--critical': data.vulnerabilitySeverity === 'high',
    });

    const vulnerabilitySyncingClass = cx('il-node-syncing', {
      'Vulnerability-Text--low': data.vulnerabilitySeverity === 'low',
      'Vulnerability-Text--medium': data.vulnerabilitySeverity === 'medium',
      'Vulnerability-Text--high': data.vulnerabilitySeverity === 'critical',
      'Vulnerability-Text--critical': data.vulnerabilitySeverity === 'high',
    });

    const containerWorkload = data.subType === 'container';
    // policy state border is applicable for enforced/test nodes
    const policyState =
      nodeWorkloadsIsReadable && (data.policyState === 'enforced' || data.policyState === 'selective');
    const unmanaged = this.props.data.type === 'workload' && this.props.data.unmanaged;
    const idle = this.props.data.type === 'workload' && this.props.data.policyState === 'idle';
    const nodeFill = this.props.data.fill || grayFill;

    let name = this.props.data.name;

    const truncateAt = 10 * GraphTransformStore.getTransform().scale;

    if (name && name.length > truncateAt) {
      name = name.slice(0, truncateAt) + '...';
    }

    let secondaryName = this.props.data.secondaryName;

    if (secondaryName && secondaryName.length > truncateAt * 1.2) {
      secondaryName = secondaryName.slice(0, truncateAt * 1.2) + '...';
    }

    let vulnerabilityValues;
    let vulnerabilityExposureScore;
    let vulnerability = <text className="il-role-text-vulnerability" />;

    if (data.vulnerability) {
      const ves = data.vulnerability.aggregatedValues?.vulnerabilityExposureScore;

      vulnerabilityValues = data.vulnerability.aggregatedValues;
      vulnerabilityExposureScore = isNaN(ves) ? ves : `${RenderUtils.roundNumber(ves)} `;
      vulnerability = (
        <text
          className="il-node-text-vulnerability"
          onMouseEnter={this.handleHoverText}
          onMouseLeave={this.handleUnhoverText}
        >
          <tspan className={vulnerabilityExposureScoreClass}>{vulnerabilityExposureScore}</tspan>
        </text>
      );

      if (ves === intl('Workloads.Status.Syncing')) {
        vulnerability = (
          <text
            className={vulnerabilitySyncingClass}
            onMouseEnter={this.handleHoverText}
            onMouseLeave={this.handleUnhoverText}
          />
        );
      }
    }

    return (
      <g
        className="il-node"
        onMouseEnter={this.handleHoverNode}
        onMouseLeave={this.handleUnhoverNode}
        data-tid={`${this.props.data.name}-${this.props.data.secondaryName}`}
      >
        {links}
        {!containerWorkload && policyState && (
          <circle
            className="il-node-policyState"
            data-tid={this.props.data.isLegend ? this.props['data-tid'] : undefined}
          />
        )}
        {!containerWorkload && (
          <rect
            className={nodeType}
            data-tid={this.props.data.isLegend ? this.props['data-tid'] : data.policyState}
            fill={nodeFill}
            onClick={this.handleSelectNode}
            onContextMenu={this.handleContextMenu}
          />
        )}

        {containerWorkload && policyState && <circle className="il-container-workload-policyState" />}
        {containerWorkload && (
          <polygon
            className="il-node-rect il-node-container-workload"
            data-tid={this.props.data.isLegend ? this.props['data-tid'] : data.policyState}
            fill={nodeFill}
            onClick={this.handleSelectNode}
            onContextMenu={this.handleContextMenu}
            onMouseEnter={this.handleHoverNode}
            onMouseLeave={this.handleUnhoverNode}
          />
        )}

        {unmanaged && (
          <rect
            className="il-node-unmanaged"
            data-tid={this.props.data.isLegend ? this.props['data-tid'] : data.policyState}
            stroke={nodeFill}
            onMouseEnter={this.handleHoverNode}
            onMouseLeave={this.handleUnhoverNode}
            onClick={this.handleSelectNode}
            onContextMenu={this.handleContextMenu}
          />
        )}
        {idle && (
          <rect
            className="il-node-idle"
            data-tid={this.props.data.isLegend ? this.props['data-tid'] : data.policyState}
            stroke={nodeFill}
            onClick={this.handleSelectNode}
            onContextMenu={this.handleContextMenu}
            onMouseEnter={this.handleHoverNode}
            onMouseLeave={this.handleUnhoverNode}
          />
        )}

        {vulnerabilityValues && !_.isEmpty(vulnerabilityValues.wideExposure) && (
          <text onClick={this.handleSelectNode} className="il-node-internet" />
        )}

        <text className="il-node-text-label" onClick={this.handleSelectNode}>
          <tspan className={nodeName} data-tid={this.props.data.name}>
            {name}
          </tspan>
          <tspan className={secondaryTextType} data-tid={this.props.data.secondaryName}>
            {secondaryName}
          </tspan>
        </text>
        {vulnerability}
        {internets}
      </g>
    );
  },
});
