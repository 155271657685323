/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import React from 'react';
import intl from 'intl';
import {findDOMNode} from 'react-dom';
import _ from 'lodash';
import {getViewportWidth} from 'utils/dom';
import {SessionStore} from '../../../stores';
import RenderUtils from '../../../utils/RenderUtils';
import VizUtils from '../../../utils/Explorer/VizUtils';
import ExplorerActions from '../../../actions/ExplorerActions';

// font sizes for axis and tick labels
const titleFontSize = 18;
const subtitleFontSize = 14;
const tickLabelHeight = 14;

const normalOpacity = 1;
const notHoveredOpacity = 0;

const LabelDict = {
  env: true,
  loc: true,
  app: true,
};

export default React.createClass({
  componentDidMount() {
    this.d3Wrapper = d3.select(findDOMNode(this));
    this.d3Wrapper.datum(this.props.data).call(this.enter).call(this.update);
    // mouseover on text need dimension and text info
    // so call function instead of just listening to event.
    this.d3Wrapper
      .select('.il-pcDimension-axis')
      .selectAll('text')
      .filter(d => d !== 'source' && d !== 'target' && d !== 'port' && d !== 'consumer' && d !== 'provider')
      .call(this.clickTickText, this.props.onClickTickText, this.props.data);
  },

  componentDidUpdate() {
    this.d3Wrapper.datum(this.props.data).call(this.update);
    this.d3Wrapper
      .select('.il-pcDimension-axis')
      .selectAll('text')
      .filter(d => d !== 'source' && d !== 'target' && d !== 'port' && d !== 'consumer' && d !== 'provider')
      .call(this.clickTickText, this.props.onClickTickText, this.props.data);
  },

  getTickLabelOpacity(vizHighlighted, axis, link, index, fadeInterval) {
    if (this.isCurrentLinkHighlighted(vizHighlighted, axis, link)) {
      // if current link is highlighted, tick labels for corresponding highlighted links need to be shown
      return normalOpacity;
    }

    if (_.isEmpty(vizHighlighted)) {
      // if there is no link being highlighted, we show as many tick labels as we can
      // and hide the rest to prevent labels from crowding/overlapping
      if (index % fadeInterval === 0) {
        return normalOpacity;
      }

      return notHoveredOpacity;
    }

    // if there is something highlighted, but not this current link, don't show label
    return notHoveredOpacity;
  },

  getTickLabelName(label) {
    if (typeof label !== 'string') {
      return label?.name || label;
    }

    return RenderUtils.truncateAppGroupName(label, 30, [10, 10, 10]);
  },

  isCurrentLinkHighlighted(vizHighlighted, axis, link) {
    return (
      vizHighlighted &&
      vizHighlighted.source &&
      ((vizHighlighted.source[link] && axis === 'source') ||
        (vizHighlighted.target[link] && axis === 'target') ||
        (vizHighlighted.port && vizHighlighted.port[link] && axis === 'port') ||
        (vizHighlighted.processName && vizHighlighted.processName[link] && axis === 'processName') ||
        (vizHighlighted.outboundProcessName &&
          vizHighlighted.outboundProcessName[link] &&
          axis === 'outboundProcessName'))
    );
  },

  enter(selection) {
    const {xCoord, yCoord, selected} = this.props;

    selection.attr('transform', d => `translate(${xCoord[d]})`);

    selection.select('.il-pcDimension-axis').each(function (d) {
      d3.select(this).call(d3.svg.axis().orient('left').scale(yCoord[d]));
    });

    selection
      .select('.il-pcDimension-text')
      .style('text-anchor', 'middle')
      .style('font-size', titleFontSize)
      .attr('y', -30)
      .text(d => d);

    selection
      .select('.il-pcDimension-icon-select')
      .attr('width', 15)
      .attr('height', 20)
      .attr('x', -100)
      .attr('y', -25)
      .style('font-size', 5);

    selection
      .select('.il-pcDimension-subtext')
      .style('text-anchor', 'middle')
      .style('font-size', subtitleFontSize)
      .attr('y', -13)
      .text(d => selected[d]);
  },

  calculateSVGWidth() {
    return getViewportWidth();
  },

  update(selection) {
    const {
      isCurrentLinkHighlighted,
      getTickLabelOpacity,
      getTickLabelName,
      calculateSVGWidth,
      props: {vizHighlighted, xCoord, yCoord, height, numTicks, selected, onHoverTickText, onUnHoverTickText, data},
    } = this;

    selection.attr('transform', d => `translate(${xCoord[d]}, 20)`);

    selection.select('.il-pcDimension-axis').each(function (d) {
      const fadeInterval = Math.ceil(numTicks[d] / (height / tickLabelHeight));
      const widthDimension = calculateSVGWidth();
      let fontSize;

      d3.select(this)
        .call(d3.svg.axis().orient('left').scale(yCoord[d]))
        .selectAll('g.tick text')
        .style('opacity', (t, i) => getTickLabelOpacity(vizHighlighted, d, t, i, fadeInterval))
        .text(t => getTickLabelName(t, d === 'source'))
        .attr('font-size', () => {
          fontSize = widthDimension * 0.007;

          if (fontSize < 13) {
            fontSize = 13;
          } else if (fontSize > 16) {
            fontSize = 16;
          } else {
            fontSize = widthDimension * 0.007 + 'px';
          }

          return fontSize;
        });

      // only show as many ticks as we can fit on the axis
      d3.select(this)
        .selectAll('g.tick line')
        .style('opacity', (t, i) => {
          if (i % fadeInterval === 0) {
            return normalOpacity;
          }

          return notHoveredOpacity;
        });

      // overlap detection when link is highlighted - check y coordinate of prev label shown
      let prevLabelY = null;

      d3.select(this)
        .selectAll('g.tick')
        .each(function (t) {
          if (!isCurrentLinkHighlighted(vizHighlighted, d, t)) {
            return;
          }

          const label = d3.select(this).select('text');
          const y = d3.transform(d3.select(this).attr('transform')).translate[1];
          const overlapping = !prevLabelY ? false : prevLabelY - y < tickLabelHeight;

          label.style('opacity', overlapping ? notHoveredOpacity : normalOpacity);

          if (!overlapping || !prevLabelY) {
            prevLabelY = y;
          }
        });

      const tickLabels = d3.select(this).selectAll('g.tick text');

      tickLabels
        .on('mouseover', d => {
          onHoverTickText(d, data, d3.event);

          if (!_.isEmpty(vizHighlighted)) {
            return; // if link is highlighted, disable mouseover effects
          }

          tickLabels.style('opacity', t => {
            if (t === d) {
              // if current label is being hovered over, pop it out and hide the rest
              return normalOpacity;
            }

            return 0;
          });
        })
        .on('mouseout', d => {
          onUnHoverTickText();

          if (!_.isEmpty(vizHighlighted)) {
            return; // if link is highlighted, disable mouseout effects
          }

          tickLabels.style('opacity', (t, i) => getTickLabelOpacity(vizHighlighted, d, t, i, fadeInterval));
        });
    });

    // y axis titles
    selection
      .select('.il-pcDimension-text')
      .style('text-anchor', 'middle')
      .style('font-size', titleFontSize)
      .attr('y', -30)
      .text(d => {
        switch (d) {
          case 'source':
            return SessionStore.isEdge() ? intl('Common.Source') : intl('Common.Consumer');
          case 'target':
            return SessionStore.isEdge() ? intl('Common.Destination') : intl('Common.Provider');
          case 'port':
            return SessionStore.isEdge() ? intl('Common.DestinationPort') : intl('Common.ProviderPort');
          case 'processName':
            return SessionStore.isEdge()
              ? intl('Explorer.DestinationProcessService')
              : intl('Explorer.ProviderProcessService');
          case 'outboundProcessName':
            return SessionStore.isEdge()
              ? intl('Explorer.SourceProcessService')
              : intl('Explorer.ConsumerProcessService');
          default:
            return _.upperFirst(d);
        }
      });

    // y axis subtitles - depends on entity type
    selection
      .select('.il-pcDimension-subtext')
      .style('text-anchor', 'middle')
      .style('font-size', subtitleFontSize)
      .attr('y', -13)
      .text(d => {
        // make subtext domain name if clicked on domain
        if (selected[d] === 'ip' && this.props.clickedTick[d].clickedLabel === 'domain') {
          return this.props.clickedTick[d].clickedValue;
        }

        switch (selected[d]) {
          case 'role':
            return intl('Common.Roles');
          case 'app':
            return SessionStore.isEdge() ? '' : intl('Common.Applications');
          case 'env':
            return SessionStore.isEdge() ? '' : intl('Common.Environments');
          case 'loc':
            return intl('Common.Locations');
          case 'group':
            return SessionStore.isEdge() ? intl('Common.Groups') : intl('Common.AppGroups');
          case 'workload':
            return VizUtils.getCurentWorkloadType()[d].length
              ? VizUtils.getCurentWorkloadType()[d].length === 1
                ? VizUtils.getCurentWorkloadType()[d][0]
                : intl('Common.Types')
              : intl('Common.Workloads');
          case 'virtual_service':
            return intl('Common.VirtualServices');
          case 'virtual_server':
            return intl('Common.VirtualServers');
          case 'ip_list':
            return intl('Common.IPLists');
          case 'ip':
            return intl('IPLists.IPAddresses');
          case 'all':
            return 'All';
          default:
            return _.upperFirst(selected[d]);
        }
      });
  },

  clickTickText(selection, clickTickTextFunc, dimension) {
    selection.on('click', d => {
      const mouseXPos = d3.event.clientX;
      let mouseYPos;

      //parentElement.parentElement gets .il-pdDimension-axis y-coord, instead of il-pcDimension-text.
      if (d3 && d3.event && d3.event.parentElement && d3.event.target.parentElement.parentElement) {
        mouseYPos = d3.event.clientY - d3.event.target.parentElement.parentElement.getBoundingClientRect().top;
      } else {
        mouseYPos = d3.event.clientY;
      }

      clickTickTextFunc(d, dimension, mouseXPos, mouseYPos);
    });
  },

  handleUpdateLabel(type, evt) {
    if (evt && evt.target) {
      const label = evt.target.value;

      ExplorerActions.choosePCLabel(type, label);
    }
  },

  render() {
    const {
      data,
      onUpdatePortSorting,
      onUpdateProcessNameSorting,
      onUpdateOutboundProcessNameSorting,
      portSorting,
      processNameSorting,
      outboundProcessNameSorting,
      selected,
    } = this.props;

    return (
      <g className="il-pcDimension">
        <g className="il-pcDimension-axis">
          <text className="il-pcDimension-text" />
          {data !== 'port' && <text className="il-pcDimension-subtext" />}
        </g>
        {(data === 'source' || data === 'target') && !SessionStore.isEdge() && (
          <foreignObject
            className="il-pcDimension-icon"
            x="-70"
            y="-25"
            width="200"
            height="20"
            style={LabelDict[selected[data]] ? null : {display: 'none'}}
          >
            <select
              className="il-pcDimension-icon-select"
              value={selected[data]}
              onChange={evt => this.handleUpdateLabel(data, evt)}
            >
              <option value="env">{intl('Common.Environment')}</option>
              <option value="loc">{intl('Common.Location')}</option>
              <option value="app">{intl('Common.Application')}</option>
            </select>
          </foreignObject>
        )}
        {data === 'port' && (
          <foreignObject className="il-pcDimension-icon" x="-70" y="-25" width="200" height="20">
            <select className="il-pcDimension-icon-select" value={portSorting} onChange={onUpdatePortSorting}>
              <option value="portNumber">{intl('Explorer.SortByPortNumber')}</option>
              <option value="flowNumber">{intl('Explorer.SortByNumberOfFlows')}</option>
            </select>
          </foreignObject>
        )}
        {data === 'processName' && (
          <foreignObject className="il-pcDimension-icon" x="-70" y="-25" width="200" height="20">
            <select
              className="il-pcDimension-icon-select"
              value={processNameSorting}
              onChange={onUpdateProcessNameSorting}
            >
              <option value="processOrServiceName">{intl('Explorer.SortByProcessName')}</option>
              <option value="flowNumber">{intl('Explorer.SortByNumberOfFlows')}</option>
            </select>
          </foreignObject>
        )}
        {data === 'outboundProcessName' && (
          <foreignObject className="il-pcDimension-icon" x="-70" y="-25" width="200" height="20">
            <select
              className="il-pcDimension-icon-select"
              value={outboundProcessNameSorting}
              onChange={onUpdateOutboundProcessNameSorting}
            >
              <option value="outboundProcessOrServiceName">{intl('Explorer.SortByProcessName')}</option>
              <option value="flowNumber">{intl('Explorer.SortByNumberOfFlows')}</option>
            </select>
          </foreignObject>
        )}
      </g>
    );
  },
});
