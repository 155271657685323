/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import GraphTransformStore from '../stores/GraphTransformStore';

export default {
  defaultX: 0,
  defaultY: 0,
  labelMargin: {top: 5, left: 10},
  duration: 750, //remove the transition effect for zoom to fit
  textSize: 15,
  numSize: 15,
  showLabelSize: 90, // the size to show group label
  showTokenSize: 40,
  overviewSize: 8,
  hoverWidth: 42,
  hoverHeight: 30,
  defaultDistance: 200,
  fullClusterScale: 36, // below this value, cluster labels doesn't have semantic zooming in full map
  grayDotScale: 0.08, // below this value, gray dot doesn't have semantic zooming

  enter(mapLevel, selection) {
    if (selection.datum().tween) {
      this.positionAttrTween(selection);
    } else {
      this.position(selection, selection.datum(), true, mapLevel);
    }

    this.addTitle(selection);
  },

  update(truncated, mapLevel, linearAnimation, diffY, selection) {
    const data = selection.datum();
    const scale = GraphTransformStore.getTransform().scale;
    const realDistance = this.defaultDistance * scale;
    const isOverviewScale = data.displayType !== 'full' && !data.isLegend && realDistance < this.showTokenSize;
    const sizeScale =
      data.displayType === 'full' ||
      data.isLegend ||
      (data.displayType !== 'full' && isOverviewScale && scale < this.grayDotScale)
        ? 1
        : scale;
    const isExpandedCluster = mapLevel === 'workload' && data.displayType === 'full';

    // if location need to be truncated
    selection.call(this.emptyGroupText);
    // color group background if it's selected
    selection.call(this.selectCluster);
    // color group background if it's hovered
    selection.call(_.bind(this.hoverCluster, this, isOverviewScale, sizeScale));

    // set opacity of group if it's truncated (if a big number of groups, gray out the groups)
    if (truncated) {
      selection.attr('opacity', 0.3);
    } else {
      selection.attr('opacity', 1);
    }

    // draw workload number
    this.drawWorkloadNum(selection, sizeScale, isOverviewScale);
    // draw rectangle for groups
    this.drawRect(selection, scale, sizeScale, isOverviewScale, isExpandedCluster);

    // draw or ignore group labels
    const showLabels =
      (!data.isLegend &&
        data.href !== 'discovered' &&
        data.href !== 'no_location' &&
        realDistance >= this.showLabelSize &&
        !truncated) ||
      (data.displayType === 'full' && scale > 0.25);

    if (showLabels) {
      this.drawLabels(selection, data, this.duration, scale, mapLevel);
    } else {
      this.ignoreLabels(selection);
    }

    // set the position of groups for the whole svg
    this.position(selection, data, linearAnimation, mapLevel, diffY);
    // add title for group when hovering
    this.addTitle(selection);
  },

  addTitle(selection) {
    selection.select('.il-cluster-title').text(d => d.name);
  },

  emptyGroupText(selection) {
    const datum = selection.datum();
    const scale = GraphTransformStore.getTransform().scale;

    selection
      .select('.il-empty-group-msg')
      .selectAll('.il-empty-text')
      .attr('text-anchor', 'middle')
      .attr('font-size', 24)
      .attr('x', datum.x)
      .attr('y', () => scale)
      .attr('opacity', 0.5);
  },

  drawWorkloadNum(selection, sizeScale, isOverviewScale) {
    // workloads number in each group in loc view
    selection
      .select('.il-cluster-workloadsNum')
      .transition()
      .duration(d => (d.hovered === 'mouseovered' || !d.hovered ? 0 : this.duration))
      .attr('text-anchor', 'middle')
      .attr('dy', '.60em')
      .attr('font-size', this.numSize / sizeScale)
      .attr('font-weight', 'bold')
      .attr('y', -(this.numSize / sizeScale) / 2 + 4 / sizeScale)
      .attr('display', () => (isOverviewScale ? 'none' : 'block'))
      .style('fill', '#277cb2')
      .style('pointer-events', 'none');
  },

  drawRect(selection, scale, sizeScale, isOverviewScale, isExpandedCluster) {
    selection
      .select('.il-cluster-type')
      .attr('stroke-width', () => (isOverviewScale ? 2 / sizeScale : 3 / sizeScale))
      .attr('stroke-dasharray', d => {
        const clusterWorkloadsIsReadable = d.isLegend || (d.caps && d.caps.workloads.includes('read'));

        if (isOverviewScale || d.policyState !== 'selective' || !clusterWorkloadsIsReadable) {
          return '';
        }

        return 6 / sizeScale + ',' + 6 / sizeScale;
      })
      .attr('stroke', d => {
        const clusterWorkloadsIsReadable = d.caps && d.caps.workloads.includes('read');

        if (
          (d.isLegend || clusterWorkloadsIsReadable) &&
          (d.policyState === 'enforced' || d.policyState === 'selective')
        ) {
          return '#435e69';
        }

        return '#b6c5cc';
      })
      .attr('fill', d => {
        if (isOverviewScale) {
          // if zoom to fit, the fill is gray for tokens
          return '#dee1e3';
        }

        if (d.connectionType === 'focused') {
          return '#fafefe';
        }

        if (d.connectionType === 'providing' || d.connectionType === 'consuming') {
          return '#f6fafe';
        }

        // if cluster is expanded, use yellow as background
        // otherwise white background
        return isExpandedCluster ? '#fafefe' : '#fafefe';
      })
      .attr('width', d => {
        const width = this.getWidth(d, scale, isOverviewScale);

        return width / sizeScale;
      })
      .attr('height', d => {
        const height = this.getHeight(d, scale, isOverviewScale);

        return height / sizeScale;
      })
      .attr('rx', d => {
        const height = this.getHeight(d, scale, isOverviewScale);

        return height / sizeScale / 2;
      })
      .attr('ry', d => {
        const height = this.getHeight(d, scale, isOverviewScale);

        return height / sizeScale / 2;
      })
      .attr('x', d => {
        const width = this.getWidth(d, scale, isOverviewScale);

        return -(width / sizeScale) / 2;
      })
      .attr('y', d => {
        const height = this.getHeight(d, scale, isOverviewScale);

        return -(height / sizeScale) / 2;
      });
  },

  drawLabels(selection, data, duration, scale, mapLevel) {
    if (!data.name) {
      return;
    }

    // label truncated more in leveled map
    const showTextLength =
      data.displayType === 'full'
        ? mapLevel === 'connectedAppGroup' || mapLevel === 'focusedAppGroup'
          ? data.width / 14
          : data.width / 25
        : 13;
    const truncatedAt = showTextLength * scale * (data.width > 1200 ? 2 : data.width < 500 ? 0.75 : 1);
    const name = data.name
      .split(' | ')
      .reduce((result, label) => {
        let res = label.length <= truncatedAt ? label : label.slice(0, truncatedAt);

        if (res !== label) {
          res += '...';
        }

        result.push(res);

        return result;
      }, [])
      .join(' | ');

    // show labels if the distance between groups is larger than showLabelSize
    // y position cal is different because full size group and token group's origin are different
    // add extra space between rect and label (original-y-location + scale)
    selection
      .select('.il-cluster-label')
      .select('.il-cluster-label-text')
      .attr('x', 0)
      .attr('y', d =>
        d.displayType === 'full' ? data.height / 2 + 20 / scale + scale : (data.height + this.labelMargin.top) / scale,
      )
      .attr('font-size', this.getLabelFontSize(scale, mapLevel, data))
      .attr('display', 'block')
      .style('pointer-events', 'fill');

    selection.select('.il-cluster-name').text(name);

    selection
      .select('.il-cluster-label')
      .select('.il-cluster-vulnerability')
      .attr('dx', '.35em')
      .attr('dy', '.35em')
      .attr('font-size', this.getLabelFontSize(scale, mapLevel, data))
      .attr('display', 'block')
      .style({'pointer-events': 'fill', 'font-weight': '400'});
  },

  getLabelFontSize(scale, mapLevel, data) {
    if (mapLevel === 'full' && 50 * scale < this.fullClusterScale) {
      return this.textSize / scale - 2;
    }

    if (mapLevel === 'group' || (mapLevel === 'workload' && data.displayType !== 'full')) {
      return this.textSize / (scale * 1.2);
    }

    return this.textSize / scale + 5;
  },

  ignoreLabels(selection) {
    selection.select('.il-cluster-label').select('.il-cluster-label-text').attr('display', 'none'); // show labels if the distance between groups is larger than showLabelSize
  },

  position(selection, data, linearAnimation, mapLevel, diffY) {
    if (mapLevel === 'connectedAppGroup' && !linearAnimation) {
      diffY ||= 0;
      // the whole selection's transform need return 0
      selection.attr('transform', 'translate(' + this.defaultX + ',' + this.defaultY + ')');

      selection.select('.il-cluster-type').attr('transform', d => {
        const transformX = d.focused ? 0 : data.x;
        const transformY = d.focused ? 0 : data.y + diffY;

        return 'translate(' + transformX + ',' + transformY + ')';
      });

      selection
        .select('.il-cluster-others')
        .attr('transform', 'translate(' + (data.x || this.defaultX) + ',' + (data.y || this.defaultY) + ')');
    } else {
      // reset transform values for rect and others in clusters
      selection.select('.il-cluster-type').attr('transform', 'translate(' + this.defaultX + ',' + this.defaultY + ')');
      selection
        .select('.il-cluster-others')
        .attr('transform', 'translate(' + this.defaultX + ',' + this.defaultY + ')');
      // linearly transform to d.x and d.y
      selection.attr('transform', d => 'translate(' + (d.x || this.defaultX) + ',' + (d.y || this.defaultY) + ')');
    }
  },

  // expanded group's animation is along with a path
  positionAttrTween(selection) {
    const datum = selection.datum();
    const points = [
      [datum.px, datum.py],
      [(datum.px + datum.x) / 2, datum.y],
      [datum.x, datum.y],
    ];
    // the path is used to calculate appGroup animation
    const path = selection
      .select('.il-cluster-animation-path')
      .data([points])
      .attr('d', d3.svg.line().interpolate('basis'))
      .style('display', 'none');

    // The duration time is shorter than this.duration
    // to make sure this transition is done before group data is updated.
    selection
      .select('.il-cluster-type')
      .transition()
      .duration(100)
      .attrTween('transform', () => {
        const l = path.node().getTotalLength();

        return t => {
          const p = path.node().getPointAtLength(t * l);

          return 'translate(' + p.x + ',' + p.y + ')';
        };
      });
  },

  // all interactions including select, hover, drag
  selectCluster(selection) {
    selection.select('.il-cluster-type').attr('filter', d => (d.selected ? 'url(#selectFilter)' : 'none'));
  },

  hoverCluster(isOverviewScale, sizeScale, selection) {
    const selectedRec = selection.select('.il-cluster-policyState').empty()
      ? selection.select('.il-cluster-type')
      : selection.select('.il-cluster-policyState');

    selectedRec
      .attr('filter', d => {
        if (d && d.selected) {
          return 'url(#selectFilter)';
        }

        if (d && d.hovered === 'hovered') {
          return 'url(#hoverFilter)';
        }

        return 'none';
      })
      .style('fill', '#fafefe')
      .style('fill-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1))
      .style('stroke-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));

    selection.select('.il-cluster-workloadsNum').style('fill-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));

    selection
      .select('.il-cluster-label')
      .style('pointer-events', 'none')
      .style('fill-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));
  },

  // This is just for stop panning event when drag the cluster now
  // TODO: I think we should have stop click cluster event as well
  dragCluster(selection, dragMove, dragEnd) {
    const drag = d3.behavior
      .drag()
      .on('dragstart', () => {
        d3.event.sourceEvent.stopPropagation();
        document.dispatchEvent(new Event('mousedown')); // for triggering menu-drop down close
      })
      .on('drag', () => {
        dragMove(d3.event.dx, d3.event.dy);
      })
      .on('dragend', () => {
        d3.event.sourceEvent.stopPropagation();
        dragEnd();
      });

    selection.call(drag);
  },

  // help function to get width and height
  getWidth(d, scale, isOverviewScale) {
    let width;

    if (isOverviewScale) {
      width = scale < this.grayDotScale ? this.overviewSize / this.grayDotScale : this.overviewSize;
    } else if (d.hovered === 'mouseovered' && d.displayType !== 'full') {
      width = this.hoverWidth;
    } else {
      width = d.width;
    }

    return width;
  },

  getHeight(d, scale, isOverviewScale) {
    let height;

    if (isOverviewScale) {
      height = scale < this.grayDotScale ? this.overviewSize / this.grayDotScale : this.overviewSize;
    } else if (d.hovered === 'mouseovered' && d.displayType !== 'full') {
      height = this.hoverHeight;
    } else {
      height = d.height;
    }

    return height;
  },
};
